/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

// Layout
import PageLayout from "examples/LayoutContainers/PageLayout";

// Auth context
import { UserAuth } from "context/AuthContext";

// Firestore
import { db } from "firebase-config";
import { collection, doc, setDoc } from "firebase/firestore";

// Images
import logo from "assets/images/logo-tree.png";

// Material Dashboard 2 PRO React context
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";

function NewUser() {
  const [gradeV, setGrade] = useState();
  const [stateV, setState] = useState();
  const [genderV, setGender] = useState();
  // const [raceV, setRace] = useState([]);
  const [studentV, setStudent] = useState();
  // const [ethnicityV, setEthnicity] = useState();
  const [dateOfBirthV, setDOB] = useState();
  const [dobError, setDobError] = useState(false);
  const { user, updateDisplayName } = UserAuth();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const navigate = useNavigate();

  // Datepicker error
  const errorProps = dobError
    ? {
        helperText: "Input is required",
        error: true,
      }
    : {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newUser = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      email: user.email,
      student: studentV,
      grade: gradeV || "",
      state: stateV,
      gender: genderV,
      // ethnicity: ethnicityV,
      // race: raceV,
      dateOfBirth: dateOfBirthV,
    };
    try {
      await updateDisplayName(newUser.firstName);
      await setDoc(doc(collection(db, "users"), user.uid), newUser, { merge: true });
      navigate("/studio");
    } catch (event) {
      // console.log(event.message);
      if (!dateOfBirthV) {
        setDobError(true);
      }
    }
  };

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  return (
    <PageLayout>
      <MDBox mt={{ xs: 4, md: 6 }} mb={{ xs: 4, md: 6 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={8}>
            <Card>
              {/* Invoice header */}
              <MDBox p={3}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12}>
                    <MDBox component="img" src={logo} width="3.5rem" p={1} mb={1} />
                  </Grid>
                  <Grid item alignItems="center" justifyContent="center" xs={12}>
                    <MDBox width="100%" textAlign="center" mt={-2}>
                      <MDTypography variant="h1" fontWeight="medium">
                        Welcome to EDYou!
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>{" "}
              </MDBox>
              <MDBox px={6} mb={2} width="100%" textAlign="center">
                <MDTypography variant="h4" fontWeight="medium">
                  To get started please tell us a bit about yourself.
                </MDTypography>
              </MDBox>
              <MDBox component="form" onSubmit={handleSubmit} role="form">
                <Grid container spacing={3} px={12} mt={1.625}>
                  <Grid item xs={12} md={6}>
                    <MDBox>
                      <MDInput
                        type="text"
                        inputRef={firstNameRef}
                        label="First Name"
                        required
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="text"
                      inputRef={lastNameRef}
                      label="Last Name"
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} px={12} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={studentV || null}
                      onChange={(event, newStudent) => {
                        setStudent(newStudent);
                      }}
                      options={["Yes", "No"]}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="outlined"
                          required
                          label="Are You a Student?"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={gradeV || null}
                      disabled={studentV !== "Yes"}
                      required
                      onChange={(event, newGrade) => {
                        setGrade(newGrade);
                      }}
                      options={[
                        "1st Grade",
                        "2nd Grade",
                        "3rd Grade",
                        "4th Grade",
                        "5th Grade",
                        "6th Grade",
                        "7th Grade",
                        "8th Grade",
                        "9th Grade",
                        "10th Grade",
                        "11th Grade",
                        "12th Grade",
                        "Other",
                      ]}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="outlined"
                          required={studentV === "Yes"}
                          label="Grade"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} px={12} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={stateV || null}
                      onChange={(event, newState) => {
                        setState(newState);
                      }}
                      options={states}
                      renderInput={(params) => (
                        <MDInput {...params} variant="outlined" required label="State" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={genderV || null}
                      onChange={(event, newGender) => {
                        setGender(newGender);
                      }}
                      options={["Male", "Female", "Non-Binary", "Prefer Not to Disclose"]}
                      renderInput={(params) => (
                        <MDInput {...params} variant="outlined" required label="Gender" />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container spacing={3} px={12} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={ethnicityV || null}
                      onChange={(event, newEthnicity) => {
                        setEthnicity(newEthnicity);
                      }}
                      options={[
                        "Hispanic or Latino",
                        "Not Hispanic or Latino",
                        "Prefer Not to Disclose",
                      ]}
                      renderInput={(params) => (
                        <MDInput {...params} variant="outlined" required label="Ethnicity" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      multiple
                      value={raceV || []}
                      onChange={(event, newRace) => {
                        setRace(newRace);
                      }}
                      options={[
                        "American Indian or Alaska Native",
                        "Asian",
                        "Black or African American",
                        "Native Hawaiian or Other Pacific Islander",
                        "White",
                        "Prefer Not to Disclose",
                      ]}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          required={raceV.length === 0}
                          label={raceV.length === 0 ? "Race" : "Race *"}
                          variant="outlined"
                          helperText="Select One or More"
                        />
                      )}
                    />
                  </Grid>
                </Grid> */}
                <Grid container item xs={12} px={12} mt={4}>
                  <MDDatePicker
                    value={dateOfBirthV}
                    onChange={(event, newDOB) => {
                      setDobError(false);
                      setDOB(newDOB);
                    }}
                    input={{
                      label: !dateOfBirthV ? "Date of Birth" : null,
                      required: true,
                      fullWidth: true,
                      ...errorProps,
                    }}
                  />
                </Grid>
                <MDBox textAlign="center" pt={6} mb={8}>
                  <MDButton type="submit" sx={{ width: "25%" }} variant="gradient" color="primary">
                    Get Started
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default NewUser;
