/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
// import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

export default function programsData() {
  const { state } = useLocation();
  const currentProgram = state.selectedProgram;
  const programProgress = state.selectedProgramHistory || null;
  let counter = 1;

  const defaultRows = [
    {
      name: (
        <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
          Loading Concepts ...
        </MDTypography>
      ),
      order: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          ...
        </MDTypography>
      ),
      /* completed: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          ...
        </MDTypography>
      ), */
    },
  ];

  const [rows, setRows] = useState(defaultRows);

  useEffect(() => {
    // declare the data fetching function
    const createRows = async () => {
      // some needed variables
      let pRows;
      if (programProgress) {
        const conceptKeys = Object.keys(programProgress);
        conceptKeys.splice(conceptKeys.indexOf("index"), 1);
        // let conceptArray = [];
        const intermediate = currentProgram.conceptList.map((concept) =>
          // eslint-disable-next-line prefer-object-spread
          Object.assign({}, concept)
        );
        const conceptArray = intermediate.map((concept) => {
          const completed = { completed: programProgress[concept.uid].completed };
          const newArray = Object.assign(concept, completed);
          return newArray;
        });

        // Print the info on all programs
        pRows = conceptArray.map((concept) => {
          // eslint-disable-next-line prefer-destructuring
          const isThisConcept = (element) => element.uid === concept.uid;
          const conceptIndex = currentProgram.conceptList.findIndex(isThisConcept);
          const selectedConceptListed = currentProgram.conceptList[conceptIndex];
          const selectedConcept = (({ completed, ...o }) => o)(selectedConceptListed);
          const proto = {
            name: (
              <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
                {selectedConcept.name}
              </MDTypography>
            ),
            order: (
              <MDTypography
                component="a"
                href="#"
                variant="button"
                color="inherit"
                fontWeight="medium"
              >
                {counter}
              </MDTypography>
            ),
            // make color white when not completed
            /* completed: (
              <CheckCircleOutlineOutlinedIcon
                color={concept.completed ? "primary" : "white"}
                fontSize="small"
              />
            ), */
          };
          counter += 1;
          return proto;
        });
      } else {
        pRows = currentProgram.conceptList.map((concept) => {
          const conceptIndex = currentProgram.conceptList.indexOf(concept);
          const selectedConcept = currentProgram.conceptList[conceptIndex];
          const proto = {
            name: (
              <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
                {selectedConcept.name}
              </MDTypography>
            ),
            order: (
              <MDTypography
                component="a"
                href="#"
                variant="button"
                color="inherit"
                fontWeight="medium"
              >
                {counter}
              </MDTypography>
            ),
            // make color white when not completed
            // completed: <CheckCircleOutlineOutlinedIcon color="white" fontSize="small" />,
          };
          counter += 1;
          return proto;
        });
      }
      setRows(pRows);
    };

    // call the function
    createRows()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const columns = [
    { Header: "concept name", accessor: "name", align: "left" },
    { Header: "order", accessor: "order", width: "12%", align: "center" },
    // { Header: "completed", accessor: "completed", width: "15%", align: "center" },
  ];

  return {
    columns,
    rows,
  };
}
