/* eslint-disable no-unused-vars */
// React
import { useState, useEffect, useLayoutEffect } from "react";

// MUI
import { Card, Pagination } from "@mui/material";

// Firestore
import { db, rdb } from "firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { child, get, ref } from "firebase/database";

// Auth Context
import { UserAuth } from "context/AuthContext/index";

// components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleFooter from "examples/Footer/SimpleFooter";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import CardDisplay from "./components/CardsDisplay";
import QuizFilter from "./components/QuizFilter";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

function QuizStore() {
  const { user } = UserAuth();
  const [loading, setLoading] = useState(false);
  const [quizData, setQuizData] = useState([]);
  const [activeQuizzes, setActiveQuizzes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [width] = useWindowSize();
  const [filters, setFilters] = useState({
    targetAudience: ["General"],
    categories: [],
  });

  // Firestore Quiz Info Fetch
  useEffect(() => {
    const getProgramData = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "programs"));
      const receivedData = querySnapshot.docs.map((doc) => doc.data());
      const quizzes = receivedData.filter((program) => program.quiz === "Yes");
      setQuizData(quizzes);
      setLoading(false);
    };

    getProgramData();
  }, []);

  // RDB Quiz History Fetch
  useEffect(() => {
    const getQuizHistory = async () => {
      const database = ref(rdb);
      await get(child(database, `quizHistory/${user.uid}`)).then((snapshot) => {
        if (snapshot.exists()) {
          const quizSnapshot = snapshot.val();
          const activeKeys = Object.keys(quizSnapshot);
          const keysWithIndex = activeKeys.map((key) => {
            const activeQuiz = { programId: key, quizProgress: quizSnapshot[key] };
            return activeQuiz;
          });
          setActiveQuizzes(keysWithIndex);
        } else {
          setActiveQuizzes([]);
        }
      });
    };
    getQuizHistory();
    setLoading(false);
  }, []);

  // eslint-disable-next-line no-nested-ternary
  const cardsPerPage = width > 1199 ? 8 : width < 1200 && width > 799 ? 6 : 4;

  const pageCount = Math.ceil(quizData.length / cardsPerPage);
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = quizData.slice(indexOfFirstCard, indexOfLastCard);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar isMini />
      <MDBox pt={6} pb={6}>
        <Card sx={{ minHeight: "40.5rem" }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={4}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5" color="white">
              Quizzes
            </MDTypography>
          </MDBox>
          <MDBox p={5}>
            <QuizFilter setFilters={setFilters} />
            <MDBox width="100%">
              <CardDisplay
                filters={filters}
                quizData={currentCards}
                activeQuizzes={activeQuizzes}
                loading={loading}
              />
            </MDBox>
            <MDBox pt={3} display="flex" width="100%" justifyContent="center">
              <Pagination
                color="info"
                count={pageCount}
                page={currentPage}
                onChange={handleChange}
              />
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <SimpleFooter />
    </DashboardLayout>
  );
}

export default QuizStore;
