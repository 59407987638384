/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import MDBox from "components/MDBox/index";
import { MathJax } from "better-react-mathjax";

export default function MathLiveElement({ element, children }) {
  return (
    <MDBox display="flex" justifyContent={element.align || "center"}>
      {children[0] === "" ? <br /> : children[0]}
      <MDBox
        display="flex"
        sx={{ position: "relative", justifyContent: element.align ? element.align : "left" }}
      >
        <MathJax inline>{`\\(${element.mathText}\\)`}</MathJax>
      </MDBox>
    </MDBox>
  );
}

MathLiveElement.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    align: PropTypes.string,
    mathText: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};
