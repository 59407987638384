/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import { Backdrop, Modal, Fade, Icon } from "@mui/material";
import { useSlateStatic } from "slate-react";
// eslint-disable-next-line import/no-unresolved, import/extensions, import/no-absolute-path
// import "//unpkg.com/mathlive";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { PropTypes } from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxWidth: "50rem",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function ModalElements(props) {
  const { handleClose } = props;
  const mf = useRef("");

  useEffect(() => {
    window.mathVirtualKeyboard.container = document.getElementById("mathModal");
  }, []);

  return (
    <>
      {/* eslint-disable-next-line react/style-prop-object */}
      <math-field
        style={{ display: "block", padding: "2px" }}
        ref={mf}
        onInput={(evt) => {
          window.mathVirtualKeyboard.show();
        }}
      >
        {mf.current ? mf.current.value : ""}
      </math-field>
      <MDButton onClick={() => handleClose(mf.current.value)}>Submit</MDButton>
    </>
  );
}

ModalElements.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

function MathModal(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    window.mathVirtualKeyboard.show();
    onClose();
  };

  const handleSubmitClose = (mathInput) => {
    window.mathVirtualKeyboard.show();
    onClose(mathInput);
  };

  return (
    <Modal
      id="mathModal"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={style}>
          <ModalElements handleClose={handleSubmitClose} />
        </MDBox>
      </Fade>
    </Modal>
  );
}

MathModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function MathModalButton({ insertMath }) {
  const [open, setOpen] = useState(false);
  const editor = useSlateStatic();

  const handleOpen = () => setOpen(true);
  const handleClose = (mathText) => {
    setOpen(false);
    if (mathText) {
      insertMath(editor, mathText);
    }
  };

  return (
    <MDBox>
      <MDButton iconOnly onClick={handleOpen}>
        <Icon>calculate</Icon>
      </MDButton>
      <MathModal open={open} onClose={handleClose} />
    </MDBox>
  );
}

MathModalButton.propTypes = {
  insertMath: PropTypes.func.isRequired,
};
