/* eslint-disable react/require-default-props */
import MDTypography from "components/MDTypography/index";
import MDBox from "components/MDBox/index";
import { Grid, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import PropTypes from "prop-types";

function TestingResourcePreview(props) {
  const { answerChoices, correctAnswer, text, question, picture } = props;
  return (
    <MDBox display="flex">
      <Grid container justifyContent="center" alignItems="center">
        {(text || picture) && (
          <Grid item xs={12} md={6}>
            {picture && (
              <MDBox
                component="img"
                sx={{
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                alt=""
                src={URL.createObjectURL(picture)}
              />
            )}
            {text && <MDTypography>{text}</MDTypography>}
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <MDTypography fontWeight="bold" pb={2}>
            {question}
          </MDTypography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={correctAnswer || ""}
              name="radio-buttons-group"
            >
              {answerChoices.map((answer) => (
                <FormControlLabel key={answer} value={answer} control={<Radio />} label={answer} />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default TestingResourcePreview;

TestingResourcePreview.propTypes = {
  answerChoices: PropTypes.arrayOf(PropTypes.string).isRequired,
  correctAnswer: PropTypes.string.isRequired,
  text: PropTypes.string,
  question: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  picture: PropTypes.any,
};
