/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useRef, useState } from "react";

// @mui material components
import { Autocomplete, Stack, Alert } from "@mui/material";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Auth Context
import { UserAuth } from "context/AuthContext";

// Firebase
import { db } from "firebase-config";
import { collection, addDoc } from "firebase/firestore";

function ContactForm() {
  const { user } = UserAuth();
  const [reasonV, setReason] = useState();
  const messageRef = useRef();
  const [successSB, setSuccessSB] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [key1, setKey1] = useState("Key 1");
  const [key2, setKey2] = useState("Key 2");

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success!"
      content="Your message has been sent. Someone will get back to you shortly!"
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const message = messageRef.current.value;
      const mail = {
        from: "contact-us@edyou.org",
        replyTo: user.email,
        to: "contact-us@edyou.org",
        message: {
          subject: reasonV,
          html: `<p>From: ${user.firstName} ${user.lastName} (${user.uid})</p>
           <p>${message}</p>`,
        },
      };
      await addDoc(collection(db, "mail"), mail);
      openSuccessSB();
      messageRef.current.value = "";
      if (key1 === "Key 1") {
        setKey1("Not Key 1");
      } else {
        setKey1("Key 1");
      }
      if (key2 === "Key 2") {
        setKey2("Not Key 2");
      } else {
        setKey2("Key 2");
      }
    } catch (event) {
      setError(event);
    }
    setLoading(false);
  };

  return (
    <MDBox py={{ xs: 3, lg: 6 }} px={{ xs: 6, md: 12 }}>
      <MDBox pt={0.5} pb={3} px={3} component="form" role="form" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Autocomplete
            key={key2}
            options={[
              "Issues With My Account",
              "Billing and Subscription Status",
              "Technical Support",
              "Request Moderator Status",
              "Request a New Concept",
              "Feedback",
              "Other",
            ]}
            fullWidth
            onChange={(event, reason) => {
              setReason(reason);
            }}
            renderInput={(params) => (
              <MDInput
                {...params}
                placeholder="- select -"
                required
                label="Reason"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
          />
          <MDInput
            variant="outlined"
            label="Your message"
            placeholder="I want to say that..."
            required
            inputRef={messageRef}
            InputLabelProps={{ shrink: true }}
            fullWidth
            multiline
            rows={6}
          />
          {error && <Alert color="error">{error.message}</Alert>}
          <MDBox textAlign="right">
            <MDButton disabled={loading} fullWidth variant="gradient" color="primary" type="submit">
              Send Message
            </MDButton>
          </MDBox>
          {renderSuccessSB}
        </Stack>
      </MDBox>
    </MDBox>
  );
}

export default ContactForm;
