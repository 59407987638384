/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useNavigate } from "react-router-dom";

// @mui material components
import { Grid, Card, Stack } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleFooter from "examples/Footer/SimpleFooter";
import DataTable from "layouts/LearningStudio/MyAccount/MyProgress/components/DataTable";

// Components
import recentHistoryData from "./components/RecentHistoryData";
import UserStatistics from "./components/UserStatistics";

function MyProgress() {
  const navigate = useNavigate();
  const { columns: pColumns, rows: pRows } = recentHistoryData();

  return (
    <DashboardLayout>
      <DashboardNavbar isMini />
      <MDBox pt={6} pb={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  My Progress
                </MDTypography>
              </MDBox>
              <MDBox pt={6} px={3}>
                <UserStatistics />
              </MDBox>
              <MDBox textAlign="center" px={6} pt={6} pb={3}>
                <MDTypography pt={3} py={5} variant="h3">
                  Learning Progress
                </MDTypography>
                <Stack direction="row" spacing={4} justifyContent="center">
                  <MDButton
                    variant="contained"
                    onClick={() => navigate("/studio/my-progress/learning-progress")}
                    color="info"
                  >
                    See Progress By Concept
                  </MDButton>
                  <MDButton
                    variant="contained"
                    onClick={() => navigate("/studio/my-progress/quiz-history")}
                    color="info"
                  >
                    See Quiz Performance
                  </MDButton>
                </Stack>
              </MDBox>
              <MDBox textAlign="center" px={6} pt={6} pb={3}>
                <MDTypography py={3} variant="h3">
                  Recent Videos
                </MDTypography>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <MDBox pt={3} pb={1}>
                  <MDButton
                    variant="contained"
                    onClick={() => navigate("/studio/my-progress/video-history")}
                    color="info"
                  >
                    See Full Video History
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <SimpleFooter />
    </DashboardLayout>
  );
}

export default MyProgress;
