/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";

// Material Kit 2 PRO React examples
import DefaultBlogCard from "layouts/CompanyWebsite/Home/components/DefaultBlogCard";

function EducatorCard(props) {
  const { image, name, description, category, route } = props;
  return (
    <MDBox display="flex" height="100%">
      <Grid
        container
        item
        xs={10}
        md={8}
        xl={6}
        sx={{ mx: "auto", px: { xs: 0, lg: 3 } }}
        height="100%"
      >
        <DefaultBlogCard
          image={image}
          category={category}
          title={name}
          description={description}
          action={{
            type: "external",
            route,
          }}
          raised={false}
          height="100%"
        />
      </Grid>
    </MDBox>
  );
}

export default EducatorCard;

EducatorCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  category: PropTypes.shape({
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};
