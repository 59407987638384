/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "layouts/CompanyWebsite/Home/components/SimpleInfoCard";

function FourDifferences() {
  return (
    <MDBox component="section" mt={6} py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={6}
        >
          <MDTypography variant="h2" mb={1}>
            What Makes EDYou Different?
          </MDTypography>
          <MDTypography variant="body1" color="text">
            EDYou&apos;s innovative approach to learning lets you explore at your own pace, follow
            your curiosity, and progress according to your learning goals.
          </MDTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleInfoCard
              color="primary"
              icon="person"
              title="Non-Linear"
              description="EDYou takes a network approach to concept progression so learners can choose their own educational adventure. Three different modes (Program Mode, Explore Mode, Search Mode) allow you to navigate based on your learning goals."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleInfoCard
              color="error"
              icon="support_agent"
              title="Non-Static"
              description="Knowledge changes and EDYou changes with it. EDYou's framework allows learners to always have access to the newest and best educational content, including newly developed methods, content presentation, and subject matter."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleInfoCard
              color="info"
              icon="person"
              title="Personal"
              description="EDYou tracks progresson a concept-by-concept basis, allowing each learner to explore and grow in their own way. We use choice-based mechanisms to put the learner in the driver seat of their own education, creating a uniquely personal experience."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleInfoCard
              color="warning"
              icon="update"
              title="Holistic"
              description="EDYou goes beyond the traditional academic subjects and rote methods. Learners can go off the beaten educational path to explore and master a wider range of topics, multiple approaches, and real-world applications."
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default FourDifferences;
