/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState, useRef } from "react";

// Auth Context
import { UserAuth } from "context/AuthContext";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

function ChangeEmail() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const newEmailRef = useRef();
  const passwordRef = useRef();
  const { user, newEmail, checkCredentials } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setSuccess(false);
      setError("");
      const oldEmail = user.email;
      const password = passwordRef.current.value;
      await checkCredentials(oldEmail, password);
      const newEmailInput = newEmailRef.current.value;
      await newEmail(newEmailInput);
      setSuccess(true);
    } catch (event) {
      setError(event.message);
    }
    setLoading(false);
  };

  return (
    <Card id="change-email">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Account Email</MDTypography>
      </MDBox>
      {error && (
        <Alert pt={2} severity="error">
          {error}
        </Alert>
      )}
      {success && (
        <Alert py={2} severity="success">
          Email updated successfully.
        </Alert>
      )}
      <MDBox component="form" onSubmit={handleSubmit} role="form" pt={2} mb={3}>
        <Grid container spacing={3} px={3}>
          <Grid item xs={12}>
            <MDBox>
              <MDInput
                type="email"
                placeholder="someone@example.com"
                inputRef={newEmailRef}
                label="New Email"
                required
                fullWidth
              />
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDInput
              type="password"
              placeholder="*********"
              inputRef={passwordRef}
              label="Password"
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <MDBox textAlign="right" mr={3} mt={3}>
          <MDButton type="submit" disabled={loading} variant="gradient" color="dark" size="small">
            set new email
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChangeEmail;
