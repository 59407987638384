// React
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

// MUI
import { Grid, Card, IconButton, Icon, Tooltip, Stack } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Contexts
import { setMiniSidenav, useMaterialUIController } from "context/NavContext";
import { UserAuth } from "context/AuthContext";

// Neo4j
import { gql, useQuery } from "@apollo/client";

// Firebase RDB and DB
import { storage, rdb, db } from "firebase-config";
import { getDoc, doc } from "firebase/firestore";
import { ref, update } from "firebase/database";
import { ref as storageRef, getDownloadURL } from "firebase/storage";

// Lodash
import { random, cloneDeep } from "lodash";

// Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout/index";
import MDBox from "components/MDBox/index";
import MDButton from "components/MDButton/index";
import MDTypography from "components/MDTypography/index";
import LevelProgressBar from "./components/LevelProgressBar/index";
import QuizResource from "./components/QuizResource";
import QuizResource2 from "./components/QuizResource copy";
// import ConceptProgressDots from "./components/ConceptProgressDots.js/index";
import SelectReviewVideo from "./components/SelectReviewVideoModal.js/index";
import ReturnToVideos from "./components/ReturnToVideosModal/index";
import ReturnButton from "./components/ReturnButton";

const GET_TESTING_RESOURCE = gql`
  query GetTestingResource($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      name
      block
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
      testingResources {
        uid
        difficulty
        text
        question
        picture
        answerChoices
        correctAnswer
        testedConcepts {
          uid
          name
        }
      }
    }
  }
`;

function QuizViewer() {
  const { state } = useLocation();
  const { user } = UserAuth();
  const { quiz, source } = state;
  const [quizIndex, setQuizIndex] = useState(state.currentIndex || 0);
  const [questionIndex, setQuestionIndex] = useState(state.currentQuestionIndex || 0);
  const [currentTest, setCurrentTest] = useState(state.currentTest);
  const [nextTest, setNextTest] = useState({});
  const [testContent, setTestContent] = useState(state.currentTestContent || {});
  const [nextTestContent, setNextTestContent] = useState({});
  const [currentConcept, setCurrentConcept] = useState(state.currentConcept);
  const [currentConceptSubmitted, setCurrentConceptSubmitted] = useState(
    state.currentConceptSubmitted || 0
  );
  const [currentCorrect, setCurrentCorrect] = useState(state.currentCorrect || 0);
  const [testingResources, setTestingResources] = useState(state.testingResources);
  const [remainingTestingResources, setRemainingTestingResources] = useState(
    state.remainingTestingResources ||
      state.testingResources.filter((test) => test.uid !== state.currentTest.uid)
  );
  const [testPicture, setTestPicture] = useState(state.testPicture || "");
  const [nextTestPicture, setNextTestPicture] = useState("");
  const [completedQuiz, setCompleteQuiz] = useState(false);
  // const [conceptsForReview, setConceptsForReview] = useState([]);
  const [quizState, setQuizState] = useState("quizzing");
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const navigate = useNavigate();
  const [nextConceptIndex, setNextConceptIndex] = useState(quizIndex + 1);
  const [delay, setDelay] = useState(false);
  const nextConcept = quiz.conceptList[nextConceptIndex] || currentConcept;
  const checkUnderstanding = !(
    source === "quiz" ||
    source === "review" ||
    source === "weekly quiz"
  );
  const firstRender = useRef(true);
  const [skipTrigger, setSkipTrigger] = useState(false);

  const handleMiniSidenav = () => {
    setMiniSidenav(dispatch, !miniSidenav);
  };

  const lastQuestion = remainingTestingResources.length === 0;

  /* const levelProgress = (index) => {
    let progress = index;
    // had floor here. not sure why but also not certain i should have changed it
    for (let i = 0; i < Math.ceil(quiz.conceptList.length / 10) && progress >= 10; i += 1) {
      if (progress >= 10) {
        progress -= 10;
      } else {
        break;
      }
    }
    // times 10 to get %
    return progress * 10;
  }; */

  const { loading: loadingNextTestingResources, data: dataNextTestingResources } = useQuery(
    GET_TESTING_RESOURCE,
    {
      variables: {
        uid: nextConcept.uid,
      },
    }
  );

  if (
    !loadingNextTestingResources &&
    dataNextTestingResources &&
    dataNextTestingResources.concepts[0].testingResources.length === 0 &&
    !skipTrigger
  ) {
    setSkipTrigger(true);
  }

  const notify = (toastV) => {
    if (toastV === "concept") {
      return toast(
        // eslint-disable-next-line no-sequences
        "On to the next 🚀🚀!!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
    if (toastV === "correct") {
      return toast.success(
        // eslint-disable-next-line no-sequences
        "Correct!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
    //  if (toastV === "incorrect") {
    return toast.error(
      // eslint-disable-next-line no-sequences
      "Incorrect. Next Concept >",
      {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
    // }
  };

  const getNextContent = async (testId) => {
    const docRef = doc(db, "checks", testId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setNextTestContent(docSnap.data());
    } else {
      setNextTestContent({});
    }
  };

  const getContent = async (testId) => {
    const docRef = doc(db, "checks", testId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setTestContent(docSnap.data());
    } else {
      setTestContent({});
    }
  };

  const handleNextConcept = () => {
    const quizConceptRefPath = `quizHistory/${user.uid}/${quiz.programId}`;
    if (nextConceptIndex === quiz.conceptList.length) {
      setQuizIndex(nextConceptIndex);
      setCompleteQuiz(true);
      update(ref(rdb, quizConceptRefPath), {
        currentConceptIndex: nextConceptIndex,
        completed: true,
      });
    } else {
      update(ref(rdb, quizConceptRefPath), {
        currentConceptIndex: nextConceptIndex,
      });
      setCurrentConcept(dataNextTestingResources.concepts[0]);
      setQuizIndex(nextConceptIndex);
      setTestingResources(dataNextTestingResources.concepts[0].testingResources);
      // setRemainingTestingResources(dataNextTestingResources.concepts[0].testingResources);
      setNextConceptIndex((oldIndex) => oldIndex + 1);
    }
  };

  const handleNextTest = (lag) => {
    if (remainingTestingResources.length > 0) {
      setDelay(true);
      const selectedIndex = random(remainingTestingResources.length - 1);
      const nextNextTest = remainingTestingResources[selectedIndex];
      const newRemainingTestingResources = cloneDeep(remainingTestingResources);
      newRemainingTestingResources.splice(selectedIndex, 1);
      if (lag) {
        setTimeout(() => {
          setCurrentTest(nextTest);
          setTestContent(nextTestContent);
          setNextTest(nextNextTest);
          if (!nextNextTest.question) {
            getNextContent(nextNextTest.uid);
          } else {
            setNextTestContent({});
          }
          setRemainingTestingResources(newRemainingTestingResources);
          setDelay(false);
        }, 3000);
      } else {
        setCurrentTest(nextTest);
        setTestContent(nextTestContent);
        setNextTest(nextNextTest);
        if (!nextNextTest.question) {
          getNextContent(nextNextTest.uid);
        } else {
          setNextTestContent({});
        }
        setRemainingTestingResources(newRemainingTestingResources);
        setDelay(false);
      }
      // const quizConceptRefPath = `quizHistory/${user.uid}/${quiz.programId}/concepts/${currentConcept.uid}`;
    } else {
      handleNextConcept();
    }
    if (nextTest && nextTest.picture) {
      setTestPicture(nextTestPicture);
    } else {
      setTestPicture("");
    }
    setQuizState("quizzing");
  };

  const handleNextConceptTest = () => {
    setDelay(true);
    if (testingResources.length > 0) {
      const selectedIndex = random(testingResources.length - 1);
      const newConceptTest = testingResources[selectedIndex];
      const newRemainingTestingResources = cloneDeep(testingResources);
      newRemainingTestingResources.splice(selectedIndex, 1);
      if (newRemainingTestingResources.length > 0) {
        const newSelectedIndex = random(newRemainingTestingResources.length - 1);
        const newConceptNextTest = newRemainingTestingResources[newSelectedIndex];
        const newNewRemainingTestingResources = cloneDeep(newRemainingTestingResources);
        newNewRemainingTestingResources.splice(newSelectedIndex, 1);
        setNextTest(newConceptNextTest);
        if (!newConceptNextTest.question) {
          getNextContent(newConceptNextTest.uid);
        } else {
          setNextTestContent({});
        }
        setRemainingTestingResources(newNewRemainingTestingResources);
      } else {
        setRemainingTestingResources(newRemainingTestingResources);
        setNextTest(newConceptTest);
        if (!newConceptTest.question) {
          getNextContent(newConceptTest.uid);
        } else {
          setNextTestContent({});
        }
      }
      if (newConceptTest.picture) {
        const picRef = storageRef(storage, `testResourceImages/${newConceptTest.uid}`);
        const getPicture = () => {
          getDownloadURL(picRef).then((url) => {
            setTestPicture(url);
          });
        };
        getPicture();
      } else {
        setTestPicture("");
      }
      setTimeout(() => {
        setCurrentTest(newConceptTest);
        getContent(newConceptTest.uid);
        setDelay(false);
      }, 3000);

      setQuizState("quizzing");
    } else {
      handleNextConcept();
    }
  };

  // Get first next test and first picture
  useEffect(() => {
    if (remainingTestingResources.length > 0) {
      const selectedIndex = random(remainingTestingResources.length - 1);
      const nextNextTest = remainingTestingResources[selectedIndex];
      const newRemainingTestingResources = cloneDeep(remainingTestingResources);
      newRemainingTestingResources.splice(selectedIndex, 1);
      setNextTest(nextNextTest);
      if (!nextNextTest.question) {
        getNextContent(nextNextTest.uid);
      } else {
        setNextTestContent({});
      }
      setRemainingTestingResources(newRemainingTestingResources);
    }

    if (!currentTest.test) {
      getContent(currentTest.uid);
    }

    /* else {
      const selectedIndex = random(testingResources.length - 1);
      const nextNextTest = testingResources[selectedIndex];
      const newRemainingTestingResources = cloneDeep(testingResources);
      newRemainingTestingResources.splice(selectedIndex, 1);
      setNextTest(nextNextTest);
      setRemainingTestingResources(newRemainingTestingResources);
    } */

    if (currentTest.picture && !state.testPicture) {
      const picRef = storageRef(storage, `testResourceImages/${currentTest.uid}`);
      const getPicture = () => {
        getDownloadURL(picRef).then((url) => {
          setTestPicture(url);
        });
      };
      getPicture();
    }
  }, []);

  // rdb write if a concept is missing testingResources
  useEffect(() => {
    if (skipTrigger && !checkUnderstanding) {
      const quizConceptRefPath = `quizHistory/${user.uid}/${quiz.programId}/concepts/${nextConcept.uid}`;
      update(ref(rdb, quizConceptRefPath), {
        conceptCategory: nextConcept.block,
        conceptName: nextConcept.name,
        currentSubmitted: 0,
      });
      setNextConceptIndex((oldIndex) => oldIndex + 1);
      setSkipTrigger(false);
    }
  }, [skipTrigger]);

  // Get picture
  useEffect(() => {
    if (nextTest.picture) {
      const picRef = storageRef(storage, `testResourceImages/${nextTest.uid}`);
      const getPicture = () => {
        getDownloadURL(picRef).then((url) => {
          setNextTestPicture(url);
        });
      };
      getPicture();
    }
  }, [nextTest]);

  useEffect(() => {
    if (!checkUnderstanding) {
      const quizRef = `quizHistory/${user.uid}/${quiz.programId}`;
      update(ref(rdb, quizRef), {
        currentCorrect,
      });
    }
  }, [currentCorrect]);

  // Change concept trigger
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (currentConceptSubmitted > 2) {
      handleNextConcept();
    } else if (currentConceptSubmitted > 0) {
      handleNextTest(true);
    }
  }, [currentConceptSubmitted]);

  // Change concept effects
  useEffect(() => {
    const quizConceptRefPath = `quizHistory/${user.uid}/${quiz.programId}/concepts/${currentConcept.uid}`;
    const testConceptRefPath = `testHistory/${user.uid}/${currentConcept.uid}`;
    if (currentConcept.uid !== state.currentConcept.uid) {
      handleNextConceptTest();
      setCurrentConceptSubmitted(0);

      // create rdb entry
      update(ref(rdb, quizConceptRefPath), {
        currentSubmitted: 0,
      });
    }

    if (!checkUnderstanding) {
      update(ref(rdb, quizConceptRefPath), {
        conceptCategory: currentConcept.block,
        conceptName: currentConcept.name,
      });
    }
    update(ref(rdb, testConceptRefPath), {
      conceptCategory: currentConcept.block,
      conceptName: currentConcept.name,
    });
  }, [currentConcept]);

  const handleSkipReview = () => {
    setCurrentConceptSubmitted(currentConceptSubmitted + 1);
  };

  const handleSkipConcept = () => {
    // setConceptsForReview((oldConcepts) => [...oldConcepts, currentConcept]);
    handleNextConcept();
  };

  return (
    <DashboardLayout>
      <Card sx={{ width: "100%", minheight: "43vh", minWidth: "45rem" }}>
        <Grid container justifyContent="flex-end" pt={0.5} pr={0.5}>
          <IconButton onClick={handleMiniSidenav} size="medium" disableRipple>
            <Tooltip title={miniSidenav ? "Open Sidebar" : "Close Sidebar"}>
              <Icon fontSize="medium">{miniSidenav ? "menu" : "menu_open"}</Icon>
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => navigate("/studio")} size="medium" disableRipple>
            <Tooltip title="Home">
              <Icon fontSize="medium">home</Icon>
            </Tooltip>
          </IconButton>
        </Grid>
        <MDBox
          width="100%"
          alignItems="center"
          minHeight="5rem"
          maxHeight="20vh"
          justifyContent="center"
          display="flex"
          py={2}
          px={6}
        >
          <LevelProgressBar
            progress={
              checkUnderstanding
                ? Math.floor((questionIndex / 3) * 100)
                : Math.floor((quizIndex / quiz.conceptList.length) * 100)
            }
            // questionIndex={questionIndex}
            // level={Math.floor(quizIndex / 10) + 1}
            color={quizIndex === quiz.conceptList.length ? "primary" : "info"}
            source={source}
          />
          {/* <ConceptProgressDots
            conceptName={currentConcept.name}
            conceptProgress={currentConceptProgress}
  /> */}
        </MDBox>
        <Grid
          container
          width="100%"
          minHeight="30rem"
          pb={6}
          px={{ xs: 2, xl: 3, xxl: 4 }}
          justifyContent="flex-column"
          alignItems="stretch"
        >
          {completedQuiz ? (
            <MDBox width="100%" display="flex" alignItems="center" justifyContent="center">
              <Stack spacing={3} mt={-3} textAlign="center">
                <MDTypography color="primary" variant="h2">
                  Congratulations!
                </MDTypography>
                <MDTypography variant="body1">
                  {checkUnderstanding
                    ? `You have completed a knowledge check for the ${currentConcept.name} concept.`
                    : `You have completed the ${quiz.name} ${source}.`}
                </MDTypography>
                <MDTypography>
                  You answered {currentCorrect} out of {questionIndex} questions correctly for score
                  of {Math.floor((currentCorrect / questionIndex) * 100)}%
                </MDTypography>
                <ReturnButton
                  selectedConcept={currentConcept}
                  selectedProgram={
                    state.sourceData ? state.sourceData.currentProgram || null : null
                  }
                  currentProgramIndex={
                    state.sourceData ? state.sourceData.currentProgramIndex || null : null
                  }
                  source={state.source}
                  completedQuiz={completedQuiz}
                />
              </Stack>
            </MDBox>
          ) : (
            <>
              {!currentTest.question ? (
                <QuizResource2
                  quizResource={currentTest}
                  setQuizState={setQuizState}
                  quizState={quizState}
                  currentConcept={currentConcept}
                  quizRefPath={`quizHistory/${user.uid}/${quiz.programId}`}
                  quizConceptRefPath={`quizHistory/${user.uid}/${quiz.programId}/concepts/${currentConcept.uid}`}
                  testConceptRefPath={`testHistory/${user.uid}/${currentConcept.uid}/tests`}
                  currentConceptSubmitted={currentConceptSubmitted}
                  setCurrentConceptSubmitted={setCurrentConceptSubmitted}
                  notify={notify}
                  currentCorrect={currentCorrect}
                  setCurrentCorrect={setCurrentCorrect}
                  questionIndex={questionIndex}
                  setQuestionIndex={setQuestionIndex}
                  lastQuestion={lastQuestion}
                  checkUnderstanding={checkUnderstanding}
                  loading={loadingNextTestingResources && !dataNextTestingResources}
                  delay={delay}
                  testContent={testContent}
                />
              ) : (
                <QuizResource
                  quizResource={currentTest}
                  quizPictureUrl={testPicture}
                  setQuizState={setQuizState}
                  quizState={quizState}
                  currentConcept={currentConcept}
                  quizRefPath={`quizHistory/${user.uid}/${quiz.programId}`}
                  quizConceptRefPath={`quizHistory/${user.uid}/${quiz.programId}/concepts/${currentConcept.uid}`}
                  testConceptRefPath={`testHistory/${user.uid}/${currentConcept.uid}/tests`}
                  currentConceptSubmitted={currentConceptSubmitted}
                  setCurrentConceptSubmitted={setCurrentConceptSubmitted}
                  notify={notify}
                  currentCorrect={currentCorrect}
                  setCurrentCorrect={setCurrentCorrect}
                  questionIndex={questionIndex}
                  setQuestionIndex={setQuestionIndex}
                  lastQuestion={lastQuestion}
                  checkUnderstanding={checkUnderstanding}
                  loading={loadingNextTestingResources && !dataNextTestingResources}
                  delay={delay}
                />
              )}
              {quizState === "incorrect" && (
                <MDBox justifyContent="center" width="100%" flexDirection="row" textAlign="center">
                  <MDTypography variant="h4" color="error" pb={2}>
                    {checkUnderstanding
                      ? `Oops! Continue the check or try watching a video.`
                      : `Oops! Let's try a video review.`}
                  </MDTypography>
                  <MDBox display="flex" justifyContent="center" alignItems="center">
                    {checkUnderstanding ? (
                      <ReturnToVideos
                        selectedConcept={currentConcept}
                        selectedProgram={state.sourceData.currentProgram}
                        currentProgramIndex={state.sourceData.currentProgramIndex}
                        source={state.source}
                        completedQuiz={completedQuiz}
                      />
                    ) : (
                      <SelectReviewVideo
                        selectedConcept={currentConcept}
                        selectedQuiz={quiz}
                        currentCorrect={currentCorrect}
                        currentQuizIndex={quizIndex}
                        setCurrentConceptSubmitted={setCurrentConceptSubmitted}
                        remainingTestingResources={remainingTestingResources}
                        testingResources={testingResources}
                        currentConceptSubmitted={currentConceptSubmitted}
                        currentQuestionIndex={questionIndex}
                        nextTest={nextTest}
                        nextTestPicture={nextTestPicture}
                        source={source}
                      />
                    )}
                    <MDButton
                      variant="text"
                      color="info"
                      onClick={
                        testingResources.length - 1 === currentConceptSubmitted ||
                        currentConceptSubmitted > 1
                          ? handleSkipConcept
                          : handleSkipReview
                      }
                    >
                      {checkUnderstanding ? "Continue Check" : "Skip Review"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              )}
            </>
          )}
          <ToastContainer />
        </Grid>
      </Card>
    </DashboardLayout>
  );
}

export default QuizViewer;
