/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState } from "react";

// Auth context
import { UserAuth } from "context/AuthContext";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Alert from "@mui/material/Alert";

function ChangePassword() {
  const { user, resetPassword } = UserAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const triggerResetEmail = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      await resetPassword(user.email);
    } catch (event) {
      setError(event.message);
    }
    setLoading(false);
  };

  const passwordRequirements = [
    "One special character",
    "Min 6 characters",
    "One number (2 are recommended)",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      {error && (
        <Alert py={2} severity="error">
          {error}
        </Alert>
      )}
      <MDBox component="form" pb={3} px={3}>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </MDBox>
          <MDBox ml="auto">
            <MDButton
              disabled={loading}
              variant="gradient"
              color="dark"
              size="small"
              onClick={triggerResetEmail}
            >
              Send Reset Email
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
