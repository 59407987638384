/* eslint-disable no-unused-vars */
import { useSlateStatic, useFocused, useSelected, ReactEditor } from "slate-react";
import { Transforms } from "slate";
import { Icon } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox/index";
import MDButton from "components/MDButton/index";
import { MathJax } from "better-react-mathjax";

export default function MathLiveElement({ attributes, children, element }) {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();

  return (
    <MDBox {...attributes}>
      {children}
      <MDBox
        display="flex"
        sx={{ position: "relative", justifyContent: element.align ? element.align : "left" }}
      >
        <MathJax inline>{`\\(${element.mathText}\\)`}</MathJax>
        <MDButton
          iconOnly
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          color="info"
          sx={{
            display: selected && focused ? "inline" : "none",
            left: "2rem",
          }}
        >
          <Icon>delete</Icon>
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

MathLiveElement.defaultProps = {
  attributes: null,
};

MathLiveElement.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    align: PropTypes.string,
    mathText: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.any,
};
