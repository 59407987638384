/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Kit 2 PRO React examples
import RaisedBlogCard from "layouts/CompanyWebsite/Home/components/RaisedBlogCard";

// Images
import student from "assets/images/student-girl.jpg";
import parent from "assets/images/parent-guy.jpg";
import teacher from "assets/images/teacher-and-students.jpg";
import contentCreator from "assets/images/content-creator-guy.jpg";

function Stakeholders() {
  return (
    <MDBox component="section" py={-6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            mx="auto"
            textAlign="center"
            pb={9}
          >
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h2" mb={1}>
                How Can EDYou Help You?
              </MDTypography>
              <MDTypography variant="body1" color="text">
                EDYou strives to empower all stakeholders in the education process.
              </MDTypography>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={11} sx={{ mx: "auto" }}>
            <Grid container spacing={{ xs: 6, md: 3 }}>
              <Grid item xs={12} md={6}>
                <RaisedBlogCard
                  image={student}
                  title="Learners"
                  description="Lifelong learning is for everyone! Studies show that learning reduces stress, boosts self-esteem and confidence, helps you connect with others, leads to new career opportunities, and produces numerous other benefits."
                  action={{
                    type: "internal",
                    route: "/learning-studio", // learning studio
                    color: "primary",
                    label: "Learn More",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RaisedBlogCard
                  image={parent}
                  title="Parents"
                  description="Help your child explore their interests and learn organically from curated content, get the information you need to help them with their homework, or use EDYou as a tutoring alternative. Our goal at EDYou is to help everyone develop a love of learning."
                  action={{
                    type: "internal",
                    route: "/learning-studio", // learning studio
                    color: "primary",
                    label: "Learn More",
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 6, md: 3 }} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <RaisedBlogCard
                  image={teacher}
                  title="Educators"
                  description="Do you provide instruction on academic, professional development, or personal interest subjects? EDYou can support your education and training goals as a supplementary learning tool and as a method of verifying learning outcomes."
                  action={{
                    type: "internal",
                    route: "/verified-educators", // learning studio
                    color: "primary",
                    label: "Learn More",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RaisedBlogCard
                  image={contentCreator}
                  title="Content Creators"
                  description="Do you make great educational content for platforms like YouTube? Expand the reach of your content to include EDYou learners! Send us a message so we can index your videos to the concepts you cover."
                  action={{
                    type: "internal",
                    route: "/verified-educators", // learning studio
                    color: "primary",
                    label: "Learn More",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Stakeholders;
