/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import { UserAuth } from "context/AuthContext";

// @mui material components
import { Alert, Checkbox } from "@mui/material";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Provider Buttons
import { GoogleLoginButton } from "react-social-login-buttons"; // , MicrosoftLoginButton

// Authentication layout components
import IllustrationLayoutPOC from "layouts/CompanyWebsite/Authentication/components/IllustrationLayoutPOC";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
// import Socials from "layouts/CompanyWebsite/Authentication/components/Socials";

function SignUp() {
  const [errorV, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { signUp, error, googleLogIn } = UserAuth(); // googleLogIn, microsoftLogIn,
  const [checked, setChecked] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleGoogleLogIn = (e) => {
    e.preventDefault();
    setError("");
    if (checked) {
      googleLogIn();
    } else {
      setError("Please check the box to accept the Terms and Privacy Policy");
    }
  };

  /* const handleMicrosoftLogIn = (e) => {
    e.preventDefault();
    setError("");
    if (checked) {
      microsoftLogIn();
    } else {
      setError("Please check the box to accept the Terms and Privacy Policy");
    }
  }; */

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await signUp(emailRef.current.value, passwordRef.current.value);
    } catch {
      throw error;
    }

    setLoading(false);
  };

  return (
    <IllustrationLayoutPOC
      title="Sign Up"
      description="Use one of the options below to register." // or use a social login. The same account is used for both apps."
      illustration={bgImage}
    >
      {error.message && (
        <Alert my={2} severity="error">
          {error.message}
        </Alert>
      )}
      {errorV && (
        <Alert my={2} severity="warning">
          {errorV}
        </Alert>
      )}
      <MDBox mb={4}>
        <GoogleLoginButton onClick={handleGoogleLogIn} />
      </MDBox>
      {/* 
      <MDBox mb={4}>
        <MicrosoftLoginButton onClick={handleMicrosoftLogIn} />
    </MDBox> */}
      <MDBox component="form" id="sign_up" onSubmit={handleSubmit} role="form">
        <MDBox my={2}>
          <MDInput type="email" inputRef={emailRef} label="Email" fullWidth />
        </MDBox>
        <MDBox mb={2}>
          <MDInput type="password" inputRef={passwordRef} label="Password" fullWidth />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Checkbox
            checked={checked}
            required
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
          >
            I agree the&nbsp;
            <MDTypography
              component={Link}
              to="/terms-of-use"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Terms of Use
            </MDTypography>{" "}
            and{" "}
            <MDTypography
              component={Link}
              to="/privacy"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Privacy Policy
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            type="submit"
            disabled={loading}
            variant="gradient"
            color="primary"
            size="large"
            fullWidth
          >
            sign up
          </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Already have an account?{" "}
            <MDTypography
              component={Link}
              to="/log-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Log In
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox textAlign="center" display={{ xs: "block", lg: "none" }} width="50%" mx="auto">
          <MDTypography pb={4} pt={2} variant="body2">
            - or - <br />
          </MDTypography>
          <MDButton component={Link} to="/" color="info" size="small" fullWidth>
            Learn More
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayoutPOC>
  );
}

export default SignUp;
