/* eslint-disable no-nested-ternary */
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Grid, ButtonBase, Stack } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox/index";
import MDButton from "components/MDButton/index";
import MDTypography from "components/MDTypography/index";
import { findIndex } from "lodash";
import TextEditor from "../../TextEditor/index";

export default function SortableItem(props) {
  const { id, content, type, setComponents, format, answerType, hasAnswer } = props;
  const [editing, setEditing] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    animateLayoutChanges: () => false,
    disabled: editing,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleActivate = (activateType) => {
    setComponents((oldComponents) => {
      const index = findIndex(oldComponents, ["id", id]);
      const newComponents = [...oldComponents];
      newComponents[index].type = activateType;
      return newComponents;
    });
    setEditing(true);
  };

  return (
    <Grid container item xs={12} md={format[0]}>
      <Grid container item xs={12} md={format[1]}>
        <MDBox
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="lg"
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          p={type ? 0 : 1}
          sx={{
            "&:hover:not(:focus)":
              type && !editing
                ? {
                    backgroundColor: "#f0f2f5",
                  }
                : null,
            border: type ? 0 : 0.5,
          }}
          bgColor="white"
        >
          {type ? (
            <ButtonBase
              component="div"
              sx={{
                alignItems: "center",
                width: "100%",
                borderRadius: 3,
              }}
              onDoubleClick={() => {
                if (!editing) {
                  setEditing(true);
                }
              }}
              disableRipple
            >
              <TextEditor
                answerType={answerType}
                type={type}
                editing={editing}
                setComponents={setComponents}
                setEditing={setEditing}
                id={id}
                content={content}
              />
            </ButtonBase>
          ) : (
            <Stack py={6} spacing={2} sx={{ alignItems: "center" }}>
              <MDButton
                variant="gradient"
                color="info"
                sx={{ width: 150 }}
                onClick={() => handleActivate("body")}
              >
                Body Block
              </MDButton>
              {!hasAnswer && (
                <>
                  <MDTypography>- or -</MDTypography>
                  <MDButton
                    disabled={!answerType}
                    variant="gradient"
                    color="info"
                    sx={{ width: 150 }}
                    onClick={() => handleActivate("answer")}
                  >
                    Answer Block
                  </MDButton>
                </>
              )}
              {!answerType && (
                <MDTypography mt={2} variant="caption">
                  Please select an answer type.
                </MDTypography>
              )}
            </Stack>
          )}
        </MDBox>
      </Grid>
    </Grid>
  );
}

SortableItem.defaultProps = {
  content: null,
  type: null,
  answerType: null,
};

SortableItem.propTypes = {
  answerType: PropTypes.string,
  hasAnswer: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.any),
  setComponents: PropTypes.func.isRequired,
  format: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};
