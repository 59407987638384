/* eslint-disable no-unused-vars */
import {
  DndContext,
  closestCenter,
  // KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  // sortableKeyboardCoordinates,
  rectSwappingStrategy,
  arraySwap,
} from "@dnd-kit/sortable";
import { findIndex } from "lodash";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import SortableItem from "./SortableItem/index";
import FormatGrid from "./SortableItem/FormatGrid";

function TestBuilderLayout({ components, setComponents, format, answerType }) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 250,
        distance: 5,
        tolerance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        distance: 100,
        tolerance: 10,
      },
    })
    /* useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }) */
  );
  const hasAnswer = findIndex(components, ["type", "answer"]) >= 0;

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setComponents((oldComponents) => {
        const oldIndex = findIndex(oldComponents, ["id", active.id]);
        const newIndex = findIndex(oldComponents, ["id", over.id]);

        return arraySwap(oldComponents, oldIndex, newIndex);
      });
    }
  };

  const formatGrid = FormatGrid(format, components.length);

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={components} strategy={rectSwappingStrategy}>
        <Grid container spacing={2}>
          {components.map((component, index) => (
            <SortableItem
              answerType={answerType}
              key={component.id}
              id={component.id}
              content={component.content}
              type={component.type}
              hasAnswer={hasAnswer}
              setComponents={setComponents}
              format={formatGrid[index]} // format[index]
            />
          ))}
        </Grid>
      </SortableContext>
    </DndContext>
  );
}

export default TestBuilderLayout;

TestBuilderLayout.defaultProps = {
  answerType: null,
};

TestBuilderLayout.propTypes = {
  answerType: PropTypes.string,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.any),
    })
  ).isRequired,
  setComponents: PropTypes.func.isRequired,
  format: PropTypes.string.isRequired,
};
