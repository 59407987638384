/* eslint-disable no-unused-vars */
import { useState } from "react";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import MDInput from "components/MDInput/index";
import { Autocomplete, alpha } from "@mui/material";
import PropTypes from "prop-types";
import { difficulties } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import { clone } from "lodash";

function PreviewConceptCard(props) {
  const { concept, setNewConcept } = props;
  const [conceptDifficultyV, setConceptDifficulty] = useState("");

  return (
    <MDBox
      p={2}
      sx={{ borderRadius: 5, border: 1, backgroundColor: alpha("#00ad43", 0.3) }}
      width="50%"
    >
      <MDTypography pb={2}>{concept.name}</MDTypography>
      <Autocomplete
        value={conceptDifficultyV || null}
        onChange={(e, difficulty) => {
          setConceptDifficulty(difficulty);
          setNewConcept((oldConcept) => {
            const newConcept = clone(oldConcept);
            newConcept.difficulty = difficulty;
            return newConcept;
          });
        }}
        options={difficulties}
        renderInput={(params) => (
          <MDInput
            {...params}
            required={conceptDifficultyV !== "" || false}
            label={conceptDifficultyV !== "" ? "Difficulty" : "Difficulty *"}
            variant="outlined"
          />
        )}
      />
    </MDBox>
  );
}

export default PreviewConceptCard;

PreviewConceptCard.propTypes = {
  concept: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    difficulty: PropTypes.string,
  }).isRequired,
  setNewConcept: PropTypes.func.isRequired,
};
