/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footer";

// About Us page sections
import TheIdea from "layouts/CompanyWebsite/AboutUs/OurMission/sections/TheIdea";
import Steps from "layouts/CompanyWebsite/AboutUs/OurMission/sections/Steps";

// Routes
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/subject-map.jpg";

function OurMission() {
  const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  return (
    <>
      <MDBox bgColor="white" py={0.25}>
        <DefaultNavbar
          routes="page"
          action={{
            type: "internal",
            route: "/log-in",
            label: "Sign Up/Log In",
            color: "primary",
          }}
          transparent
          light
        />
      </MDBox>
      <MDBox
        ref={headerRef}
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.9),
              rgba(gradients.dark.state, 0.9)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MDTypography
              variant="h1"
              color="white"
              pb={2}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Our Mission
            </MDTypography>
            <MDTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
              EDYou&apos;s mission is make learning fun and purposeful. You tell us where you want
              to go and we&apos;ll get you there, with every step of your journey customized to your
              interests and learning preferences. If you change along the way, we&apos;ll change
              with you. Learn whatever you want, wherever you want, whenever you want to, all for
              less than a textbook. It&apos;s education your way.
            </MDTypography>
          </Grid>
        </Container>
      </MDBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <TheIdea />
        <Steps />
      </Card>
      <MDBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MDBox>
    </>
  );
}

export default OurMission;
