// React

// MUI

import PageLayout from "examples/LayoutContainers/PageLayout/index";
import DefaultNavbar from "examples/Navbars/DefaultNavbar/index";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import { Grid, Card } from "@mui/material";
import logo from "assets/images/logo-tree.png";
import TestDifficultyCoverage from "./components/TestDifficultyCoverage";
import VideoDifficultyCoverage from "./components/VideoDifficultyCoverage";

function Reports() {
  return (
    <PageLayout>
      <DefaultNavbar routes="mod" />
      <MDBox mx={{ xs: 4, md: 6 }} mb={6} mt={12}>
        <Card p={3} sx={{ minHeight: "85vh" }}>
          <MDBox p={3}>
            <Grid container justifyContent="space-between" mb={3}>
              <Grid item xs={12}>
                <MDBox component="img" src={logo} width="3.5rem" p={1} mb={1} />
              </Grid>
              <Grid item alignItems="center" justifyContent="center" xs={12}>
                <MDBox width="100%" textAlign="center" mt={-2}>
                  <MDTypography variant="h1" fontWeight="medium" pb={4}>
                    Reports
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} s={6} md={4} xl={3}>
                <TestDifficultyCoverage />
              </Grid>
              <Grid item xs={12} s={6} md={4} xl={3}>
                <VideoDifficultyCoverage />
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </PageLayout>
  );
}

export default Reports;
