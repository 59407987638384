/* eslint-disable no-nested-ternary */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// @mui material components
import { Icon, IconButton, Card, Tooltip } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";

// Lodash
import { clone } from "lodash";

// Realtime DB
import { rdb } from "firebase-config";
import { ref, set, serverTimestamp, push, update } from "firebase/database";

// import { gql } from "@apollo/client";
import ReactPlayer from "react-player/youtube";

// Contexts
import { setMiniSidenav, useMaterialUIController } from "context/NavContext";
import { UserAuth } from "context/AuthContext";

// components
import VideoSurvey from "./components/VideoSurvey";
import Navigation from "./components/Navigation/index";
import VideoAttribution from "./components/VideoAttribution/index";

function MediaViewer() {
  const { state } = useLocation();
  const { user } = UserAuth();
  const currentProgram = state.selectedProgram;
  const [currentProgramIndex, setProgramIndex] = useState(state.currentProgramIndex);
  const [currentVideo, setCurrentVideo] = useState(state.selectedVideo); // set default as embedID (the parameter)
  const [currentConcept, setCurrentConcept] = useState(state.selectedConcept);
  const [currentVideoType, setVideoType] = useState(state.videoType);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const [completedSurvey, setCompletedSurvey] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [surveyData, setSurveyData] = useState();
  const [prevHistory, setPrevVideos] = useState([]);
  const navigate = useNavigate();
  // const isFirstRender = useRef(true);

  const handleMiniSidenav = () => {
    setMiniSidenav(dispatch, !miniSidenav);
  };

  // Pass this to the modals. If completedSurvey (is not null) then pop a notification instead of opening
  // The handle close for the top level of the modal should handle the submission if not the current video

  useEffect(() => {
    // Program Update
    if (currentProgram) {
      const conceptList = currentProgram.conceptList.map((concept) => concept.uid);
      // eslint-disable-next-line react/prop-types
      if (conceptList.includes(currentConcept.uid)) {
        set(
          ref(rdb, `programHistory/${user.uid}/${currentProgram.programId}/${currentConcept.uid}`),
          {
            completed: true,
          }
        );
      }
    }

    // Concept and Video History
    if (currentConcept.uid !== "Default Concept") {
      const conceptRef = ref(rdb, `conceptHistory/${user.uid}/${currentConcept.uid}`);
      const conceptVideoRef = ref(rdb, `conceptHistory/${user.uid}/${currentConcept.uid}/videos`);
      const conceptVideoPushRef = push(conceptVideoRef);
      const videoRef = ref(rdb, `videoHistory/${user.uid}`);
      const videoPushRef = push(videoRef);
      const enterTimestamp = serverTimestamp();
      update(conceptRef, {
        conceptName: currentConcept.name,
        conceptCategory: currentConcept.block,
      });
      set(conceptVideoPushRef, {
        videoTitle: currentVideo.title,
        videoUid: currentVideo.uid,
        videoType: currentVideoType,
        timestamp: enterTimestamp,
        // difficulty: currentVideo.difficulty,
      });
      set(videoPushRef, {
        videoTitle: currentVideo.title,
        videoUid: currentVideo.uid,
        videoType: currentVideoType,
        conceptName: currentConcept.name,
        conceptUid: currentConcept.uid,
        timestamp: enterTimestamp,
        // difficulty: currentVideo.difficulty,
      });
    }

    // Previous 5 - Keeps previous only for each entry into the media viewer. Will want to store this in session storage and use a useEffect to set the state.
    if (prevHistory.length < 6) {
      const newPrevHistory = clone(prevHistory);
      const entry = {
        concept: currentConcept,
        video: currentVideo,
        videoType: currentVideoType,
      };
      const filteredPrevHistory = newPrevHistory.filter(
        (element) => element.video.uid !== entry.video.uid
      );
      filteredPrevHistory.push(entry);
      setPrevVideos(filteredPrevHistory);
    }
    if (prevHistory.length >= 6) {
      const newPrevHistory = clone(prevHistory);
      const entry = {
        concept: currentConcept,
        video: currentVideo,
        videoType: currentVideoType,
      };
      const filteredPrevHistory = newPrevHistory.filter(
        (element) => element.video.uid !== entry.video.uid
      );
      filteredPrevHistory.push(entry);
      if (filteredPrevHistory.length > 6) {
        filteredPrevHistory.shift();
      }
      setPrevVideos(filteredPrevHistory);
    }
  }, [currentVideo]);

  return (
    <DashboardLayout>
      <Card
        sx={{
          minHeight: "51rem",
          minWidth: "45rem",
        }}
      >
        <MDBox p={3} display="flex" width="100%" sx={{ flexWrap: "nowrap" }}>
          <MDBox height="100%" width="100%" display="flex" sx={{ flexDirection: "column" }}>
            {/* Viewer Params */}
            <VideoAttribution currentVideo={currentVideo} currentConcept={currentConcept} />
            <MDBox minHeight="29.5rem" height="85vh" display="flex-column" alignItems="center">
              <ReactPlayer
                url={`https://www.youtube.com/watch?v=${currentVideo.embedId}`}
                config={{
                  youtube: {
                    playerVars: { rel: 0, controls: 1 },
                  },
                }}
                width="100%"
                height="100%"
              />
            </MDBox>
            <MDBox pt={2.5}>
              <VideoSurvey
                setCompletedSurvey={setCompletedSurvey}
                setSurveyData={setSurveyData}
                currentVideo={currentVideo}
              />
            </MDBox>
          </MDBox>
          <MDBox
            minWidth="10rem"
            maxWidth={{ xs: "10rem", md: "11rem", xl: "12rem" }}
            display="flex"
            pl={3}
            sx={{ flexDirection: "column" }}
          >
            <MDBox display="flex" alignItems="center" justifyContent="flex-end" mt={-2} mr={-2}>
              <IconButton onClick={handleMiniSidenav} size="medium" disableRipple>
                <Tooltip title={miniSidenav ? "Open Sidebar" : "Close Sidebar"}>
                  <Icon fontSize="medium">{miniSidenav ? "menu" : "menu_open"}</Icon>
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => navigate("/studio")} size="medium" disableRipple>
                <Tooltip title="Home">
                  <Icon fontSize="medium">home</Icon>
                </Tooltip>
              </IconButton>
            </MDBox>
            <MDBox height="100%" width="100%" display="flex" alignItems="center" mt={-2}>
              <Navigation
                currentVideo={currentVideo}
                setCurrentVideo={setCurrentVideo}
                currentConcept={currentConcept}
                setCurrentConcept={setCurrentConcept}
                currentProgram={currentProgram}
                currentProgramIndex={currentProgramIndex}
                setProgramIndex={setProgramIndex}
                completedSurvey={completedSurvey}
                setVideoType={setVideoType}
                prevHistory={prevHistory}
                source={state.source}
                sourceData={state.sourceData}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default MediaViewer;
