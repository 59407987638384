/* eslint-disable no-unused-vars */
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Icon from "@mui/material/Icon";
import ButtonGroup from "@mui/material/ButtonGroup";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { PropTypes } from "prop-types";
import PPEStepper from "./PPEStepper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxWidth: "50rem",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function PPEStepperModal(props) {
  const { onClose, open, currentVideo, currentConcept, conceptOptions, specialType } = props;

  const handleClose = () => {
    onClose(currentConcept, currentVideo);
  };

  const handleSelectionClose = (concept, video) => {
    onClose(concept, video);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={style}>
          <PPEStepper
            handleClose={handleSelectionClose}
            currentVideo={currentVideo}
            currentConcept={currentConcept}
            conceptOptions={conceptOptions}
            specialType={specialType}
          />
        </MDBox>
      </Fade>
    </Modal>
  );
}

export default function PPEModalWithSteps({
  currentVideo,
  setCurrentVideo,
  setVideoType,
  currentConcept,
  setCurrentConcept,
  peopleConceptOptions,
  placeConceptOptions,
  eventConceptOptions,
}) {
  const [openPerson, setOpenPerson] = useState(false);
  const handleOpenPerson = () => setOpenPerson(true);
  const handleClosePerson = (concept, video) => {
    setOpenPerson(false);
    setCurrentConcept(concept);
    if (video.uid !== currentVideo.uid) {
      setVideoType("method");
    }
    setCurrentVideo(video);
  };
  const [openPlace, setOpenPlace] = useState(false);
  const handleOpenPlace = () => setOpenPlace(true);
  const handleClosePlace = (concept, video) => {
    setOpenPlace(false);
    setCurrentConcept(concept);
    if (video.uid !== currentVideo.uid) {
      setVideoType("method");
    }
    setCurrentVideo(video);
  };
  const [openEvent, setOpenEvent] = useState(false);
  const handleOpenEvent = () => setOpenEvent(true);
  const handleCloseEvent = (concept, video) => {
    setOpenEvent(false);
    setCurrentConcept(concept);
    if (video.uid !== currentVideo.uid) {
      setVideoType("method");
    }
    setCurrentVideo(video);
  };

  return (
    <MDBox>
      <ButtonGroup fullWidth>
        <MDButton
          disabled={peopleConceptOptions[0].uid === "No Concepts"}
          variant="gradient"
          sx={{ height: 45 }}
          size="small"
          color="learn"
          onClick={handleOpenPerson}
        >
          <Icon sx={{ fontSize: "large" }}>person</Icon>
        </MDButton>
        <PPEStepperModal
          open={openPerson}
          onClose={handleClosePerson}
          currentVideo={currentVideo}
          currentConcept={currentConcept}
          conceptOptions={peopleConceptOptions}
          specialType="Related People"
        />
        <MDButton
          disabled={placeConceptOptions[0].uid === "No Concepts"}
          variant="gradient"
          sx={{ height: 45 }}
          size="small"
          color="explore"
          onClick={handleOpenPlace}
        >
          <Icon>map</Icon>
        </MDButton>
        <PPEStepperModal
          open={openPlace}
          onClose={handleClosePlace}
          currentVideo={currentVideo}
          currentConcept={currentConcept}
          conceptOptions={placeConceptOptions}
          specialType="Related Places"
        />
        <MDButton
          disabled={eventConceptOptions[0].uid === "No Concepts"}
          variant="gradient"
          sx={{ height: 45 }}
          size="small"
          color="extra"
          onClick={handleOpenEvent}
        >
          <Icon>event</Icon>
        </MDButton>
        <PPEStepperModal
          open={openEvent}
          onClose={handleCloseEvent}
          currentVideo={currentVideo}
          currentConcept={currentConcept}
          conceptOptions={eventConceptOptions}
          specialType="Related Events"
        />
      </ButtonGroup>
    </MDBox>
  );
}

const conceptProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  person: PropTypes.bool.isRequired,
  place: PropTypes.bool.isRequired,
  event: PropTypes.bool.isRequired,
  methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
});

const videoProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  embedId: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  methodConcepts: PropTypes.arrayOf(conceptProps),
  interestConcepts: PropTypes.arrayOf(conceptProps),
});

PPEStepperModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  currentConcept: conceptProps.isRequired,
  currentVideo: videoProps.isRequired,
  conceptOptions: PropTypes.arrayOf(conceptProps).isRequired,
  specialType: PropTypes.string.isRequired,
};

PPEModalWithSteps.propTypes = {
  currentVideo: videoProps.isRequired,
  setCurrentVideo: PropTypes.func.isRequired,
  setVideoType: PropTypes.func.isRequired,
  currentConcept: conceptProps.isRequired,
  setCurrentConcept: PropTypes.func.isRequired,
  peopleConceptOptions: PropTypes.arrayOf(conceptProps).isRequired,
  placeConceptOptions: PropTypes.arrayOf(conceptProps).isRequired,
  eventConceptOptions: PropTypes.arrayOf(conceptProps).isRequired,
};
