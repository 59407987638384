/* eslint-disable react/prop-types */

// React
import { useState } from "react";

// Props
import { PropTypes } from "prop-types";

// Mui
import { Dialog, DialogContent, DialogActions, DialogTitle, Icon, Stack } from "@mui/material";

// Material Kit
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

// Lodash
import { pull } from "lodash";

// Firebase
import { rdb } from "firebase-config";
import { ref, update } from "firebase/database";

// Auth Context
import { UserAuth } from "context/AuthContext";
import { programConceptProps } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

export default function RemoveProgramDialog(props) {
  const { program, setActivePrograms, setActive } = props;
  const { user } = UserAuth();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (remove) => {
    if (remove) {
      setActivePrograms((prevState) => {
        const newState = pull(prevState, program.programId);
        return newState;
      });
      setActive(false);
    }
    setOpen(false);
  };

  const handleKeep = () => {
    handleClose(false);
  };

  const handleRemove = async (e) => {
    e.preventDefault();
    await update(ref(rdb, `programHistory/${user.uid}/${program.programId}`), {
      active: false,
    });
    handleClose(true);
  };

  return (
    <MDBox>
      <MDButton
        variant="gradient"
        circular
        iconOnly
        size="medium"
        color="success"
        onClick={handleClickOpen}
      >
        <Icon fontSize="large">check</Icon>
      </MDButton>
      <Dialog onClose={handleKeep} open={open}>
        <MDBox display="flex" justifyContent="center" textAlign="center" py={2}>
          <Stack>
            <DialogTitle>Remove Program</DialogTitle>
            <DialogContent>
              Are you sure you want to remove {program.name} from your My Programs page?
            </DialogContent>
            <DialogActions>
              <MDBox width="100%" display="flex" justifyContent="center">
                <MDButton color="primary" onClick={handleKeep}>
                  &nbsp;&nbsp;Keep&nbsp;&nbsp;
                </MDButton>
                <MDButton sx={{ ml: 3 }} color="error" onClick={handleRemove}>
                  Remove
                </MDButton>
              </MDBox>
            </DialogActions>
          </Stack>
        </MDBox>
      </Dialog>
    </MDBox>
  );
}

RemoveProgramDialog.propTypes = {
  program: PropTypes.shape({
    addedBy: PropTypes.string,
    conceptList: PropTypes.arrayOf(programConceptProps),
    name: PropTypes.string,
    programId: PropTypes.string,
  }).isRequired,
  setActivePrograms: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
};
