/* eslint-disable no-nested-ternary */
import { useState } from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CloseIcon from "@mui/icons-material/Close";
import { blue, grey } from "@mui/material/colors";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import { videoProps } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

function AnotherProgramVideoDialog(props) {
  const { onClose, open, currentVideo, videoOptions } = props;

  const handleClose = () => {
    onClose(currentVideo);
  };

  const handleListItemClick = (video) => {
    if (video.uid === "Default Video" || video.uid === "No Videos") {
      return;
    }
    onClose(video);
  };

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>Select a Video</DialogTitle>
      <List sx={{ pt: 0, px: 2, pb: 2 }}>
        {videoOptions.map((video) => (
          <ListItem button onClick={() => handleListItemClick(video)} key={video.uid}>
            <ListItemAvatar>
              <MDAvatar sx={{ color: blue[600] }}>
                <PlayCircleIcon fontSize="large" />
              </MDAvatar>
            </ListItemAvatar>
            <ListItemText primary={video.title} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleListItemClick(currentVideo)}>
          <ListItemAvatar>
            <MDAvatar sx={{ color: grey[600] }}>
              <CloseIcon fontSize="medium" />
            </MDAvatar>
          </ListItemAvatar>
          <ListItemText primary="Cancel" />
        </ListItem>
      </List>
    </Dialog>
  );
}

export default function AnotherProgramVideo({
  currentVideo,
  setCurrentVideo,
  videoOptions,
  setVideoType,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (video) => {
    setOpen(false);
    if (video.uid !== currentVideo.uid) {
      setVideoType(video.videoType);
    }
    setCurrentVideo(video);
  };

  return (
    <MDBox pt={1}>
      <MDButton
        fullWidth
        disabled={videoOptions[0].uid === "No Videos" || videoOptions[0].uid === "Default Video"}
        variant="gradient"
        sx={{ height: 45 }}
        size="small"
        color="extra"
        onClick={handleClickOpen}
      >
        Program Videos
      </MDButton>
      <AnotherProgramVideoDialog
        open={open}
        onClose={handleClose}
        currentVideo={currentVideo}
        videoOptions={videoOptions}
      />
    </MDBox>
  );
}

AnotherProgramVideoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  currentVideo: videoProps.isRequired,
  videoOptions: PropTypes.arrayOf(videoProps).isRequired,
};

AnotherProgramVideo.propTypes = {
  currentVideo: videoProps.isRequired,
  setCurrentVideo: PropTypes.func.isRequired,
  videoOptions: PropTypes.arrayOf(videoProps).isRequired,
  setVideoType: PropTypes.func.isRequired,
};
