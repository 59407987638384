/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useNavigate } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import laptop from "assets/images/google-pixelbook-mockup.png";

function Banner() {
  const navigate = useNavigate();

  const handleStartNow = () => navigate("/log-in");
  const handleReadMore = () => navigate("/learning-studio");

  return (
    <MDBox
      variant="gradient"
      bgColor="primary"
      position="relative"
      borderRadius="xl"
      mx={{ xs: 2, xl: 3, xxl: 16 }}
      mt={2}
      py={13}
      px={3}
      sx={{ overflow: "hidden" }}
    >
      <MDBox
        component="img"
        src={bgPattern}
        alt="pattern-lines"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.6}
      />
      <Container sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} lg={5} py={{ xs: 3, sm: 6 }} mr="auto" position="relative">
            <MDTypography
              textAlign={{ xs: "center", md: "left" }}
              variant="h1"
              color="white"
              mb={{ xs: 3, md: 1 }}
            >
              Learning Studio
            </MDTypography>
            <MDTypography
              textAlign={{ xs: "center", md: "left" }}
              variant="body1"
              color="white"
              mb={6}
            >
              EDYou&apos;s learning studio provides a personal space where you can learn what you
              want, wherever you want, whenever you want to. Explore tons of entertaining videos
              sourced from the best educational content creators, and curated by our amazing team of
              verified educators.
            </MDTypography>
            <MDBox textAlign={{ xs: "center", md: "left" }}>
              <MDButton variant="gradient" color="warning" onClick={handleStartNow}>
                Start now
              </MDButton>
              <MDButton variant="text" color="white" onClick={handleReadMore} sx={{ ml: 1 }}>
                Read more
              </MDButton>
            </MDBox>
          </Grid>
          <Grid
            item
            xs={12}
            mt={{ xs: 3, sm: 1, md: 0 }}
            position={{ md: "absolute" }}
            left={{ md: "50%" }}
            mr={{ md: -32 }}
            width={{ xs: "100%", md: "75%" }}
          >
            <MDBox
              component="img"
              src={laptop}
              alt="macbook"
              width="100%"
              display={{ xs: "none", md: "block" }}
            />
            <MDBox
              component="img"
              src={laptop}
              alt="macbook2"
              width="100%"
              display={{ xs: "block", md: "none" }}
            />
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Banner;
