/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import MDButton from "components/MDButton/index";
import MDProgress from "components/MDProgress/index";
import { db, rdb } from "firebase-config";
import { getDoc, doc } from "firebase/firestore";
import { ref, update, serverTimestamp } from "firebase/database";
import { gql, useQuery } from "@apollo/client";
import { random, pullAllBy, cloneDeep } from "lodash";
import { UserAuth } from "context/AuthContext/index";
import { quizProps } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

const GET_TESTING_RESOURCE = gql`
  query GetTestingResource($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      name
      block
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
      testingResources {
        uid
        difficulty
        text
        question
        picture
        answerChoices
        correctAnswer
        testedConcepts {
          uid
          name
        }
      }
    }
  }
`;

function Progress({ color, value }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );
}

function QuizCard(props) {
  const { quiz, active } = props;
  const [testData, setTestData] = useState();
  // const [testIndex, setIndex] = useState(-1);
  const { user } = UserAuth();
  const navigate = useNavigate();
  const completed = active ? active.quizProgress.completed : false;

  const currentIndex = active
    ? completed
      ? active.quizProgress.currentConceptIndex - 1
      : active.quizProgress.currentConceptIndex
    : 0;
  // const currentLevel = active ? Math.floor(active.quizProgress.currentIndex / 10) + 1 : 0;
  // const totalLevels = Math.ceil(quiz.conceptList.length / 10);

  const getTestData = async (data) => {
    const currentConcept = quiz.conceptList[currentIndex];
    const { currentSubmitted } = active ? active.quizProgress.concepts[currentConcept.uid] : 0;
    // removing already completed testing resources
    const completedTests =
      active && currentSubmitted > 0
        ? Object.values(active.quizProgress.concepts[currentConcept.uid].tests)
        : null;
    const iteree = completedTests ? completedTests.map((test) => ({ uid: test.testId })) : null;
    // to avoid a read only property error
    const newTestArr = cloneDeep(data.concepts[0].testingResources);
    const remainingTestingResources = iteree
      ? pullAllBy(newTestArr, iteree, "uid")
      : data.concepts[0].testingResources;
    const testingResource = remainingTestingResources[random(remainingTestingResources.length - 1)];

    let testContent = {};
    if (!testingResource.question) {
      const docRef = doc(db, "checks", testingResource.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        testContent = docSnap.data();
      }
    }
    const testDataV = {
      currentConcept,
      currentConceptSubmitted: currentSubmitted,
      currentTest: testingResource,
      currentTestContent: testContent,
      testingResources: data.concepts[0].testingResources,
      remainingTestingResources,
    };
    setTestData(testDataV);
  };

  const { loading: loadingTestingResource, data: dataTestingResource } = useQuery(
    GET_TESTING_RESOURCE,
    {
      variables: {
        uid: quiz.conceptList[currentIndex].uid,
      },
      onCompleted: (newData) => {
        getTestData(newData);
      },
    }
  );

  /* const levelProgress = (index) => {
    let progress = index;
    // had floor here. not sure why but also not certain i should have changed it
    for (let i = 0; i < Math.ceil(quiz.conceptList.length / 10) && progress >= 10; i += 1) {
      if (progress >= 10) {
        progress -= 10;
      } else {
        break;
      }
    }
    // times 10 to get %
    return progress * 10;
  }; */

  const handleClick = async () => {
    // test data
    const {
      currentConcept,
      currentConceptSubmitted,
      currentTest,
      currentTestContent,
      testingResources,
      remainingTestingResources,
    } = testData;

    // update quizHistory
    const quizRefPath = `quizHistory/${user.uid}/${quiz.programId}`;
    if (!active) {
      await update(ref(rdb, quizRefPath), {
        currentConceptIndex: 0,
        currentQuestionIndex: 0,
        currentCorrect: 0,
        title: quiz.name,
        numConcepts: quiz.conceptList.length,
        type: "quiz",
        completed: false,
      });
      await update(ref(rdb, `${quizRefPath}/concepts/${quiz.conceptList[currentIndex].uid}`), {
        currentSubmitted: 0,
      });
    }

    const timestamp = serverTimestamp();
    update(ref(rdb, quizRefPath), { timestamp });

    // getting concept data
    /* const currentConcept = quiz.conceptList[currentIndex];
    const { currentSubmitted } = active ? active.quizProgress.concepts[currentConcept.uid] : 0;

    // removing already completed testing resources
    const completedTests =
      active && currentSubmitted > 0
        ? Object.values(active.quizProgress.concepts[currentConcept.uid].tests)
        : null;
    const iteree = completedTests ? completedTests.map((test) => ({ uid: test.testId })) : null;
    // to avoid a read only property error
    const newTestArr = cloneDeep(dataTestingResource.concepts[0].testingResources);
    const remainingTestingResources = iteree
      ? pullAllBy(newTestArr, iteree, "uid")
      : dataTestingResource.concepts[0].testingResources;
    const testingResource = remainingTestingResources[random(remainingTestingResources.length - 1)]; */

    navigate("/studio/test-viewer", {
      state: {
        quiz,
        currentConcept,
        currentConceptSubmitted,
        currentTest,
        currentTestContent,
        testingResources,
        remainingTestingResources,
        currentIndex,
        currentCorrect: active ? active.quizProgress.currentCorrect : 0,
        source: "quiz",
        currentQuestionIndex: active ? active.quizProgress.currentQuestionIndex : 0,
      },
    });
  };

  return (
    <MDBox
      width="100%"
      borderRadius="xxl"
      sx={{ border: 1 }}
      justifyContent="center"
      alignItems="center"
      p={3}
    >
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <MDTypography variant="h5">{quiz.name}</MDTypography>
        <MDTypography variant="body2">{quiz.description}</MDTypography>
        <MDBox display="flex" width="100%" justifyContent="center" alignItems="center">
          <MDTypography width="100%" variant="button" fontWeight="bold" textAlign="center" mr={2}>
            Progress
          </MDTypography>
          <Progress
            color={completed ? "primary" : "info"}
            value={
              active
                ? active.quizProgress.completed
                  ? 100
                  : Math.floor((currentIndex / quiz.conceptList.length) * 100)
                : 0
            }
          />
        </MDBox>
        <MDButton
          disabled={
            // !(!loadingTestingResource && dataTestingResource) ||
            // dataTestingResource.concepts[0].testingResources.length === 0 ||
            !testData || completed
          }
          color={active ? "primary" : "info"}
          variant="gradient"
          onClick={handleClick}
        >
          {active ? (completed ? "completed" : "resume") : "start"}
        </MDButton>
      </Stack>
    </MDBox>
  );
}

export default QuizCard;

Progress.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

QuizCard.defaultProps = {
  active: null,
};

QuizCard.propTypes = {
  quiz: quizProps.isRequired,
  active: PropTypes.shape({
    programId: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    quizProgress: PropTypes.object.isRequired,
  }),
  // userProgress: PropTypes.shape
};
