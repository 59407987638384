import { useState } from "react";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import MDInput from "components/MDInput/index";
import { Autocomplete, alpha, Icon } from "@mui/material";
import PropTypes from "prop-types";
import { difficulties } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import { clone } from "lodash";
import MDButton from "components/MDButton/index";

function ConceptCard(props) {
  const { concept, setConcepts, index } = props;
  const [conceptDifficultyV, setConceptDifficulty] = useState(concept.difficulty);

  const handleChangeDifficulty = (e, newConceptDifficulty) => {
    setConceptDifficulty(newConceptDifficulty);
    setConcepts((oldConcepts) => {
      const newConcepts = clone(oldConcepts);
      newConcepts[index].difficulty = newConceptDifficulty;
      return newConcepts;
    });
  };

  const handleDeleteConcept = () => {
    setConcepts((oldConcepts) => {
      const newConcepts = clone(oldConcepts);
      newConcepts.splice(index, 1);
      return newConcepts;
    });
  };

  return (
    <MDBox
      p={2}
      sx={{ borderRadius: 5, border: 1, backgroundColor: alpha("#00ad43", 0.3) }}
      width="100%"
      display="flex"
      alignItems="center"
    >
      <MDTypography pr={2}>{concept.name}</MDTypography>
      <Autocomplete
        value={conceptDifficultyV || null}
        onChange={handleChangeDifficulty}
        options={difficulties}
        sx={{ flexGrow: 1 }}
        renderInput={(params) => (
          <MDInput
            {...params}
            size="small"
            required={conceptDifficultyV !== ""}
            label={conceptDifficultyV !== "" ? "Difficulty" : "Difficulty *"}
            variant="outlined"
          />
        )}
      />
      <MDButton variant="text" color="text" iconOnly circular onClick={handleDeleteConcept}>
        <Icon>close</Icon>
      </MDButton>
    </MDBox>
  );
}

export default ConceptCard;

ConceptCard.propTypes = {
  concept: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    difficulty: PropTypes.string.isRequired,
  }).isRequired,
  setConcepts: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
