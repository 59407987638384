/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the page layout of Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the DefaultNavbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `name` key is used for the name of the route on the DefaultNavbar.
  3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
  5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  6. The `route` key is used to store the route location which is used for the react router.
  7. The `href` key is used to store the external links location.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Studio Home
import StudioHome from "layouts/LearningStudio/MyAccount/StudioHome";

// Pages
import AddConcept from "layouts/LearningStudio/AddResource/addConcept";
import AddProgram from "layouts/LearningStudio/AddResource/addProgram";
import AddVideoProgram from "layouts/LearningStudio/AddResource/addVideoProgram";
import AddTest from "layouts/LearningStudio/AddResource/addTest/index";
// import AddTest2 from "layouts/LearningStudio/AddResource/addTest/index copy";
import AddVideo from "layouts/LearningStudio/AddResource/addVideo/index";
import EditVideoInfo from "layouts/LearningStudio/AddResource/editVideoInfo/index";
import AddQuizOfTheWeek from "layouts/LearningStudio/AddResource/addQuizOfTheWeek/index";
import EditTestInfo from "layouts/LearningStudio/AddResource/editTestInfo/index";
import Reports from "layouts/LearningStudio/AddResource/Reports/index";

const moderatorRoutes = [
  // Navbar routes
  {
    name: "studio",
    icon: <Icon>home</Icon>,
    route: "/studio",
    component: <StudioHome />,
    render: true,
  },
  {
    name: "add content",
    icon: <Icon>contacts</Icon>,
    render: true,
    columns: 2,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "add resources",
        collapse: [
          {
            name: "concept",
            route: "/add-concept",
            component: <AddConcept />,
          },
          {
            name: "video",
            route: "/add-video",
            component: <AddVideo />,
          },
          {
            name: "test",
            route: "/add-test",
            component: <AddTest />,
          },
        ],
      },
      {
        name: "add experiences",
        collapse: [
          {
            name: "concept program",
            route: "/add-program",
            component: <AddProgram />,
          },
          {
            name: "video program",
            route: "/add-video-program",
            component: <AddVideoProgram />,
          },
          {
            name: "weekly quiz",
            route: "/quiz-of-the-week",
            component: <AddQuizOfTheWeek />,
          },
        ],
      },
    ],
  },
  {
    name: "review content",
    icon: <Icon>contacts</Icon>,
    render: true,
    collapse: [
      {
        name: "edit videos",
        route: "/edit-video-info",
        component: <EditVideoInfo />,
      },
      {
        name: "edit tests",
        route: "/edit-test-info",
        component: <EditTestInfo />,
      },
    ],
  },
  {
    name: "Reports",
    icon: <Icon>article</Icon>,
    route: "/reports",
    component: <Reports />,
    render: true,
  },
];

export default moderatorRoutes;
