/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleFooter from "examples/Footer/SimpleFooter";
import DataTable from "layouts/LearningStudio/MyAccount/MyProgress/components/VideoHistory/components/DataTable";

// Data
import quizHistoryData from "./components/QuizHistoryData/index";

function QuizHistory() {
  const { columns: pColumns, rows: pRows } = quizHistoryData();

  return (
    <DashboardLayout>
      <DashboardNavbar isMini />
      <MDBox pt={6} pb={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Quiz History
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  showTotalEntries
                  noEndBorder
                  isSorted={false}
                  // canSearch
                  // really want to get sort and search working
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <SimpleFooter />
    </DashboardLayout>
  );
}

export default QuizHistory;
