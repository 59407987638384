/* eslint-disable no-unused-vars */
import MDBox from "components/MDBox/index";
import MDButton from "components/MDButton/index";
import MDTypography from "components/MDTypography/index";
import { countBy } from "lodash";

// Neo4j
import { gql, useQuery } from "@apollo/client";

const GET_CONCEPTS = gql`
  query GetConcepts {
    concepts {
      uid
      block
      name
      testingResources {
        uid
        difficulty
        question
        text
      }
    }
  }
`;

function TestDifficultyCoverage() {
  const { loading, error, data } = useQuery(GET_CONCEPTS);

  let rows;
  if (!loading && data) {
    rows = data.concepts.map((concept) => {
      const difficultyCount = countBy(concept.testingResources, "difficulty");
      return [
        concept.name.includes(",") ? `"${concept.name}"` : concept.name,
        concept.uid,
        concept.block,
        difficultyCount["K-2"] || 0,
        difficultyCount.Intro || 0,
        difficultyCount.Foundational || 0,
        difficultyCount.Core || 0,
        difficultyCount.Specialist || 0,
        difficultyCount.Challenge || 0,
      ];
    });
  }

  const handleClick = () => {
    rows.unshift([
      "Concept Name",
      "Concept UID",
      "Concept Category",
      "K-2 Tests",
      "Intro Tests",
      "Foundational Tests",
      "Core Tests",
      "Specialist Tests",
      "Challenge Tests",
    ]);

    // eslint-disable-next-line prefer-template
    const csvContent = "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "test-difficulty-report.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  return (
    <MDBox textAlign="center">
      <MDTypography mb={2} variant="h3" fontWeight="medium">
        Test-Difficulty Coverage Report
      </MDTypography>
      <MDButton disabled={loading && !data} onClick={handleClick} variant="gradient" color="info">
        Get Report
      </MDButton>
      {error && (
        <MDTypography mt={2} display="block" variant="caption" color="error">
          {error.message}
        </MDTypography>
      )}
    </MDBox>
  );
}

export default TestDifficultyCoverage;
