/* eslint-disable no-unused-vars */
import { Card } from "@mui/material";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { gql, useQuery } from "@apollo/client";
import { countBy } from "lodash";
import { categories } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

function CategoryCoverage() {
  const GET_CONCEPTS = gql`
    query GetConcepts {
      concepts {
        uid
        name
        person
        place
        event
        block
        methodVideos {
          uid
          title
          embedId
          channelName
        }
        interestVideos {
          uid
          title
          embedId
          channelName
        }
      }
    }
  `;

  const { loading: loadingConcepts, data: dataConcepts } = useQuery(GET_CONCEPTS);
  const conceptOptions = !loadingConcepts && dataConcepts ? dataConcepts : null;

  const categoryCount =
    !loadingConcepts && dataConcepts ? countBy(conceptOptions.concepts, "block") : [];

  const orderedData =
    !loadingConcepts && dataConcepts
      ? categories.map((category) => (categoryCount[category] ? categoryCount[category] : 0))
      : [];

  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: categories,
    datasets: [
      {
        label: "# of Concepts",
        data: orderedData,
        backgroundColor: [
          "rgba(255, 255, 0, 1)",
          "rgba(153, 0, 0, 1)",
          "rgba(0, 128, 0, 1)",
          "rgba(0, 255, 0, 1)",
          "rgba(0, 255, 255, 1)",
          "rgba(0, 128, 255, 1)",
          "rgba(0, 0, 255, 1)",
          "rgba(128, 50, 255, 1)",
          "rgba(255, 0, 255, 1)",
          "rgba(255, 50, 128, 1)",
          "rgba(255, 0, 0, 1)",
          "rgba(255, 128, 0, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 0, 1)",
          "rgba(153, 76, 0, 1)",
          "rgba(0, 128, 0, 1)",
          "rgba(0, 255, 0, 1)",
          "rgba(0, 255, 255, 1)",
          "rgba(0, 128, 255, 1)",
          "rgba(0, 0, 255, 1)",
          "rgba(128, 50, 255, 1)",
          "rgba(255, 0, 255, 1)",
          "rgba(255, 50, 128, 1)",
          "rgba(255, 0, 0, 1)",
          "rgba(255, 128, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox width="100%" p={3} textAlign="center">
        <MDTypography variant="h4" fontWeight="medium">
          Category Coverage
        </MDTypography>
        <MDBox>
          <Doughnut data={data} />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CategoryCoverage;

/* const GET_CONCEPTS = gql`
  query GetConcepts {
    concepts {
      uid
      name
      person
      place
      event
      block
      methodVideos {
        uid
        title
        embedId
        channelName
      }
      interestVideos {
        uid
        title
        embedId
        channelName
      }
    }
  }
`;

const { loading: loadingConcepts, data: dataConcepts } = useQuery(GET_CONCEPTS);
const conceptOptions = !loadingConcepts && dataConcepts ? dataConcepts : null;

const categoryCount = !loadingConcepts && dataConcepts ? countBy(conceptOptions, "block") : [];

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: categories,
  datasets: [
    {
      label: "# of Concepts",
      data: categoryCount,
      backgroundColor: [
        "rgba(255, 0, 0, 0.2)",
        "rgba(255, 128, 0, 0.2)",
        "rgba(255, 255, 0, 0.2)",
        "rgba(128, 255, 0, 0.2)",
        "rgba(0, 255, 0, 0.2)",
        "rgba(0, 255, 128, 0.2)",
        "rgba(0, 255, 255, 0.2)",
        "rgba(0, 128, 255, 0.2)",
        "rgba(0, 0, 255, 0.2)",
        "rgba(128, 0, 255, 0.2)",
        "rgba(255, 0, 255, 0.2)",
        "rgba(255, 0, 128, 0.2)",
      ],
      borderColor: [
        "rgba(255, 0, 0, 0.2)",
        "rgba(255, 128, 0, 0.2)",
        "rgba(255, 255, 0, 0.2)",
        "rgba(128, 255, 0, 0.2)",
        "rgba(0, 255, 0, 0.2)",
        "rgba(0, 255, 128, 0.2)",
        "rgba(0, 255, 255, 0.2)",
        "rgba(0, 128, 255, 0.2)",
        "rgba(0, 0, 255, 0.2)",
        "rgba(128, 0, 255, 0.2)",
        "rgba(255, 0, 255, 0.2)",
        "rgba(255, 0, 128, 0.2)",
      ],
      borderWidth: 1,
    },
  ],
};

console.log(categoryCount); */
