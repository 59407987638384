/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
import { useState, useEffect } from "react";
import MDTypography from "components/MDTypography/index";
import MDBox from "components/MDBox/index";
import { rdb } from "firebase-config";
import { ref, update, serverTimestamp, push, set } from "firebase/database";
import { FormControl, FormControlLabel, Radio, RadioGroup, Alert, Stack } from "@mui/material";
import { shuffle, upperFirst } from "lodash";
import PropTypes from "prop-types";
import { testProps } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import MDButton from "components/MDButton/index";

function QuizResource(props) {
  const {
    quizResource,
    quizPictureUrl,
    setQuizState,
    quizState,
    quizRefPath,
    quizConceptRefPath,
    testConceptRefPath,
    currentConceptSubmitted,
    setCurrentConceptSubmitted,
    currentCorrect,
    setCurrentCorrect,
    questionIndex,
    setQuestionIndex,
    lastQuestion,
    notify,
    checkUnderstanding,
    loading,
    delay,
  } = props;
  const [shuffledAnswerChoices, setAnswerChoices] = useState(quizResource.answerChoices);
  const [answerV, setAnswer] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setAnswerChoices(shuffle(quizResource.answerChoices));
  }, [quizResource]);

  const handleAnswer = (event) => {
    setAnswer(event.target.value);
  };

  const handleSubmit = () => {
    if (!answerV) {
      setError("Please select an answer choice.");
      return;
    }
    const conceptRef = ref(rdb, quizConceptRefPath);
    const newQuizRef = push(ref(rdb, `${quizConceptRefPath}/tests`));
    const newTestRef = push(ref(rdb, testConceptRefPath));
    if (answerV === quizResource.correctAnswer) {
      const testData = {
        testId: quizResource.uid,
        timestamp: serverTimestamp(),
        answer: answerV,
        correct: true,
        difficulty: quizResource.difficulty,
      };
      if (!checkUnderstanding) {
        set(newQuizRef, testData);
      }
      set(newTestRef, testData);
      setCurrentCorrect(currentCorrect + 1);
      setCurrentConceptSubmitted(currentConceptSubmitted + 1);
      notify(currentConceptSubmitted > 1 ? "concept" : "correct");
      setQuizState("correct");
      setAnswer("");
    } else {
      const testData = {
        testId: quizResource.uid,
        timestamp: serverTimestamp(),
        answer: answerV,
        correct: false,
        difficulty: quizResource.difficulty,
      };
      if (!checkUnderstanding) {
        set(newQuizRef, testData);
      }
      set(newTestRef, testData);
      if (currentConceptSubmitted > 1 || lastQuestion) {
        setCurrentConceptSubmitted(currentConceptSubmitted + 1);
        notify("incorrect");
      }
      setQuizState("incorrect");
    }
    setQuestionIndex((oldIndex) => oldIndex + 1);
    if (!checkUnderstanding) {
      update(conceptRef, { currentSubmitted: currentConceptSubmitted + 1 });
      update(ref(rdb, quizRefPath), {
        currentQuestionIndex: questionIndex + 1,
      });
    }
    setError("");
  };

  return (
    <MDBox display="flex" width="100%" justifyContent="center" alignItems="center">
      {(quizResource.text || quizResource.picture) && (
        <MDBox width="50%" textAlign="center" p={3}>
          {quizResource.picture &&
            (quizPictureUrl.length === 0 ? (
              <MDTypography>Loading image ...</MDTypography>
            ) : (
              <MDBox
                component="img"
                sx={{
                  maxHeight: "50vh",
                  maxWidth: "50vh",
                }}
                alt=""
                src={quizPictureUrl}
              />
            ))}
          {quizResource.text && <MDTypography>{quizResource.text}</MDTypography>}
        </MDBox>
      )}
      <MDBox
        width={quizResource.text || quizResource.picture ? "50%" : "100%"}
        display="flex"
        justifyContent="center"
      >
        <Stack spacing={3}>
          <MDTypography fontWeight="bold">{quizResource.question}</MDTypography>
          <FormControl>
            <RadioGroup value={answerV} onChange={handleAnswer}>
              {shuffledAnswerChoices.map((answer) => (
                <FormControlLabel
                  disabled={quizState === "incorrect" || delay}
                  key={answer}
                  value={answer}
                  control={<Radio />}
                  label={upperFirst(answer)}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {error && <Alert color="error">{error}</Alert>}
          {quizState === "quizzing" && (
            <MDButton
              disabled={(loading && currentConceptSubmitted > 1) || delay}
              color="info"
              onClick={handleSubmit}
            >
              submit
            </MDButton>
          )}
        </Stack>
      </MDBox>
    </MDBox>
  );
}

export default QuizResource;

QuizResource.propTypes = {
  quizResource: testProps.isRequired,
  quizPictureUrl: PropTypes.string,
  setQuizState: PropTypes.func.isRequired,
  quizState: PropTypes.string.isRequired,
  quizRefPath: PropTypes.string.isRequired,
  quizConceptRefPath: PropTypes.string.isRequired,
  testConceptRefPath: PropTypes.string.isRequired,
  currentConceptSubmitted: PropTypes.number.isRequired,
  setCurrentConceptSubmitted: PropTypes.func.isRequired,
  currentCorrect: PropTypes.number.isRequired,
  setCurrentCorrect: PropTypes.func.isRequired,
  questionIndex: PropTypes.number.isRequired,
  setQuestionIndex: PropTypes.func.isRequired,
  lastQuestion: PropTypes.bool.isRequired,
  notify: PropTypes.func.isRequired,
  checkUnderstanding: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  delay: PropTypes.bool.isRequired,
};

QuizResource.defaultProps = {
  quizPictureUrl: null,
  loading: true,
};
