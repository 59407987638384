/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState } from "react";

// Apollo
import { gql, useQuery } from "@apollo/client";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleFooter from "examples/Footer/SimpleFooter";
import { countBy } from "lodash";
import SelectVideo from "./components/SelectVideoModal";
import { defaultConcept, noConcepts, categories } from "../MediaViewer/components/DefaultValues";

function SearchConcepts() {
  // eslint-disable-next-line no-unused-vars
  const [categoryV, setCategory] = useState();
  const [conceptsV, setConcept] = useState();

  const GET_CONCEPTS = gql`
    query GetConcepts {
      concepts {
        uid
        name
        block
        person
        place
        event
        block
        methodVideos {
          uid
          title
          embedId
          channelName
        }
        interestVideos {
          uid
          title
          embedId
          channelName
        }
      }
    }
  `;

  const {
    loading: loadingConcepts,
    error: errorConcepts,
    data: dataConcepts,
  } = useQuery(GET_CONCEPTS);

  const filterConceptOptions = (data) => {
    const filteredForVideos =
      data.concepts.length !== 0
        ? data.concepts.filter(
            (concept) => concept.methodVideos.length !== 0 || concept.interestVideos.length !== 0
          )
        : noConcepts;
    return filteredForVideos;
  };

  const conceptOptions =
    !loadingConcepts && dataConcepts ? filterConceptOptions(dataConcepts) : defaultConcept;

  // eslint-disable-next-line no-unused-vars
  const categoryCount = !loadingConcepts && dataConcepts ? countBy(conceptOptions, "block") : [];

  const activeCategories = categories.filter((category) => categoryCount[category] >= 10);

  return (
    <DashboardLayout>
      <DashboardNavbar isMini />
      <MDBox pt={6} pb={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Search Concept Library
                </MDTypography>
              </MDBox>
              <MDBox display="flex" minHeight="30rem" alignItems="center" justifyContent="center">
                <MDBox width="100%" maxWidth="60rem">
                  <Grid container py={12} justifyContent="center" spacing={3}>
                    <Grid item xs={8} px={6}>
                      <MDTypography>Select Concept:</MDTypography>
                    </Grid>
                    <Grid item xs={8} px={6}>
                      <Autocomplete
                        options={activeCategories}
                        fullWidth
                        getOptionLabel={(option) => option}
                        renderOption={(props, option) => (
                          <MDBox component="li" {...props}>
                            {option} ({categoryCount[option] ? categoryCount[option] : 0})
                          </MDBox>
                        )}
                        onChange={(event, category) => {
                          setCategory(category);
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            placeholder="- select -"
                            label="Category"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            helperText="Category filter is optional"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} px={6}>
                      <Autocomplete
                        value={conceptsV || null}
                        onChange={(event, newConcept) => {
                          setConcept(newConcept);
                        }}
                        options={
                          categoryV
                            ? conceptOptions.filter((concept) => concept.block === categoryV)
                            : conceptOptions
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <MDInput {...params} variant="outlined" required label="Concept" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} px={6}>
                      <SelectVideo
                        selectedConcept={
                          !loadingConcepts && dataConcepts && conceptsV
                            ? conceptsV
                            : defaultConcept[0]
                        }
                      />
                    </Grid>
                  </Grid>
                  {errorConcepts && (
                    <Alert color="error">Failed to load concepts: {errorConcepts.message}</Alert>
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <SimpleFooter />
    </DashboardLayout>
  );
}

export default SearchConcepts;
