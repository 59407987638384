/* eslint-disable no-nested-ternary */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "context/ZustandStore";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton/index";

// Logo
import logo from "assets/images/logo-tree.png";

// Page components
import BaseLayout from "layouts/LearningStudio/MyAccount/components/BaseLayout";
import AddVideo from "./components/AddVideo/index";
import AddProgram from "./components/AddProgram/index";
import RequestConcept from "./components/RequestConcept/index";
import SuggestVideo from "./components/SuggestVideo/index";
import RequestProgram from "./components/RequestProgram/index";

// const checkIfMod = (value) => value === "admin" || value === "moderator1" || value === "moderator2";
function Contribute() {
  const [activeTab, setActiveTab] = useState(2);
  const [tabType, setTabType] = useState("request-concept");
  const navigate = useNavigate();

  // Bind Zustand
  const roles = useStore((state) => state.roles);

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
    <BaseLayout stickyNavbar>
      <MDBox mb={{ xs: 4, md: 8 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "100%", minWidth: "35rem", minHeight: "35rem" }}>
              <MDBox p={3}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12}>
                    <MDBox component="img" src={logo} width="3.5rem" p={1} mb={1} />
                  </Grid>
                  <Grid item alignItems="center" justifyContent="center" xs={12}>
                    <MDBox width="100%" textAlign="center" mt={{ xs: -6, lg: -2 }}>
                      <MDTypography variant="h2" fontWeight="medium">
                        Request Content
                      </MDTypography>
                      {roles.admin || roles.moderator1 || roles.moderator2 ? (
                        <MDTypography pt={2} variant="h5" fontWeight="regular">
                          Use the forms below to request content you want to see in our library.
                        </MDTypography>
                      ) : (
                        <MDTypography pt={2} variant="h5" fontWeight="regular">
                          Use the forms below to add content to our library. Your help is what makes
                          EDYou special!
                        </MDTypography>
                      )}
                    </MDBox>
                  </Grid>
                </Grid>{" "}
              </MDBox>
              <MDBox
                display="flex"
                width="100%"
                height="100%"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                sx={{ flexDirection: "column", flexGrow: 1 }}
                mb={6}
              >
                {!(roles.admin || roles.moderator1 || roles.moderator2) ? (
                  <Container>
                    <Grid container>
                      <MDBox width="100%" borderRadius="lg" bgColor="primary" opacity={0.9}>
                        <Grid item xs={10} sx={{ mx: "auto", py: 2, textAlign: "center" }}>
                          <Tabs value={activeTab} onChange={handleTabType}>
                            <Tab
                              id="suggest-video"
                              label={
                                <MDBox py={0.5} px={2} color="inherit">
                                  Suggest Video
                                </MDBox>
                              }
                            />
                            <Tab
                              id="request-program"
                              label={
                                <MDBox py={0.5} px={2} color="inherit">
                                  Request Program
                                </MDBox>
                              }
                            />
                            <Tab
                              id="request-concept"
                              label={
                                <MDBox py={0.5} px={2} color="inherit">
                                  Request New Concept
                                </MDBox>
                              }
                            />
                          </Tabs>
                        </Grid>
                      </MDBox>
                    </Grid>
                    <MDBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
                      {tabType === "add-video" ? (
                        <AddVideo />
                      ) : tabType === "suggest-video" ? (
                        <SuggestVideo />
                      ) : tabType === "add-concept-program" ? (
                        <AddProgram />
                      ) : tabType === "request-program" ? (
                        <RequestProgram />
                      ) : tabType === "request-concept" ? (
                        <RequestConcept />
                      ) : (
                        <MDTypography>Tab Error</MDTypography>
                      )}
                    </MDBox>
                  </Container>
                ) : (
                  <MDButton color="info" variant="gradient" onClick={() => navigate("/add-video")}>
                    To Moderator Forms
                  </MDButton>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Contribute;
