import { useSlateStatic, useSelected, useFocused, ReactEditor } from "slate-react";
import { Transforms } from "slate";
import { Icon } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox/index";
import MDButton from "components/MDButton/index";

function Image({ attributes, children, element }) {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  return (
    <MDBox {...attributes}>
      {children}
      <MDBox sx={{ position: "relative" }}>
        <MDBox
          component="img"
          src={element.url}
          display="block"
          maxWidth="100%"
          maxHeight="20em"
          sx={{ boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none" }}
          alt=""
        />
        <MDButton
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          color="white"
          sx={{
            display: selected && focused ? "inline" : "none",
            position: "absolute",
            top: "0.5em",
            left: "0.5em",
          }}
        >
          <Icon>delete</Icon>
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

Image.defaultProps = {
  attributes: null,
};

Image.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.any,
};

export default Image;
