/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import MDInput from "components/MDInput/index";
import { Autocomplete, alpha } from "@mui/material";
import PropTypes from "prop-types";
import {
  difficulties,
  conceptProps,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import { clone, concat } from "lodash";

function PreviewConceptCard(props) {
  const { concept, setNewConcept } = props;
  const [conceptDifficultyV, setConceptDifficulty] = useState("");
  const [conceptVideosV, setConceptVideos] = useState([]);

  const methodWithType = concept.methodVideos.map((video) => {
    const cloneVideo = clone(video);
    cloneVideo.videoType = "method";
    return cloneVideo;
  });
  const interestWithType = concept.interestVideos.map((video) => {
    const cloneVideo = clone(video);
    cloneVideo.videoType = "interest";
    return cloneVideo;
  });
  const videoOptions = concat(methodWithType, interestWithType);

  useEffect(() => {
    setConceptDifficulty("");
    setConceptVideos([]);
  }, [concept.uid]);

  return (
    <MDBox
      p={2}
      sx={{ borderRadius: 5, border: 1, backgroundColor: alpha("#00ad43", 0.3) }}
      width="50%"
    >
      <MDTypography pb={2}>{concept.name}</MDTypography>
      <Autocomplete
        value={conceptDifficultyV || null}
        onChange={(e, difficulty) => {
          setConceptDifficulty(difficulty);
          setNewConcept((oldConcept) => {
            const newConcept = clone(oldConcept);
            newConcept.difficulty = difficulty;
            return newConcept;
          });
        }}
        options={difficulties}
        renderInput={(params) => (
          <MDInput
            {...params}
            required={conceptDifficultyV !== ""}
            label={conceptDifficultyV !== "" ? "Difficulty" : "Difficulty *"}
            variant="outlined"
          />
        )}
      />
      <Autocomplete
        multiple
        value={conceptVideosV || null}
        onChange={(e, programVideos) => {
          setConceptVideos(programVideos);
          setNewConcept((oldConcept) => {
            const newConcept = clone(oldConcept);
            newConcept.programVideos = programVideos;
            return newConcept;
          });
        }}
        options={videoOptions}
        getOptionLabel={(option) => `${option.title} (${option.channelName})`}
        sx={{ mt: 2 }}
        renderInput={(params) => (
          <MDInput
            {...params}
            required={conceptVideosV !== ""}
            label={conceptVideosV !== "" ? "Program Videos" : "Program Videos *"}
            variant="outlined"
          />
        )}
      />
    </MDBox>
  );
}

export default PreviewConceptCard;

PreviewConceptCard.propTypes = {
  concept: conceptProps.isRequired,
  setNewConcept: PropTypes.func.isRequired,
};
