import { create } from "zustand";

const noRoles = { admin: false, subscriber: false, moderator1: false, moderator2: false };
const storedRoles = JSON.parse(window.sessionStorage.getItem("roles"));

// Zustand Store
// eslint-disable-next-line import/prefer-default-export
export const useStore = create((set) => ({
  roles: storedRoles || noRoles,
  setRoles: (newRoles) => set({ roles: newRoles }),
}));
