/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useRef, useState } from "react";

// @mui material components
import { Autocomplete, Stack, Alert } from "@mui/material";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography/index";

// Auth Context
import { UserAuth } from "context/AuthContext";

// Firebase
import { db } from "firebase-config";
import { collection, addDoc } from "firebase/firestore";
import { categories } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

function RequestProgram() {
  const { user } = UserAuth();
  const [categoryV, setCategory] = useState();
  const messageRef = useRef();
  const [successSB, setSuccessSB] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [key1, setKey1] = useState("Key 1");
  const [key2, setKey2] = useState("Key 2");

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success!"
      content="Your message has been sent. Someone will get back to you shortly!"
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const message = messageRef.current.value;
      const mail = {
        from: "contact-us@edyou.org",
        replyTo: user.email,
        to: "contact-us@edyou.org",
        message: {
          subject: "New Program Request",
          html: `<p>From: ${user.displayName} (${user.uid})</p>
          <p>Recommended Category: ${categoryV}</p>
           <p>Requested Program Description: ${message}</p>`,
        },
      };
      await addDoc(collection(db, "mail"), mail);
      openSuccessSB();
      messageRef.current.value = "";
      if (key1 === "Key 1") {
        setKey1("Not Key 1");
      } else {
        setKey1("Key 1");
      }
      if (key2 === "Key 2") {
        setKey2("Not Key 2");
      } else {
        setKey2("Key 2");
      }
    } catch (event) {
      setError(event);
    }
    setLoading(false);
  };

  return (
    <MDBox my={{ xs: 4, md: 6 }} px={{ xs: 6, md: 12 }}>
      <MDBox width="100%" textAlign="center" py={1} px={3}>
        <MDTypography variant="h3" fontWeight="medium" pb={4}>
          Request a New Program
        </MDTypography>
      </MDBox>
      <MDBox pt={0.5} pb={3} px={3} component="form" role="form" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Autocomplete
            key={key2}
            options={categories}
            fullWidth
            onChange={(event, category) => {
              setCategory(category);
            }}
            renderInput={(params) => (
              <MDInput
                {...params}
                placeholder="- select -"
                required
                label="Category"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
          />
          <MDInput
            variant="outlined"
            label="Request Description"
            placeholder="I want a program about ..."
            helperText="Please include a list of concepts you would expect to see in such a program"
            inputRef={messageRef}
            InputLabelProps={{ shrink: true }}
            fullWidth
            multiline
            required
            rows={6}
          />
          {error && <Alert color="error">{error.message}</Alert>}
          <MDBox textAlign="right">
            <MDButton disabled={loading} fullWidth variant="gradient" color="primary" type="submit">
              Send Message
            </MDButton>
          </MDBox>
          {renderSuccessSB}
        </Stack>
      </MDBox>
    </MDBox>
  );
}

export default RequestProgram;
