/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
// import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footer";
import PageLayout from "examples/LayoutContainers/PageLayout";

// DesktopApp page sections
import ProductLS from "layouts/CompanyWebsite/AboutEDYou/LearningStudio/sections/ProductLS";
// import LearningShouldBe from "layouts/CompanyWebsite/AboutEDYou/LearningStudio/sections/LearningShouldBe";
import Educators from "layouts/CompanyWebsite/AboutEDYou/LearningStudio/sections/Educators";
// import Testimonials from "layouts/pages/CompanyWebsite/AboutEDYou/LearningStudio/sections/Testimonials";
// import Pricing from "layouts/pages/CompanyWebsite/AboutEDYou/LearningStudio/sections/Pricing";

// Routes
import footerRoutes from "footer.routes";
import LearningModes from "./sections/LearningModes";

function LearningStudio() {
  return (
    <PageLayout>
      <DefaultNavbar
        routes="page"
        action={{
          type: "internal",
          route: "/log-in",
          label: "Sign Up/Log In",
          color: "primary",
        }}
        transparent
        light
      />
      <MDBox bgColor="white">
        <MDBox
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
              linearGradient(gradients.dark.main, gradients.dark.state),
          }}
        />
        <ProductLS />
        {/* <LearningShouldBe />    <- to be replaced by something */}
        <LearningModes />
        <Educators />
        {/* Use the Lifetime Membership section in pages/company/pricing if we decide to offer this */}
        <MDBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MDBox>
      </MDBox>
    </PageLayout>
  );
}

export default LearningStudio;
