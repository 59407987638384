/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState } from "react";

// PropTypes
import PropTypes from "prop-types";

// @mui material components
import { Container, Grid, Autocomplete } from "@mui/material"; // Checkbox, Stack

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {
  categories,
  targetAudience,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

function QuizFilter(props) {
  const { setFilters } = props;
  const [targetAudienceV, setTargetAudience] = useState(["General"]);
  const [categoriesV, setCategories] = useState([]);
  // const [includeActive, setIncludeActive] = useState(false);

  const handleFilterSubmit = () => {
    setFilters({
      targetAudience: targetAudienceV,
      categories: categoriesV,
    });
  };

  return (
    <MDBox component="section">
      <Container>
        <Grid
          container
          spacing={{ xs: 0, lg: 3 }}
          sx={{ pt: 0, pb: 3, px: 2, mx: "auto" }}
          justifyContent="space-evenly"
        >
          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
            <MDTypography display="block" variant="button" fontWeight="regular" color="text" mb={1}>
              Target Audience
            </MDTypography>
            <Autocomplete
              multiple
              value={targetAudienceV || null}
              onChange={(event, newTargetAudience) => {
                setTargetAudience(newTargetAudience);
              }}
              options={targetAudience}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  placeholder={targetAudienceV.length === 0 ? "No Filter" : ""}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
            <MDTypography display="block" variant="button" fontWeight="regular" color="text" mb={1}>
              Categories
            </MDTypography>
            <Autocomplete
              multiple
              value={categoriesV || null}
              onChange={(event, newCategories) => {
                setCategories(newCategories);
              }}
              options={categories}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  placeholder={categoriesV.length === 0 ? "No Filter" : ""}
                  variant="standard"
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} lg={1.5} sx={{ mt: 2 }}>
            <MDBox display="flex" textAlign={{ xs: "left", lg: "center" }}>
              <Stack direction={{ xs: "row", lg: "column" }} alignItems="center">
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Include Active
                </MDTypography>
                <Checkbox
                  checked={includeActive}
                  onChange={(event) => {
                    setIncludeActive(event.target.checked);
                  }}
                />
              </Stack>
            </MDBox>
                </Grid> */}
          <Grid item xs={12} lg={1.5} sx={{ mt: 3 }}>
            <MDButton variant="gradient" color="info" onClick={handleFilterSubmit} fullWidth>
              Filter
            </MDButton>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

QuizFilter.propTypes = {
  setFilters: PropTypes.func.isRequired,
};

export default QuizFilter;
