/* eslint-disable no-unused-vars */
// React
import { useState, useEffect } from "react";

// Material Dashboard 2
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// MUI
import Grid from "@mui/material/Grid";

// Auth Context
import { UserAuth } from "context/AuthContext/index";
import { countBy } from "lodash";

// RTDB Firebase
import { child, getDatabase, get, ref } from "firebase/database";

// Components
import DefaultCounterCard from "./components/DefaultCounterCard";

function UserStatistics() {
  const [conceptHistory, setConceptHistory] = useState({});
  const [vidHistory, setVidHistory] = useState({});
  const [testHistory, setTestHistory] = useState({});
  const { user } = UserAuth();

  useEffect(() => {
    const getData = async () => {
      const rdb = ref(getDatabase());
      await get(child(rdb, `conceptHistory/${user.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setConceptHistory(snapshot.val());
          }
        })
        .catch((error) => {
          console.error(error);
        });
      await get(child(rdb, `videoHistory/${user.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setVidHistory(snapshot.val());
          }
        })
        .catch((error) => {
          console.error(error);
        });
      await get(child(rdb, `testHistory/${user.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setTestHistory(snapshot.val());
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getData();
  }, []);

  const testKeys = Object.keys(testHistory);

  const countCorrectAnswers = () => {
    let correctCount = 0;
    testKeys.forEach((key) => {
      const { tests } = testHistory[key];
      // eslint-disable-next-line no-return-assign
      if (tests && !(Object.keys(tests).length === 0 && tests.constructor === Object)) {
        Object.keys(tests).forEach((test) => {
          if (tests[test].correct) {
            correctCount += 1;
          }
        });
      }
    });
    return correctCount;
  };

  return (
    <MDBox mx={{ xs: 3, lg: 8, xl: 12 }} border={1} py={5} borderRadius="lg" shadow="xl">
      <MDTypography pb={3} textAlign="center" variant="h2">
        Progress Report
      </MDTypography>
      <Grid container sx={{ justifyContent: "center" }} spacing={8}>
        <Grid item>
          <DefaultCounterCard
            color="primary"
            count={Object.keys(conceptHistory).length}
            title="Concepts"
            description="# of Concepts Learned"
          />
        </Grid>
        <Grid item>
          <DefaultCounterCard
            color="primary"
            count={Object.keys(vidHistory).length}
            title="Videos"
            description="# of Videos Watched"
          />
        </Grid>
        <Grid item>
          <DefaultCounterCard
            color="primary"
            count={testKeys.length > 0 ? countCorrectAnswers() : 0}
            title="Questions"
            description="# of Correct Answers"
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default UserStatistics;
