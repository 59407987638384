import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { PropTypes } from "prop-types";
import {
  conceptProps,
  videoProps,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import VideoConceptStepper from "./VideoConceptStepper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxWidth: "50rem",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function VideoConceptStepperModal(props) {
  const { onClose, open, currentVideo, currentConcept, conceptOptions } = props;

  const handleClose = () => {
    onClose(currentConcept, currentVideo);
  };

  const handleSelectionClose = (concept, video) => {
    onClose(concept, video);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={style}>
          <VideoConceptStepper
            handleClose={handleSelectionClose}
            currentVideo={currentVideo}
            currentConcept={currentConcept}
            conceptOptions={conceptOptions}
          />
        </MDBox>
      </Fade>
    </Modal>
  );
}

export default function VideoConceptModalWithSteps({
  currentVideo,
  setCurrentVideo,
  setVideoType,
  currentConcept,
  setCurrentConcept,
  conceptOptions,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (concept, video) => {
    setOpen(false);
    setCurrentConcept(concept);
    if (video.uid !== currentVideo.uid) {
      setVideoType("method");
    }
    setCurrentVideo(video);
  };

  return (
    <MDBox>
      <MDButton
        fullWidth
        disabled={conceptOptions[0].uid === "No Concepts"}
        variant="gradient"
        sx={{ height: 45 }}
        size="small"
        color="learn"
        onClick={handleOpen}
      >
        Topics from this Video
      </MDButton>
      <VideoConceptStepperModal
        open={open}
        onClose={handleClose}
        currentVideo={currentVideo}
        currentConcept={currentConcept}
        conceptOptions={conceptOptions}
      />
    </MDBox>
  );
}

VideoConceptStepperModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  currentVideo: videoProps.isRequired,
  currentConcept: conceptProps.isRequired,
  conceptOptions: PropTypes.arrayOf(conceptProps).isRequired,
};

VideoConceptModalWithSteps.propTypes = {
  currentVideo: videoProps.isRequired,
  setCurrentVideo: PropTypes.func.isRequired,
  setVideoType: PropTypes.func.isRequired,
  currentConcept: conceptProps.isRequired,
  setCurrentConcept: PropTypes.func.isRequired,
  conceptOptions: PropTypes.arrayOf(conceptProps).isRequired,
};
