/* eslint-disable no-nested-ternary */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState } from "react";

// @mui material components
import { Card, Grid } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/index";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography/index";

// images
import logo from "assets/images/logo-tree.png";

// components
import StoreTiles from "./components/StoreTiles/index";
import StoreFilter from "./components/StoreFilter/index";

function ProgramStore() {
  const [filters, setFilters] = useState({
    // type: "",
    targetAudience: ["General"],
    categories: [],
    includeActive: false,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar isMini />
      <MDBox>
        <Card sx={{ width: "100%", minHeight: "40.5rem", minWidth: "40.5rem" }}>
          <MDBox p={3}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <MDBox component="img" src={logo} width="3.5rem" p={1} mb={1} />
              </Grid>
              <Grid item alignItems="center" justifyContent="center" xs={12}>
                <MDBox width="100%" textAlign="center" mt={{ xs: -10, lg: -6 }}>
                  <MDTypography variant="h1" fontWeight="medium">
                    Program Store
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>{" "}
          </MDBox>
          <StoreFilter setFilters={setFilters} />
          <StoreTiles filters={filters} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ProgramStore;
