/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";

// @mui material components
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import Autocomplete from "@mui/material/Autocomplete";

// Auth context
import { UserAuth } from "context/AuthContext";

// Material Dashboard 2 PRO React context
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import { difficulties } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import ResourceFormLayout from "../components/ResourceFormLayout";

function AddVideo() {
  const [video, setVideo] = useState(false);
  const [existingVideo, setExistingVideo] = useState(false);
  const [languageV, setLanguage] = useState("English");
  const [methodConceptsV, setMethodConcepts] = useState([]);
  const [interestConceptsV, setInterestConcepts] = useState([]);
  const [contentAdvisoryV, setContentAdvisory] = useState([]);
  const [difficultyV, setDifficulty] = useState("Intro");
  const { user } = UserAuth();
  const [title, setTitle] = useState("");
  const [channelName, setChannelName] = useState("");
  const [embedId, setEmbedId] = useState("");
  const [snackbarInfo, setSnackbarInfo] = useState("");
  const [successSB, setSuccessSB] = useState(false);

  const duplicate = existingVideo && existingVideo.length !== 0;

  const ADD_VIDEO = gql`
    mutation AddVideo(
      $uid: ID!
      $title: String!
      $channelName: String!
      $embedId: String!
      $language: String!
      $contentAdvisory: [String]!
      $difficulty: String!
      $addedBy: String!
      $embeddable: Boolean!
    ) {
      createVideos(
        input: [
          {
            uid: $uid
            title: $title
            channelName: $channelName
            embedId: $embedId
            language: $language
            contentAdvisory: $contentAdvisory
            difficulty: $difficulty
            addedBy: $addedBy
            embeddable: $embeddable
          }
        ]
      ) {
        videos {
          uid
          title
        }
      }
    }
  `;

  const ADD_METHOD_CONCEPTS = gql`
    mutation AddMethodConcepts($uid: ID!, $methodConcepts: [ConceptWhere!]) {
      updateVideos(
        update: { methodConcepts: { connect: { where: { node: { OR: $methodConcepts } } } } }
        where: { uid: $uid }
      ) {
        videos {
          uid
          title
        }
      }
    }
  `;

  const ADD_INTEREST_CONCEPTS = gql`
    mutation AddInterestConcepts($uid: ID!, $interestConcepts: [ConceptWhere!]) {
      updateVideos(
        update: { interestConcepts: { connect: { where: { node: { OR: $interestConcepts } } } } }
        where: { uid: $uid }
      ) {
        videos {
          uid
          title
        }
      }
    }
  `;

  const GET_CONCEPTS = gql`
    query GetConcepts {
      concepts {
        uid
        name
      }
    }
  `;

  const GET_VIDEO = gql`
    query GetVideo($embedId: String!) {
      videos(where: { embedId: $embedId }) {
        uid
        title
        channelName
        methodConcepts {
          uid
          name
        }
        interestConcepts {
          uid
          name
        }
      }
    }
  `;

  const [createVideos, { loading, error }] = useMutation(ADD_VIDEO, {
    fetchPolicy: "no-cache",
  });
  const {
    loading: loadingConcepts,
    error: errorConcepts,
    data: dataConcepts,
    refetch,
  } = useQuery(GET_CONCEPTS);
  const [dupeCheck] = useLazyQuery(GET_VIDEO, {
    fetchPolicy: "no-cache",
  });

  const [updateMethodConcepts] = useMutation(ADD_METHOD_CONCEPTS);
  const [updateInterestConcepts] = useMutation(ADD_INTEREST_CONCEPTS);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const reset = async () => {
    setTitle("");
    setChannelName("");
    setLanguage("English");
    setContentAdvisory([]);
    setDifficulty("Intro");
    setMethodConcepts([]);
    setInterestConcepts([]);
    setExistingVideo(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success!"
      content={`"${snackbarInfo}" video has been ${duplicate ? "updated" : "added"}.`}
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  // create Neo4j entry except relationships
  const handleSubmit = async (e) => {
    e.preventDefault();
    await createVideos({
      variables: {
        uid: uuidv4(),
        title,
        channelName,
        embedId,
        language: languageV,
        contentAdvisory: contentAdvisoryV,
        difficulty: difficultyV,
        addedBy: user.uid,
        embeddable: true,
      },
      onCompleted: (newData) => setVideo(newData.createVideos.videos[0]),
    });
  };

  // handle submission for update to Neo4j data
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (methodConceptsV !== existingVideo[0].methodConcepts) {
      const methodConceptsZ = methodConceptsV.map((a) => ({ ...a }));
      methodConceptsZ.forEach((element) => delete element["__typename"]);
      updateMethodConcepts({
        variables: {
          uid: existingVideo[0].uid,
          methodConcepts: methodConceptsZ,
        },
        onCompleted: setExistingVideo(false),
      });
    }
    if (interestConceptsV !== existingVideo[0].interestConcepts) {
      const interestConceptsZ = interestConceptsV.map((a) => ({ ...a }));
      interestConceptsZ.forEach((element) => delete element["__typename"]);
      updateInterestConcepts({
        variables: {
          uid: existingVideo[0].uid,
          interestConcepts: interestConceptsZ,
        },
        onCompleted: setExistingVideo(false),
      });
    }
    if (!error) {
      refetch(dataConcepts);
      setSnackbarInfo(title);
      openSuccessSB();
      reset();
      setEmbedId("");
    }
  };

  // check Neo4j for the given embedId
  const handleDupeCheck = async (e) => {
    e.preventDefault();
    await dupeCheck({
      variables: { embedId },
      onCompleted: (newData) => setExistingVideo(newData.videos),
    });
  };

  // handle data submission for new Neo4j entry
  useEffect(() => {
    if (!video) {
      return;
    }
    if (methodConceptsV.length !== 0) {
      const methodConceptsZ = methodConceptsV.map((a) => ({ ...a }));
      methodConceptsZ.forEach((element) => delete element["__typename"]);
      updateMethodConcepts({
        variables: {
          uid: video.uid,
          methodConcepts: methodConceptsZ,
        },
        onCompleted: setVideo(false),
      });
    }
    if (interestConceptsV.length !== 0) {
      const interestConceptsZ = interestConceptsV.map((a) => ({ ...a }));
      interestConceptsZ.forEach((element) => delete element["__typename"]);
      updateInterestConcepts({
        variables: {
          uid: video.uid,
          interestConcepts: interestConceptsZ,
        },
        onCompleted: setVideo(false),
      });
    }
    if (!error) {
      refetch(dataConcepts);
      setSnackbarInfo(title);
      openSuccessSB();
      reset();
      setEmbedId("");
    }
  }, [video]);

  // Set existing video information
  useEffect(() => {
    if (!existingVideo) {
      return;
    }
    if (existingVideo.length !== 0) {
      setTitle(existingVideo[0].title);
      setChannelName(existingVideo[0].channelName);
      setMethodConcepts(existingVideo[0].methodConcepts);
      setInterestConcepts(existingVideo[0].interestConcepts);
    }
  }, [existingVideo]);

  useEffect(() => {
    if (embedId.length === 11) {
      fetch(
        `https://noembed.com/embed?dataType=json&url=https://www.youtube.com/watch?v=${embedId}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setTitle(data.title);
            setChannelName(data.author_name);
          }
        });
    }
  }, [embedId]);

  return (
    <ResourceFormLayout title="Add a Video">
      <MDBox
        width="100%"
        textAlign="center"
        component="form"
        onSubmit={handleDupeCheck}
        role="form"
        pb={5}
      >
        <MDTypography display={{ xs: "block", md: "inline" }} pr={{ xs: 0, md: 3 }}>
          Check for Existing Video:
        </MDTypography>
        <MDInput
          display={{ xs: "block", md: "inline" }}
          type="text"
          autoFocus
          value={embedId}
          onChange={(event) => {
            setExistingVideo(false);
            reset();
            setEmbedId(event.target.value);
          }}
          label="Video Embed ID"
          helperText="Everything after 'v=' in the YouTube URL"
          required
        />
        <MDButton type="submit" display={{ xs: "block", md: "inline" }}>
          Check
        </MDButton>
      </MDBox>
      <MDBox component="form" onSubmit={duplicate ? handleUpdate : handleSubmit} role="form">
        <Grid container mt={1.625} px={{ xs: 4, md: 6 }}>
          <Grid container item xs={12} md={6} px={{ xs: 0, md: 3, xl: 6 }} spacing={3}>
            <MDBox display="flex">
              <MDTypography pr={3} pt={0.5}>
                Video Information:
              </MDTypography>
              {existingVideo && (
                <MDBadge
                  badgeContent={duplicate ? "Existing Video" : "New Video"}
                  color={duplicate ? "info" : "success"}
                  container
                  circular
                  variant="contained"
                />
              )}
            </MDBox>
            <Grid item xs={12}>
              <MDBox>
                <MDInput
                  disabled={!existingVideo}
                  inputProps={{ readOnly: duplicate }}
                  type="text"
                  value={title}
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                  label="Title"
                  required
                  fullWidth
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDInput
                  disabled={!existingVideo}
                  inputProps={{ readOnly: duplicate }}
                  sx={{ focused: true }}
                  type="text"
                  value={channelName}
                  onChange={(event) => {
                    setChannelName(event.target.value);
                  }}
                  label="Channel Name"
                  required
                  fullWidth
                />
              </MDBox>
            </Grid>
            {existingVideo && existingVideo.length === 0 && (
              <Grid item xs={12}>
                <Autocomplete
                  disabled={!existingVideo}
                  value={languageV || null}
                  onChange={(event, newLanguage) => {
                    setLanguage(newLanguage);
                  }}
                  options={["English", "Spanish", "French", "Hindi", "Chinese", "Vietnamese"]}
                  renderInput={(params) => (
                    <MDInput {...params} variant="outlined" required label="Language" />
                  )}
                />
              </Grid>
            )}
            {existingVideo && existingVideo.length === 0 && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  value={contentAdvisoryV || []}
                  onChange={(event, newContentAdvisory) => {
                    setContentAdvisory(newContentAdvisory);
                  }}
                  options={["Language", "Violence/Gore", "Mental Illness", "Racism"]}
                  renderInput={(params) => (
                    <MDInput {...params} variant="outlined" label="Content Advisory" />
                  )}
                />
              </Grid>
            )}
            {existingVideo && existingVideo.length === 0 && (
              <Grid item xs={12}>
                <Autocomplete
                  value={difficultyV || "Intro"}
                  disabled={!existingVideo}
                  onChange={(event, newDifficulty) => {
                    setDifficulty(newDifficulty);
                  }}
                  options={difficulties}
                  renderInput={(params) => (
                    <MDInput {...params} label="Difficulty" required variant="outlined" />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} md={6} px={{ xs: 0, md: 3, xl: 6 }} spacing={3}>
            <MDBox mt={{ xs: 6, md: 0 }}>
              <MDTypography pt={0.5}>Video Concepts:</MDTypography>
            </MDBox>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disabled={!existingVideo || loadingConcepts}
                value={methodConceptsV || null}
                onChange={(event, newMethodConcepts) => {
                  setMethodConcepts(newMethodConcepts);
                }}
                options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <MDInput {...params} variant="outlined" label="Is Method For ..." />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disabled={!existingVideo || loadingConcepts}
                value={interestConceptsV || null}
                onChange={(event, newInterestConcepts) => {
                  setInterestConcepts(newInterestConcepts);
                }}
                options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <MDInput {...params} variant="outlined" label="Is Interest For ..." />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {errorConcepts && (
          <Alert color="error">Failed to load concepts: {errorConcepts.message}</Alert>
        )}
        {error && <Alert color="error">{error.message}</Alert>}
        <MDBox textAlign="center" pt={6} mb={8}>
          <MDButton
            type="submit"
            disabled={loading}
            sx={{ width: "25%" }}
            variant="gradient"
            color={duplicate ? "info" : "primary"}
          >
            {duplicate ? "Update" : "Submit"}
          </MDButton>
          {renderSuccessSB}
        </MDBox>
      </MDBox>
    </ResourceFormLayout>
  );
}

export default AddVideo;
