import React from "react";
import { PropTypes } from "prop-types";
import MDBox from "components/MDBox/index";
import Grid from "@mui/material/Grid";
import ProgramCard from "./ProgramCard/index";

function CardDisplay(props) {
  const { filteredProgramData, loading, activePrograms, setActivePrograms, onceActivePrograms } =
    props;
  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <MDBox>
      <Grid container rowSpacing={{ xs: 6, md: 7 }} columnSpacing={4}>
        {/* eslint-disable-next-line arrow-body-style */}
        {filteredProgramData?.map((program) => {
          return (
            <Grid
              key={program.programId}
              item
              xs={12}
              md={6}
              lg={4}
              xxl={3}
              justifyContent="center"
            >
              <MDBox width="100%" display="flex" justifyContent="center" height="100%">
                <ProgramCard
                  program={program}
                  setActivePrograms={setActivePrograms}
                  onceActivePrograms={onceActivePrograms}
                  activePrograms={activePrograms}
                />
              </MDBox>
            </Grid>
          );
        })}
      </Grid>
    </MDBox>
  );
}

const programConceptProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  person: PropTypes.bool,
  place: PropTypes.bool,
  event: PropTypes.bool,
  methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  interestVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  programConcepts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
});

CardDisplay.propTypes = {
  filteredProgramData: PropTypes.arrayOf(
    PropTypes.shape({
      addedBy: PropTypes.string.isRequired,
      programId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      targetAudience: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      conceptList: PropTypes.arrayOf(programConceptProps),
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  activePrograms: PropTypes.arrayOf(PropTypes.string).isRequired,
  onceActivePrograms: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActivePrograms: PropTypes.func.isRequired,
};

export default CardDisplay;
