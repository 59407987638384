/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Kit 2 PRO React examples
import TransparentBlogCard from "layouts/CompanyWebsite/AboutEDYou/LearningStudio/components/TransparentBlogCard";

// HelpCenter page components
import ListItem from "layouts/CompanyWebsite/AboutEDYou/LearningStudio/components/ListItem";

function LearningModes() {
  return (
    <MDBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          xs={10}
          lg={5}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MDTypography variant="h1" mt={4} mb={2}>
            3 Learning Modes
          </MDTypography>
          <MDTypography variant="body2" color="text">
            We offer three interoperable learning modalities. Use your favorite or use them all. The
            choice is yours!
          </MDTypography>
        </Grid>
        <Grid container spacing={3} alignItems="center" sx={{ mt: 8 }}>
          <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
            <TransparentBlogCard
              image="https://images.unsplash.com/photo-1501504905252-473c47e087f8?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              label="program mode"
              title="Follow Your Curiosity"
              description="We encourage people to dream big and learn endlessly. Explore as much as you like from our diverse library of resources."
              action={{
                type: "internal",
                color: "dark",
                route: "/log-in",
                label: "start exploring",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <MDTypography variant="h2" mb={2}>
              Program Mode
            </MDTypography>
            <ListItem title="1. Curiosity-Driven Learning">
              Explore moderator-created learning experiences by choosing the concepts you want to
              learn next. You can dive deeper into a topic, take a step back to see the bigger
              picture, see related people, places, and events. You have control!
            </ListItem>
            <ListItem title="2. Learn With A Theme">
              Check out our thematic programs, such as the &#34;Chemistry of Baking&#34; or
              &#34;Famous Scientists and Their Creations&#34;. Learn academic subjects through
              related interests, a specific perspective, or a particular group of instructors.
            </ListItem>
            <ListItem title="3. Learning Without Limits">
              This is learning unbounded. When you are working in a program we will recommend
              concepts from other learning experiences so you can exceed the boundaries of any one
              experience.
            </ListItem>
          </Grid>
        </Grid>
        <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MDTypography variant="h2" mb={2}>
              Quiz Mode
            </MDTypography>
            <ListItem title="1. Check Your Understanding">
              If quiz questions are available, you&apos;ll have the option to check your
              understanding after watching a video or by generating a review for an active program.
              Attempted questions are stored on your progress page so you can revisit concepts you
              haven&apos;t quite mastered.
            </ListItem>
            <ListItem title="2. Browse our Catalog of Quizzes">
              Find the quiz that&apos;s right for you! Do you think you remember elementary school
              math? Find out! Are you a weather junkie? See how much you&apos;ve learned. Brush up
              on concepts as you go or complete the whole quiz. You can always find a summary of the
              concepts you haven&apos;t mastered on the “My Progress” page.
            </ListItem>
            <ListItem title="3. Challenge Yourself">
              Each week, 7 “Quizzes of the Week” challenge you to think critically and expand your
              knowledge about hot topics. The best way to “train your brain” and stay sharp is to
              learn something new every day. This fun variety of weekly quizzes is a great way to
              stay mentally fit!
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <TransparentBlogCard
              image="https://images.unsplash.com/photo-1509869175650-a1d97972541a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzF8fHF1aXp8ZW58MHx8MHx8fDA%3D"
              label="quiz mode"
              title="Put Your Knowledge to the Test"
              description="Testing is not the end goal of our platform, but challenging yourself to answer questions is a great way to consolidate your learning."
              action={{
                type: "internal",
                color: "dark",
                route: "/log-in",
                label: "Test Yourself",
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
            <TransparentBlogCard
              image="https://images.unsplash.com/photo-1483736762161-1d107f3c78e1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDN8fGRhdGF8ZW58MHx8MHx8fDA%3D"
              label="search mode"
              title="Search Our Concept Library"
              description="Search our library of concepts for the topics that interest you most. After a search we help you explore related topics to learn more."
              action={{
                type: "internal",
                color: "dark",
                route: "/log-in",
                label: "Search Concepts Now",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <MDTypography variant="h2" mb={2}>
              Search Mode
            </MDTypography>
            <ListItem title="1.	Focus on a Specific Learning Goal">
              Search our library of concepts to learn more about the hottest topics. We are
              constantly expanding the topics we cover so you can always be up-to-date on trending
              technologies, skills, and policy issues.
            </ListItem>
            <ListItem title="2.	Guided Progression">
              Whatever your starting point, EDYou&apos;s navigation options will help you choose a
              logical next step. You can dive deep on that topic, zoom out a bit, explore related
              topics, or choose “See It In Action” to see how that concept relates to the real
              world.
            </ListItem>
            <ListItem title="3. There's More Than One Explanation">
              For each concept, EDYou indexes a variety of high-quality videos so you can find
              content creators and teaching methods that work best for you. Our library is
              constantly expanding to bring you videos from experts all over the world on each
              concept. Don&apos;t see what you&apos;re looking for? Go to “Request Content” in the
              studio and let us know.
            </ListItem>
          </Grid>
        </Grid>
        <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MDTypography variant="h2" mb={2}>
              Track Your Progress
            </MDTypography>
            <ListItem title="1. Progress Anywhere is Progress Everywhere">
              We track user progress at the concept and video level. Whatever learning mode you work
              in, your progress will be saved and count towards your progress in other modes.
            </ListItem>
            <ListItem title="2. Statistics for Your Growth">
              Check out your personal progress dashboard. See how many videos and concepts
              you&apos;ve mastered, which subjects are your favorite, and more!
            </ListItem>
            <ListItem title="3. Turn Your Progress Into Self-Expression (Future)">
              We want EDYou to be a place that connects learning to how you see yourself. We plan to
              roll out an achievement system that will reward you with access to themes and assets
              that reflect your learning.
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <TransparentBlogCard
              image="https://images.unsplash.com/photo-1674027444485-cec3da58eef4?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              label="Track Progress"
              title="Check Your Progress Metrics"
              description="See how much you've learned with your personal progress dashboard!"
              action={{
                type: "internal",
                color: "dark",
                route: "/log-in",
                label: "See You Progress",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default LearningModes;
