/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Firebase
import { analytics } from "firebase-config";
import { logEvent } from "firebase/analytics";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context/NavContext";
import { ErrorBoundary } from "react-error-boundary";
import { UserAuth } from "context/AuthContext/index";

// Images
import brandWhite from "assets/images/logo-tree.png";
import brandDark from "assets/images/logo-tree-dark.png";
import ProtectedRoute from "protected-route";
import AppError from "layouts/LearningStudio/ErrorBoundary/AppError";
import { MathJaxContext } from "better-react-mathjax";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const { user, roles, userRoles } = UserAuth();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const errorRedirect = () => {
    if (user && (roles.stripeRole === "subscriber" || userRoles.modRole === "admin")) {
      navigate("/studio");
    } else {
      navigate("/");
    }
  };

  const logError = (error) => {
    logEvent(analytics, "error", error);
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              route.protected ? (
                <ProtectedRoute access={route.protected} route={route.route}>
                  {route.component}
                </ProtectedRoute>
              ) : (
                route.component
              )
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <MathJaxContext>
        <CssBaseline />
        {layout === "dashboard" && (
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="EDYou Learning Studio"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
        {layout === "vr"}
        {!process.env.NODE_ENV || process.env.NODE_ENV === "development" ? (
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        ) : (
          <ErrorBoundary FallbackComponent={AppError} onReset={errorRedirect} onError={logError}>
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </ErrorBoundary>
        )}
      </MathJaxContext>
    </ThemeProvider>
  );
}
