/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// MUI
import EastIcon from "@mui/icons-material/East";
import { values, uniqBy, countBy, union } from "lodash";

// Firestore && RTDB
import { child, getDatabase, ref, get } from "firebase/database";

// Auth Context
import { UserAuth } from "context/AuthContext";
import SelectDifficulty from "../SelectDifficultyModal/index";

// Defaults
// import { defaultConcept } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

/* function convertTimestamp(timestamp) {
  const dateInMillis = timestamp;
  const date = new Date(dateInMillis);
  const stringDate = date.toLocaleDateString();
  return stringDate;
} */

export default function conceptProgressData() {
  const { user } = UserAuth();
  // const [selectedTest, setTest] = useState();

  const defaultRows = [
    {
      conceptName: (
        <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
          Loading Progress ...
        </MDTypography>
      ),
      conceptBlock: (
        <MDTypography variant="button" lineHeight={2} fontWeight="medium">
          ...
        </MDTypography>
      ),
      numVideos: (
        <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
          ...
        </MDTypography>
      ),
      numCorrectTests: (
        <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
          ...
        </MDTypography>
      ),
      knowledgeCheck: (
        <MDButton>
          <EastIcon color="dark" fontSize="large" />
        </MDButton>
      ),
    },
  ];

  const [rows, setRows] = useState(defaultRows);

  /* if (!loadingTests && dataTests && !selectedTest) {
    const concept = dataTests.concepts[0];
    const selectedIndex = random(concept.testingResources.length - 1);
    const remainingTestingResources = cloneDeep(concept.testingResources);
    remainingTestingResources.splice(selectedIndex, 1);
    const test = concept.testingResources[selectedIndex];
    setTest({
      quiz: {
        programId: concept.uid,
        conceptList: [concept],
      },
      currentConcept: concept,
      currentTest: test,
      testingResources: concept.testingResources,
      remainingTestingResources,
    });
  } */

  useEffect(() => {
    // declare the data fetching function
    const createRows = async () => {
      // some needed variables
      let conceptHistory;
      let testHistory;

      const rdb = ref(getDatabase());
      await get(child(rdb, `conceptHistory/${user.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            conceptHistory = snapshot.val();
          }
        })
        .catch((error) => {
          console.error(error);
        });

      await get(child(rdb, `testHistory/${user.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            testHistory = snapshot.val();
          }
        })
        .catch((error) => {
          console.error(error);
        });

      const conceptHistoryKeys = Object.keys(conceptHistory);
      const testHistoryKeys = Object.keys(testHistory);
      const historyKeys = union(conceptHistoryKeys, testHistoryKeys);

      const history = historyKeys.map((key) => {
        const inConceptHistory = conceptHistoryKeys.includes(key)
          ? Object.prototype.hasOwnProperty.call(conceptHistory[key], "videos")
          : false;
        const inTestHistory = testHistoryKeys.includes(key)
          ? Object.prototype.hasOwnProperty.call(testHistory[key], "tests")
          : false;
        return {
          uid: key,
          conceptName: inConceptHistory
            ? conceptHistory[key].conceptName
            : inTestHistory
            ? testHistory[key].conceptName
            : "{missing}",
          conceptCategory: inConceptHistory
            ? conceptHistory[key].conceptCategory
            : inTestHistory
            ? testHistory[key].conceptCategory
            : "{missing}",
          videos: inConceptHistory ? values(conceptHistory[key].videos) : [],
          tests: inTestHistory ? values(testHistory[key].tests) : [],
        };
      });

      // Print the info on all programs
      let pRows;
      if (history) {
        pRows = history.map((concept) => {
          // const dateTime = convertTimestamp(concept.timestamp);
          const proto = {
            conceptName: (
              <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
                {concept.conceptName}
              </MDTypography>
            ),
            conceptBlock: (
              <MDTypography variant="button" lineHeight={2} fontWeight="medium">
                {concept.conceptCategory}
              </MDTypography>
            ),
            numVideos: (
              <MDTypography component="a" variant="button" fontWeight="medium">
                {uniqBy(concept.videos, "videoUid").length}
              </MDTypography>
            ),
            numCorrectTests: (
              <MDTypography component="a" variant="button" fontWeight="medium">
                {concept.tests.length > 0 ? countBy(concept.tests, "correct").true : 0}
              </MDTypography>
            ),
            knowledgeCheck: <SelectDifficulty selectedConcept={concept.uid} />,
          };
          return proto;
        });
      } else {
        pRows = [
          {
            conceptName: (
              <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
                No Learning History
              </MDTypography>
            ),
            numVideos: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                ...
              </MDTypography>
            ),
            numCorrectTests: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                ...
              </MDTypography>
            ),
            knowledgeCheck: (
              <MDButton>
                <EastIcon color="dark" fontSize="large" />
              </MDButton>
            ),
          },
        ];
      }
      setRows(pRows);
    };

    // call the function
    createRows()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const columns = [
    { Header: "concept name", accessor: "conceptName", align: "left" },
    { Header: "category", accessor: "conceptBlock", align: "left" },
    { Header: "videos watched", accessor: "numVideos", width: "10%", align: "center" },
    { Header: "correct answers", accessor: "numCorrectTests", width: "10%", align: "center" },
    { Header: "knowledge check", accessor: "knowledgeCheck", width: "10%", align: "center" },
  ];

  return {
    columns,
    rows,
  };
}
