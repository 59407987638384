/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { useRef, useEffect, useState } from "react";
import { Card } from "@mui/material";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import { db, rdb } from "firebase-config";
import { getDocs, collection, query, orderBy, limit, where } from "firebase/firestore";
import { child, get, ref } from "firebase/database";
import { UserAuth } from "context/AuthContext/index";
import { pickBy } from "lodash";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

// components
import ProgramCard from "./ProgramCard/index";

function NewlyAddedPrograms() {
  const [programData, setProgramData] = useState([]);
  const [activePrograms, setActivePrograms] = useState([]);
  const [onceActivePrograms, setOnceActivePrograms] = useState([]);
  const { user } = UserAuth();

  SwiperCore.use([Autoplay, Navigation]);

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  // const [swiperEl, setSwiperEl] = useState(null);

  // Fetch 5 Most Recently Added Programs
  useEffect(() => {
    const getProgramData = async () => {
      const colRef = collection(db, "programs");
      const q = query(
        colRef,
        where("timestamp", "!=", null),
        orderBy("timestamp", "desc"),
        limit(5)
      );
      const querySnapshot = await getDocs(q);
      const docData = [];
      querySnapshot.forEach((doc) => {
        docData.push(doc.data());
      });
      setProgramData(docData);
    };
    getProgramData();
  }, []);

  // Fetch RDB Program History
  useEffect(() => {
    const getProgramHistory = async () => {
      const database = ref(rdb);
      await get(child(database, `programHistory/${user.uid}`)).then((snapshot) => {
        if (snapshot.exists()) {
          const programSnapshot = snapshot.val();
          const onceActiveKeys = Object.keys(programSnapshot);
          const activeKeys = Object.keys(pickBy(programSnapshot, (program) => program.active));
          setOnceActivePrograms(onceActiveKeys);
          setActivePrograms(activeKeys);
        }
      });
    };
    getProgramHistory();
  }, []);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox height="100%" width="100%" p={3} textAlign="center">
        <MDTypography variant="h3" fontWeight="medium">
          Newly Added Programs
        </MDTypography>
        <Swiper
          navigation
          modules={[Navigation]}
          onInit={(swiper) => {
            // setSwiperEl(swiper);

            const { navigation: nav } = swiper.params;
            const { navigation } = swiper;

            nav.prevEl = navigationPrevRef.current;
            nav.nextEl = navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          autoplay={{ delay: 5000 }}
          speed={800}
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={false}
          loop
        >
          {programData.map((program) => (
            <SwiperSlide key={program.programId}>
              <MDBox
                py={3}
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ProgramCard
                  program={program}
                  setActivePrograms={setActivePrograms}
                  onceActivePrograms={onceActivePrograms}
                  activePrograms={activePrograms}
                />
              </MDBox>
            </SwiperSlide>
          ))}
        </Swiper>
      </MDBox>
    </Card>
  );
}

export default NewlyAddedPrograms;
