/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleFooter from "examples/Footer/SimpleFooter";
import DataTable from "layouts/LearningStudio/FocusMode/MyPrograms/components/SeeList/components/DataTable";

// Data
import programsData from "layouts/LearningStudio/FocusMode/MyPrograms/components/SeeList/components/SeeListData";
import GenerateReview from "./components/GenerateReviewModal/index";

function SeeList() {
  const { state } = useLocation();
  const program = state.selectedProgram;
  const { columns: pColumns, rows: pRows } = programsData();

  return (
    <DashboardLayout>
      <DashboardNavbar isMini />
      <MDBox pt={6} pb={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h5" color="white">
                  {program.name}
                </MDTypography>
                <GenerateReview selectedProgram={program} />
              </MDBox>
              <MDBox pt={3} width="100%" display>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  showTotalEntries
                  noEndBorder
                  isSorted={false}
                  // canSearch
                  // really want to get sort and search working
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <SimpleFooter />
    </DashboardLayout>
  );
}

export default SeeList;
