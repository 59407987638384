/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { Grid, Card, Icon } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton/index";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleFooter from "examples/Footer/SimpleFooter";
import DataTable from "layouts/LearningStudio/FocusMode/MyPrograms/components/SeeList/components/DataTable";

// Firebase RDB
import { rdb } from "firebase-config";
import { set, ref, update } from "firebase/database";

// UserAuth
import { UserAuth } from "context/AuthContext/index";

// Data
import programsData from "./components/SeeListData";

function ProgramList() {
  const { state } = useLocation();
  const program = state.selectedProgram;
  // eslint-disable-next-line prefer-destructuring
  const onceActivePrograms = state.onceActivePrograms || [];
  const active = state.active || false;
  const { user } = UserAuth();
  const navigate = useNavigate();
  const { columns: pColumns, rows: pRows } = programsData();

  const handleAddProgram = async (e) => {
    e.preventDefault();
    if (onceActivePrograms.includes(program.programId)) {
      await update(ref(rdb, `programHistory/${user.uid}/${program.programId}`), {
        active: true,
      });
    } else {
      await set(ref(rdb, `programHistory/${user.uid}/${program.programId}`), {
        index: 0,
        active: true,
      });
      await program.conceptList.forEach((concept) =>
        set(ref(rdb, `programHistory/${user.uid}/${program.programId}/${concept.uid}`), {
          completed: false,
        })
      );
    }
    // setActivePrograms((prevState) => [...prevState, program.programId]);
    navigate("/studio/my-programs");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar isMini />
      <MDBox pt={6} pb={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {program.name}
                </MDTypography>
                {!active && (
                  <MDButton
                    variant="gradient"
                    circular
                    iconOnly
                    size="medium"
                    color="white"
                    onClick={handleAddProgram}
                  >
                    <Icon fontSize="large" color="info">
                      add
                    </Icon>
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  showTotalEntries
                  noEndBorder
                  isSorted={false}
                  // canSearch
                  // really want to get sort and search working
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <SimpleFooter />
    </DashboardLayout>
  );
}

export default ProgramList;
