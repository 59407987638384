import { useState } from "react";
import { Backdrop, Modal, Fade } from "@mui/material";
import { useSlateStatic } from "slate-react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { PropTypes } from "prop-types";
import AnswersForm from "./AnswersForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxWidth: "50rem",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function AnswerModal(props) {
  const { onClose, open, answerType } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmitClose = (data) => {
    onClose(data);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={style}>
          <AnswersForm answerType={answerType} handleSubmitClose={handleSubmitClose} />
        </MDBox>
      </Fade>
    </Modal>
  );
}

AnswerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  answerType: PropTypes.string.isRequired,
};

export default function AnswerModalButton({
  insertAnswers,
  answerType,
  setAnswerData,
  // answerData,
}) {
  const [open, setOpen] = useState(false);
  const editor = useSlateStatic();

  const handleOpen = () => setOpen(true);
  const handleClose = (data) => {
    setOpen(false);
    if (data) {
      setAnswerData(data);
      insertAnswers(editor, data, answerType);
    }
  };

  return (
    <MDBox display="flex" justifyContent="center" mt={-1} mb={2}>
      <MDButton variant="contained" color="info" onClick={handleOpen}>
        Insert Answers
      </MDButton>
      <AnswerModal open={open} onClose={handleClose} answerType={answerType} />
    </MDBox>
  );
}

AnswerModalButton.propTypes = {
  insertAnswers: PropTypes.func.isRequired,
  answerType: PropTypes.string.isRequired,
  answerData: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string).isRequired,
    correctAnswer: PropTypes.string,
    answerType: PropTypes.string.isRequired,
  }).isRequired,
  setAnswerData: PropTypes.func.isRequired,
};
