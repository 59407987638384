/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import EastIcon from "@mui/icons-material/East";
import { blue, grey } from "@mui/material/colors";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import { gql, useLazyQuery } from "@apollo/client";
import { rdb } from "firebase-config";
import { ref, update } from "firebase/database";
import { UserAuth } from "context/AuthContext";
import {
  defaultVideo,
  defaultConcept,
  videoProps,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

const GET_FULL_CONCEPT = gql`
  query GetFullConcept($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      name
      block
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
    }
  }
`;

function StartHereVideoDialog(props) {
  const { onClose, selectedVideo, open, selectedConcept } = props;

  let videoOptions = defaultVideo;

  const [getFullConcept, { loading: loadingConcept }] = useLazyQuery(GET_FULL_CONCEPT);

  const handleClose = () => {
    onClose(selectedConcept, selectedVideo);
  };

  const handleListItemClick = async (video) => {
    if (loadingConcept) {
      return;
    }
    await getFullConcept({
      variables: { uid: selectedConcept.uid },
      onCompleted: (newData) => onClose(newData.concepts[0], video),
    });
  };

  if (open) {
    videoOptions = selectedConcept.programVideos;
  }

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <MDBox display="flex" justifyContent="space-between">
        <DialogTitle>Select a Video:&nbsp;&nbsp;{selectedConcept.name}</DialogTitle>
        {loadingConcept && (
          <MDBox pr={2} pt={2}>
            <CircularProgress color="info" display="inline" size={30} />
          </MDBox>
        )}
      </MDBox>
      <List sx={{ pt: 0, px: 2, pb: 2 }}>
        {videoOptions.map((video) => (
          <ListItem button onClick={() => handleListItemClick(video)} key={video.uid}>
            <ListItemAvatar>
              <MDAvatar sx={{ color: blue[600] }}>
                <PlayCircleIcon fontSize="large" />
              </MDAvatar>
            </ListItemAvatar>
            <ListItemText primary={video.title} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleClose()}>
          <ListItemAvatar>
            <MDAvatar sx={{ color: grey[600] }}>
              <CloseIcon fontSize="medium" />
            </MDAvatar>
          </ListItemAvatar>
          <ListItemText primary="Cancel" />
        </ListItem>
      </List>
    </Dialog>
  );
}

export default function StartHereVideo({ selectedConcept, selectedProgram, currentProgramIndex }) {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [open, setOpen] = useState(false);
  const [selectedVideo, setVideo] = useState(defaultVideo[0]);
  const [fullSelectedConcept, setFullSelectedConcept] = useState(defaultConcept[0]);
  const isFirstRender = useRef(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (concept, video) => {
    setOpen(false);
    setFullSelectedConcept(concept);
    setVideo(video);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    update(
      ref(
        rdb,
        `programHistory/${user.uid}/${selectedProgram.programId}/${fullSelectedConcept.uid}`
      ),
      {
        completed: true,
      }
    );

    update(ref(rdb, `programHistory/${user.uid}/${selectedProgram.programId}`), {
      index: currentProgramIndex,
    });
    navigate("/studio/viewer", {
      state: {
        selectedVideo,
        selectedConcept: fullSelectedConcept,
        selectedProgram,
        currentProgramIndex,
        videoType: selectedVideo.videoType,
        source: "program",
      },
    });
  }, [selectedVideo]);

  return (
    <MDBox>
      <MDButton onClick={handleClickOpen}>
        <EastIcon color="dark" fontSize="large" />
      </MDButton>
      <StartHereVideoDialog
        selectedVideo={selectedVideo}
        open={open}
        onClose={handleClose}
        selectedConcept={selectedConcept}
      />
    </MDBox>
  );
}

const videoProgramConceptProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  programVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
});

StartHereVideoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedVideo: videoProps.isRequired,
  selectedConcept: videoProgramConceptProps.isRequired,
};

StartHereVideo.propTypes = {
  selectedConcept: videoProgramConceptProps.isRequired,
  selectedProgram: PropTypes.shape({
    addedBy: PropTypes.string,
    conceptList: PropTypes.arrayOf(videoProgramConceptProps.isRequired),
    name: PropTypes.string,
    programId: PropTypes.string,
  }).isRequired,
  currentProgramIndex: PropTypes.number.isRequired,
};
