/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";

function Leaf({ attributes, children, leaf }) {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <span {...attributes}>{children}</span>;
}

Leaf.defaultProps = {
  attributes: null,
};

Leaf.propTypes = {
  children: PropTypes.node.isRequired,
  leaf: PropTypes.shape({
    bold: PropTypes.bool,
    code: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.any,
};

export default Leaf;
