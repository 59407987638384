import PropTypes from "prop-types";
import MDBox from "components/MDBox/index";

function Image({ element, children }) {
  console.log(element.align);
  return (
    <MDBox display="flex" justifyContent={element.align || "center"}>
      {children}
      <MDBox sx={{ position: "relative" }}>
        <MDBox
          component="img"
          src={element.url}
          display="block"
          maxWidth="100%"
          maxHeight="20em"
          alt=""
        />
      </MDBox>
    </MDBox>
  );
}

Image.defaultProps = {
  children: null,
};

Image.propTypes = {
  children: PropTypes.node,
  element: PropTypes.shape({
    align: PropTypes.string,
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};

export default Image;
