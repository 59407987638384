import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import MDBox from "components/MDBox/index";

export default function MultipleChoiceAnswers(props) {
  const { attributes, children, answerData } = props;
  return (
    <MDBox contentEditable={false} pl={2} {...attributes}>
      <FormControl sx={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby="answer-choices"
          // defaultValue={answerData.correctAnswer || null}
          value={answerData.correctAnswer}
        >
          {answerData.answers.map((answer) => (
            <MDBox key={uniqueId(`answer-`)} display="flex" width="100%">
              <FormControlLabel value={answer} control={<Radio />} label={answer} />
            </MDBox>
          ))}
        </RadioGroup>
      </FormControl>
      {children}
    </MDBox>
  );
}

MultipleChoiceAnswers.defaultProps = {
  attributes: null,
};

MultipleChoiceAnswers.propTypes = {
  children: PropTypes.node.isRequired,
  answerData: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string).isRequired,
    correctAnswer: PropTypes.string.isRequired,
    answerType: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.any,
  setAnswerData: PropTypes.func.isRequired,
};
