/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// MUI
import EastIcon from "@mui/icons-material/East";
import CircularProgress from "@mui/material/CircularProgress";

// GraphQl
import { gql, useLazyQuery } from "@apollo/client";

// Firestore && RTDB
import { child, getDatabase, ref, get } from "firebase/database";

// Auth Context
import { UserAuth } from "context/AuthContext";

// Defaults
import {
  defaultVideo,
  defaultConcept,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

const GET_METHOD_VIDEO = gql`
  query GetMethodVideo($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      block
      name
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
      interestVideos {
        uid
        embedId
        title
        channelName
      }
    }
  }
`;

function convertTimestamp(timestamp) {
  const dateInMillis = timestamp;
  const date = new Date(dateInMillis);
  const stringDate = date.toLocaleDateString();
  return stringDate;
}
function timeout(delay) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((res) => setTimeout(res, delay));
}

export default function recentHistoryData() {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const firstRender = useRef(true);
  const firstRender2 = useRef(true);
  const [selectedVideo, setSelectedVideo] = useState({
    conceptId: defaultConcept[0].uid,
    videoId: defaultVideo[0].uid,
    videoType: "method",
  });
  const [finalVideo, setFinalVideo] = useState();

  const defaultRows = [
    {
      title: (
        <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
          Loading Videos ...
        </MDTypography>
      ),
      concept: (
        <MDTypography variant="button" lineHeight={2} fontWeight="medium">
          ...
        </MDTypography>
      ),
      videoType: (
        <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
          ...
        </MDTypography>
      ),
      dateWatched: (
        <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
          ...
        </MDTypography>
      ),
      startHere: (
        <MDButton>
          <EastIcon color="dark" fontSize="large" />
        </MDButton>
      ),
    },
  ];

  const [rows, setRows] = useState(defaultRows);

  const [getFullData, { loading: loadingMethodVideos, data: dataMethodVideos }] =
    useLazyQuery(GET_METHOD_VIDEO);

  useEffect(() => {
    if (firstRender2.current) {
      firstRender2.current = false;
      return;
    }
    const handleVideoClick = async () => {
      // Need to call getFullData with the concept uid
      await getFullData({ variables: { uid: selectedVideo.conceptId } });
    };
    handleVideoClick();
  }, [selectedVideo]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    navigate("/studio/viewer", {
      state: {
        selectedConcept: finalVideo.concept,
        selectedVideo: finalVideo.video,
        videoType: finalVideo.videoType,
        source: "history",
      },
    });
  }, [finalVideo]);

  if (!loadingMethodVideos && dataMethodVideos && !finalVideo) {
    const concept = dataMethodVideos.concepts[0];
    const video =
      selectedVideo.videoType === "method"
        ? concept.methodVideos.find((element) => element.uid === selectedVideo.videoId)
        : concept.interestVideos.find((element) => element.uid === selectedVideo.videoId);
    setFinalVideo({
      concept,
      video,
      videoType: selectedVideo.videoType,
    });
  }

  useEffect(() => {
    // declare the data fetching function
    const createRows = async () => {
      // some needed variables
      let videoHistory;

      const rdb = ref(getDatabase());
      await get(child(rdb, `videoHistory/${user.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            videoHistory = Object.values(snapshot.val()).reverse();
          }
        })
        .catch((error) => {
          console.error(error);
        });

      // Print the info on all programs
      let pRows;
      if (videoHistory) {
        const uniqueVideoHistory = [];
        videoHistory.forEach((c) => {
          if (!uniqueVideoHistory.find((element) => element.videoUid === c.videoUid)) {
            if (uniqueVideoHistory.length >= 5) {
              return;
            }
            uniqueVideoHistory.push(c);
          }
        });
        pRows = uniqueVideoHistory.map((video) => {
          const dateTime = convertTimestamp(video.timestamp);
          const textColor =
            video.videoType === "method"
              ? "primary"
              : video.videoType === "interest"
              ? "warning"
              : "inherit";
          const proto = {
            title: (
              <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
                {video.videoTitle}
              </MDTypography>
            ),
            concept: (
              <MDTypography variant="button" lineHeight={2} fontWeight="medium">
                {video.conceptName}
              </MDTypography>
            ),
            videoType: (
              <MDTypography component="a" variant="button" color={textColor} fontWeight="medium">
                {video.videoType}
              </MDTypography>
            ),
            dateWatched: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                {dateTime}
              </MDTypography>
            ),
            startHere: (
              <MDButton
                variant="text"
                circular
                disabled={loadingMethodVideos && !dataMethodVideos}
                color="dark"
                onClick={() => {
                  setSelectedVideo({
                    conceptId: video.conceptUid,
                    videoId: video.videoUid,
                    videoType: video.videoType,
                  });
                }}
              >
                {!loadingMethodVideos && <EastIcon />}
                {loadingMethodVideos && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "info",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </MDButton>
            ),
          };
          return proto;
        });
      } else {
        pRows = [
          {
            title: (
              <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
                No Video History
              </MDTypography>
            ),
            concept: (
              <MDTypography variant="button" lineHeight={2} fontWeight="medium">
                ...
              </MDTypography>
            ),
            videoType: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                ...
              </MDTypography>
            ),
            dateWatched: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                ...
              </MDTypography>
            ),
            startHere: (
              <MDButton>
                <EastIcon color="dark" fontSize="large" />
              </MDButton>
            ),
          },
        ];
      }
      setRows(pRows);
    };

    // call the function
    createRows()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const columns = [
    { Header: "title", accessor: "title", align: "left" },
    { Header: "concept", accessor: "concept", align: "left" },
    { Header: "video type", accessor: "videoType", width: "10%", align: "center" },
    { Header: "date watched", accessor: "dateWatched", width: "10%", align: "center" },
    { Header: "start here", accessor: "startHere", width: "10%", align: "center" },
  ];

  return {
    columns,
    rows,
  };
}
