/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { gql, useMutation, useQuery } from "@apollo/client";

// @mui material components
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import Autocomplete from "@mui/material/Autocomplete";

// Auth context
import { UserAuth } from "context/AuthContext";

// Material Dashboard 2 PRO React context
import MDButton from "components/MDButton";
import { Stack } from "@mui/material";
import { categories } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import ResourceFormLayout from "../components/ResourceFormLayout";

function AddConcept() {
  const [concept, setConcept] = useState(false);
  // const [completed, setCompleted] = useState(false);
  const [categoriesV, setCategories] = useState();
  const [specialType, setSpecialType] = useState();
  const [broaderConceptsV, setBroaderConcepts] = useState([]);
  const [narrowerConceptsV, setNarrowerConcepts] = useState([]);
  const [peopleConceptsV, setPeopleConcepts] = useState([]);
  const [placeConceptsV, setPlaceConcepts] = useState([]);
  const [eventConceptsV, setEventConcepts] = useState([]);
  const [personForConceptsV, setPersonForConcepts] = useState([]);
  const [placeForConceptsV, setPlaceForConcepts] = useState([]);
  const [eventForConceptsV, setEventForConcepts] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const { user } = UserAuth();
  const nameRef = useRef("");

  const ADD_CONCEPT = gql`
    mutation AddConcept(
      $uid: ID!
      $name: String!
      $block: String!
      $addedBy: String!
      $person: Boolean!
      $place: Boolean!
      $event: Boolean!
    ) {
      createConcepts(
        input: [
          {
            uid: $uid
            name: $name
            block: $block
            addedBy: $addedBy
            person: $person
            place: $place
            event: $event
          }
        ]
      ) {
        concepts {
          uid
          name
          person
          place
          event
        }
      }
    }
  `;

  const ADD_BROADER_CONCEPTS = gql`
    mutation AddBroaderConcepts($uid: ID!, $broaderConcepts: [ConceptWhere!]) {
      updateConcepts(
        update: { broaderConcepts: { connect: { where: { node: { OR: $broaderConcepts } } } } }
        where: { uid: $uid }
      ) {
        concepts {
          uid
          name
        }
      }
    }
  `;

  const ADD_NARROWER_CONCEPTS = gql`
    mutation AddNarrowerConcepts($uid: ID!, $narrowerConcepts: [ConceptWhere!]) {
      updateConcepts(
        update: { narrowerConcepts: { connect: { where: { node: { OR: $narrowerConcepts } } } } }
        where: { uid: $uid }
      ) {
        concepts {
          uid
          name
        }
      }
    }
  `;

  const ADD_PEOPLE_CONCEPTS = gql`
    mutation AddPeopleConcepts($uid: ID!, $peopleConcepts: [ConceptWhere!]) {
      updateConcepts(
        update: { peopleConcepts: { connect: { where: { node: { OR: $peopleConcepts } } } } }
        where: { uid: $uid }
      ) {
        concepts {
          uid
          name
        }
      }
    }
  `;

  const ADD_PLACE_CONCEPTS = gql`
    mutation AddPlaceConcepts($uid: ID!, $placeConcepts: [ConceptWhere!]) {
      updateConcepts(
        update: { placeConcepts: { connect: { where: { node: { OR: $placeConcepts } } } } }
        where: { uid: $uid }
      ) {
        concepts {
          uid
          name
        }
      }
    }
  `;

  const ADD_EVENT_CONCEPTS = gql`
    mutation AddEventConcepts($uid: ID!, $eventConcepts: [ConceptWhere!]) {
      updateConcepts(
        update: { eventConcepts: { connect: { where: { node: { OR: $eventConcepts } } } } }
        where: { uid: $uid }
      ) {
        concepts {
          uid
          name
        }
      }
    }
  `;

  const GET_CONCEPTS = gql`
    query GetConcepts {
      concepts {
        uid
        name
      }
    }
  `;

  const [createConcepts, { loading, error }] = useMutation(ADD_CONCEPT, {
    fetchPolicy: "no-cache",
  });
  const {
    loading: loadingConcepts,
    error: errorConcepts,
    data: dataConcepts,
    refetch,
  } = useQuery(GET_CONCEPTS, {
    fetchPolicy: "network-only",
  });
  const [updateBroaderConcepts] = useMutation(ADD_BROADER_CONCEPTS);
  const [updateNarrowerConcepts] = useMutation(ADD_NARROWER_CONCEPTS);
  const [updatePeopleConcepts] = useMutation(ADD_PEOPLE_CONCEPTS);
  const [updatePlaceConcepts] = useMutation(ADD_PLACE_CONCEPTS);
  const [updateEventConcepts] = useMutation(ADD_EVENT_CONCEPTS);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success!"
      content={`"${nameRef.current.value}" concept has been added.`}
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createConcepts({
      variables: {
        uid: uuidv4(),
        name: nameRef.current.value,
        block: categoriesV,
        addedBy: user.uid,
        person: specialType === "person",
        place: specialType === "place",
        event: specialType === "event",
      },
      onCompleted: (newData) => setConcept(newData.createConcepts.concepts[0]),
    });
  };

  useEffect(() => {
    if (!concept) {
      return;
    }
    if (broaderConceptsV.length !== 0) {
      const broaderConceptsZ = broaderConceptsV.map((a) => ({ ...a }));
      // eslint-disable-next-line no-param-reassign, dot-notation
      broaderConceptsZ.forEach((element) => delete element["__typename"]);
      updateBroaderConcepts({
        variables: {
          uid: concept.uid,
          broaderConcepts: broaderConceptsZ,
        },
        onCompleted: setConcept(false),
      });
    }
    if (narrowerConceptsV.length !== 0) {
      const narrowerConceptsZ = narrowerConceptsV.map((a) => ({ ...a }));
      // eslint-disable-next-line no-param-reassign, dot-notation
      narrowerConceptsZ.forEach((element) => delete element["__typename"]);
      updateNarrowerConcepts({
        variables: {
          uid: concept.uid,
          narrowerConcepts: narrowerConceptsZ,
        },
        onCompleted: setConcept(false),
      });
    }
    if (peopleConceptsV.length !== 0) {
      const peopleConceptsZ = peopleConceptsV;
      // eslint-disable-next-line no-param-reassign, dot-notation
      peopleConceptsZ.forEach((element) => delete element["__typename"]);
      updatePeopleConcepts({
        variables: {
          uid: concept.uid,
          peopleConcepts: peopleConceptsZ,
        },
        onCompleted: setConcept(false),
      });
    }
    if (placeConceptsV.length !== 0) {
      const placeConceptsZ = placeConceptsV.map((a) => ({ ...a }));
      // eslint-disable-next-line no-param-reassign, dot-notation
      placeConceptsZ.forEach((element) => delete element["__typename"]);
      updatePlaceConcepts({
        variables: {
          uid: concept.uid,
          placeConcepts: placeConceptsZ,
        },
        onCompleted: setConcept(false),
      });
    }
    if (eventConceptsV.length !== 0) {
      const eventConceptsZ = eventConceptsV.map((a) => ({ ...a }));
      // eslint-disable-next-line no-param-reassign, dot-notation
      eventConceptsZ.forEach((element) => delete element["__typename"]);
      updateEventConcepts({
        variables: {
          uid: concept.uid,
          eventConcepts: eventConceptsZ,
        },
        onCompleted: setConcept(false),
      });
    }
    if (concept.person && personForConceptsV.length !== 0) {
      const personForConceptsZ = personForConceptsV.map((a) => ({ ...a }));
      const conceptZ = concept;
      personForConceptsZ.forEach((element) => {
        // eslint-disable-next-line no-param-reassign, dot-notation
        delete conceptZ["__typename"];
        updatePeopleConcepts({
          variables: {
            uid: element.uid,
            peopleConcepts: conceptZ,
          },
          onCompleted: setConcept(false),
        });
      });
    }
    if (concept.place && placeForConceptsV.length !== 0) {
      const placeForConceptsZ = placeForConceptsV.map((a) => ({ ...a }));
      const conceptZ = concept;
      placeForConceptsZ.forEach((element) => {
        // eslint-disable-next-line no-param-reassign, dot-notation
        delete conceptZ["__typename"];
        updatePlaceConcepts({
          variables: {
            uid: element.uid,
            placeConcepts: conceptZ,
          },
          onCompleted: setConcept(false),
        });
      });
    }
    if (concept.event && eventForConceptsV.length !== 0) {
      const eventForConceptsZ = eventForConceptsV.map((a) => ({ ...a }));
      const conceptZ = concept;
      eventForConceptsZ.forEach((element) => {
        // eslint-disable-next-line no-param-reassign, dot-notation
        delete conceptZ["__typename"];
        updateEventConcepts({
          variables: {
            uid: element.uid,
            eventConcepts: conceptZ,
          },
          onCompleted: setConcept(false),
        });
      });
    }
    if (!error) {
      refetch(dataConcepts);
      openSuccessSB();
      setBroaderConcepts([]);
      setNarrowerConcepts([]);
      setPeopleConcepts([]);
      setPlaceConcepts([]);
      setEventConcepts([]);
      setPersonForConcepts([]);
      setPlaceForConcepts([]);
      setEventForConcepts([]);
      setCategories(null);
      setSpecialType();
    }
  }, [concept]);

  return (
    <ResourceFormLayout title="Add a Concept">
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <Grid container pt={1.625} px={{ xs: 4, md: 6 }}>
          <Grid container item xs={12} md={6} px={{ xs: 0, md: 3, xl: 6 }} spacing={3}>
            <MDTypography>Concept Information:</MDTypography>
            <Grid item xs={12}>
              <MDBox>
                <MDInput type="text" inputRef={nameRef} label="Name" required fullWidth />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={categoriesV || null}
                required
                onChange={(event, newCategories) => {
                  setCategories(newCategories);
                }}
                options={categories}
                renderInput={(params) => (
                  <MDInput {...params} label="Categories" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDBox width="100%">
                  <Stack alignItems="center" direction="row">
                    <MDTypography variant="caption" textAlign="center" width="33.33%">
                      Person
                    </MDTypography>
                    <MDTypography variant="caption" textAlign="center" width="33.33%">
                      Place
                    </MDTypography>
                    <MDTypography variant="caption" textAlign="center" width="33.33%">
                      Event
                    </MDTypography>
                  </Stack>
                </MDBox>
                <ToggleButtonGroup
                  color="primary"
                  size="medium"
                  fullWidth
                  value={specialType}
                  onChange={(event, newValue) => setSpecialType(newValue)}
                  exclusive
                  sx={{ mt: 1 }}
                >
                  <ToggleButton value="person">
                    <Icon fontSize="medium">person</Icon>
                  </ToggleButton>
                  <ToggleButton value="place">
                    <Icon fontSize="medium">map</Icon>
                  </ToggleButton>
                  <ToggleButton value="event">
                    <Icon fontSize="medium">event</Icon>
                  </ToggleButton>
                </ToggleButtonGroup>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} px={{ xs: 0, md: 3, xl: 6 }} spacing={3}>
            <MDBox mt={{ xs: 6, md: 0 }}>
              <MDTypography>Concept Relationships:</MDTypography>
            </MDBox>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disabled={loadingConcepts}
                value={broaderConceptsV || null}
                onChange={(event, newBroaderConcepts) => {
                  setBroaderConcepts(newBroaderConcepts);
                }}
                options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <MDInput {...params} variant="outlined" label="Broader Concepts" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disabled={loadingConcepts}
                value={narrowerConceptsV || null}
                onChange={(event, newNarrowerConcepts) => {
                  setNarrowerConcepts(newNarrowerConcepts);
                }}
                options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <MDInput {...params} variant="outlined" label="Narrower Concepts" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disabled={loadingConcepts}
                value={peopleConceptsV || null}
                onChange={(event, newPeopleConcepts) => {
                  setPeopleConcepts(newPeopleConcepts);
                }}
                options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <MDInput {...params} variant="outlined" label="Related People" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disabled={loadingConcepts}
                value={placeConceptsV || null}
                onChange={(event, newPlaceConcepts) => {
                  setPlaceConcepts(newPlaceConcepts);
                }}
                options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <MDInput {...params} variant="outlined" label="Related Places" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disabled={loadingConcepts}
                value={eventConceptsV || null}
                onChange={(event, newEventConcepts) => {
                  setEventConcepts(newEventConcepts);
                }}
                options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <MDInput {...params} variant="outlined" label="Related Events" />
                )}
              />
            </Grid>
            {specialType === "person" && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  disabled={loadingConcepts}
                  value={personForConceptsV || null}
                  onChange={(event, newPersonForConcepts) => {
                    setPersonForConcepts(newPersonForConcepts);
                  }}
                  options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <MDInput {...params} variant="outlined" label="Related Concepts" />
                  )}
                />
              </Grid>
            )}
            {specialType === "place" && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  disabled={loadingConcepts}
                  value={placeForConceptsV || null}
                  onChange={(event, newPlaceForConcepts) => {
                    setPlaceForConcepts(newPlaceForConcepts);
                  }}
                  options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <MDInput {...params} variant="outlined" label="Related Concepts" />
                  )}
                />
              </Grid>
            )}
            {specialType === "event" && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  disabled={loadingConcepts}
                  value={eventForConceptsV || null}
                  onChange={(event, newEventForConcepts) => {
                    setEventForConcepts(newEventForConcepts);
                  }}
                  options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <MDInput {...params} variant="outlined" label="Related Concepts" />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {errorConcepts && (
          <Alert color="error">Failed to load concepts: {errorConcepts.message}</Alert>
        )}
        {error && <Alert color="error">{error.message}</Alert>}
        <MDBox textAlign="center" pt={6} mb={8}>
          <MDButton
            type="submit"
            disabled={loading}
            sx={{ width: "25%" }}
            variant="gradient"
            color="primary"
          >
            Submit
          </MDButton>
          {renderSuccessSB}
        </MDBox>
      </MDBox>
    </ResourceFormLayout>
  );
}

export default AddConcept;
