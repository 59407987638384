/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton/index";

// Material Kit 2 PRO React example components
import textLogo from "assets/images/edyou-logo-text.png";
import textLogo2 from "assets/images/edyou-logo-text 2.png";

import image from "assets/images/network-bg.jpg";
import PageLayout from "examples/LayoutContainers/PageLayout";

function IllustrationLayoutPOC({ header, title, description, children }) {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <MDBox width="100%" height="100%" bgColor="white">
        <Grid container>
          <Grid item xs={12} lg={6}>
            <MDBox
              display={{ xs: "none", lg: "flex" }}
              width="calc(100% - 2rem)"
              height="calc(100vh - 2rem)"
              minHeight="47rem"
              borderRadius="lg"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mx={2}
              mt={2}
              bgColor="primary"
            >
              <MDBox
                color="white"
                bgColor="100"
                minHeight="43rem"
                height="calc(100vh - 6rem)"
                maxWidth="42rem"
                maxHeight={{ xs: "44rem", xl: "52rem" }}
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                opacity={1}
                mx={6}
                p={2}
                pt={3}
                display="flex"
                flexDirection="column"
                textAlign="center"
                justifyContent="center"
              >
                <MDBox textAlign="center" display={{ xs: "none", xl: "block" }}>
                  <img src={textLogo} alt="" />
                </MDBox>
                <MDBox textAlign="center" mx={2.5} pt={3}>
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    borderRadius="lg"
                    coloredShadow="primary"
                    width="100%"
                    position="relative"
                    zIndex={2}
                    sx={{
                      backgroundImage: ({
                        palette: { gradients },
                        functions: { linearGradient, rgba },
                      }) =>
                        `${linearGradient(
                          rgba(gradients.primary.main, 0.85),
                          rgba(gradients.primary.main, 0.85)
                        )}, url(${image})`,
                      backgroundSize: "cover",
                      backfaceVisibility: "hidden",
                    }}
                  >
                    <MDBox py={9} px={3} textAlign="center" lineHeight={1}>
                      <MDTypography variant="h3" color="white" pb={2} gutterBottom>
                        Education Your Way
                      </MDTypography>
                      <MDTypography variant="body1" color="white" pb={4} opacity={0.8}>
                        Empower your learning with your own personalized learning space.
                      </MDTypography>
                      <MDBox width="50%" mx="auto">
                        <MDButton onClick={() => navigate("/")} color="info" size="small" fullWidth>
                          Learn More
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={4} textAlign="center" pb={2} mx={2.5}>
                  <MDTypography variant="body1" fontStyle="italic" color="text" textAlign="center">
                    &ldquo;If knowledge is power, then learning is a superpower&rdquo; <br />- Jim
                    Kwik
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={11} sm={8} md={5} lg={4} xl={3} sx={{ mx: "auto" }}>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              minHeight="49rem"
              height="100vh"
            >
              <MDBox
                display={{ xs: "flex", xl: "none" }}
                ml={1.5}
                justifyContent="center"
                px={3}
                mb={8}
              >
                <img src={textLogo2} alt="" />
              </MDBox>
              <MDBox p={3} textAlign="center">
                {!header ? (
                  <>
                    <MDBox mb={1} textAlign="center">
                      <MDTypography variant="h4" fontWeight="bold">
                        {title}
                      </MDTypography>
                    </MDBox>
                    <MDTypography variant="body2" color="text">
                      {description}
                    </MDTypography>
                  </>
                ) : (
                  header
                )}
              </MDBox>
              <MDBox px={3}>{children}</MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayoutPOC.defaultProps = {
  header: "",
  title: "",
  description: "",
};

// Typechecking props for the IllustrationLayout
IllustrationLayoutPOC.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default IllustrationLayoutPOC;
