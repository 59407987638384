import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { PropTypes } from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { blue } from "@mui/material/colors";
import MDTypography from "components/MDTypography";
import {
  conceptProps,
  videoProps,
  noVideos,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

const steps = ["Select a related concept", "Select a video"];

export default function RelatedStepper(props) {
  const { handleClose, currentVideo, currentConcept, conceptOptions } = props;
  const [newConcept, setNewConcept] = useState(currentConcept);
  const [newVideo, setNewVideo] = useState(currentVideo);
  const [activeStep, setActiveStep] = useState(0);

  const videoOptions =
    newConcept !== currentConcept
      ? newConcept.methodVideos.filter((video) => video.uid !== currentVideo.uid)
      : noVideos;

  const handleNext = (concept) => {
    if (concept.uid === "Default Concept" || concept.uid === "No Concepts") {
      return;
    }
    setNewConcept(concept);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = (video) => {
    if (video.uid === "Default Video" || video.uid === "No Videos") {
      return;
    }
    setNewVideo(video);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    handleClose(currentConcept, currentVideo);
  };

  useEffect(() => {
    if (activeStep === steps.length) {
      handleClose(newConcept, newVideo);
      setActiveStep(0);
    }
  }, [activeStep]);

  return (
    <MDBox sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 ? (
        <MDBox>
          <MDBox pt={3} pb={1} px={2}>
            <MDTypography variant="h5">Related Concepts:</MDTypography>
          </MDBox>
          <List sx={{ pt: 0, px: 2, pb: 2 }}>
            {conceptOptions.map((concept) => (
              <ListItem button onClick={() => handleNext(concept)} key={concept.uid}>
                <ListItemAvatar>
                  <MDAvatar sx={{ color: blue[600] }}>
                    <LightbulbIcon fontSize="large" />
                  </MDAvatar>
                </ListItemAvatar>
                <ListItemText primary={concept.name} />
              </ListItem>
            ))}
          </List>
          <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <MDButton color="info" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </MDButton>
            <MDBox sx={{ flex: "1 1 auto" }} />
            <MDButton onClick={handleCancel}>Cancel</MDButton>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox>
          <MDBox pt={3} pb={1} px={2}>
            <MDTypography variant="h5">Concept: {newConcept.name}</MDTypography>
          </MDBox>
          <List sx={{ pt: 0, px: 2, pb: 2 }}>
            {videoOptions.map((video) => (
              <ListItem button onClick={() => handleSubmit(video)} key={video.uid}>
                <ListItemAvatar>
                  <MDAvatar sx={{ color: blue[600] }}>
                    <PlayCircleIcon fontSize="large" />
                  </MDAvatar>
                </ListItemAvatar>
                <ListItemText primary={video.title} />
              </ListItem>
            ))}
          </List>
          <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <MDButton color="info" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </MDButton>
            <MDBox sx={{ flex: "1 1 auto" }} />
            <MDButton onClick={handleCancel}>Cancel</MDButton>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

RelatedStepper.propTypes = {
  handleClose: PropTypes.func.isRequired,
  currentConcept: conceptProps.isRequired,
  currentVideo: videoProps.isRequired,
  conceptOptions: PropTypes.arrayOf(conceptProps).isRequired,
};
