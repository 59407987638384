/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Spare Components
// import ProfileOverview from "layouts/pages/profile/profile-overview";
// import AllProjects from "layouts/pages/profile/all-projects";
// import Invoice from "layouts/pages/account/invoice";
// import Notifications from "layouts/pages/notifications";
// import Wizard from "layouts/applications/wizard";
// import NewProduct from "layouts/ecommerce/products/new-product";

// Authentication and Sign Up Pages
import LogIn from "layouts/CompanyWebsite/Authentication/LogIn/index";
import ResetPassword from "layouts/CompanyWebsite/Authentication/ResetPassword";
import NewPasswordCover from "layouts/CompanyWebsite/Authentication/ResetPassword/NewPasswordCover";
import SignUp from "layouts/CompanyWebsite/Authentication/SignUp/index";
import NewUser from "layouts/LearningStudio/Onboarding/new-user";

// Learning Studio Subscriber Pages
import Settings from "layouts/LearningStudio/MyAccount/Settings";
import StudioHome from "layouts/LearningStudio/MyAccount/StudioHome";
import MyPrograms from "layouts/LearningStudio/FocusMode/MyPrograms/index";
import MediaViewer from "layouts/LearningStudio/MediaViewer";
import SearchConcepts from "layouts/LearningStudio/SearchLibrary";
import SeeList from "layouts/LearningStudio/FocusMode/MyPrograms/components/SeeList";
import ProgramList from "layouts/LearningStudio/FocusMode/ProgramStore/components/StoreTiles/SeeList/index";
import MyProgress from "layouts/LearningStudio/MyAccount/MyProgress";
import VideoHistory from "layouts/LearningStudio/MyAccount/MyProgress/components/VideoHistory";
import LearningProgress from "layouts/LearningStudio/MyAccount/MyProgress/components/LearningProgress";
import SupportLS from "layouts/LearningStudio/SupportLS";
// import TestingViewer from "layouts/LearningStudio/ReviewMode/TestViewer/index";
// import TestViewer from "layouts/LearningStudio/FocusMode/ReviewViewer/index";
import QuizStore from "layouts/LearningStudio/QuizMode/QuizStore/index";
import QuizViewer from "layouts/LearningStudio/QuizMode/QuizViewer/index";
// import ComingSoonLS from "layouts/SpareComponents/coming-soon";

// Learning Studio Moderator Pages
import AddConcept from "layouts/LearningStudio/AddResource/addConcept";
import AddProgram from "layouts/LearningStudio/AddResource/addProgram";
import AddVideoProgram from "layouts/LearningStudio/AddResource/addVideoProgram";
import Contribute from "layouts/LearningStudio/Contribute/index";
import AddTest from "layouts/LearningStudio/AddResource/addTest/index";
// import AddTest2 from "layouts/LearningStudio/AddResource/addTest/index copy";
import AddVideo from "layouts/LearningStudio/AddResource/addVideo/index";
import EditVideoInfo from "layouts/LearningStudio/AddResource/editVideoInfo/index";

// Company Website Pages
import Home from "layouts/CompanyWebsite/Home";
// import ComingSoon from "layouts/CompanyWebsite/ComingSoon";
import OurTeam from "layouts/CompanyWebsite/AboutUs/OurTeam";
import OurMission from "layouts/CompanyWebsite/AboutUs/OurMission";
import LearningStudio from "layouts/CompanyWebsite/AboutEDYou/LearningStudio";
// import PricingPage from "layouts/CompanyWebsite/AboutEDYou/Pricing";
import ContactUs from "layouts/CompanyWebsite/Support/ContactUs";
import PrivacyPolicy from "layouts/CompanyWebsite/Support/PrivacyPolicy/index";
import VerifiedEducators from "layouts/CompanyWebsite/AboutEDYou/VerifiedEducators";
// import BlogHome from "layouts/CompanyWebsite/Blog/BlogHome";
// import SingleArticle from "layouts/CompanyWebsite/Blog/SingleArticle";

// @mui icons
import Icon from "@mui/material/Icon";
import ProgramStore from "layouts/LearningStudio/FocusMode/ProgramStore/index";
import TermsOfUse from "layouts/CompanyWebsite/Support/TermsOfUse/index";
// import Subscribe from "layouts/LearningStudio/Onboarding/Subscribe/index";
import Tutorial from "layouts/LearningStudio/Tutorial/index";
// import PaymentConfirmation from "layouts/LearningStudio/Onboarding/PaymentConfirmation/index";
import Reports from "layouts/LearningStudio/AddResource/Reports/index";
import EditTestInfo from "layouts/LearningStudio/AddResource/editTestInfo/index";
import AddQuizOfTheWeek from "layouts/LearningStudio/AddResource/addQuizOfTheWeek/index";
import QuizHistory from "layouts/LearningStudio/MyAccount/MyProgress/QuizHistory/index";

const routes = [
  { type: "title", title: "Account", key: "account-title", render: true },
  {
    type: "collapse",
    name: "My Account",
    key: "my-account",
    render: true,
    icon: <Icon>account_circle</Icon>,
    collapse: [
      {
        name: "Studio",
        key: "ls-home",
        route: "/studio",
        protected: ["admin", "subscriber", "moderator1", "moderator2"],
        component: <StudioHome />,
      },
      {
        name: "My Progress",
        key: "my-progress",
        route: "/studio/my-progress",
        protected: ["admin", "subscriber", "moderator1", "moderator2"],
        component: <MyProgress />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/studio/settings",
        protected: ["admin", "subscriber", "moderator1", "moderator2"],
        component: <Settings />,
      },
    ],
  },
  /* {
    type: "collapse",
    name: "All Projects",
    key: "all-projects",
    icon: <Icon fontSize="medium">image</Icon>,
    route: "/all-projects",
    component: <AllProjects />,
    render: false,
    protected: true,
    noCollapse: true,
  }, */
  {
    type: "collapse",
    name: "New User",
    key: "new-user",
    icon: <Icon fontSize="medium">image</Icon>,
    route: "/new-user",
    component: <NewUser />,
    render: false,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Video History",
    key: "video-history",
    icon: <Icon fontSize="medium">image</Icon>,
    route: "/studio/my-progress/video-history",
    component: <VideoHistory />,
    render: false,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Quiz History",
    key: "quiz-history",
    icon: <Icon fontSize="medium">image</Icon>,
    route: "/studio/my-progress/quiz-history",
    component: <QuizHistory />,
    render: false,
    protected: ["admin", "subscriber"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Learning Progress",
    key: "learning-progress",
    icon: <Icon fontSize="medium">image</Icon>,
    route: "/studio/my-progress/learning-progress",
    component: <LearningProgress />,
    render: false,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    noCollapse: true,
  },
  /* {
    type: "collapse",
    name: "Invoice",
    key: "invoice",
    icon: <Icon fontSize="medium">image</Icon>,
    route: "/invoice",
    component: <Invoice />,
    render: false,
    protected: true,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Notfications",
    key: "notifications",
    icon: <Icon fontSize="medium">image</Icon>,
    route: "/notifications",
    component: <Notifications />,
    render: false,
    protected: true,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    render: false,
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Wizard",
        key: "wizard",
        route: "/wizard",
        protected: true,
        component: <Wizard />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    render: false,
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/new-product",
            protected: true,
            component: <NewProduct />,
          },
        ],
      },
    ],
  }, */
  {
    type: "collapse",
    name: "Contact Us",
    render: false,
    key: "contactUs",
    route: "/contact-us",
    component: <ContactUs />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reports",
    render: false,
    protected: ["admin", "moderator1", "moderator2"],
    key: "reports",
    route: "/reports",
    component: <Reports />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Concept",
    render: false,
    protected: ["admin", "moderator1", "moderator2"],
    key: "add-concept",
    route: "/add-concept",
    component: <AddConcept />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Quiz of the Week",
    render: false,
    protected: ["admin", "moderator1", "moderator2"],
    key: "quiz-of-the-week",
    route: "/quiz-of-the-week",
    component: <AddQuizOfTheWeek />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Video",
    render: false,
    protected: ["admin", "moderator1", "moderator2"],
    key: "add-video",
    route: "/add-video",
    component: <AddVideo />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Program",
    render: false,
    protected: ["admin", "moderator1", "moderator2"],
    key: "add-program",
    route: "/add-program",
    component: <AddProgram />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Testing Resource",
    render: false,
    protected: ["admin", "moderator1", "moderator2"],
    key: "add-test",
    route: "/add-test",
    component: <AddTest />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Add Video Program",
    render: false,
    protected: ["admin", "moderator1", "moderator2"],
    key: "add-program",
    route: "/add-video-program",
    component: <AddVideoProgram />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit Video Info",
    render: false,
    protected: ["admin"],
    key: "edit-video-info",
    route: "/edit-video-info",
    component: <EditVideoInfo />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Edit Test Info",
    render: false,
    protected: ["admin"],
    key: "edit-test-info",
    route: "/edit-test-info",
    component: <EditTestInfo />,
    noCollapse: true,
  },
  { type: "title", title: "Learning", key: "learning-title", render: true },
  {
    type: "collapse",
    name: "My Programs",
    key: "my-programs",
    render: true,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    route: "/studio/my-programs",
    component: <MyPrograms />,
    icon: <Icon fontSize="medium">videocam</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Quizzes",
    key: "quizzes",
    render: true,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    route: "/studio/quizzes",
    component: <QuizStore />,
    icon: <Icon fontSize="medium">quiz</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Test Viewer",
    key: "test-viewer",
    render: false,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    route: "/studio/test-viewer",
    component: <QuizViewer />,
    icon: <Icon fontSize="medium">quiz</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "See List",
    icon: <Icon fontSize="medium">class</Icon>,
    render: false,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    key: "see-list",
    route: "/studio/my-programs/program-list",
    component: <SeeList />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Program List",
    icon: <Icon fontSize="medium">class</Icon>,
    render: false,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    key: "program-list",
    route: "/studio/program-store/program-list",
    component: <ProgramList />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Search",
    icon: <Icon fontSize="medium">search</Icon>,
    render: true,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    key: "search",
    route: "/studio/search",
    component: <SearchConcepts />,
    noCollapse: true,
  },
  { type: "title", title: "Store", key: "store-title", render: true },
  {
    type: "collapse",
    name: "Program Store",
    key: "program-store",
    render: true,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    route: "/studio/program-store",
    component: <ProgramStore />,
    icon: <Icon fontSize="medium">library_add</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Media Viewer",
    render: false,
    protected: ["admin", "subscriber", "moderator1", "moderator2"],
    key: "media-viewer",
    route: "/studio/viewer",
    component: <MediaViewer />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Test Viewer",
  //   render: false,
  //   protected: ["admin", "subscriber"],
  //   key: "test-viewer",
  //   route: "/studio/test-viewer",
  //   component: <TestViewer />,
  //   noCollapse: true,
  // },
  { type: "title", title: "Other", key: "other-title", render: true },
  {
    type: "collapse",
    name: "Request Content",
    icon: <Icon fontSize="medium">group_add</Icon>,
    render: true,
    key: "request content",
    route: "/studio/request-content",
    component: <Contribute />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Help & Support",
    key: "help-and-support",
    render: true,
    icon: <Icon>contact_support</Icon>,
    collapse: [
      {
        protected: ["admin", "subscriber", "moderator1", "moderator2"],
        name: "Contact Us",
        key: "contact-us",
        route: "/studio/contact-us",
        component: <SupportLS />,
      },
      {
        protected: ["admin", "subscriber", "moderator1", "moderator2"],
        name: "Tutorial",
        key: "tutorial",
        route: "/studio/tutorial",
        component: <Tutorial />,
      },
      {
        name: "About EDYou",
        key: "home",
        route: "/",
        component: <Home />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Our Mission",
    render: false,
    key: "ourMission",
    route: "/our-mission",
    component: <OurMission />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Our Team",
    render: false,
    key: "ourTeam",
    route: "/our-team",
    component: <OurTeam />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Learning Studio",
    render: false,
    key: "learningStudio",
    route: "/learning-studio",
    component: <LearningStudio />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Verified Educators",
    render: false,
    key: "verifiedEducators",
    route: "/verified-educators",
    component: <VerifiedEducators />,
    noCollapse: true,
  },
  /* {
    type: "collapse",
    name: "Upcoming Features",
    render: false,
    protected: false,
    key: "upcomingFeatures",
    route: "/upcoming-features",
    component: <ComingSoon />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Pricing",
    render: false,
    protected: false,
    key: "pricing",
    route: "/pricing",
    component: <PricingPage />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Blog",
    render: false,
    protected: false,
    key: "blog",
    route: "/blog",
    component: <BlogHome />,
    noCollapse: true,
  }, */
  {
    type: "collapse",
    name: "Log In",
    render: false,
    key: "logIn",
    route: "/log-in",
    component: <LogIn />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    render: false,
    key: "signUp",
    route: "/sign-up",
    component: <SignUp />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reset Password",
    render: false,
    key: "resetPassword",
    route: "/reset-password",
    component: <ResetPassword />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "New Password",
    render: false,
    key: "newPassword",
    route: "/new-password",
    component: <NewPasswordCover />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Privacy Policy",
    render: false,
    key: "privacyPolicy",
    route: "/privacy",
    component: <PrivacyPolicy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Terms of Use",
    render: false,
    key: "termsOfUse",
    route: "/terms-of-use",
    component: <TermsOfUse />,
    noCollapse: true,
  },
  /* {
    type: "collapse",
    name: "Subscribe",
    render: false,
    key: "subscribe",
    route: "/subscribe",
    component: <Subscribe />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Payment Confirmation",
    render: false,
    key: "payment-confirmation",
    route: "/payment-confirmation",
    component: <PaymentConfirmation />,
    noCollapse: true,
  },
  /* {
    type: "collapse",
    name: "Microsoft Verification",
    render: false,
    key: "microsoftVerification",
    route: "/new-password",
    component: <NewPasswordCover />,
    noCollapse: true,
  }, */
  /* {
    type: "collapse",
    name: "Blog Article",
    render: false,
    key: "blogArticle",
    route: "/blog-article",
    component: <SingleArticle />,
    noCollapse: true,
  }, */
];

export default routes;
