/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { gql, useQuery } from "@apollo/client";

// @mui material components
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";

// Auth context
import { UserAuth } from "context/AuthContext";

// Firestore
import { db } from "firebase-config";
import { collection, doc, setDoc, serverTimestamp } from "firebase/firestore";
import {
  categories,
  targetAudience,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import ProgramCardPreview from "./ProgramCardPreview";

function AddProgram() {
  // eslint-disable-next-line no-unused-vars
  const [previewActive, setPreviewActive] = useState(false);
  const [targetAudienceV, setTargetAudience] = useState(null);
  const [categoriesV, setCategories] = useState([]);
  const [conceptsV, setConcepts] = useState([]);
  const [previewProgram, setProgramPreview] = useState({});
  const [snackbarName, setSnackbarName] = useState(null);
  const [successSB, setSuccessSB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { user } = UserAuth();
  const nameRef = useRef("");
  const imageUrlRef = useRef("");
  const descriptionRef = useRef("");

  const GET_CONCEPTS = gql`
    query GetConcepts {
      concepts {
        uid
        name
      }
    }
  `;

  const {
    loading: loadingConcepts,
    error: errorConcepts,
    data: dataConcepts,
  } = useQuery(GET_CONCEPTS);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success!"
      content={`"${snackbarName}" program has been created.`}
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handlePreview = async (e) => {
    e.preventDefault();
    const newProgram = {
      programId: uuidv4(),
      name: nameRef.current.value,
      image: imageUrlRef.current.value,
      description: descriptionRef.current.value,
      type: "Concept",
      targetAudience: targetAudienceV,
      categories: categoriesV,
      conceptList: conceptsV,
      addedBy: user.uid,
    };
    setProgramPreview(newProgram);
    setPreviewActive(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newProgram = {
      programId: uuidv4(),
      name: nameRef.current.value,
      image: imageUrlRef.current.value,
      description: descriptionRef.current.value,
      type: "Concept",
      targetAudience: targetAudienceV,
      categories: categoriesV,
      conceptList: conceptsV,
      addedBy: user.uid,
      timestamp: serverTimestamp(),
    };
    setSnackbarName(nameRef.current.value);
    try {
      setLoading(true);
      await setDoc(doc(collection(db, "programs"), newProgram.programId), newProgram);
      openSuccessSB();
    } catch (event) {
      setError(true);
    }
    setLoading(false);
    setPreviewActive(false);
    nameRef.current.value = "";
    imageUrlRef.current.value = "";
    descriptionRef.current.value = "";
    setTargetAudience(null);
    setCategories([]);
    setConcepts([]);
  };

  return (
    <MDBox my={{ xs: 4, md: 6 }}>
      <MDBox width="100%" textAlign="center" py={1} px={3}>
        <MDTypography variant="h3" fontWeight="medium" pb={4}>
          Create a Program
        </MDTypography>
      </MDBox>
      <Grid container pb={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <MDBox
            component="form"
            display="flex"
            justifyContent="center"
            onSubmit={handleSubmit}
            role="form"
          >
            <Stack direction="column" spacing={2} width="80%">
              <MDTypography mb={1}>Program Details:</MDTypography>
              <MDBox>
                <MDInput type="text" inputRef={nameRef} label="Program Name" required fullWidth />
              </MDBox>
              <MDBox>
                <MDInput
                  type="text"
                  placeholder="https:// ..."
                  inputRef={imageUrlRef}
                  label="Image URL"
                  required
                  fullWidth
                />
              </MDBox>
              <MDBox>
                <MDInput
                  type="text"
                  inputRef={descriptionRef}
                  label="Program Description"
                  multiline
                  rows={4}
                  required
                  fullWidth
                  helperText="Character Limit: 150"
                  inputProps={{ maxLength: 150 }}
                />
              </MDBox>
              <MDBox>
                <Autocomplete
                  value={targetAudienceV || null}
                  onChange={(event, newTargetAudience) => {
                    setTargetAudience(newTargetAudience);
                  }}
                  options={targetAudience}
                  renderInput={(params) => (
                    <MDInput {...params} required variant="outlined" label="Target Audience?" />
                  )}
                />
              </MDBox>
              <MDBox>
                <Autocomplete
                  multiple
                  value={categoriesV || []}
                  onChange={(event, newCategories) => {
                    setCategories(newCategories);
                  }}
                  options={categories}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      required={categoriesV.length === 0}
                      label={categoriesV.length === 0 ? "Categories" : "Categories *"}
                      variant="outlined"
                    />
                  )}
                />
              </MDBox>
              <MDBox>
                <Autocomplete
                  multiple
                  disabled={loadingConcepts}
                  value={conceptsV || []}
                  onChange={(event, newBroaderConcepts) => {
                    setConcepts(newBroaderConcepts);
                  }}
                  options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="outlined"
                      required={conceptsV.length === 0}
                      label={conceptsV.length === 0 ? "Program Concepts" : "Program Concepts *"}
                      helperText="Use the dropdown to add concepts. The concept tags must be in the program order."
                    />
                  )}
                />
              </MDBox>
              {errorConcepts && (
                <Alert color="error">Failed to load concepts: {errorConcepts.message}</Alert>
              )}
              {error && <Alert color="error">Something went wrong</Alert>}
              {!previewActive ? (
                <MDBox textAlign="center" pt={4}>
                  <MDButton
                    disabled={loading}
                    sx={{ width: "25%" }}
                    variant="gradient"
                    color="info"
                    onClick={handlePreview}
                  >
                    Preview
                  </MDButton>
                  {renderSuccessSB}
                </MDBox>
              ) : (
                <MDBox display="flex" justifyContent="space-between" pt={4}>
                  <MDButton
                    disabled={loading}
                    sx={{ width: "35%" }}
                    variant="gradient"
                    color="info"
                    onClick={handlePreview}
                  >
                    Preview
                  </MDButton>
                  <MDButton
                    type="submit"
                    disabled={loading}
                    sx={{ width: "35%" }}
                    variant="gradient"
                    color="primary"
                  >
                    Submit
                  </MDButton>
                  {renderSuccessSB}
                </MDBox>
              )}
            </Stack>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6}>
          {previewActive ? (
            <MDBox display="flex" justifyContent="center" pt={{ xs: 8, md: 0 }}>
              <ProgramCardPreview program={previewProgram} />
            </MDBox>
          ) : (
            <MDBox pb={12} pt={{ xs: 8, md: 0 }}>
              <MDTypography variant="h4" fontWeight="light">
                &lt; Preview &gt;
              </MDTypography>
            </MDBox>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default AddProgram;
