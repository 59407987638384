/* eslint-disable react/prop-types */

// React
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Props
import PropTypes, { objectOf } from "prop-types";
import {
  defaultVideo,
  videoProps,
  defaultConcept,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

// Mui
import { List, ListItem, ListItemAvatar, ListItemText, Dialog, DialogTitle } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CloseIcon from "@mui/icons-material/Close";
import { blue, grey } from "@mui/material/colors";

// Material Kit
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";

// Neo4j
import { gql, useQuery } from "@apollo/client";

// Firebase
import { rdb } from "firebase-config";
import { ref, update } from "firebase/database";

// Auth Context
import { UserAuth } from "context/AuthContext";

const GET_METHOD_VIDEO = gql`
  query GetMethodVideo($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      name
      block
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
      interestVideos {
        uid
        embedId
        title
        channelName
      }
    }
  }
`;

function SelectVideoDialog(props) {
  const { onClose, selectedVideo, open, selectedConcept } = props;

  let videoOptions = defaultVideo;
  let concept = selectedConcept;

  const handleClose = () => {
    onClose(selectedConcept, selectedVideo);
  };

  const handleListItemClick = (video) => {
    onClose(concept, video);
  };

  if (open) {
    const { loading: loadingMethodVideos, data: dataMethodVideos } = useQuery(GET_METHOD_VIDEO, {
      variables: {
        uid: selectedConcept.uid,
      },
    });
    videoOptions =
      !loadingMethodVideos && dataMethodVideos
        ? dataMethodVideos.concepts[0].methodVideos
        : defaultVideo;
    concept = videoOptions !== defaultVideo ? dataMethodVideos.concepts[0] : selectedConcept;
  }

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>Select a Video:&nbsp;&nbsp;{selectedConcept.name}</DialogTitle>
      <List sx={{ pt: 0, px: 2, pb: 2 }}>
        {videoOptions.map((video) => (
          <ListItem button onClick={() => handleListItemClick(video)} key={video.uid}>
            <ListItemAvatar>
              <MDAvatar sx={{ color: blue[600] }}>
                <PlayCircleIcon fontSize="large" />
              </MDAvatar>
            </ListItemAvatar>
            <ListItemText primary={video.title} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleClose()}>
          <ListItemAvatar>
            <MDAvatar sx={{ color: grey[600] }}>
              <CloseIcon fontSize="medium" />
            </MDAvatar>
          </ListItemAvatar>
          <ListItemText primary="Cancel" />
        </ListItem>
      </List>
    </Dialog>
  );
}

export default function SelectVideo({
  selectedConcept,
  selectedProgram,
  currentProgramIndex,
  completed,
  firstIncomplete,
}) {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [open, setOpen] = useState(false);
  const [selectedVideo, setVideo] = useState(defaultVideo[0]);
  const [fullSelectedConcept, setFullSelectedConcept] = useState(defaultConcept[0]);

  let currentIndex = currentProgramIndex;
  useEffect(() => {
    if (
      currentProgramIndex === selectedProgram.conceptList.length - 1 &&
      Object.keys(firstIncomplete).length !== 0
    ) {
      currentIndex = selectedProgram.conceptList.indexOf(firstIncomplete);
      setFullSelectedConcept(firstIncomplete);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (concept, video) => {
    setOpen(false);
    setFullSelectedConcept(concept);
    setVideo(video);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (
      currentProgramIndex === selectedProgram.conceptList.length - 1 &&
      Object.keys(firstIncomplete).length !== 0
    ) {
      update(ref(rdb, `programHistory/${user.uid}/${selectedProgram.programId}`), {
        index: currentIndex,
      });
    }
    navigate("/studio/viewer", {
      state: {
        selectedVideo,
        selectedConcept: fullSelectedConcept,
        selectedProgram,
        currentProgramIndex: currentIndex,
        videoType: "method",
        source: "program",
      },
    });
  }, [selectedVideo]);

  return (
    <MDBox>
      <MDButton variant="text" color="info" fontWeight="medium" onClick={handleClickOpen}>
        {completed > 0 ? "Resume" : "Start"}
      </MDButton>
      <SelectVideoDialog
        selectedVideo={selectedVideo}
        open={open}
        onClose={handleClose}
        selectedConcept={selectedConcept}
      />
    </MDBox>
  );
}

SelectVideoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedVideo: videoProps.isRequired,
  selectedConcept: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    person: PropTypes.bool,
    place: PropTypes.bool,
    event: PropTypes.bool,
    methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }).isRequired,
};

SelectVideo.propTypes = {
  selectedConcept: PropTypes.objectOf(PropTypes.string).isRequired,
  selectedProgram: PropTypes.shape({
    addedBy: PropTypes.string,
    conceptList: PropTypes.arrayOf(objectOf(PropTypes.string)),
    name: PropTypes.string,
    programId: PropTypes.string,
  }).isRequired,
  currentProgramIndex: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  firstIncomplete: PropTypes.object.isRequired,
};
