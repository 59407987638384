import { Card, Icon, Grid, Stack } from "@mui/material";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import { UserAuth } from "context/AuthContext/index";
import logo from "assets/images/logo-tree.png";

function Announcements() {
  const { user } = UserAuth();
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={6} px={3}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={3}>
            <MDBox component="img" src={logo} width="5rem" p={1} mb={1} />
          </Grid>
          <Grid item alignItems="center" justifyContent="center" xs={9}>
            <MDBox width="100%" textAlign="left" mt={-2}>
              <MDTypography variant="h2" fontWeight="medium">
                Welcome {user ? ` ${user.displayName}` : ""}!
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>{" "}
      </MDBox>
      <MDBox width="100%" textAlign="center" pt={3} px={4}>
        <MDTypography variant="h4" fontWeight="medium" pb={2}>
          Announcements
        </MDTypography>
        <MDTypography textAlign="left">
          Thank you for signing up for the beta version of our EDYou Learning Studio! We want to
          make this the best learning platform for everyone, and any feedback is appreciated.
        </MDTypography>
      </MDBox>
      <MDBox pt={3} pb={{ xs: 6, md: 4 }} px={4}>
        <Stack spacing={3}>
          <MDBox display="flex">
            <Icon fontSize="large">campaign</Icon>
            <MDBox ml={2}>
              <MDTypography>
                <strong>Tutorial:</strong> You can find a tutorial video by clicking{" "}
                <a href="/studio/tutorial">
                  <strong>Tutorial</strong>
                </a>{" "}
                in the sidebar.
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex">
            <Icon fontSize="large">campaign</Icon>
            <MDBox ml={2}>
              <MDTypography>
                <strong>Feedback:</strong> Send us feedback using the form at{" "}
                <a href="/studio/contact-us">
                  <strong>Contact Us</strong>
                </a>{" "}
                .
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex">
            <Icon fontSize="large">campaign</Icon>
            <MDBox ml={2}>
              <MDTypography>
                <strong>Content:</strong> We want to provide the educational content you want to
                see. You can use the forms at{" "}
                <a href="/studio/request-content">
                  <strong>Request Content</strong>
                </a>{" "}
                to tell us what programs you would like to see next.
              </MDTypography>
            </MDBox>
          </MDBox>
        </Stack>
      </MDBox>
    </Card>
  );
}

export default Announcements;
