/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Kit 2 PRO React examples
import TransparentTeamCard from "layouts/CompanyWebsite/AboutUs/OurTeam/components/TransparentTeamCard";

// Images
import team1 from "assets/images/team-rees.jpg";
import team2 from "assets/images/team-gita.jpeg";

function Team() {
  const socialIcons = (
    <>
      <Tooltip placement="top" title="Follow me!">
        <MDBox
          component="i"
          fontSize="1.125rem"
          className="fab fa-twitter"
          sx={{
            color: ({ palette: { socialMediaColors } }) => socialMediaColors.twitter.main,
            cursor: "pointer",
          }}
        />
      </Tooltip>
      <Tooltip placement="top" title="Follow me!">
        <MDBox
          component="i"
          fontSize="1.125rem"
          className="fab fa-dribbble"
          sx={{
            color: ({ palette: { socialMediaColors } }) => socialMediaColors.dribbble.main,
            cursor: "pointer",
          }}
        />
      </Tooltip>
      <Tooltip placement="top" title="Follow me!">
        <MDBox
          component="i"
          fontSize="1.125rem"
          className="fab fa-linkedin"
          sx={{
            color: ({ palette: { socialMediaColors } }) => socialMediaColors.linkedin.main,
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </>
  );

  return (
    <MDBox component="section" py={6}>
      <Container>
        <Grid container item alignItems="center" flexDirection="column" xs={12} lg={12} mb={6}>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="3rem"
            height="3rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="lg"
            mb={2}
          >
            <Icon>supervisor_account</Icon>
          </MDBox>
          <MDTypography variant="h2" mb={1}>
            The Founders
          </MDTypography>
          <MDTypography variant="body2" color="text">
            The crazy people who think they can revolutionize education
          </MDTypography>
        </Grid>
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} md={6} lg={6}>
            <TransparentTeamCard
              image={team1}
              name="Rees Horton"
              position="CEO / Co-Founder"
              description="Rees Horton is the visionary and technical lead, with a J.D. from Duke School of Law and bachelor's degrees in mathematics and economics from the University of Georgia. In short, he's our resident space cadet. His tagline might as well be, &#34;Uhhh ... I could probably do that&#34;."
              socials={socialIcons}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TransparentTeamCard
              image={team2}
              name="Gita Vatave"
              position="President / Co-Founder"
              description="Gita Vatave is a technology executive and former educator for Grade 6-8 Science and English with degrees from Yale and Johns Hopkins and 20 years of executive experience. That's the fancy stuff. Basically, she's our resident boss lady and expert at talking to people. "
              socials={socialIcons}
            />
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Team;
