/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import ReactPlayer from "react-player/youtube";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

// Layout
import PageLayout from "examples/LayoutContainers/PageLayout";
import DefaultNavbar from "examples/Navbars/DefaultNavbar/index";

// Auth context
import { UserAuth } from "context/AuthContext";

// Material Dashboard 2 PRO React context
import MDButton from "components/MDButton";
import { difficulties } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

function EditVideoInfo() {
  const [currentVideo, setVideo] = useState(null);
  const [currentIndex, setIndex] = useState(0);
  const [videoCount, setVideoCount] = useState(0);
  const [languageV, setLanguage] = useState("English");
  const [embeddableV, setEmbeddable] = useState(null);
  const [methodConceptsV, setMethodConcepts] = useState([]);
  const [interestConceptsV, setInterestConcepts] = useState([]);
  const [contentAdvisoryV, setContentAdvisory] = useState([]);
  const [difficultyV, setDifficulty] = useState("");
  const { user } = UserAuth();
  const [title, setTitle] = useState("");
  const [channelName, setChannelName] = useState("");
  const [embedId, setEmbedId] = useState("");

  const UPDATE_VIDEO = gql`
    mutation UpdateVideo(
      $uid: ID!
      $title: String!
      $channelName: String!
      $embedId: String!
      $language: String!
      $contentAdvisory: [String]
      $difficulty: String!
      $addedBy: String!
      $embeddable: Boolean!
    ) {
      updateVideos(
        where: { uid: $uid }
        update: {
          title: $title
          channelName: $channelName
          embedId: $embedId
          language: $language
          contentAdvisory: $contentAdvisory
          difficulty: $difficulty
          addedBy: $addedBy
          embeddable: $embeddable
        }
      ) {
        videos {
          uid
          title
        }
      }
    }
  `;

  const ADD_METHOD_CONCEPTS = gql`
    mutation AddMethodConcepts($uid: ID!, $methodConcepts: [ConceptWhere!]) {
      updateVideos(
        update: { methodConcepts: { connect: { where: { node: { OR: $methodConcepts } } } } }
        where: { uid: $uid }
      ) {
        videos {
          uid
          title
        }
      }
    }
  `;

  const ADD_INTEREST_CONCEPTS = gql`
    mutation AddInterestConcepts($uid: ID!, $interestConcepts: [ConceptWhere!]) {
      updateVideos(
        update: { interestConcepts: { connect: { where: { node: { OR: $interestConcepts } } } } }
        where: { uid: $uid }
      ) {
        videos {
          uid
          title
        }
      }
    }
  `;

  const GET_CONCEPTS = gql`
    query GetConcepts {
      concepts {
        uid
        name
      }
    }
  `;

  const GET_VIDEOS = gql`
    query GetVideos {
      videos {
        uid
        title
        embedId
        channelName
        contentAdvisory
        language
        difficulty
        embeddable
        methodConcepts {
          uid
          name
        }
        interestConcepts {
          uid
          name
        }
      }
    }
  `;

  const [updateVideos, { loading, error }] = useMutation(UPDATE_VIDEO, {
    fetchPolicy: "no-cache",
  });
  const {
    loading: loadingConcepts,
    error: errorConcepts,
    data: dataConcepts,
  } = useQuery(GET_CONCEPTS);
  const { loading: loadingVideos, error: errorVideos, data: dataVideos } = useQuery(GET_VIDEOS);

  const filteredVideos =
    !loadingVideos && dataVideos
      ? dataVideos.videos.filter(
          (video) => !(video.difficulty || video.methodConcepts.length === 0 || !video.embeddable)
        )
      : null;

  const [updateMethodConcepts] = useMutation(ADD_METHOD_CONCEPTS);
  const [updateInterestConcepts] = useMutation(ADD_INTEREST_CONCEPTS);

  const handleStart = (e) => {
    e.preventDefault();
    setVideoCount(filteredVideos.length);
    setVideo(filteredVideos[currentIndex]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateVideos({
      variables: {
        uid: currentVideo.uid,
        title,
        channelName,
        embedId,
        language: languageV,
        contentAdvisory: contentAdvisoryV,
        difficulty: embeddableV ? difficultyV : "not embeddable",
        addedBy: user.uid,
        embeddable: embeddableV,
      },
    });
    if (methodConceptsV !== currentVideo.methodConcepts) {
      const methodConceptsZ = methodConceptsV.map((a) => ({ ...a }));
      methodConceptsZ.forEach((element) => delete element["__typename"]);
      await updateMethodConcepts({
        variables: {
          uid: currentVideo.uid,
          methodConcepts: methodConceptsZ,
        },
      });
    }
    if (interestConceptsV !== currentVideo.interestConcepts) {
      const interestConceptsZ = interestConceptsV.map((a) => ({ ...a }));
      interestConceptsZ.forEach((element) => delete element["__typename"]);
      await updateInterestConcepts({
        variables: {
          uid: currentVideo.uid,
          interestConcepts: interestConceptsZ,
        },
      });
    }
    setIndex(currentIndex + 1);
    setVideoCount((oldCount) => oldCount - 1);
  };

  const handleSkip = (e) => {
    e.preventDefault();
    setIndex(currentIndex + 1);
  };

  useEffect(() => {
    if (currentIndex !== 0) {
      setVideo(filteredVideos[currentIndex]);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (currentVideo) {
      setTitle(currentVideo.title);
      setChannelName(currentVideo.channelName);
      setMethodConcepts(currentVideo.methodConcepts);
      setInterestConcepts(currentVideo.interestConcepts);
      setLanguage(currentVideo.language);
      setContentAdvisory(currentVideo.contentAdvisory);
      setDifficulty(currentVideo.difficulty);
      setEmbedId(currentVideo.embedId);
      setEmbeddable(currentVideo.embeddable);
    }
  }, [currentVideo]);

  return (
    <PageLayout>
      <DefaultNavbar routes="mod" />
      <Grid container item justifyContent="center" mt={12}>
        <Card>
          <MDBox pt={3} px={3} pb={1} textAlign="center">
            <MDTypography variant="h1" fontWeight="medium" pb={4}>
              Edit Video Info &#40;{`${videoCount} videos remaining`}&#41;
            </MDTypography>
          </MDBox>
          <MDBox component="form" onSubmit={handleSubmit} role="form">
            {" "}
            {currentVideo ? (
              <Grid container mt={1.625} px={6}>
                <Grid container item xs={12} md={6} px={6} spacing={3}>
                  <MDBox display="flex">
                    <MDTypography pr={3} pt={0.5}>
                      Video Information:
                    </MDTypography>
                  </MDBox>
                  <Grid item xs={12}>
                    <MDBox>
                      <MDInput
                        type="text"
                        value={title || ""}
                        onChange={(event) => {
                          setTitle(event.target.value);
                        }}
                        label="Title"
                        required
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox>
                      <MDInput
                        type="text"
                        value={channelName || ""}
                        onChange={(event) => {
                          setChannelName(event.target.value);
                        }}
                        label="Channel Name"
                        required
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" alignItems="center">
                      <MDTypography variant="button">Uncheck if not embeddable:</MDTypography>
                      <Checkbox
                        label="Embeddable"
                        checked={embeddableV}
                        onChange={(e) => setEmbeddable(e.target.checked)}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      value={languageV || ""}
                      onChange={(event, newLanguage) => {
                        setLanguage(newLanguage);
                      }}
                      options={["English", "Spanish", "French", "Hindi", "Chinese", "Vietnamese"]}
                      renderInput={(params) => (
                        <MDInput {...params} variant="outlined" required label="Language" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      value={contentAdvisoryV || []}
                      onChange={(event, newContentAdvisory) => {
                        setContentAdvisory(newContentAdvisory);
                      }}
                      options={["Language", "Violence/Gore", "Mental Illness", "Racism"]}
                      renderInput={(params) => (
                        <MDInput {...params} variant="outlined" label="Content Advisory" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      value={difficultyV || ""}
                      onChange={(event, newDifficulty) => {
                        setDifficulty(newDifficulty);
                      }}
                      options={difficulties}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          label="Difficulty"
                          required={embeddableV}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      disabled={!currentVideo || loadingConcepts}
                      value={methodConceptsV || []}
                      onChange={(event, newMethodConcepts) => {
                        setMethodConcepts(newMethodConcepts);
                      }}
                      options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <MDInput {...params} variant="outlined" label="Is Method For ..." />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      disabled={!currentVideo || loadingConcepts}
                      value={interestConceptsV || []}
                      onChange={(event, newInterestConcepts) => {
                        setInterestConcepts(newInterestConcepts);
                      }}
                      options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <MDInput {...params} variant="outlined" label="Is Interest For ..." />
                      )}
                    />
                  </Grid>
                  <MDBox
                    textAlign="center"
                    pt={4}
                    mb={8}
                    display="flex"
                    width="100%"
                    justifyContent="space-evenly"
                  >
                    <MDButton
                      type="submit"
                      disabled={loading}
                      sx={{ width: "35%" }}
                      variant="gradient"
                      color="primary"
                    >
                      Submit
                    </MDButton>
                    <MDButton
                      onClick={handleSkip}
                      disabled={loading}
                      sx={{ width: "35%" }}
                      variant="gradient"
                      color="info"
                    >
                      Skip
                    </MDButton>
                  </MDBox>
                </Grid>
                <Grid container item xs={12} md={6} px={6} spacing={3} pb={8}>
                  <ReactPlayer
                    url={`https://www.youtube.com/watch?v=${currentVideo.embedId}`}
                    config={{
                      youtube: {
                        playerVars: { rel: 0, controls: 1 },
                      },
                    }}
                    width="100%"
                    height="100%"
                  />
                </Grid>
              </Grid>
            ) : (
              <MDBox width="100%" textAlign="center" mb={6}>
                {loadingVideos && !dataVideos && <MDTypography pb={3}>Loading ...</MDTypography>}
                <MDButton
                  disabled={loadingVideos && !dataVideos}
                  variant="gradient"
                  color="info"
                  onClick={handleStart}
                >
                  Start
                </MDButton>
              </MDBox>
            )}
            {errorConcepts && (
              <Alert color="error">Failed to load concepts: {errorConcepts.message}</Alert>
            )}
            {error && <Alert color="error">{error.message}</Alert>}
            {errorVideos && (
              <Alert color="error">Failed to load videos: {errorVideos.message}</Alert>
            )}
          </MDBox>
        </Card>
      </Grid>
    </PageLayout>
  );
}

export default EditVideoInfo;
