// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import Pagination from "@mui/material/Pagination";

// react
import { useEffect, useState, useLayoutEffect } from "react";

// PropTypes
import PropTypes from "prop-types";

// Lodash
import { intersection, pickBy } from "lodash";

// Firestore
import { db, rdb } from "firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { child, get, ref } from "firebase/database";

// Auth Context
import { UserAuth } from "context/AuthContext/index";

// components
import CardDisplay from "./CardDisplay";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

// eslint-disable-next-line no-unused-vars
function StoreTiles(props) {
  const { filters } = props;
  const { user } = UserAuth();
  const [loading, setLoading] = useState(false);
  const [programData, setProgramData] = useState([]);
  const [activePrograms, setActivePrograms] = useState([]);
  const [onceActivePrograms, setOnceActivePrograms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [width] = useWindowSize();

  // Firestore Program Info Fetch
  useEffect(() => {
    const getProgramData = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "programs"));
      const receivedData = querySnapshot.docs.map((doc) => doc.data());
      setProgramData(receivedData);
      setLoading(false);
    };

    getProgramData();
  }, []);

  // RDB Program History Fetch
  useEffect(() => {
    const getProgramHistory = async () => {
      const database = ref(rdb);
      await get(child(database, `programHistory/${user.uid}`)).then((snapshot) => {
        if (snapshot.exists()) {
          const programSnapshot = snapshot.val();
          const onceActiveKeys = Object.keys(programSnapshot);
          const activeKeys = Object.keys(pickBy(programSnapshot, (program) => program.active));
          setOnceActivePrograms(onceActiveKeys);
          setActivePrograms(activeKeys);
        }
      });
    };
    getProgramHistory();
    setLoading(false);
  }, []);

  // Implement filters
  const filteredProgramData = programData.filter((program) => {
    // const typeFilter = !(
    //   !(filters.type === null || filters.type === "") && program.type !== filters.type
    // );
    const audienceFilter = !(
      filters.targetAudience.length !== 0 &&
      filters.targetAudience.indexOf(program.targetAudience) < 0
    );
    const categoriesFilter = !(
      filters.categories.length !== 0 &&
      intersection(filters.categories, program.categories).length === 0
    );
    const includeActiveFilter = filters.includeActive
      ? true
      : !activePrograms.includes(program.programId);
    return audienceFilter && categoriesFilter && includeActiveFilter; // typeFilter &&
  });

  // eslint-disable-next-line no-nested-ternary
  const cardsPerPage = width > 1199 ? 8 : width < 1200 && width > 799 ? 6 : 4;

  const pageCount = Math.ceil(filteredProgramData.length / cardsPerPage);
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredProgramData.slice(indexOfFirstCard, indexOfLastCard);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <MDBox component="section" p={3} mt={{ xs: 2, lg: 0 }}>
      <MDBox width="100%">
        <CardDisplay
          filteredProgramData={currentCards}
          activePrograms={activePrograms}
          onceActivePrograms={onceActivePrograms}
          setActivePrograms={setActivePrograms}
          loading={loading}
        />
      </MDBox>
      <MDBox pt={3} display="flex" width="100%" justifyContent="center">
        <Pagination color="info" count={pageCount} page={currentPage} onChange={handleChange} />
      </MDBox>
    </MDBox>
  );
}

StoreTiles.propTypes = {
  filters: PropTypes.shape({
    // type: PropTypes.string,
    targetAudience: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    categories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    includeActive: PropTypes.bool.isRequired,
  }).isRequired,
};

export default StoreTiles;
