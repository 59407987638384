/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { ApolloClient, ApolloProvider, InMemoryCache, ApolloLink, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { AuthContextProvider } from "context/AuthContext/index";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context/NavContext";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors);
  }
  if (networkError) {
    console.log(networkError);
  }
});

// need to make this trigger on an environment variable and tweak the build scripts
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_NEO4J_FUNCTION_URI,
});

const link = ApolloLink.from([errorLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <MaterialUIControllerProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </MaterialUIControllerProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
