/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useNavigate } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function Educators() {
  const navigate = useNavigate();

  const handleContactUs = () => navigate("/contact-us");

  return (
    <MDBox variant="gradient" bgColor="dark" borderRadius="lg" shadow="small" sx={{ mx: 5, my: 5 }}>
      <MDBox component="section" py={{ xs: 6, lg: 12 }}>
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h2" color="white" mb={1}>
                Are you an educator?
              </MDTypography>
              <MDTypography variant="body2" color="white" mb={2}>
                We are looking for schools and teachers to pilot test an early version of our
                product. We would be happy to work with you to develop and curate collections and
                programs for your students&apos; specific needs. If you are interested, you can find
                the contact form to get in touch with us by clicking the button.
              </MDTypography>
            </Grid>
            <Grid item xs={12} lg={6} textAlign="right">
              <MDButton variant="gradient" color="primary" onClick={handleContactUs}>
                Contact Us
              </MDButton>
            </Grid>
          </Grid>
        </Container>
      </MDBox>
    </MDBox>
  );
}

export default Educators;
