import { ToggleButtonGroup, ToggleButton, Icon } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox/index";

// menu icons
import block2 from "assets/images/icons/format-menu/block-2.svg";
import grid2 from "assets/images/icons/format-menu/grid-2.svg";
import block3 from "assets/images/icons/format-menu/block-3.svg";
import gridBottom3 from "assets/images/icons/format-menu/grid-bottom-3.svg";

function FormatMenu(props) {
  const { componentCount, format, setFormat } = props;

  const handleChange = (e, newFormat) => {
    if (newFormat !== null) {
      setFormat(newFormat);
    }
  };

  switch (componentCount) {
    case 1:
      return (
        <ToggleButtonGroup size="large" value={format} exclusive onChange={handleChange}>
          <ToggleButton value="block">
            <Icon fontSize="medium">square</Icon>
          </ToggleButton>
        </ToggleButtonGroup>
      );
    case 2:
      return (
        <ToggleButtonGroup size="large" value={format} exclusive onChange={handleChange}>
          <ToggleButton value="block">
            <Icon fontSize="medium" color="inherit">
              <img alt="block-2" src={block2} />
            </Icon>
          </ToggleButton>
          <ToggleButton value="grid">
            <Icon fontSize="medium" color="inherit">
              <img alt="grid-2" src={grid2} />
            </Icon>
          </ToggleButton>
        </ToggleButtonGroup>
      );
    case 3:
      return (
        <ToggleButtonGroup size="large" value={format} exclusive onChange={handleChange}>
          <ToggleButton value="block">
            <Icon fontSize="medium" color="inherit">
              <img alt="block-3" src={block3} />
            </Icon>
          </ToggleButton>
          <ToggleButton value="grid">
            <Icon
              fontSize="medium"
              color="inherit"
              sx={{
                transform: "rotate(90deg)",
              }}
            >
              <img alt="block-3" src={block3} />
            </Icon>
          </ToggleButton>
          <ToggleButton value="grid-top">
            <Icon
              fontSize="medium"
              color="inherit"
              sx={{
                transform: "rotate(180deg)",
              }}
            >
              <img alt="grid-bottom-3" src={gridBottom3} />
            </Icon>
          </ToggleButton>
          <ToggleButton value="grid-bottom">
            <Icon fontSize="medium" color="inherit">
              <img alt="grid-bottom-3" src={gridBottom3} />
            </Icon>
          </ToggleButton>
          {/* <ToggleButton value="grid-right">
            <Icon>square</Icon>
          </ToggleButton>
          <ToggleButton value="grid-left">
            <Icon>square</Icon>
      </ToggleButton> */}
        </ToggleButtonGroup>
      );
    default:
      return <MDBox />;
  }
}

export default FormatMenu;

FormatMenu.propTypes = {
  componentCount: PropTypes.number.isRequired,
  setFormat: PropTypes.func.isRequired,
  format: PropTypes.string.isRequired,
};
