/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { pull, pullAll, cloneDeep, isObject } from "lodash";

// Firebase
import { storage } from "firebase-config";
import { ref, uploadBytes, listAll } from "firebase/storage";

// @mui material components
import {
  Grid,
  Card,
  Alert,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Icon,
  Checkbox,
  FormGroup,
  Stack,
  styled,
  Autocomplete,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Layout
import PageLayout from "examples/LayoutContainers/PageLayout";
import DefaultNavbar from "examples/Navbars/DefaultNavbar/index";

// Auth context
import { UserAuth } from "context/AuthContext";

// Components
import { difficulties } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import TestingResourcePreview from "./components/TestingResourcePreview";

function EditTestInfo() {
  const [deleteActive, setDeleteActive] = useState(false);
  const [deleteAnswerChoices, setDeleteAnswerChocies] = useState([]);
  const [currentTest, setTest] = useState(null);
  const [typeV, setType] = useState("Multiple Choice");
  const [difficultyV, setDifficulty] = useState("Intro");
  const [testedConceptsV, setTestedConcepts] = useState([]);
  const [answerChoicesV, setAnswerChoices] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [pictureUpload, setPictureUpload] = useState();
  const [textV, setText] = useState("");
  const [questionV, setQuestion] = useState("");
  const [errorV, setError] = useState();
  const [testCount, setTestCount] = useState(0);
  const [currentIndex, setIndex] = useState(0);
  const [testsWithImages, setTestsWithImages] = useState([]);
  const { user } = UserAuth();
  const newAnswerChoiceRef = useRef();
  const pictureUploadActive = isObject(pictureUpload);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const UPDATE_TEST = gql`
    mutation UpdateTest(
      $uid: ID!
      $type: String!
      $difficulty: String!
      $text: String!
      $question: String!
      $picture: Boolean!
      $addedBy: String!
      $answerChoices: [String!]!
      $correctAnswer: String!
    ) {
      updateTests(
        where: { uid: $uid }
        update: {
          type: $type
          difficulty: $difficulty
          text: $text
          question: $question
          picture: $picture
          addedBy: $addedBy
          answerChoices: $answerChoices
          correctAnswer: $correctAnswer
        }
      ) {
        tests {
          uid
          type
          addedBy
          text
          question
        }
      }
    }
  `;

  const ADD_TESTED_CONCEPTS = gql`
    mutation AddTestedConcepts($uid: ID!, $testedConcepts: [ConceptWhere!]) {
      updateTests(
        update: { testedConcepts: { connect: { where: { node: { OR: $testedConcepts } } } } }
        where: { uid: $uid }
      ) {
        tests {
          uid
          question
          testedConcepts {
            name
            uid
          }
        }
      }
    }
  `;

  const GET_CONCEPTS = gql`
    query GetConcepts {
      concepts {
        uid
        name
      }
    }
  `;

  const GET_TESTS = gql`
    query GetTests {
      tests {
        uid
        type
        difficulty
        text
        question
        picture
        answerChoices
        correctAnswer
        testedConcepts {
          uid
          name
        }
      }
    }
  `;

  useEffect(() => {
    if (currentTest) {
      setType(currentTest.type);
      setDifficulty(currentTest.difficulty);
      setText(currentTest.text);
      setQuestion(currentTest.question);
      setPictureUpload(currentTest.picture);
      setAnswerChoices(currentTest.answerChoices);
      setCorrectAnswer(currentTest.correctAnswer);
      setTestedConcepts(currentTest.testedConcepts);
    }
  }, [currentTest]);

  useEffect(() => {
    const getStoredImageUids = async () => {
      const listRef = ref(storage, "testResourceImages");
      listAll(listRef).then((res) => {
        const testUidsWithImages = [];
        res.items.forEach((itemRef) => testUidsWithImages.push(itemRef.name));
        setTestsWithImages(testUidsWithImages);
      });
    };
    getStoredImageUids();
  }, []);

  const [updateTests, { loading, error }] = useMutation(UPDATE_TEST, {
    fetchPolicy: "no-cache",
  });
  const {
    loading: loadingConcepts,
    error: errorConcepts,
    data: dataConcepts,
  } = useQuery(GET_CONCEPTS);
  const { loading: loadingTests, error: errorTests, data: dataTests } = useQuery(GET_TESTS);
  const [updateTestedConcepts] = useMutation(ADD_TESTED_CONCEPTS);

  const filteredTests =
    !loadingTests && dataTests
      ? dataTests.tests.filter((test) => test.picture && !testsWithImages.includes(test.uid))
      : null;

  const handleStart = (e) => {
    e.preventDefault();
    setTestCount(filteredTests.length);
    setTest(filteredTests[currentIndex]);
  };

  const handleSkip = (e) => {
    e.preventDefault();
    setIndex(currentIndex + 1);
  };

  // Next test trigger
  useEffect(() => {
    if (currentIndex !== 0) {
      setTest(filteredTests[currentIndex]);
    }
  }, [currentIndex]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (correctAnswer === "") {
      setError("Please select a correct answer.");
      return;
    }
    if (!isObject(pictureUpload)) {
      setError("Please upload a picture.");
      return;
    }

    // Neo4j
    await updateTests({
      variables: {
        uid: currentTest.uid,
        text: textV,
        question: questionV,
        type: typeV,
        addedBy: user.uid,
        difficulty: difficultyV,
        answerChoices: answerChoicesV,
        picture: pictureUploadActive,
        correctAnswer,
      },
    });

    if (testedConceptsV.length !== 0) {
      const testedConceptsZ = testedConceptsV.map((a) => ({ ...a }));
      // eslint-disable-next-line no-param-reassign, dot-notation
      testedConceptsZ.forEach((element) => delete element["__typename"]);
      updateTestedConcepts({
        variables: {
          uid: currentTest.uid,
          testedConcepts: testedConceptsZ,
        },
        onCompleted: setTest(false),
      });
    }

    // Firebase image storage
    if (pictureUploadActive) {
      const imageRef = ref(storage, `testResourceImages/${currentTest.uid}`);
      await uploadBytes(imageRef, pictureUpload);
    }

    // Next Resource
    setIndex(currentIndex + 1);
    setTestCount((oldCount) => oldCount - 1);
  };

  const handleNewAnswerChoice = async (e) => {
    e.preventDefault();
    await setAnswerChoices((currentAnswerChoices) => [
      ...currentAnswerChoices,
      newAnswerChoiceRef.current.value,
    ]);
    newAnswerChoiceRef.current.value = "";
  };

  const handleCorrectAnswer = (event) => {
    setCorrectAnswer(event.target.value);
  };

  const handleDeleteSelecion = (e) => {
    e.preventDefault();
    const newDeleteAnswerChoices = cloneDeep(deleteAnswerChoices);
    if (e.target.checked) {
      newDeleteAnswerChoices.push(e.target.name);
    } else {
      pull(newDeleteAnswerChoices, e.target.name);
    }
    setDeleteAnswerChocies(() => newDeleteAnswerChoices);
  };

  const handleDeleteAnswerChoices = (e) => {
    e.preventDefault();
    const newAnswerChoices = cloneDeep(answerChoicesV);
    pullAll(newAnswerChoices, deleteAnswerChoices);
    setAnswerChoices(() => newAnswerChoices);
    setDeleteAnswerChocies([]);
    setDeleteActive(false);
  };

  return (
    <PageLayout>
      <DefaultNavbar routes="mod" />
      <Grid container item justifyContent="center" mt={12}>
        <Card>
          <MDBox pt={3} px={3} pb={1} textAlign="center">
            <MDTypography variant="h1" fontWeight="medium" pb={4}>
              Edit Testing Resource Info &#40;{`${testCount} videos remaining`}&#41;
            </MDTypography>
          </MDBox>
          {currentTest ? (
            <MDBox component="form" onSubmit={handleSubmit} role="form">
              <Grid container pt={1.625}>
                <Grid container item xs={12} md={6} px={6} spacing={3}>
                  <MDTypography>Testing Resource Information:</MDTypography>
                  <Grid item xs={12}>
                    <Autocomplete
                      value={typeV || "Multiple Choice"}
                      required
                      onChange={(event, newType) => {
                        setType(newType);
                      }}
                      options={["Multiple Choice"]}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          label="Type of Testing Resource"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      value={difficultyV || "Intro"}
                      required
                      onChange={(event, newDifficulty) => {
                        setDifficulty(newDifficulty);
                      }}
                      options={difficulties}
                      renderInput={(params) => (
                        <MDInput {...params} label="Difficulty" required variant="outlined" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={3} direction="row" alignItems="center">
                      <MDTypography variant="button" fontWeight="medium">
                        Picture
                      </MDTypography>
                      <MDButton disabled={isObject(pictureUpload)} component="label" color="info">
                        Upload File
                        <VisuallyHiddenInput
                          type="file"
                          onChange={(e) => setPictureUpload(e.target.files[0])}
                        />
                      </MDButton>
                      {isObject(pictureUpload) && (
                        <>
                          <MDButton
                            iconOnly
                            variant="text"
                            size="large"
                            color="info"
                            onClick={() => setPictureUpload(null)}
                          >
                            <Icon>close</Icon>
                          </MDButton>
                          <MDTypography variant="button">{pictureUpload.name}</MDTypography>
                        </>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <MDInput
                      type="text"
                      value={textV || ""}
                      onChange={(e) => {
                        setText(e.target.value);
                      }}
                      label="Informational Text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MDInput
                      type="text"
                      value={questionV || ""}
                      onChange={(e) => {
                        setQuestion(e.target.value);
                      }}
                      label="Question"
                      required
                      fullWidth
                    />
                  </Grid>
                  <MDBox mt={3} textAlign="center">
                    <MDTypography>Concepts Tested:</MDTypography>
                  </MDBox>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      disabled={loadingConcepts}
                      value={testedConceptsV || null}
                      onChange={(event, newTestedConcepts) => {
                        setTestedConcepts(newTestedConcepts);
                      }}
                      options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="outlined"
                          required={testedConceptsV.length === 0}
                          label={testedConceptsV.length === 0 ? "Concepts" : "Concepts *"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={6} px={6} spacing={3}>
                  <Grid item xs={12}>
                    <MDTypography mb={2}>Answer Choices:</MDTypography>
                    <Stack direction="row" spacing={2}>
                      <MDInput
                        fullWidth
                        type="text"
                        inputRef={newAnswerChoiceRef}
                        label="New Answer Choice"
                      />
                      <MDButton color="info" onClick={handleNewAnswerChoice}>
                        submit
                      </MDButton>
                      <MDButton
                        color="error"
                        onClick={(e) => {
                          e.preventDefault();
                          setDeleteActive(!deleteActive);
                        }}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography mb={2}>Please select the correct answer:</MDTypography>
                    <FormControl>
                      {!deleteActive ? (
                        <RadioGroup value={correctAnswer || ""} onChange={handleCorrectAnswer}>
                          {answerChoicesV.map((answer) => (
                            <FormControlLabel
                              key={answer}
                              value={answer}
                              control={<Radio />}
                              label={answer}
                            />
                          ))}
                        </RadioGroup>
                      ) : (
                        <>
                          <FormGroup onChange={handleDeleteSelecion}>
                            {answerChoicesV.map((answer) => (
                              <FormControlLabel
                                key={`delete-${answer}`}
                                control={<Checkbox name={answer} />}
                                label={answer}
                              />
                            ))}
                          </FormGroup>
                          <MDButton variant="contained" onClick={handleDeleteAnswerChoices}>
                            Delete
                          </MDButton>
                        </>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {errorConcepts && (
                <Alert color="error">Failed to load concepts: {errorConcepts.message}</Alert>
              )}
              {error && <Alert color="error">{error.message}</Alert>}
              {errorV && <Alert color="error">{errorV}</Alert>}
              {errorTests && (
                <Alert color="error">Failed to load testing resources: {errorTests.message}</Alert>
              )}
              <MDBox display="flex" justifyContent="space-evenly" pt={4}>
                <MDButton
                  type="submit"
                  disabled={loading}
                  sx={{ width: "25%" }}
                  variant="gradient"
                  color="primary"
                >
                  Submit
                </MDButton>
                <MDButton
                  disabled={loading}
                  sx={{ width: "25%" }}
                  variant="gradient"
                  color="info"
                  onClick={handleSkip}
                >
                  Skip
                </MDButton>
              </MDBox>
              <Grid item xs={12} justifyContent="center" my={4}>
                <MDBox pb={3} pt={{ xs: 8, md: 4 }} mx={10} sx={{ border: 1 }}>
                  <TestingResourcePreview
                    answerChoices={answerChoicesV}
                    correctAnswer={correctAnswer}
                    text={textV}
                    question={questionV}
                    picture={pictureUploadActive ? pictureUpload : null}
                  />
                </MDBox>
              </Grid>
            </MDBox>
          ) : (
            <MDBox width="100%" textAlign="center" mb={6}>
              {loadingTests && !dataTests && <MDTypography pb={3}>Loading ...</MDTypography>}
              <MDButton
                disabled={loadingTests && !dataTests}
                variant="gradient"
                color="info"
                onClick={handleStart}
              >
                Start
              </MDButton>
            </MDBox>
          )}
        </Card>
      </Grid>
    </PageLayout>
  );
}

export default EditTestInfo;
