/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footer";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Routes
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/to-do.jpg";
import ContactForm from "./sections/ContactForm";
import Faq from "./sections/Faq";

function ContactUs() {
  return (
    <PageLayout>
      <DefaultNavbar
        routes="page"
        action={{
          type: "internal",
          route: "/log-in",
          label: "Sign Up/Log In",
          color: "primary",
        }}
        transparent
        light
      />
      <MDBox
        minHeight="23rem"
        height="50vh"
        maxHeight="33rem"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Grid item xs={12}>
              <MDTypography
                variant="h2"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Contact Us!
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body1" color="white" mt={1}>
                Check out our FAQ below or reach out to us through the contact form.
              </MDTypography>
            </Grid>
          </Grid>
        </Container>
      </MDBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        <Faq />
        <ContactForm />
      </Card>
      <MDBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MDBox>
    </PageLayout>
  );
}

export default ContactUs;
