/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { useNavigate } from "react-router-dom";
import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Firebase RDB
import { rdb } from "firebase-config";
import { set, ref, update } from "firebase/database";

// Auth Context
import { UserAuth } from "context/AuthContext";
import RemoveProgram from "./RemoveProgramDialog";

function ProgramCard(props) {
  const { program, activePrograms, setActivePrograms, onceActivePrograms } = props;
  const [active, setActive] = useState(activePrograms.includes(program.programId));
  const { user } = UserAuth();
  const navigate = useNavigate();

  const handleAddProgram = async (e) => {
    e.preventDefault();
    if (onceActivePrograms.includes(program.programId)) {
      await update(ref(rdb, `programHistory/${user.uid}/${program.programId}`), {
        active: true,
      });
    } else {
      await set(ref(rdb, `programHistory/${user.uid}/${program.programId}`), {
        index: 0,
        active: true,
      });
      await program.conceptList.forEach((concept) =>
        set(ref(rdb, `programHistory/${user.uid}/${program.programId}/${concept.uid}`), {
          completed: false,
        })
      );
    }
    // setActivePrograms((prevState) => [...prevState, program.programId]);
    navigate("/studio/my-programs");
  };

  return (
    <Card
      sx={{
        maxHeight: "35rem",
        maxWidth: "20rem",
        height: "100%",
      }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        minHeight="12rem"
        maxHeight="12rem"
        mx={2}
        mt={-3}
        display="flex"
      >
        <MDBox
          component="img"
          src={program.image}
          alt={program.name}
          borderRadius="lg"
          shadow="md"
          width="auto"
          height="auto"
          maxWidth="100%"
          sx={{ objectFit: "cover" }}
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${program.image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        p={3}
        mt={-1}
      >
        <Stack>
          <MDTypography variant="button" color="text" fontWeight="regular" mb={0.75}>
            {program.quiz === "Yes"
              ? `${program.type} Program • ${program.conceptList.length} Concepts • Quiz ✓`
              : `${program.type} Program • ${program.conceptList.length} Concepts`}
          </MDTypography>
          <MDTypography variant="h5" fontWeight="bold">
            {program.name}
          </MDTypography>
          <MDBox mt={1} mb={3}>
            <MDTypography variant="body2" component="p" color="text">
              {program.description}
            </MDTypography>
          </MDBox>
        </Stack>
        <MDBox display="flex" justifyContent="space-between">
          <MDButton
            variant="outlined"
            size="small"
            color="info"
            onClick={() =>
              navigate("/studio/program-store/program-list", {
                state: {
                  // selectedProgramHistory,
                  selectedProgram: program,
                  onceActivePrograms,
                  active,
                },
              })
            }
          >
            See Program
          </MDButton>
          {active ? (
            <RemoveProgram
              program={program}
              setActivePrograms={setActivePrograms}
              setActive={setActive}
            />
          ) : (
            <MDButton
              variant="gradient"
              circular
              iconOnly
              size="medium"
              color="info"
              onClick={handleAddProgram}
            >
              <Icon fontSize="large">add</Icon>
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Typechecking props for the ProgramCard
const programConceptProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  person: PropTypes.bool,
  place: PropTypes.bool,
  event: PropTypes.bool,
  methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  interestVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  programConcepts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
});

ProgramCard.propTypes = {
  program: PropTypes.shape({
    addedBy: PropTypes.string.isRequired,
    programId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    targetAudience: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    conceptList: PropTypes.arrayOf(programConceptProps),
    type: PropTypes.string.isRequired,
    quiz: PropTypes.string,
  }).isRequired,
  activePrograms: PropTypes.arrayOf(PropTypes.string).isRequired,
  onceActivePrograms: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActivePrograms: PropTypes.func.isRequired,
};

export default ProgramCard;
