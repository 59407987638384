/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState, useRef } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";

// Firestore
import { db } from "firebase-config";
import { doc, setDoc, collection } from "firebase/firestore";

function BasicInfo({ user }) {
  const [updating, setUpdating] = useState(false);
  const [gradeV, setGrade] = useState(user.grade);
  const [stateV, setState] = useState(user.state);
  const [genderV, setGender] = useState(user.gender);
  // const [raceV, setRace] = useState(user.race);
  const [studentV, setStudent] = useState(user.student);
  // const [ethnicityV, setEthnicity] = useState(user.ethnicity);
  const [dateOfBirthV, setDOB] = useState(user.dateOfBirth);
  const [dobError, setDobError] = useState(false);
  const firstNameRef = useRef(user.firstName);
  const lastNameRef = useRef(user.lastName);

  // Datepicker error
  const errorProps = dobError
    ? {
        helperText: "Input is required",
        error: true,
      }
    : {};

  const handleUpdating = () => {
    setUpdating(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const newUser = {
      uid: user.uid,
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      email: user.email,
      student: studentV,
      grade: gradeV || "",
      state: stateV,
      gender: genderV,
      // ethnicity: ethnicityV,
      // race: raceV,
      dateOfBirth: dateOfBirthV,
    };
    try {
      await setDoc(doc(collection(db, "users"), user.uid), newUser, { merge: true });
      setUpdating(false);
    } catch (event) {
      setDobError(true);
    }
  };

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" onSubmit={handleUpdate} role="form" mb={3}>
        <Grid container spacing={3} px={3}>
          <Grid item xs={12} md={6}>
            <MDBox>
              <MDInput
                type="text"
                defaultValue={user.firstName}
                disabled={!updating}
                inputRef={firstNameRef}
                InputProps={{
                  readOnly: !updating,
                }}
                label="First Name"
                required
                fullWidth
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDInput
              type="text"
              defaultValue={user.lastName}
              disabled={!updating}
              inputRef={lastNameRef}
              InputProps={{
                readOnly: !updating,
              }}
              label="Last Name"
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} px={3} mt={1}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={studentV || null}
              onChange={(event, newStudent) => {
                setStudent(newStudent);
              }}
              disabled={!updating}
              options={["Yes", "No"]}
              renderInput={(params) => (
                <MDInput {...params} variant="outlined" required label="Are You a Student?" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={gradeV || null}
              onChange={(event, newGrade) => {
                setGrade(newGrade);
              }}
              disabled={!updating || studentV !== "Yes"}
              required
              options={[
                "1st Grade",
                "2nd Grade",
                "3rd Grade",
                "4th Grade",
                "5th Grade",
                "6th Grade",
                "7th Grade",
                "8th Grade",
                "9th Grade",
                "10th Grade",
                "11th Grade",
                "12th Grade",
                "Other",
              ]}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="outlined"
                  required={user.student === "Yes"}
                  label="Grade"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} px={3} mt={1}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={stateV || null}
              onChange={(event, newState) => {
                setState(newState);
              }}
              disabled={!updating}
              options={states}
              renderInput={(params) => (
                <MDInput {...params} variant="outlined" required label="State" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={genderV || null}
              onChange={(event, newGender) => {
                setGender(newGender);
              }}
              disabled={!updating}
              options={["Male", "Female", "Non-Binary", "Prefer Not to Disclose"]}
              renderInput={(params) => (
                <MDInput {...params} variant="outlined" required label="Gender" />
              )}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={3} px={3} mt={1}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={ethnicityV || null}
              onChange={(event, newEthnicity) => {
                setEthnicity(newEthnicity);
              }}
              disabled={!updating}
              options={["Hispanic or Latino", "Not Hispanic or Latino", "Prefer Not to Disclose"]}
              renderInput={(params) => (
                <MDInput {...params} variant="outlined" required label="Ethnicity" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              disabled={!updating}
              value={raceV || []}
              onChange={(event, newRace) => {
                setRace(newRace);
              }}
              options={[
                "American Indian or Alaska Native",
                "Asian",
                "Black or African American",
                "Native Hawaiian or Other Pacific Islander",
                "White",
                "Prefer Not to Disclose",
              ]}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  disabled={!updating}
                  required={raceV.length === 0}
                  label={raceV.length === 0 ? "Race" : "Race *"}
                  variant="outlined"
                  helperText="Select One or More"
                />
              )}
            />
              </Grid> 
        </Grid> */}
        <Grid container item xs={12} px={3} mt={4}>
          <MDDatePicker
            value={dateOfBirthV}
            onChange={(event, newDOB) => {
              setDobError(false);
              setDOB(newDOB);
            }}
            disabled={!updating}
            input={{
              label: "Date of Birth",
              required: true,
              fullWidth: true,
              disabled: !updating,
              ...errorProps,
            }}
          />
        </Grid>
        <MDBox textAlign="right" mr={3} mt={3}>
          {updating && (
            <MDButton type="submit" variant="gradient" color="info" size="small">
              submit
            </MDButton>
          )}{" "}
          {!updating && (
            <MDButton variant="gradient" color="dark" size="small" onClick={handleUpdating}>
              update info
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;

BasicInfo.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    student: PropTypes.string,
    grade: PropTypes.string,
    state: PropTypes.string,
    gender: PropTypes.string,
    ethnicity: PropTypes.string,
    race: PropTypes.arrayOf(PropTypes.string),
    dateOfBirth: PropTypes.string,
  }).isRequired,
};
