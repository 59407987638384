/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Container, Grid, Card } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
// import MKBadge from "components/MKBadge";
import MDTypography from "components/MDTypography";
import MDSocialButton from "components/MDSocialButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footer";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Home page sections
import Product from "layouts/CompanyWebsite/Home/sections/Product";
import Counters from "layouts/CompanyWebsite/Home/sections/Counters";
import Stakeholders from "layouts/CompanyWebsite/Home/sections/Stakeholders";
import InspiringEducators from "layouts/CompanyWebsite/Home/sections/InspiringEducators";
import FourDifferences from "layouts/CompanyWebsite/Home/sections/FourDifferences";
// import Newsletter from "layouts/CompanyWebsite/Home/sections/Newsletter";

// Routes
import footerRoutes from "footer.routes";

// Images
import textLogo from "assets/images/edyou-logo-text.png";
import iconBg from "assets/images/subject-bg.jpg";
// import LatestArticles from "./sections/LatestArticles";

function Home() {
  return (
    <PageLayout>
      <DefaultNavbar
        routes="page"
        action={{
          type: "internal",
          route: "/log-in",
          label: "Sign Up/Log In",
          color: "primary",
        }}
        sticky
      />
      <MDBox
        minHeight="35rem"
        width="100%"
        sx={{
          backgroundImage: `url(${iconBg})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MDBox
              color="white"
              bgColor="100"
              variant="gradient"
              borderRadius="lg"
              sx={{
                border: 2,
                borderColor: "primary.main",
              }}
              shadow="lg"
              opacity={1}
              p={2}
            >
              <img src={textLogo} alt="" />
              <MDTypography
                variant="h3"
                color="text"
                textAlign="center"
                mt={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Education Your Way
              </MDTypography>
            </MDBox>
          </Grid>
        </Container>
      </MDBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Product />
        <FourDifferences />
        <Stakeholders />
        {/* <LatestArticles /> */}
        <Container sx={{ my: 3, mt: -6 }}>
          <InspiringEducators />
        </Container>
        {/* <Newsletter /> */}
        <MDBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid
                container
                item
                xs={12}
                lg={4}
                ml="auto"
                sx={{ textAlign: { xs: "center", lg: "left" } }}
              >
                <Grid item xs={12} lg={11}>
                  <MDTypography variant="h4" fontWeight="bold" mb={0.5}>
                    Help us spread the word!
                  </MDTypography>
                  <MDTypography variant="body1" color="text">
                    Share a link to EDYou today
                  </MDTypography>
                </Grid>
                <Grid
                  item
                  xs={0}
                  lg={1}
                  sx={{ display: { xs: "none", lg: "flex" } }}
                  alignItems="center"
                >
                  <ArrowForwardIosIcon fontSize="large" />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                lg={8}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MDSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Start%20learning%20your%20way%20at%20%40EDYou_for_all%20%23alwayslearning%20%23elearning%20%23lovelearning%20%23learningisfun&amp;url=https%3A%2F%2Fedyou.org"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MDSocialButton>
                <MDSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://edyou.org"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MDSocialButton>
                <MDSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://edyou.org"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MDSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MDBox>
      </Card>
      <MDBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MDBox>
    </PageLayout>
  );
}

export default Home;
