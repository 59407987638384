/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
import { useState } from "react";
import MDBox from "components/MDBox/index";
import { rdb } from "firebase-config";
import { ref, update, serverTimestamp, push, set } from "firebase/database";
import { Alert, Grid, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import { findIndex, uniqueId } from "lodash";
import { testProps } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import MDButton from "components/MDButton/index";
import Serializer from "layouts/LearningStudio/AddResource/addTest/components/Serializer/index";
import FormatGrid from "layouts/LearningStudio/AddResource/addTest/components/TestBuilderLayout/SortableItem/FormatGrid";

function QuizResource(props) {
  const {
    quizResource,
    setQuizState,
    quizState,
    quizRefPath,
    quizConceptRefPath,
    testConceptRefPath,
    currentConceptSubmitted,
    setCurrentConceptSubmitted,
    currentCorrect,
    setCurrentCorrect,
    questionIndex,
    setQuestionIndex,
    lastQuestion,
    notify,
    checkUnderstanding,
    loading,
    delay,
    testContent,
  } = props;
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!answer) {
      setError("Please select an answer choice.");
      return;
    }
    const conceptRef = ref(rdb, quizConceptRefPath);
    const newQuizRef = push(ref(rdb, `${quizConceptRefPath}/tests`));
    const newTestRef = push(ref(rdb, testConceptRefPath));

    // Find correcAnswer
    const ansIndex = findIndex(testContent.components, ["type", "answer"]);
    const answerBlockIndex = findIndex(testContent.components[ansIndex].content, [
      "type",
      "answer-block",
    ]);
    const { correctAnswer } = testContent.components[ansIndex].content[answerBlockIndex].answerData;
    if (answer === correctAnswer) {
      const testData = {
        testId: quizResource.uid,
        timestamp: serverTimestamp(),
        answer,
        correct: true,
        difficulty: quizResource.difficulty,
      };
      if (!checkUnderstanding) {
        set(newQuizRef, testData);
      }
      set(newTestRef, testData);
      setCurrentCorrect(currentCorrect + 1);
      setCurrentConceptSubmitted(currentConceptSubmitted + 1);
      notify(currentConceptSubmitted > 1 ? "concept" : "correct");
      setQuizState("correct");
      setAnswer("");
    } else {
      const testData = {
        testId: quizResource.uid,
        timestamp: serverTimestamp(),
        answer,
        correct: false,
        difficulty: quizResource.difficulty,
      };
      if (!checkUnderstanding) {
        set(newQuizRef, testData);
      }
      set(newTestRef, testData);
      if (currentConceptSubmitted > 1 || lastQuestion) {
        setCurrentConceptSubmitted(currentConceptSubmitted + 1);
        notify("incorrect");
      }
      setQuizState("incorrect");
    }
    setQuestionIndex((oldIndex) => oldIndex + 1);
    if (!checkUnderstanding) {
      update(conceptRef, { currentSubmitted: currentConceptSubmitted + 1 });
      update(ref(rdb, quizRefPath), {
        currentQuestionIndex: questionIndex + 1,
      });
    }
    setError("");
  };

  return (
    <MDBox display="flex" width="100%" justifyContent="center" alignItems="center" pt={3}>
      <MDBox display="block" width="100%" textAlign="center">
        {Object.keys(testContent).length > 0 ? (
          <Grid container spacing={2}>
            {testContent.components.map((component, index) => (
              <Serializer
                key={uniqueId("serialized-")}
                format={FormatGrid(testContent.format, testContent.components.length)[index]}
                content={component.content}
                answer={answer}
                setAnswer={setAnswer}
              />
            ))}
          </Grid>
        ) : (
          <CircularProgress />
        )}
        <MDBox display="block">
          {error && <Alert color="error">{error}</Alert>}
          {quizState === "quizzing" && (
            <MDButton
              disabled={(loading && currentConceptSubmitted > 1) || delay}
              color="info"
              onClick={handleSubmit}
            >
              submit
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default QuizResource;

QuizResource.propTypes = {
  quizResource: testProps.isRequired,
  quizPictureUrl: PropTypes.string,
  setQuizState: PropTypes.func.isRequired,
  quizState: PropTypes.string.isRequired,
  quizRefPath: PropTypes.string.isRequired,
  quizConceptRefPath: PropTypes.string.isRequired,
  testConceptRefPath: PropTypes.string.isRequired,
  currentConceptSubmitted: PropTypes.number.isRequired,
  setCurrentConceptSubmitted: PropTypes.func.isRequired,
  currentCorrect: PropTypes.number.isRequired,
  setCurrentCorrect: PropTypes.func.isRequired,
  questionIndex: PropTypes.number.isRequired,
  setQuestionIndex: PropTypes.func.isRequired,
  lastQuestion: PropTypes.bool.isRequired,
  notify: PropTypes.func.isRequired,
  checkUnderstanding: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  delay: PropTypes.bool.isRequired,
  testContent: PropTypes.shape({
    components: PropTypes.arrayOf(PropTypes.object),
    format: PropTypes.string,
  }).isRequired,
};

QuizResource.defaultProps = {
  quizPictureUrl: null,
  loading: true,
};
