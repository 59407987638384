/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { random, find, cloneDeep, pullAllBy } from "lodash";
import { gql, useQuery } from "@apollo/client";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { db, rdb } from "firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { ref, get, child, update, serverTimestamp } from "firebase/database";
import { UserAuth } from "context/AuthContext/index";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { Card, Icon, Stack } from "@mui/material";
import MDBox from "components/MDBox/index";
import MDButton from "components/MDButton/index";
import "./styles.css";
import MDTypography from "components/MDTypography/index";

function LeftArrow() {
  const { initComplete, isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);
  // NOTE initComplete is a hack for  prevent blinking on init
  // Can get visibility of item only after it's rendered

  return (
    <Arrow
      disabled={!initComplete || (initComplete && isFirstItemVisible)}
      onClick={() => scrollPrev()}
    >
      <Icon>chevron_left</Icon>
    </Arrow>
  );
}

function RightArrow() {
  const { initComplete, isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  return (
    <Arrow disabled={initComplete && isLastItemVisible} onClick={() => scrollNext()}>
      <Icon>chevron_right</Icon>
    </Arrow>
  );
}

function Arrow({ children, disabled, onClick, className, testId }) {
  return (
    <MDButton
      disabled={disabled}
      onClick={onClick}
      size="large"
      variant="gradient"
      color="info"
      className={`arrow-${className}`}
      data-testid={testId}
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        userSelect: "none",
        borderRadius: "6px",
        borderWidth: "1px",
        width: "3rem",
      }}
    >
      {children}
    </MDButton>
  );
}

// function onWheel(apiObj, ev) {
//   // NOTE: no good standart way to distinguish touchpad scrolling gestures
//   // but can assume that gesture will affect X axis, mouse scroll only Y axis
//   // of if deltaY too small probably is it touchpad
//   const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

//   if (isThouchpad) {
//     ev.stopPropagation();
//     return;
//   }

//   if (ev.deltaY < 0) {
//     apiObj.scrollNext();
//   } else {
//     apiObj.scrollPrev();
//   }
// }

const GET_TESTING_RESOURCE = gql`
  query GetTestingResource($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      name
      block
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
      testingResources {
        uid
        difficulty
        text
        question
        picture
        answerChoices
        correctAnswer
        testedConcepts {
          uid
          name
        }
      }
    }
  }
`;

export default function QuizzesOfTheWeek() {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quizData, setQuizData] = useState([]);
  const [activeQuizzes, setActiveQuizzes] = useState([]);
  const { user } = UserAuth();

  if (loading) {
    return <h2>Loading...</h2>;
  }

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleItemClick = (itemId) => {
    const itemSelected = isItemSelected(itemId);

    setSelected((currentSelected) =>
      itemSelected ? currentSelected.filter((el) => el !== itemId) : currentSelected.concat(itemId)
    );
  };

  // Firestore Quiz Info Fetch
  useEffect(() => {
    const getProgramData = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "weeklyQuizzes"));
      const quizzes = querySnapshot.docs.map((doc) => doc.data());
      setQuizData(quizzes);
      setLoading(false);
    };

    getProgramData();
  }, []);

  // RDB Quiz History Fetch
  useEffect(() => {
    const getQuizHistory = async () => {
      const database = ref(rdb);
      await get(child(database, `quizHistory/${user.uid}`)).then((snapshot) => {
        if (snapshot.exists()) {
          const quizSnapshot = snapshot.val();
          const activeKeys = Object.keys(quizSnapshot);
          const keysWithIndex = activeKeys.map((key) => {
            const activeQuiz = { programId: key, quizProgress: quizSnapshot[key] };
            return activeQuiz;
          });
          setActiveQuizzes(keysWithIndex);
        } else {
          setActiveQuizzes([]);
        }
      });
    };
    getQuizHistory();
    setLoading(false);
  }, []);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox className="scrollContainer" width="100%" height="100%" textAlign="center" padding={3}>
        <MDTypography variant="h2" mt={3}>
          Quizzes of the Week
        </MDTypography>
        <MDTypography variant="body1" mt={1} mb={2}>
          Empower your learning with weekly mini-programs and test your knowledge with the quiz!
        </MDTypography>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {" "}
          {/* onWheel={onWheel} */}
          {quizData.map((quiz) => (
            <CardScroll
              itemId={`scroll-${quiz.uid}`} // NOTE: itemId is required for track items
              key={`scroll-${quiz.uid}`}
              onClick={() => handleItemClick(quiz.uid)}
              selected={isItemSelected(quiz.uid)}
              quiz={quiz}
              activeQuizzes={activeQuizzes}
            />
          ))}
        </ScrollMenu>
      </MDBox>
    </Card>
  );
}

function CardScroll({ onClick, selected, itemId, quiz, activeQuizzes }) {
  const { user } = UserAuth();
  const visibility = useContext(VisibilityContext);
  const navigate = useNavigate();
  // const active = activeQuizzes.includes(quiz.programId);
  const active = find(activeQuizzes, ["programId", quiz.programId]);
  const completed = active ? active.quizProgress.completed : false;

  const currentIndex = active
    ? completed
      ? active.quizProgress.currentConceptIndex - 1
      : active.quizProgress.currentConceptIndex
    : 0;
  // const currentLevel = active ? Math.floor(active.quizProgress.currentIndex / 10) + 1 : 0;
  // const totalLevels = Math.ceil(quiz.conceptList.length / 10);

  const { loading: loadingTestingResource, data: dataTestingResource } = useQuery(
    GET_TESTING_RESOURCE,
    {
      variables: {
        uid: quiz.conceptList[currentIndex].uid,
      },
    }
  );

  /* const visible =
    !visibility.initComplete || (visibility.initComplete && visibility.isItemVisible(itemId)); */

  const handleClick = async () => {
    // update quizHistory
    const quizRefPath = `quizHistory/${user.uid}/${quiz.programId}`;
    if (!active) {
      await update(ref(rdb, quizRefPath), {
        currentConceptIndex: 0,
        currentQuestionIndex: 0,
        currentCorrect: 0,
        title: quiz.name,
        numConcepts: quiz.conceptList.length,
        type: "weekly quiz",
        completed: false,
      });
      await update(ref(rdb, `${quizRefPath}/concepts/${quiz.conceptList[currentIndex].uid}`), {
        currentSubmitted: 0,
      });
    }

    const timestamp = serverTimestamp();
    update(ref(rdb, quizRefPath), { timestamp });

    // getting concept data
    const currentConcept = quiz.conceptList[currentIndex];
    const { currentSubmitted } = active ? active.quizProgress.concepts[currentConcept.uid] : 0;

    // removing already completed testing resources
    const completedTests =
      active && currentSubmitted > 0
        ? Object.values(active.quizProgress.concepts[currentConcept.uid].tests)
        : null;
    const iteree = completedTests ? completedTests.map((test) => ({ uid: test.testId })) : null;
    // to avoid a read only property error
    const newTestArr = cloneDeep(dataTestingResource.concepts[0].testingResources);
    const remainingTestingResources = iteree
      ? pullAllBy(newTestArr, iteree, "uid")
      : dataTestingResource.concepts[0].testingResources;
    const testingResource = remainingTestingResources[random(remainingTestingResources.length - 1)];

    navigate("/studio/test-viewer", {
      state: {
        source: "weekly quiz",
        quiz,
        currentConcept: dataTestingResource.concepts[0],
        currentCorrect: active ? active.quizProgress.currentCorrect : 0,
        currentConceptSubmitted: currentSubmitted,
        currentTest: testingResource,
        testingResources: dataTestingResource.concepts[0].testingResources,
        currentQuestionIndex: active ? active.quizProgress.currentQuestionIndex : 0,
        currentIndex,
      },
    });
  };

  return (
    <Card
      data-cy={itemId}
      key={`card-${quiz.uid}`}
      onClick={() => onClick(visibility)}
      onKeyDown={(ev) => ev.code === "Enter" && onClick(visibility)}
      tabIndex={0}
      selected={selected}
      sx={{
        maxHeight: "35rem",
        width: "20rem",
        height: "100%",
        border: 2.5,
        borderColor: "gray",
        margin: "0 1.3rem",
        overflow: "hidden",
      }}
    >
      <MDBox position="relative" borderRadius="lg" maxHeight="12rem" mx={2} mt={2}>
        <MDBox
          component="img"
          src={quiz.image}
          alt={quiz.name}
          borderRadius="lg"
          width="100%"
          height="100%"
          sx={{ objectFit: "cover" }}
          position="relative"
          zIndex={1}
        />
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        p={3}
        mt={-2}
      >
        <Stack>
          <MDTypography variant="button" color="text" fontWeight="regular" mb={0.75}>
            {quiz.type} Quiz&nbsp;&bull;&nbsp;{quiz.conceptList.length} Concepts
          </MDTypography>
          <MDTypography
            variant="h5"
            fontWeight="bold"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {quiz.name}
          </MDTypography>
          <MDBox mt={1} mb={3} display="flex">
            <MDTypography
              variant="body2"
              component="p"
              color="text"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "4",
                WebkitBoxOrient: "vertical",
              }}
            >
              {quiz.description}
            </MDTypography>
          </MDBox>
        </Stack>
        <MDButton
          disabled={
            !(!loadingTestingResource && dataTestingResource) ||
            dataTestingResource.concepts[0].testingResources.length === 0
          }
          width="50%"
          color={active ? "primary" : "info"}
          variant="gradient"
          onClick={handleClick}
        >
          {active ? "resume" : "start"}
        </MDButton>
      </MDBox>
    </Card>
  );
}
