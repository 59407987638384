/* eslint-disable import/no-unresolved */
/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useRef } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import EducatorCard from "layouts/CompanyWebsite/Home/components/EducatorCard";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import kenRobinson from "assets/images/ken-robinson.jpeg";
import georgeCarlin from "assets/images/georgeCarlin.jpg";

function InspiringEducators() {
  // pages images
  const educators = [
    {
      image: kenRobinson,
      name: "Sir Ken Robinson",
      category: { color: "primary", label: "Educator" },
      route: "https://www.youtube.com/watch?v=iG9CE55wbtY",
      description:
        "Former Director of the Arts in Schools Project and Knight Bachelor for services to the arts.",
    },
    {
      image: georgeCarlin,
      name: "George Carlin",
      category: { color: "warning", label: "Comedian" },
      route: "https://www.youtube.com/watch?v=ILQepXUhJ98",
      description: "An iconic American stand-up comedian and social critic.",
    },
  ];

  SwiperCore.use([Autoplay, Navigation]);

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <MDBox
      position="relative"
      variant="gradient"
      borderRadius="lg"
      bgColor="dark"
      mt={{ xs: 12 }}
      mx={-2}
    >
      <MDBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        width={{ xs: "auto", lg: "100%" }}
        height={{ xs: "100%", lg: "auto" }}
        opacity={0.6}
      />
      <Container>
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            lg={5}
            sx={{ my: "auto", py: 6, pr: { xs: 3, lg: 2 }, pl: { xs: 3, lg: 0 } }}
          >
            <MDTypography variant="h2" color="white" mb={3}>
              Meet Some of the People that Inspired Us
            </MDTypography>
            <MDTypography variant="body2" color="white" mb={2} opacity={0.8}>
              If you click on a card it will take you to one of their speeches that influenced us.
            </MDTypography>
          </Grid>
          <Grid item xs={12} lg={7} py={3}>
            <Swiper
              navigation
              modules={[Navigation]}
              onInit={(swiper) => {
                // setSwiperEl(swiper);

                const { navigation: nav } = swiper.params;
                const { navigation } = swiper;

                nav.prevEl = navigationPrevRef.current;
                nav.nextEl = navigationNextRef.current;
                navigation.init();
                navigation.update();
              }}
              autoplay={{ delay: 5000 }}
              speed={800}
              spaceBetween={0}
              slidesPerView={1}
              allowTouchMove={false}
              loop
            >
              {educators.map((educator) => (
                <SwiperSlide key={educator.name}>
                  <MDBox
                    py={3}
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <EducatorCard
                      name={educator.name}
                      image={educator.image}
                      description={educator.description}
                      category={educator.category}
                      route={educator.route}
                    />
                  </MDBox>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default InspiringEducators;
