/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState } from "react";
import { FormControl, InputLabel, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/CompanyWebsite/Authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/lock-header.jpg";

function NewPasswordCover() {
  const [newValues, setNewValues] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmValues, setConfirmValues] = useState({
    password: "",
    showPassword: false,
  });
  const handleNewPassChange = (prop) => (event) => {
    setNewValues({ ...newValues, [prop]: event.target.value });
  };
  const handleConfirmPassChange = (prop) => (event) => {
    setConfirmValues({ ...confirmValues, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setNewValues({
      ...newValues,
      showPassword: !newValues.showPassword,
    });
    setConfirmValues({
      ...confirmValues,
      showPassword: !confirmValues.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <MDBox
        borderRadius="lg"
        bgColor="white"
        coloredShadow="success"
        mx={-1}
        mt={-3}
        py={2}
        mb={1}
        textAlign="center"
      >
        <MDBox mt={2} mb={1} justifyContent="center" textAlign="center">
          <MDTypography variant="h4" fontWeight="bold">
            New Password
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          Set your new password below. Passwords must be more than 8 characters.
        </MDTypography>
        <MDBox pt={6} pb={4} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-new-password">New Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-new-password"
                  variant="outlined"
                  type={newValues.showPassword ? "text" : "password"}
                  value={newValues.password}
                  onChange={handleNewPassChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {newValues.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
              </FormControl>
            </MDBox>
            <MDBox mb={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-confirm-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-confirm-password"
                  variant="outlined"
                  type={confirmValues.showPassword ? "text" : "password"}
                  value={confirmValues.password}
                  onChange={handleConfirmPassChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {confirmValues.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
              </FormControl>
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="primary" fullWidth>
                Set Password
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </CoverLayout>
  );
}

export default NewPasswordCover;
