import PropTypes from "prop-types";

export default function FormatGrid(format, componentCount) {
  switch (componentCount) {
    case 1:
      return [[12, 12]];
    case 2:
      if (format === "block") {
        return [
          [12, 12],
          [12, 12],
        ];
      }
      return [
        [6, 12],
        [6, 12],
      ];

    case 3:
      // eslint-disable-next-line no-case-declarations
      const formatSwitch = () => {
        switch (format) {
          case "grid":
            return [
              [4, 12],
              [4, 12],
              [4, 12],
            ];
          case "grid-top":
            return [
              [6, 12],
              [6, 12],
              [12, 12],
            ];
          case "grid-bottom":
            return [
              [12, 12],
              [6, 12],
              [6, 12],
            ];
          /* case "grid-right":
            return [
              [6, 6],
              [6, 6],
              [6, 12],
            ];
          /* case "grid-left":
            return [
              [12, 12],
              [12, 6],
              [12, 6],
            ]; */
          default:
            // block
            return [
              [12, 12],
              [12, 12],
              [12, 12],
            ];
        }
      };
      return formatSwitch();
    default:
      break;
  }
}

FormatGrid.propTypes = {
  format: PropTypes.string.isRequired,
  componentCount: PropTypes.number.isRequired,
};
