/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState, useEffect } from "react";

// Auth Context
import { UserAuth } from "context/AuthContext";

// Firestore
import { db } from "firebase-config";
import { doc, getDoc } from "firebase/firestore";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import BaseLayout from "layouts/LearningStudio/MyAccount/components/BaseLayout";
import Sidenav from "layouts/LearningStudio/MyAccount/Settings/components/Sidenav";
import Header from "layouts/LearningStudio/MyAccount/Settings/components/Header";
import BasicInfo from "layouts/LearningStudio/MyAccount/Settings/components/BasicInfo";
import ChangePassword from "layouts/LearningStudio/MyAccount/Settings/components/ChangePassword";
import ChangeEmail from "./components/Change Email";
// import ManageBilling from "./components/ManageBilling/index";
// import Authentication from "layouts/LearningStudio/MyAccount/Settings/components/Authentication";
// import Notifications from "layouts/LearningStudio/MyAccount/Settings/components/Notifications";
// import DeleteAccount from "layouts/LearningStudio/MyAccount/Settings/components/DeleteAccount";

function Settings() {
  const { user } = UserAuth();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const getUser = await getDoc(doc(db, "users", user.uid));
      const info = getUser.data();
      setUserInfo(info);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch("An User Error Has Occurred");
  }, [user]);

  return (
    <BaseLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              {userInfo && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Header user={userInfo} />
                  </Grid>
                  <Grid item xs={12}>
                    <BasicInfo user={userInfo} />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangePassword />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangeEmail />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <ManageBilling />
                  </Grid> */}
                  {/*              <Grid item xs={12}>
                  <Authentication />
                </Grid>
                <Grid item xs={12}>
                  <Notifications />
                </Grid>
                <Grid item xs={12}>
                  <DeleteAccount />
  </Grid> */}
                </Grid>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Settings;
