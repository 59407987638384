// MUI
import { Card } from "@mui/material";

import PropTypes from "prop-types";

import PageLayout from "examples/LayoutContainers/PageLayout/index";
// eslint-disable-next-line import/no-cycle
import DefaultNavbar from "examples/Navbars/DefaultNavbar/index";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";

// images
import logo from "assets/images/edyou-logo-tree.png";

function ResourceFormLayout({ children, title }) {
  return (
    <PageLayout>
      <DefaultNavbar routes="mod" />
      <MDBox mt={10} mb={{ xs: 4, md: 8 }} p={3} sx={{ minWidth: "550px" }}>
        <Card>
          <MDBox p={3} sx={{ minWidth: "500px" }}>
            <MDBox justifyContent="flex-start" component="img" src={logo} width="3.5rem" mb={1} />
            <MDBox width="100%" textAlign="center" mt={-2}>
              <MDTypography variant="h1" fontWeight="medium" pb={4}>
                {title}
              </MDTypography>
            </MDBox>
            {children}
          </MDBox>
        </Card>
      </MDBox>
    </PageLayout>
  );
}

export default ResourceFormLayout;

ResourceFormLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
