/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateProfile,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthCredential,
  setPersistence,
  browserSessionPersistence,
  GoogleAuthProvider,
  // FacebookAuthProvider,
  // OAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "firebase-config";
import { getAnalytics, logEvent } from "firebase/analytics";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { useStore } from "context/ZustandStore";

const UserContext = createContext();

// eslint-disable-next-line react/prop-types
export function AuthContextProvider({ children }) {
  // const [newUser, setNewUser] = useState();
  const [user, setUser] = useState({});
  const [error, setError] = useState({});
  // const [userRoles, setUserRoles] = useState();
  const [loading, setLoading] = useState(true);
  const analytics = getAnalytics();
  const navigate = useNavigate();

  // Bind Zustand
  const setRoles = useStore((state) => state.setRoles);

  const defaultRoles = { admin: false, subscriber: true, moderator1: false, moderator2: false };

  // const logInRedirect = (userResult) => {
  //   /* if (
  //     userResult.reloadUserInfo.customAttributes === subscriberToken ||
  //     userRoles.modRole === "admin"
  //   ) {
  //     navigate("/studio");
  //   } else {
  //     navigate("/subscribe");
  //   } */
  //   navigate("/studio");
  // };

  // Email and Password Functions
  const signUp = (email, password) => {
    setPersistence(auth, browserSessionPersistence).then(() => {
      createUserWithEmailAndPassword(auth, email, password)
        .then((result) => {
          logEvent(analytics, "sign_up", "password");
          setRoles(defaultRoles);
          navigate("/new-user");
        })
        .catch((event) => {
          setError(event);
          // ..
        });
    });
  };

  const logIn = (email, password) => {
    setPersistence(auth, browserSessionPersistence).then(() => {
      signInWithEmailAndPassword(auth, email, password)
        .then((result) => {
          const { isNewUser } = getAdditionalUserInfo(result);
          if (isNewUser) {
            setRoles(defaultRoles);
            logEvent(analytics, "sign_up", "password");
            navigate("/new-user");
          } else {
            logEvent(analytics, "login", "password");
            navigate("/studio");
          }
        })
        .catch((event) => {
          setError(event);
        });
    });
  };

  // Social Button Functions

  const googleLogIn = () => {
    setPersistence(auth, browserSessionPersistence).then(() => {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          setError({});
          const { isNewUser } = getAdditionalUserInfo(result);
          if (isNewUser) {
            setRoles(defaultRoles);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const method = credential.signInMethod;
            logEvent(analytics, "sign_up", { method });
            navigate("/new-user");
          } else {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const method = credential.signInMethod;
            logEvent(analytics, "login", { method });
            navigate("/studio");
          }
          // setNewUser(isNewUser);
        })
        .catch((event) => {
          // Handle Errors here.
          // const errorCode = error.code;
          setError(event);
        });
    });
  };
  /*
  const facebookLogIn = () => {
    setPersistence(auth, browserSessionPersistence).then(() => {
      const provider = new FacebookAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          setError({});
          const { isNewUser } = getAdditionalUserInfo(result);
          if (isNewUser) {
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const method = credential.signInMethod;
            logEvent(analytics, "sign_up", { method });
            navigate("/new-user");
          } else {
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const method = credential.signInMethod;
            logEvent(analytics, "login", { method });
            logInRedirect(result.user);
          }
          // setNewUser(isNewUser);
        })
        .catch((event) => {
          // Handle Errors here.
          // const errorCode = error.code;
          setError(event);
        });
    });
  };
  const microsoftLogIn = () => {
    setPersistence(auth, browserSessionPersistence).then(() => {
      const provider = new OAuthProvider("microsoft.com");
      signInWithPopup(auth, provider)
        .then((result) => {
          setError({});
          const { isNewUser } = getAdditionalUserInfo(result);
          if (isNewUser) {
            const credential = OAuthProvider.credentialFromResult(result);
            const method = credential.signInMethod;
            logEvent(analytics, "sign_up", { method });
            navigate("/new-user");
          } else {
            const credential = OAuthProvider.credentialFromResult(result);
            const method = credential.signInMethod;
            logEvent(analytics, "login", { method });
            logInRedirect(result.user);
          }
          // setNewUser(isNewUser);
        })
        .catch((event) => {
          // Handle Errors here.
          // const errorCode = error.code;
          setError(event);
        });
    });
  }; */

  const logOut = () => {
    signOut(auth);
    window.sessionStorage.removeItem("roles");
  };

  const resetPassword = (email) => sendPasswordResetEmail(auth, email);

  const newEmail = (email) => updateEmail(user, email);

  const updateDisplayName = (firstName) =>
    updateProfile(user, {
      displayName: firstName,
    });

  const checkCredentials = (email, password) => {
    const credential = new EmailAuthCredential(email, password);
    reauthenticateWithCredential(user, credential);
  };

  const checkRoles = async (userUid) => {
    const accessSnapshot = await getDoc(doc(db, "access", userUid));
    if (accessSnapshot.exists()) {
      const userRoles = accessSnapshot.data();

      // session storage to prevent refresh problem
      window.sessionStorage.setItem("roles", JSON.stringify(userRoles));
      setRoles(userRoles);
    } else {
      await setDoc(doc(db, "access", userUid), defaultRoles, { merge: true });
      // return defaultRoles;
      setRoles(defaultRoles);
    }
  };

  /* useEffect(() => {
    if (user) {
      checkRoles(user.uid);
    } else {
      setRoles({ admin: false, subscriber: false, moderator1: false, moderator2: false });
    }
  }, [user]); */

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        checkRoles(currentUser.uid);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        error,
        signUp,
        logIn,
        checkCredentials,
        newEmail,
        updateDisplayName,
        resetPassword,
        logOut,
        googleLogIn,
        // facebookLogIn,
        // microsoftLogIn,
      }}
    >
      {!loading && children}
    </UserContext.Provider>
  );
}

export const UserAuth = () => useContext(UserContext);
