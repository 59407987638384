/* eslint-disable react/prop-types */
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CloseIcon from "@mui/icons-material/Close";
import { blue, grey } from "@mui/material/colors";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import {
  defaultVideo,
  conceptProps,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

function SelectVideoDialog(props) {
  const { onClose, selectedVideo, open, videoOptions } = props;

  const handleClose = () => {
    onClose(selectedVideo);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>Select a Video</DialogTitle>
      <List sx={{ pt: 0, px: 2, pb: 2 }}>
        {videoOptions.map((video) => (
          <ListItem button onClick={() => handleListItemClick(video)} key={video.uid}>
            <ListItemAvatar>
              <MDAvatar sx={{ color: blue[600] }}>
                <PlayCircleIcon fontSize="large" />
              </MDAvatar>
            </ListItemAvatar>
            <ListItemText primary={video.title} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleClose()}>
          <ListItemAvatar>
            <MDAvatar sx={{ color: grey[600] }}>
              <CloseIcon fontSize="medium" />
            </MDAvatar>
          </ListItemAvatar>
          <ListItemText primary="Cancel" />
        </ListItem>
      </List>
    </Dialog>
  );
}

SelectVideoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedVideo: PropTypes.objectOf(PropTypes.string).isRequired,
  videoOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default function SelectVideo({ selectedConcept }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedVideo, setVideo] = useState(defaultVideo[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (value) => {
    setOpen(false);
    setVideo(value);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    navigate("/studio/viewer", {
      state: { selectedVideo, selectedConcept, videoType: "method", source: "search" },
    });
  }, [selectedVideo]);

  const allVideos = selectedConcept.methodVideos.concat(selectedConcept.interestVideos);

  return (
    <MDBox>
      <MDButton
        fullWidth
        disabled={selectedConcept.uid === "Default Concept"}
        variant="gradient"
        size="small"
        color="info"
        onClick={handleClickOpen}
      >
        Submit
      </MDButton>
      <SelectVideoDialog
        selectedVideo={selectedVideo}
        open={open}
        onClose={handleClose}
        videoOptions={allVideos}
      />
    </MDBox>
  );
}

SelectVideo.propTypes = {
  selectedConcept: conceptProps.isRequired,
};
