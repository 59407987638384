import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import MDBox from "components/MDBox/index";

function MultipleChoice(props) {
  const { answerData, answer, setAnswer, style } = props;
  return (
    <MDBox pl={2} display="flex" width="100%" justifyContent={style}>
      <FormControl>
        <RadioGroup
          aria-labelledby="answer-choices"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        >
          {answerData.answers.map((ans) => (
            <MDBox key={uniqueId(`answer-`)} display="flex" width="100%">
              <FormControlLabel value={ans} control={<Radio />} label={ans} />
            </MDBox>
          ))}
        </RadioGroup>
      </FormControl>
    </MDBox>
  );
}

export default MultipleChoice;

MultipleChoice.defaultProps = {
  answer: null,
  style: "center",
};

MultipleChoice.propTypes = {
  answerData: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string).isRequired,
    correctAnswer: PropTypes.string.isRequired,
    answerType: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  answer: PropTypes.string,
  setAnswer: PropTypes.func.isRequired,
  style: PropTypes.string,
};
