/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import logo from "assets/images/logo-tree.png";

// Invoice page components
import BaseLayout from "../MyAccount/components/BaseLayout/index";

function Tutorial() {
  const embedID = "NpqdVY2hMco";

  return (
    <BaseLayout stickyNavbar>
      <MDBox mb={{ xs: 4, md: 8 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "100%", height: "80vh", minWidth: "50rem", minHeight: "35rem" }}>
              <MDBox p={3}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12}>
                    <MDBox component="img" src={logo} width="3.5rem" p={1} mb={1} />
                  </Grid>
                  <Grid item alignItems="center" justifyContent="center" xs={12}>
                    <MDBox width="100%" textAlign="center" mt={-6}>
                      <MDTypography variant="h2" fontWeight="medium">
                        Learning Studio Tutorial
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>{" "}
              </MDBox>
              <MDBox px={{ xs: 3, xl: 6 }} minWidth="40rem" py={{ xs: 2, xl: 3 }} height="40vh">
                <MDBox borderRadius="lg" overflow="hidden">
                  <iframe
                    style={{ display: "block", width: "100%", height: "50vh", minHeight: "21rem" }}
                    src={`https://www.youtube.com/embed/${embedID}?rel=0&ytp-pause-overlay=0&modestbranding=1`}
                    title="Video of the Day"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Tutorial;
