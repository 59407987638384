/* eslint-disable react/prop-types */
import { Card, Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout/index";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import MDButton from "components/MDButton/index";
import logo from "assets/images/logo-tree.png";

function AppError(props) {
  const { resetErrorBoundary } = props;
  return (
    <DashboardLayout>
      <MDBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={8}>
            <Card>
              {/* Invoice header */}
              <MDBox p={3}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12}>
                    <MDBox component="img" src={logo} width="3.5rem" p={1} mb={1} />
                  </Grid>
                  <Grid item alignItems="center" justifyContent="center" xs={12}>
                    <MDBox width="100%" textAlign="center" mt={4}>
                      <MDTypography variant="h2" fontWeight="medium" color="error">
                        Error
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={1} mb={6} width="100%" textAlign="center">
                <MDTypography variant="h4" fontWeight="medium">
                  You have encountered in an error. We apologize for the inconvenience.
                </MDTypography>
                <MDButton
                  sx={{ mt: 4 }}
                  variant="gradient"
                  color="info"
                  onClick={resetErrorBoundary}
                >
                  Return
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AppError;
