// React
import { useState } from "react";

// Props
import { PropTypes } from "prop-types";

// Mui
import { Dialog, DialogContent, DialogActions, DialogTitle, Icon, Stack } from "@mui/material";

// Material Kit
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

// Firebase
import { rdb } from "firebase-config";
import { ref, update } from "firebase/database";

// Auth Context
import { UserAuth } from "context/AuthContext";

export default function RemoveProgramDialog(props) {
  const { program } = props;
  const { user } = UserAuth();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeep = () => {
    handleClose();
  };

  const handleRemove = async (e) => {
    e.preventDefault();
    await update(ref(rdb, `programHistory/${user.uid}/${program.programId}`), {
      active: false,
    });
    handleClose();
  };

  return (
    <MDBox>
      <MDButton iconOnly size="medium" onClick={handleClickOpen}>
        <Icon>close</Icon>
      </MDButton>
      <Dialog onClose={handleKeep} open={open}>
        <MDBox display="flex" justifyContent="center" textAlign="center" py={2}>
          <Stack>
            <DialogTitle>Remove Program</DialogTitle>
            <DialogContent>
              Are you sure you want to remove {program.name} from your My Programs page?
            </DialogContent>
            <DialogContent>Note: The program will be removed upon refresh.</DialogContent>
            <DialogActions>
              <MDBox width="100%" display="flex" justifyContent="center">
                <MDButton color="primary" onClick={handleKeep}>
                  &nbsp;&nbsp;Keep&nbsp;&nbsp;
                </MDButton>
                <MDButton sx={{ ml: 3 }} color="error" onClick={handleRemove}>
                  Remove
                </MDButton>
              </MDBox>
            </DialogActions>
          </Stack>
        </MDBox>
      </Dialog>
    </MDBox>
  );
}

const programConceptProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  programVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
});

RemoveProgramDialog.propTypes = {
  program: PropTypes.shape({
    addedBy: PropTypes.string,
    type: PropTypes.string,
    conceptList: PropTypes.arrayOf(programConceptProps),
    name: PropTypes.string,
    programId: PropTypes.string,
  }).isRequired,
};
