/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function ProgramCardPreview({ program }) {
  return (
    <Card
      sx={{
        maxHeight: "35rem",
        maxWidth: "20rem",
        height: "100%",
      }}
    >
      <MDBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MDBox
          component="img"
          src={program.image}
          alt={program.name}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${program.image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox display="flex" flexDirection="column" textAlign="left" height="100%" p={3} mt={-2}>
        <Stack>
          <MDTypography variant="button" color="text" fontWeight="regular" mb={0.75}>
            {program.type} Program&nbsp;&bull;&nbsp;{program.conceptList.length} Concepts
          </MDTypography>
          <MDTypography variant="h5" fontWeight="bold">
            {program.name}
          </MDTypography>
          <MDBox mt={1} mb={3}>
            <MDTypography variant="body2" component="p" color="text">
              {program.description}
            </MDTypography>
          </MDBox>
        </Stack>
        <MDBox display="flex" justifyContent="space-between">
          <MDButton variant="outlined" size="small" color="info">
            See Program
          </MDButton>
          <MDButton variant="gradient" circular iconOnly size="medium" color="info">
            <Icon fontSize="large">add</Icon>
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Typechecking props for the ProgramCard
const programConceptProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  person: PropTypes.bool,
  place: PropTypes.bool,
  event: PropTypes.bool,
  methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  interestVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  programConcepts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
});

ProgramCardPreview.propTypes = {
  program: PropTypes.shape({
    addedBy: PropTypes.string.isRequired,
    programId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    conceptList: PropTypes.arrayOf(programConceptProps),
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProgramCardPreview;
