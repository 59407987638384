import { useRef, useState } from "react";
import { Card, Alert } from "@mui/material";
import MDBox from "components/MDBox/index";
import MDInput from "components/MDInput/index";
import MDTypography from "components/MDTypography/index";
import MDButton from "components/MDButton/index";
import MDSnackbar from "components/MDSnackbar/index";
import { UserAuth } from "context/AuthContext";
import { db } from "firebase-config";
import { collection, addDoc } from "firebase/firestore";

function FeedbackForm() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const { user } = UserAuth();
  const messageRef = useRef();

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success!"
      content="Your message has been sent. Someone will get back to you shortly!"
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const message = messageRef.current.value;
      const mail = {
        from: "contact-us@edyou.org",
        replyTo: user.email,
        to: "contact-us@edyou.org",
        message: {
          subject: "Feedback - Studio Home",
          html: `<p>From: ${user.displayName} (${user.uid})</p>
           <p>Message: ${message}</p>`,
        },
      };
      await addDoc(collection(db, "mail"), mail);
      openSuccessSB();
      messageRef.current.value = "";
    } catch (event) {
      setError(event);
    }
    setLoading(false);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox
        width="100%"
        p={3}
        textAlign="center"
        component="form"
        role="form"
        onSubmit={handleSubmit}
      >
        <MDTypography variant="h3" fontWeight="medium" mb={3}>
          Feedback Form
        </MDTypography>
        <MDInput
          variant="outlined"
          label="Feedback"
          placeholder="Your message here ..."
          inputRef={messageRef}
          InputLabelProps={{ shrink: true }}
          fullWidth
          multiline
          required
          rows={6}
        />
        {error && <Alert color="error">{error.message}</Alert>}
        <MDBox textAlign="center" mt={3}>
          <MDButton disabled={loading} width="35%" variant="gradient" color="primary" type="submit">
            Send Message
          </MDButton>
        </MDBox>
        {renderSuccessSB}
      </MDBox>
    </Card>
  );
}

export default FeedbackForm;
