/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState } from "react";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
// eslint-disable-next-line import/no-unresolved
import "swiper/css";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/navigation";

// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Steps() {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [swiperEl, setSwiperEl] = useState(null);

  const slideTo = (index) => swiperEl && swiperEl.slideTo(index);

  const slides = [
    {
      image:
        "https://images.unsplash.com/photo-1617169509092-cc163a75a463?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80",
      label: "First Principle",
      title: "Multifaceted Learning",
      description:
        "People have multiple interests and they should be able to grow in all of them. Learn for your career, schoolwork, general knowledge, life skills, and hobbies, all in one place.",
    },
    {
      image:
        "https://images.pexels.com/photos/3758105/pexels-photo-3758105.jpeg?auto=compress&fit=crop&cs=tinysrgb&w=500&h=700&dpr=1",
      label: "Second Principle",
      title: "Evolving With Innovation",
      description:
        "The world is changing fast and traditional curriculum development is too slow to keep pace. By sourcing from content creation communities we deliver education at the speed of innovation.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1529079018732-bdb88456f8c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80",
      label: "Third Principle",
      title: "Agency In Learning",
      description:
        "Education too often dictates the scope of learning. We strive to let you go beyond these boundaries by giving you choices in your learning progression. We believe learning should be limited only by your curiosity.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1607004468138-e7e23ea26947?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZGlmZmVyZW50fGVufDB8MXwwfHw%3D&auto=format&fit=crop&w=400&q=60",
      label: "Fourth Principle",
      title: "Nonconforming",
      description:
        'Learning does not require conformity. We encourage you to explore your unique interests, develop your unique strengths, and pursue your unique destiny. As Jobs said, "Have the courage to follow you heart and intuition."',
    },
    {
      image:
        "https://images.pexels.com/photos/7429566/pexels-photo-7429566.jpeg?auto=compress&cs=tinysrgb&fit=crop&w=500&h=700&dpr=1",
      label: "Fifth Principle",
      title: "Individual Fit",
      description:
        "Modern technology allows us to personalize our world more the ever. Education should be the same. We use data science to optimize resource and concept recommendations for your interest, strengths, and goals.",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1661476126478-a43171605fe5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8ZXhwZXJ0fGVufDB8MXwwfHw%3D&auto=format&fit=crop&w=400&q=60",
      label: "Sixth Principle",
      title: "Not Just Teachers",
      description:
        "Teachers continue to be heroes by preparing the next generation, but others can participate in educating too. We engage academics, professionals, and content creators to add more perspectives to learning.",
    },
    {
      image:
        "https://images.pexels.com/photos/7235805/pexels-photo-7235805.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      label: "Seventh Principle",
      title: "Global Perspectives",
      description:
        "Education is often geographically siloed in a way that does not reflect the globalized world we live in. We source the best educators from around the world to bring global perspectives, methods, and expertise.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1550475476-44c382c5f2a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      label: "Eighth Principle",
      title: "Fun & Exciting",
      description:
        "Educational institutions sometimes forget that learning should be fun. Not every resource needs to be instructional. We encourage learners to watch videos of real-world applications and interesting context.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1566554273541-37a9ca77b91f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      label: "Ninth Principle",
      title: "Unbounded Scope",
      description:
        "There are more subjects than math, science, language arts, and social studies. And there are more paths than going to college. Whatever you need to learn for your journey, we will work to make you the best at it.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1544164559-90f4302d5142?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=770&q=80",
      label: "Tenth Principle",
      title: "Lifelong",
      description:
        "Learning does not stop after K-12 or college. We are working to provide the learning content you need for every stage of life, so you can keep on growing.",
    },
  ];

  const steps = [
    {
      number: "1",
      firstLetter: "M",
      label: "ultifaceted",
    },
    {
      number: "2",
      firstLetter: "E",
      label: "volving",
    },
    {
      number: "3",
      firstLetter: "A",
      label: "gency",
    },
    {
      number: "4",
      firstLetter: "N",
      label: "onconforming",
    },
    {
      number: "5",
      firstLetter: "I",
      label: "ndividual Fit",
    },
    {
      number: "6",
      firstLetter: "N",
      label: "ot Just Teachers",
    },
    {
      number: "7",
      firstLetter: "G",
      label: "lobal Perspectives",
    },
    {
      number: "8",
      firstLetter: "F",
      label: "un & Exciting",
    },
    {
      number: "9",
      firstLetter: "U",
      label: "nbounded Scope",
    },
    {
      number: "10",
      firstLetter: "L",
      label: "ifelong",
    },
  ];

  return (
    <MDBox
      component="section"
      pb={14}
      pt={6}
      justifyContent="center"
      alignItems="center"
      maxWidth="90%"
      alignContent="center"
      alignSelf="center"
    >
      <Grid container justifyContent="center">
        <Grid
          container
          item
          alignItems="center"
          xs={12}
          xl={3}
          pt={{ xs: 0, sm: 4 }}
          pb={{ xs: 4, sm: 6, xl: 8 }}
          ml={{ xs: 0, sm: 6, xl: 0 }}
        >
          {steps.map(({ number, firstLetter, label }, index) => (
            <Grid key={label} item xs={12} sm={6} lg={4} xl={12} textAlign="left">
              <MDTypography
                variant="body1"
                fontWeight="bold"
                pl={2}
                color="info"
                opacity={0.9}
                textGradient
                sx={{ fontFamily: ({ typography: { h1 } }) => h1.fontFamily, cursor: "pointer" }}
                onClick={() => slideTo(index + 1)}
              >
                <MDBox component="span">{number}</MDBox>
                <MDTypography
                  textGradient
                  variant="h4"
                  fontWeight="bold"
                  color="dark"
                  component="span"
                  ml={1}
                >
                  {firstLetter}
                </MDTypography>
                <MDBox component="span">{label}</MDBox>
              </MDTypography>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} xl={9}>
          <Swiper
            navigation
            modules={[Navigation]}
            onInit={(swiper) => {
              setSwiperEl(swiper);

              const { navigation: nav } = swiper.params;
              const { navigation } = swiper;

              nav.prevEl = navigationPrevRef.current;
              nav.nextEl = navigationNextRef.current;
              navigation.init();
              navigation.update();
            }}
            autoplay={{ delay: 10000 }}
            speed={800}
            spaceBetween={0}
            slidesPerView={1}
            allowTouchMove={false}
            loop
          >
            {slides.map(({ image, label, title, description }) => (
              <SwiperSlide key={label}>
                <Grid container spacing={3} alignItems="center" sx={{ mb: { xs: 6, md: 0 } }}>
                  <Grid item xs={12} md={5} ml={{ xs: 0, md: 6, lg: "auto" }}>
                    <MDBox p={2}>
                      <MDBox
                        component="img"
                        src={image}
                        alt={title}
                        width="100%"
                        borderRadius="xl"
                        maxHeight="37.5rem"
                      />
                    </MDBox>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    ml={{ xs: 2, lg: 0 }}
                    mr={{ xs: 2, lg: "auto" }}
                    position="relative"
                  >
                    <MDTypography
                      component="h6"
                      variant="button"
                      opacity={0.7}
                      textTransform="uppercase"
                      fontWeight="bold"
                    >
                      {label}
                    </MDTypography>
                    <MDTypography
                      variant="h1"
                      fontWeight="bold"
                      sx={{
                        fontSize: ({ typography: { d5, d6 } }) => ({
                          xs: d6.fontSize,
                          lg: d5.fontSize,
                        }),
                      }}
                    >
                      {title}
                    </MDTypography>
                    <MDTypography variant="body1" my={3}>
                      {description}
                    </MDTypography>
                  </Grid>
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Steps;
