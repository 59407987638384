import { useState } from "react";
import {
  Stack,
  Alert,
  Collapse,
  Icon,
  IconButton,
  Divider,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { uniqueId } from "lodash";
import MDTypography from "components/MDTypography/index";
import MDButton from "components/MDButton/index";
import MDBox from "components/MDBox/index";
import PropTypes from "prop-types";
import MDInput from "components/MDInput/index";

function ValidateAnswer(answerData) {
  const noNullChoices = answerData.answers.indexOf("") < 0;
  const correctAnswerSelected = !!answerData.correctAnswer;
  const twoOrMoreChoices = answerData.answers.length > 1;
  return !(noNullChoices && correctAnswerSelected && twoOrMoreChoices);
}

function AnswersForm({ answerType, handleSubmitClose }) {
  const [answers, setAnswers] = useState([]);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null);
  const [error, setError] = useState();

  switch (answerType) {
    case "Multiple Choice": {
      const answerCount = answers.length || 0;
      return (
        <MDBox component="form" type="submit" role="form" textAlign="center">
          <MDTypography variant="h4">{answerType}</MDTypography>
          <Divider />
          <Stack direction="row" spacing={1} sx={{ alignItems: "center", justifyContent: "left" }}>
            <MDTypography>Answer Choices:</MDTypography>
            <MDButton
              disabled={answers.length === 0}
              onClick={() => {
                setAnswers((oldAnswers) => {
                  const newAnswers = [...oldAnswers];
                  const nullIndex = newAnswers.indexOf("");
                  if (nullIndex >= 0) {
                    newAnswers.splice(nullIndex, 1);
                  } else {
                    newAnswers.pop();
                  }
                  return newAnswers;
                });
              }}
              iconOnly
            >
              <Icon>remove_circle</Icon>
            </MDButton>
            <MDTypography>{answerCount}</MDTypography>
            <MDButton
              disabled={answerCount === 6}
              iconOnly
              onClick={() => {
                setAnswers((oldAnswers) => {
                  const newAnswers = [...oldAnswers];
                  newAnswers.push("");
                  return newAnswers;
                });
              }}
            >
              <Icon>add_circle</Icon>
            </MDButton>
          </Stack>
          <MDBox pt={2}>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                aria-labelledby="answer-choices"
                defaultValue={correctAnswerIndex}
                value={correctAnswerIndex}
                onChange={(e) => {
                  setCorrectAnswerIndex(e.target.value);
                }}
              >
                {answers.map((answer, index) => (
                  <MDBox key={uniqueId(`answer-`)} display="flex" width="100%">
                    <FormControlLabel value={index} control={<Radio />} label=" " />
                    <MDInput
                      type="text"
                      fullWidth
                      defaultValue={answer}
                      label={`Answer Choice ${index + 1}`}
                      onChange={(e) => {
                        setAnswers((oldAnswers) => {
                          const newAnswers = oldAnswers;
                          newAnswers[index] = e.target.value;
                          return newAnswers;
                        });
                      }}
                    />
                    <MDButton
                      iconOnly
                      onClick={() => {
                        setAnswers((oldAnswers) => {
                          const newAnswers = [...oldAnswers];
                          newAnswers.splice(index, 1);
                          return newAnswers;
                        });
                        if (index < correctAnswerIndex) {
                          setCorrectAnswerIndex((oldIndex) => oldIndex - 1);
                        } else if (index === correctAnswerIndex) {
                          setCorrectAnswerIndex(null);
                        }
                      }}
                    >
                      <Icon>close</Icon>
                    </MDButton>
                  </MDBox>
                ))}
              </RadioGroup>
            </FormControl>
          </MDBox>
          {error && (
            <MDBox sx={{ width: "100%" }}>
              <Collapse in={!!error}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setError(null);
                      }}
                    >
                      <Icon fontSize="inherit">close</Icon>
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {error}
                </Alert>
              </Collapse>
            </MDBox>
          )}
          <MDButton
            mt={2}
            variant="contained"
            color="info"
            onClick={() => {
              setError(null);
              const answersV = {
                answers,
                correctAnswer: answers[correctAnswerIndex],
                answerType,
              };
              if (ValidateAnswer(answersV)) {
                setError(
                  "Please ensure a correct answer is selected and all answer choices are filled in."
                );
              } else {
                handleSubmitClose(answersV);
              }
            }}
          >
            Submit
          </MDButton>
        </MDBox>
      );
    }
    case "Validated Free Response (Math)":
      return <MDTypography>Not finished</MDTypography>;

    default:
      return <MDTypography> No answer type selected.</MDTypography>;
  }
}

export default AnswersForm;

AnswersForm.propTypes = {
  answerType: PropTypes.string.isRequired,
  handleSubmitClose: PropTypes.func.isRequired,
};
