/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Rental page components
import FaqCollapse from "layouts/CompanyWebsite/Support/ContactUs/components/FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MDBox component="section" pt={12} pb={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MDTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MDTypography>
            <MDBox mb={2}>
              <MDTypography variant="body2" align="center" color="text">
                A bit about us, our timeline, and plans going forward.
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="When will EDYou officially launch?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              We are launching Version 1.0 on February 12, 2024. On launch we will have a variety of
              professional development and Grade 6-12 content, as well as a bunch of content that
              just interests us. While the initial launch will be somewhat limited, we are planning
              to launch Version 2.0 in Summer 2024 with iOS and Android apps for the Learning
              Studio, Explore Mode &#40;our intended core mode&#41;, big upgrades to the user
              Progress Reports, and additional tools for educators and organizations to create
              public and private learning experiences within the platform.
            </FaqCollapse>
            <FaqCollapse
              title="How much does EDYou cost?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              Long-term we will be targeting a low-cost subscription of about $149/year. However, we
              will be launching with a much cheaper $49/year subscription while the features and
              content scope of the product are limited.
            </FaqCollapse>
            <FaqCollapse
              title="Is EDYou only for students?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              Definitely not. While we hope to have content that is useful for students, EDYou is
              meant to be for everyone. In fact, our approach to lifelong learning specifically
              ignores school standards because we believe there are incredible skills and topics to
              learn that you could never get exposure to in institutional education.
            </FaqCollapse>
            <FaqCollapse
              title="Can I request new content be added to EDYou?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              Yes! EDYou is meant to be collaborative. While it will be difficult to meet every
              request while it is just the two of us sourcing and organizing content, we are looking
              to create a community of educators, content creators, professionals, and enthusiasts
              who want to source content and create learning experiences for the subjects they love.
              If you have an interest in contributing, reach out to us! We might be able to work
              together sooner than you think.
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Faq;
