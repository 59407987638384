// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import TikTokIcon from "assets/theme/components/tiktok-dark";
import PinterestIcon from "@mui/icons-material/Pinterest";

// Material Kit 2 PRO React components
import MDTypography from "components/MDTypography";

// Images
import logoCT from "assets/images/favicon-32x32.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "EDYou LLC",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/profile.php?id=100093896413969",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/EDYou_for_all",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/edyou_for_all",
    },
    {
      icon: <TikTokIcon />,
      link: "https://www.tiktok.com/@edyou_for_all",
    },
    {
      icon: <PinterestIcon />,
      link: "https://www.pinterest.com/horton8899/",
    },
  ],
  menus: [
    {
      name: "About Us",
      items: [
        { name: "our mission", route: "/our-mission" },
        { name: "our team", route: "/our-team" },
      ],
    },
    {
      name: "About EDYou",
      items: [
        { name: "learning studio", route: "/learning-studio" },
        { name: "verified educators", route: "/verified-educators" },
        /* { name: "upcoming features", route: "/upcoming-features" },
        { name: "pricing", route: "/pricing" }, */
      ],
    },
    {
      name: "Learn More",
      items: [
        // { name: "blog", route: "/blog" },
        { name: "contact us", route: "/contact-us" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "privacy policy", route: "/privacy" },
        { name: "terms of use", route: "/terms-of-use" },
      ],
    },
  ],
  copyright: (
    <MDTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{" "}
      <MDTypography
        component="a"
        route="/home"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        EDYou LLC
      </MDTypography>
      .
    </MDTypography>
  ),
};
