/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { rdb } from "firebase-config";
import { ref, update, serverTimestamp } from "firebase/database";
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { cloneDeep, findIndex, pullAllBy, random } from "lodash";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { programConceptProps } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import MDTypography from "components/MDTypography/index";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { UserAuth } from "context/AuthContext/index";

const GET_TESTING_RESOURCE = gql`
  query GetTestingResource($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      name
      block
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
      testingResources {
        uid
        difficulty
        text
        question
        picture
        answerChoices
        correctAnswer
        testedConcepts {
          uid
          name
        }
      }
    }
  }
`;

const GET_TEST_COUNT = gql`
  query GetTestCount {
    concepts {
      uid
      testingResourcesAggregate {
        count
      }
    }
  }
`;

function GenerateReviewDialog(props) {
  const { onClose, open, selectedProgram } = props;
  const [reviewConceptList, setReviewConceptList] = useState(
    cloneDeep(selectedProgram.conceptList).map((concept) => {
      const newConcept = concept;
      newConcept.review = false;
      return newConcept;
    })
  );
  const [quiz, setQuiz] = useState();
  const navigate = useNavigate();
  const { user } = UserAuth();

  const handleClose = () => {
    onClose();
    setReviewConceptList(
      selectedProgram.conceptList.map((concept) => {
        const newConcept = concept;
        newConcept.review = false;
        return newConcept;
      })
    );
  };

  const [fetchTests] = useLazyQuery(GET_TESTING_RESOURCE, {
    onCompleted: (data) => {
      const quizRefPath = `quizHistory/${user.uid}/${quiz.programId}`;
      update(ref(rdb, quizRefPath), {
        currentConceptIndex: 0,
        currentQuestionIndex: 0,
        currentCorrect: 0,
        title: quiz.name,
        numConcepts: quiz.conceptList.length,
        type: "review",
        completed: false,
      });
      update(ref(rdb, `${quizRefPath}/concepts/${quiz.conceptList[0].uid}`), {
        currentSubmitted: 0,
      });

      const timestamp = serverTimestamp();
      update(ref(rdb, quizRefPath), { timestamp });

      const { testingResources } = data.concepts[0];

      navigate("/studio/test-viewer", {
        state: {
          quiz,
          source: "review",
          currentConcept: data.concepts[0],
          currentCorrect: 0,
          currentTest: testingResources[random(testingResources.length - 1)],
          testingResources,
          currentQuestionIndex: 0,
          currentConceptSubmitted: 0,
          currentIndex: 0,
        },
      });
    },
  });

  const handleGenerate = () => {
    // create quiz
    const quizConceptList = cloneDeep(reviewConceptList);
    pullAllBy(quizConceptList, [{ review: false }], "review");

    const newQuiz = {
      programId: selectedProgram.programId,
      conceptList: quizConceptList,
      name: selectedProgram.name,
    };

    if (newQuiz.conceptList.length > 0) {
      setQuiz(newQuiz);
      fetchTests({ variables: { uid: newQuiz.conceptList[0].uid } });
    }
  };

  const { loading: loadingTestCount, data: dataTestCount } = useQuery(GET_TEST_COUNT);

  const handleListItemClick = (conceptId) => {
    const newList = cloneDeep(reviewConceptList);
    const index = findIndex(newList, ["uid", conceptId]);
    newList[index].review = !reviewConceptList[index].review;
    setReviewConceptList(newList);
  };

  function hasTests(concept) {
    const index = findIndex(dataTestCount.concepts, ["uid", concept.uid]);
    return dataTestCount.concepts[index].testingResourcesAggregate.count > 0;
  }

  const filteredConcepts =
    !loadingTestCount && dataTestCount ? selectedProgram.conceptList.filter(hasTests) : null;

  return (
    <Dialog fullWidth onClose={handleClose} open={open} scroll="paper">
      {loadingTestCount && !dataTestCount ? (
        <>
          <DialogTitle>Loading Review Resources:</DialogTitle>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </>
      ) : filteredConcepts.length > 0 ? (
        <>
          <DialogTitle>Select Concepts:</DialogTitle>
          <DialogContent dividers>
            <List sx={{ pt: 0, px: 2, pb: 2 }}>
              {filteredConcepts.map((concept) => (
                <ListItem onClick={() => handleListItemClick(concept.uid)} key={concept.uid}>
                  <ListItemText primary={concept.name} />
                  <Checkbox onChange={() => handleListItemClick(concept.uid)} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <MDButton variant="contained" color="primary" onClick={handleGenerate}>
              Generate
            </MDButton>
            <MDButton variant="contained" color="info" onClick={() => handleClose()}>
              Cancel
            </MDButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>We are sorry.</DialogTitle>
          <DialogContent dividers>
            <MDTypography>
              There are no review resources available for these concepts at this time.
            </MDTypography>
          </DialogContent>
          <DialogActions>
            <MDButton variant="contained" color="info" onClick={() => handleClose()}>
              Cancel
            </MDButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default function GenerateReview({ selectedProgram }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MDBox>
      <MDButton variant="outlined" onClick={handleClickOpen}>
        <MDTypography color="white" variant="button" fontWeight="bold">
          Generate Review
        </MDTypography>
      </MDButton>
      <GenerateReviewDialog selectedProgram={selectedProgram} open={open} onClose={handleClose} />
    </MDBox>
  );
}

GenerateReviewDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedProgram: PropTypes.shape({
    addedBy: PropTypes.string,
    conceptList: PropTypes.arrayOf(programConceptProps),
    name: PropTypes.string,
    programId: PropTypes.string,
  }).isRequired,
};

GenerateReview.propTypes = {
  selectedProgram: PropTypes.shape({
    addedBy: PropTypes.string,
    conceptList: PropTypes.arrayOf(programConceptProps),
    name: PropTypes.string,
    programId: PropTypes.string,
  }).isRequired,
};
