/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { useMemo, useCallback, forwardRef, useState } from "react";
import PropTypes from "prop-types";
import imageExtensions from "image-extensions";
import isUrl from "is-url";
import isHotkey from "is-hotkey";
import { Transforms, createEditor, Editor, Element as SlateElement } from "slate";
import { Slate, Editable, withReact, useSlate } from "slate-react";
import { withHistory } from "slate-history";
import { Icon, Toolbar } from "@mui/material";
import { findIndex } from "lodash";
import MDButton from "components/MDButton/index";
import MDBox from "components/MDBox/index";
import MathModalButton from "./MathModal";
import Element from "./Element";
import Leaf from "./Leaf";
import deserialize from "./Deserializer";
import ImageModalButton from "./ImageModal";
import AnswerModalButton from "./AnswersModal";

const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};

const insertImage = (editor, url) => {
  const text = { text: "" };
  const image = { type: "image", url, children: [text] };
  Transforms.insertNodes(editor, image);
  Transforms.insertNodes(editor, {
    type: "paragraph",
    children: [{ text: "" }],
  });
};

const insertMath = (editor, mathText) => {
  const text = { text: "" };
  const mathLive = { type: "math-live", mathText, children: [text] };
  Transforms.insertNodes(editor, mathLive);
  Transforms.insertNodes(editor, {
    type: "paragraph",
    children: [{ text: "" }],
  });
};

const insertAnswers = (editor, data) => {
  const text = { text: "" };
  const answerBlock = { type: "answer-block", answerData: data, children: [text] };
  Transforms.insertNodes(editor, answerBlock);
  Transforms.insertNodes(editor, {
    type: "paragraph",
    children: [{ text: "" }],
  });
};

const HOTKEYS2 = [
  { key: "mod+b", type: "bold" },
  { key: "mod+i", type: "italic" },
  { key: "mod+u", type: "underline" },
  { key: "mod+`", type: "code" },
  { key: "mod+a", type: "select" },
];

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const withHtml = (editor) => {
  const { insertData, isInline, isVoid } = editor;

  editor.isInline = (element) => (element.type === "link" ? true : isInline(element));

  editor.isVoid = (element) => (element.type === "image" ? true : isVoid(element));

  editor.insertData = (data) => {
    const html = data.getData("text/html");
    const { files } = data;

    // image files
    if (files && files.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(html)) {
      insertImage(editor, html);
    } else if (html) {
      const parsed = new DOMParser().parseFromString(html, "text/html");
      const fragment = deserialize(parsed.body);
      Transforms.insertFragment(editor, fragment);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const SlateButton = forwardRef(({ active, reversed, ...props }, ref) => (
  <MDButton
    iconOnly
    variant={active ? "contained" : "text"}
    {...props}
    ref={ref}
    sx={{
      cursor: "pointer",
    }}
    color={reversed ? (active ? "white" : "dark") : active ? "dark" : "secondary"}
  />
));

SlateButton.defaultProps = {
  reversed: false,
};

SlateButton.propTypes = {
  active: PropTypes.bool.isRequired,
  reversed: PropTypes.bool,
};

function BlockButton({ format, icon }) {
  const editor = useSlate();
  return (
    <SlateButton
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type")}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </SlateButton>
  );
}

BlockButton.propTypes = {
  format: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

function MarkButton({ format, icon }) {
  const editor = useSlate();
  return (
    <SlateButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </SlateButton>
  );
}

MarkButton.propTypes = {
  format: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

function TextEditor({ editing, setComponents, id, setEditing, content, type, answerType }) {
  const [newContent, setContent] = useState(content);
  const [answerData, setAnswerData] = useState({ answers: [], correctAnswer: null, answerType });
  const hasAnswerBlock = findIndex(newContent, ["type", "answer-block"]) >= 0;
  const renderElement = useCallback(
    (props) => <Element {...props} setAnswerData={setAnswerData} answerData={answerData} />,
    []
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHtml(withHistory(withReact(createEditor()))), []);

  const initialValue = content || [
    {
      type: "paragraph",
      children: [
        {
          text: "",
        },
      ],
    },
  ];

  const handleSubmit = () => {
    setComponents((oldComponents) => {
      const index = findIndex(oldComponents, ["id", id]);
      const newComponents = [...oldComponents];
      newComponents[index].content = newContent;
      return newComponents;
    });
    setEditing(false);
  };

  const handleDelete = () => {
    setComponents((oldComponents) => {
      const index = findIndex(oldComponents, ["id", id]);
      const newComponents = [...oldComponents];
      newComponents.splice(index, 1);
      return newComponents;
    });
  };

  return (
    <MDBox>
      <Slate editor={editor} initialValue={initialValue} onChange={(value) => setContent(value)}>
        <Toolbar sx={{ display: editing ? "flex" : "none", flexWrap: "wrap", mb: 1 }}>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <MarkButton format="code" icon="code" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <BlockButton format="left" icon="format_align_left" />
          <BlockButton format="center" icon="format_align_center" />
          <BlockButton format="right" icon="format_align_right" />
          <BlockButton format="justify" icon="format_align_justify" />
          <MathModalButton insertMath={insertMath} />
          <ImageModalButton insertImage={insertImage} />
        </Toolbar>
        {type === "answer" && answerType && editing && !hasAnswerBlock && (
          <AnswerModalButton
            answerType={answerType}
            insertAnswers={insertAnswers}
            answerData={answerData}
            setAnswerData={setAnswerData}
          />
        )}
        <Editable
          readOnly={!editing}
          onKeyDown={(event) => {
            HOTKEYS2.forEach((hotkey) => {
              if (isHotkey(hotkey.key, event)) {
                event.preventDefault();
                if (hotkey.key === "mod+a") {
                  Transforms.select(editor, []);
                } else {
                  const mark = hotkey.type;
                  toggleMark(editor, mark);
                }
              }
            });
          }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          placeholder="Enter some text..."
          style={{ padding: "3px" }}
        />
      </Slate>
      <MDBox display={editing ? "flex" : "none"} justifyContent="space-evenly" mt={2}>
        <MDButton
          iconOnly
          disabled={!newContent}
          variant="gradient"
          color="primary"
          onClick={handleSubmit}
        >
          <Icon>check</Icon>
        </MDButton>
        <MDButton iconOnly variant="gradient" color="error" onClick={handleDelete}>
          <Icon>delete</Icon>
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default TextEditor;

TextEditor.defaultProps = {
  content: null,
  answerType: null,
};

TextEditor.propTypes = {
  answerType: PropTypes.string,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  setComponents: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(PropTypes.any),
};
