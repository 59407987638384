import { useState, useEffect } from "react";
import {
  Grid,
  Autocomplete,
  Divider,
  Stack,
  Icon,
  Collapse,
  Alert,
  IconButton,
} from "@mui/material";
import { findIndex, uniqueId } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { gql, useMutation, useQuery } from "@apollo/client";
import { db } from "firebase-config";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { UserAuth } from "context/AuthContext/index";
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import MDInput from "components/MDInput/index";
import MDButton from "components/MDButton/index";
import MDSnackbar from "components/MDSnackbar/index";
import { difficulties } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import ResourceFormLayout from "../components/ResourceFormLayout";
import TestBuilderLayout from "./components/TestBuilderLayout/index";
import FormatMenu from "./components/TestBuilderLayout/FormatMenu";
// import Serializer from "./components/Serializer/index";
// import FormatGrid from "./components/TestBuilderLayout/SortableItem/FormatGrid";

const ADD_TEST = gql`
  mutation AddTest($uid: ID!, $type: String!, $difficulty: String!, $addedBy: String!) {
    createTests(input: [{ uid: $uid, type: $type, difficulty: $difficulty, addedBy: $addedBy }]) {
      tests {
        uid
      }
    }
  }
`;

const ADD_TESTED_CONCEPTS = gql`
  mutation AddTestedConcepts($uid: ID!, $testedConcepts: [ConceptWhere!]) {
    updateTests(
      update: { testedConcepts: { connect: { where: { node: { OR: $testedConcepts } } } } }
      where: { uid: $uid }
    ) {
      tests {
        uid
        testedConcepts {
          name
          uid
        }
      }
    }
  }
`;

const GET_CONCEPTS = gql`
  query GetConcepts {
    concepts {
      uid
      name
    }
  }
`;

function AddTest() {
  const [answerTypeV, setType] = useState("Multiple Choice");
  const [difficultyV, setDifficulty] = useState("Intro");
  const [components, setComponents] = useState([{ id: "component-0", type: null, content: null }]);
  const [format, setFormat] = useState("block");
  const [validationError, setValError] = useState();
  const [testedConceptsV, setTestedConcepts] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const [test, setTest] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { user } = UserAuth();
  const componentCount = components.length || 0;

  // How to load a resource
  /* const [preview, setPreview] = useState();
  const [answer, setAnswer] = useState();
  useEffect(() => {
    const getResource = async () => {
      const docRef = doc(db, "checks", "9145b3c3-be6b-4c27-ba7a-3d1e2cdcabd4");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPreview(docSnap.data());
      }
    };
    getResource();
  }, []); */

  const [createTests, { error }] = useMutation(ADD_TEST, {
    fetchPolicy: "no-cache",
  });
  const {
    loading: loadingConcepts,
    error: errorConcepts,
    data: dataConcepts,
    refetch,
  } = useQuery(GET_CONCEPTS, {
    fetchPolicy: "network-only",
  });
  const [updateTestedConcepts] = useMutation(ADD_TESTED_CONCEPTS);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success!"
      content="Your testing resource has been added."
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  function ValidateSubmission(data) {
    const hasFormat = !!data.format;
    const noNullComponents = findIndex(data.components, (o) => !o.type) < 0;
    const ansIndex = findIndex(data.components, ["type", "answer"]);
    const answerBlockIndex =
      ansIndex >= 0 ? findIndex(data.components[ansIndex].content, ["type", "answer-block"]) : -1;
    const hasAnswer = ansIndex >= 0 && answerBlockIndex >= 0;
    const ansData = hasAnswer ? components[ansIndex].content[answerBlockIndex].answerData : null;
    const hasAnswerData = hasAnswer
      ? !!ansData.correctAnswer && ansData.answerType === answerTypeV
      : false;
    if (!hasFormat) {
      setValError("Please select a valid format");
    } else if (!noNullComponents) {
      setValError("Please remove or finish submitting any incomplete components");
    } else if (!hasAnswer) {
      setValError(
        "Make sure your testing resource has an answer component with a completed answers element."
      );
    } else if (!hasAnswerData) {
      setValError(
        "Make sure the selected Answer Type matches the type of answer choices in your answer component."
      );
    }
    return hasFormat && noNullComponents && hasAnswer && hasAnswerData;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValError(null);
    const testResourceUid = uuidv4();
    setSubmitLoading(true);

    if (ValidateSubmission({ components, format })) {
      try {
        // Neo4j submit
        await createTests({
          variables: {
            uid: testResourceUid,
            type: answerTypeV,
            addedBy: user.uid,
            difficulty: difficultyV,
          },
          onCompleted: (newData) => setTest(newData.createTests.tests[0]),
        });

        // Store content in Firestore
        const timestamp = serverTimestamp();
        const docData = {
          components,
          format,
          answerType: answerTypeV,
          addedBy: user.uid,
          timestamp,
        };
        await setDoc(doc(db, "checks", testResourceUid), docData);

        // Form Reset
        setType("Multiple Choice");
        setDifficulty("Intro");
      } catch (event) {
        setValError(`Submission Failed: ${event.message}`);
      }
    }
    setSubmitLoading(false);
  };

  // Feel like I can improve this but don't want to right now
  useEffect(() => {
    if (!test) {
      return;
    }
    if (testedConceptsV.length !== 0) {
      const testedConceptsZ = testedConceptsV.map((a) => ({ ...a }));
      // eslint-disable-next-line no-param-reassign, dot-notation
      testedConceptsZ.forEach((element) => delete element["__typename"]);
      updateTestedConcepts({
        variables: {
          uid: test.uid,
          testedConcepts: testedConceptsZ,
        },
        onCompleted: setTest(false),
      });
    }
    if (!error) {
      refetch(dataConcepts);
      openSuccessSB();
      setComponents([{ id: "component-0", type: null, content: null }]);
      setTestedConcepts([]);
    }
  }, [test]);

  return (
    <ResourceFormLayout title="Add Testing Resource">
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDTypography mb={2}>Testing Resource Information:</MDTypography>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} lg={4}>
            <Autocomplete
              value={answerTypeV}
              required
              onChange={(event, newType) => {
                setType(newType);
              }}
              options={["Multiple Choice"]} // , "Validated Free Response (Math)"  "Multiple-Multiple Choice",
              renderInput={(params) => (
                <MDInput {...params} label="Answer Type" variant="outlined" required />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Autocomplete
              multiple
              disabled={loadingConcepts}
              value={testedConceptsV || null}
              onChange={(event, newTestedConcepts) => {
                setTestedConcepts(newTestedConcepts);
              }}
              options={!loadingConcepts && dataConcepts ? dataConcepts.concepts : []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="outlined"
                  required={testedConceptsV.length === 0}
                  label={testedConceptsV.length === 0 ? "Concepts" : "Concepts *"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Autocomplete
              value={difficultyV || "Intro"}
              required
              onChange={(event, newDifficulty) => {
                setDifficulty(newDifficulty);
              }}
              options={difficulties}
              renderInput={(params) => (
                <MDInput {...params} label="Difficulty" required variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
        <Divider />
        <MDBox display="flex" alignItems="center" mb={2}>
          <Stack
            direction="row"
            spacing={1}
            ml={3}
            pr={2}
            sx={{ alignItems: "center", borderRight: 1 }}
          >
            <MDTypography>Components:</MDTypography>
            <MDButton
              disabled={componentCount === 0}
              onClick={() => {
                setComponents((oldComponents) => {
                  const newComponents = [...oldComponents];
                  const nullIndex = findIndex(oldComponents, ["type", null]);
                  if (nullIndex >= 0) {
                    newComponents.splice(nullIndex, 1);
                  } else {
                    newComponents.pop();
                  }
                  return newComponents;
                });
                setFormat("block");
              }}
              iconOnly
            >
              <Icon>remove_circle</Icon>
            </MDButton>
            <MDTypography>{componentCount}</MDTypography>
            <MDButton
              disabled={componentCount === 3}
              iconOnly
              onClick={() => {
                setComponents((oldComponents) => [
                  ...oldComponents,
                  { id: uniqueId("component-"), type: null, content: null },
                ]);
              }}
            >
              <Icon>add_circle</Icon>
            </MDButton>
          </Stack>
          <MDTypography mx={3}>Formats:</MDTypography>
          <FormatMenu componentCount={componentCount} setFormat={setFormat} format={format} />
        </MDBox>
        <Divider />
        <TestBuilderLayout
          answerType={answerTypeV}
          components={components}
          setComponents={setComponents}
          format={format}
        />
        {errorConcepts && (
          <Alert color="error">Failed to load concepts: {errorConcepts.message}</Alert>
        )}
        {error && <Alert color="error">{error.message}</Alert>}
        {validationError && (
          <MDBox sx={{ width: "100%" }}>
            <Collapse in={!!validationError}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setValError(null);
                    }}
                  >
                    <Icon fontSize="inherit">close</Icon>
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {validationError}
              </Alert>
            </Collapse>
          </MDBox>
        )}
        {renderSuccessSB}
        <MDBox mt={3} textAlign="center">
          <MDButton disabled={submitLoading} type="submit" color="primary">
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
      {/* preview && (
        <MDBox mt={3}>
          <Grid container spacing={2}>
            {preview.components.map((component, index) => (
              <Serializer
                key={uniqueId("serialized-")}
                format={FormatGrid(preview.format, preview.components.length)[index]}
                content={component.content}
                answer={answer}
                setAnswer={setAnswer}
              />
            ))}
          </Grid>
        </MDBox>
      ) */}
    </ResourceFormLayout>
  );
}

export default AddTest;
