/* eslint-disable no-nested-ternary */

// React
import { useState, useRef } from "react";

// Props
import PropTypes from "prop-types";
import {
  conceptProps,
  videoProps,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

// @mui
import { Autocomplete, Stack, Alert, Dialog, DialogContent, DialogTitle } from "@mui/material";

// Material Kit 2
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

// Auth Context
import { UserAuth } from "context/AuthContext";

// Firebase
import { db } from "firebase-config";
import { collection, addDoc } from "firebase/firestore";

function ReportDialog(props) {
  const { onClose, open, currentVideo, currentConcept } = props;
  const { user } = UserAuth();
  const [reasonV, setReason] = useState();
  const messageRef = useRef();
  const [successSB, setSuccessSB] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [key1, setKey1] = useState("Key 1");
  const [key2, setKey2] = useState("Key 2");

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success!"
      content="Your message has been sent. Someone will get back to you shortly!"
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const message = messageRef.current.value;
      const mail = {
        from: "contact-us@edyou.org",
        replyTo: user.email,
        to: "contact-us@edyou.org",
        message: {
          subject: "Reported Video",
          html: `<p>From: ${user.displayName} (${user.uid})</p>
          <h5>Reported Video</h5>
          <p>Reported Reason: ${reasonV}</p>
          <p>Video Title: ${currentVideo.title}</p>
          <p>Video Channel: ${currentVideo.channelName}</p>
          <p>Video Uid: ${currentVideo.uid}</p>
          <p>Concept Name: ${currentConcept.name}</p>
          <p>Concept Uid: ${currentConcept.uid}</p>
          <p>Additional Info: ${message}</p>`,
        },
      };
      await addDoc(collection(db, "mail"), mail);
      openSuccessSB();
      messageRef.current.value = "";
      if (key1 === "Key 1") {
        setKey1("Not Key 1");
      } else {
        setKey1("Key 1");
      }
      if (key2 === "Key 2") {
        setKey2("Not Key 2");
      } else {
        setKey2("Key 2");
      }
    } catch (event) {
      setError(event);
    }
    handleClose();
    setLoading(false);
  };

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>Report Video</DialogTitle>
      <DialogContent>
        <MDBox pt={0.5} pb={3} px={3} component="form" role="form" onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Autocomplete
              key={key2}
              options={[
                "Broken Link",
                "Inappropriate",
                "Poor Quality",
                "False Information",
                "Other",
              ]}
              fullWidth
              onChange={(event, reason) => {
                setReason(reason);
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  placeholder="- select -"
                  required
                  label="Reason"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              )}
            />
            <MDInput
              variant="outlined"
              label="Additional Info"
              placeholder="I want to say that..."
              inputRef={messageRef}
              InputLabelProps={{ shrink: true }}
              fullWidth
              multiline
              rows={6}
            />
            {error && <Alert color="error">{error.message}</Alert>}
            <MDBox textAlign="right">
              <MDButton
                disabled={loading}
                fullWidth
                variant="gradient"
                color="primary"
                type="submit"
              >
                Send Message
              </MDButton>
            </MDBox>
            {renderSuccessSB}
          </Stack>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
}

export default function ReportButton({ currentVideo, currentConcept }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MDBox sx={{ flexGrow: 1 }} component="span" textAlign="right">
      <MDButton variant="contained" size="small" sx={{ mt: -0.5 }} onClick={handleClickOpen}>
        Report Video
      </MDButton>
      <ReportDialog
        open={open}
        onClose={handleClose}
        currentVideo={currentVideo}
        currentConcept={currentConcept}
      />
    </MDBox>
  );
}

ReportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  currentVideo: videoProps.isRequired,
  currentConcept: conceptProps.isRequired,
};

ReportButton.propTypes = {
  currentVideo: videoProps.isRequired,
  currentConcept: conceptProps.isRequired,
};
