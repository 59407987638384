/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { gql, useQuery } from "@apollo/client";
import { clone } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// @mui material components
import { Grid, Alert, Stack, Divider, Icon } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";

// Auth context
import { UserAuth } from "context/AuthContext";

// Firestore
import { db } from "firebase-config";
import { collection, doc, setDoc, serverTimestamp } from "firebase/firestore";

// Program Card Preview
import ProgramCardPreview from "layouts/LearningStudio/Contribute/components/AddProgram/ProgramCardPreview";
import {
  categories,
  targetAudience,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import ConceptCard from "./components/ConceptCard";
import PreviewConceptCard from "./components/PreviewConceptCard";
import ResourceFormLayout from "../components/ResourceFormLayout";

function AddVideoProgram() {
  const [previewActive, setPreviewActive] = useState(false);
  const [targetAudienceV, setTargetAudience] = useState(null);
  const [categoriesV, setCategories] = useState([]);
  const [quizV, setQuiz] = useState("No");
  const [conceptsV, setConcepts] = useState([]);
  const [newConceptV, setNewConcept] = useState();
  const [previewProgram, setProgramPreview] = useState({});
  const [snackbarName, setSnackbarName] = useState(null);
  const [successSB, setSuccessSB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [previewError, setPreviewError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [difficultyError, setDifficultyError] = useState(false);
  const { user } = UserAuth();
  const nameRef = useRef("");
  const imageUrlRef = useRef("");
  const descriptionRef = useRef("");

  const GET_CONCEPTS = gql`
    query GetConcepts {
      concepts {
        uid
        name
        block
        methodVideos {
          uid
          title
          channelName
          embedId
        }
        interestVideos {
          uid
          title
          channelName
          embedId
        }
      }
    }
  `;

  const {
    loading: loadingConcepts,
    error: errorConcepts,
    data: dataConcepts,
  } = useQuery(GET_CONCEPTS);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success!"
      content={`"${snackbarName}" program has been created.`}
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handlePreview = async (e) => {
    e.preventDefault();
    setPreviewError("");
    const newProgram = {
      programId: uuidv4(),
      name: nameRef.current.value,
      image: imageUrlRef.current.value,
      description: descriptionRef.current.value,
      type: "Video",
      targetAudience: targetAudienceV,
      categories: categoriesV,
      conceptList: conceptsV,
      addedBy: user.uid,
      quiz: quizV,
    };
    const programNoConceptList = (({ conceptList, ...o }) => o)(newProgram);
    const isRequiredCheck = Object.values(programNoConceptList).some(
      (x) => x === null || x === "" || x.length === 0
    );
    if (!isRequiredCheck) {
      setProgramPreview(newProgram);
      setPreviewActive(true);
    } else setPreviewError("Please fill out all required fields.");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError("");
    const timestamp = serverTimestamp();
    const conceptsListV = conceptsV.map(({ methodVideos, ...item }) => item);
    const newProgram = {
      programId: uuidv4(),
      name: nameRef.current.value,
      image: imageUrlRef.current.value,
      description: descriptionRef.current.value,
      type: "Video",
      targetAudience: targetAudienceV,
      categories: categoriesV,
      conceptList: conceptsListV,
      addedBy: user.uid,
      quiz: quizV,
      timestamp,
    };
    setSnackbarName(nameRef.current.value);
    const isRequiredCheck = Object.values(newProgram).some(
      (x) => x === null || x === "" || x.length === 0
    );
    if (!isRequiredCheck) {
      try {
        setLoading(true);
        await setDoc(doc(collection(db, "programs"), newProgram.programId), newProgram);
        openSuccessSB();
      } catch (event) {
        setError(true);
      }
      setLoading(false);
      setPreviewActive(false);
      nameRef.current.value = "";
      imageUrlRef.current.value = "";
      descriptionRef.current.value = "";
      setTargetAudience(null);
      setCategories([]);
      setConcepts([]);
    } else {
      setSubmitError("Please fill out all required fields, including adding concepts.");
    }
  };

  const handleAddConcept = () => {
    setDifficultyError(false);
    if (newConceptV) {
      if (newConceptV.difficulty && newConceptV.programVideos.length > 0) {
        setConcepts((oldConcepts) => {
          const newConcepts = clone(oldConcepts);
          newConcepts.push(newConceptV);
          return newConcepts;
        });
        setNewConcept(null);
      } else {
        setDifficultyError("Please select a difficulty and program videos.");
      }
    } else {
      setDifficultyError("Please select a concept to add.");
    }
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(conceptsV);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setConcepts(items);
  }

  return (
    <ResourceFormLayout title="Create a Video Program">
      <Grid container pb={6} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <MDBox component="form" display="flex" justifyContent="center" textAlign="center">
            <Stack direction="column" spacing={2} width="80%">
              <MDTypography mb={1}>Program Details:</MDTypography>
              <MDInput type="text" inputRef={nameRef} label="Program Name" required fullWidth />
              <MDInput
                type="text"
                placeholder="https:// ..."
                inputRef={imageUrlRef}
                label="Image URL"
                required
                fullWidth
              />
              <MDInput
                type="text"
                inputRef={descriptionRef}
                label="Program Description"
                multiline
                rows={4}
                required
                fullWidth
                helperText="Character Limit: 150"
                inputProps={{ maxLength: 150 }}
              />
              <Autocomplete
                value={targetAudienceV || null}
                onChange={(event, newTargetAudience) => {
                  setTargetAudience(newTargetAudience);
                }}
                options={targetAudience}
                renderInput={(params) => (
                  <MDInput {...params} required variant="outlined" label="Target Audience?" />
                )}
              />
              <Autocomplete
                multiple
                value={categoriesV || []}
                onChange={(event, newCategories) => {
                  setCategories(newCategories);
                }}
                options={categories}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    required={categoriesV.length === 0}
                    label={categoriesV.length === 0 ? "Categories" : "Categories *"}
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                value={quizV || "No"}
                onChange={(event, newQuiz) => {
                  setQuiz(newQuiz);
                }}
                options={["Yes", "No"]}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    required={categoriesV.length === 0}
                    label={categoriesV.length === 0 ? "Quiz?" : "Quiz? *"}
                    variant="outlined"
                  />
                )}
              />
              {errorConcepts && (
                <Alert color="error">Failed to load concepts: {errorConcepts.message}</Alert>
              )}
              {error && <Alert color="error">Something went wrong</Alert>}
              <MDBox textAlign="center" pt={4}>
                <MDButton
                  disabled={loading}
                  sx={{ width: "35%" }}
                  variant="gradient"
                  color="info"
                  onClick={handlePreview}
                >
                  Preview
                </MDButton>
                {renderSuccessSB}
              </MDBox>
            </Stack>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} mt={{ xs: 6, md: 0 }}>
          {previewActive ? (
            <MDBox display="flex" textAlign="center" justifyContent="center">
              <ProgramCardPreview program={previewProgram} />
            </MDBox>
          ) : (
            <MDBox textAlign="center" pb={12} pr={{ xs: 0, md: 6 }}>
              <MDTypography variant="h4" fontWeight="light">
                &lt; Preview &gt;
              </MDTypography>
            </MDBox>
          )}
        </Grid>
      </Grid>
      {previewError && <Alert color="error">{previewError}</Alert>}
      <Divider />
      <MDBox p={3} width="100%" display="flex" textAlign="center">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Stack justifyContent="center" spacing={3} p={3}>
              <MDTypography>Add Concept:</MDTypography>
              <MDBox width="100%" display="flex" alignItems="center">
                <Autocomplete
                  sx={{ flexGrow: 1, mr: 2 }}
                  disabled={loadingConcepts}
                  value={newConceptV || null}
                  onChange={(event, newConcept) => {
                    setNewConcept(newConcept);
                  }}
                  options={
                    !loadingConcepts && dataConcepts
                      ? dataConcepts.concepts.filter(
                          (concept) =>
                            concept.methodVideos.length > 0 || concept.interestVideos.length > 0
                        )
                      : []
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="outlined"
                      required={conceptsV.length === 0}
                      label={conceptsV.length === 0 ? "Program Concepts" : "Program Concepts *"}
                    />
                  )}
                />
                <MDButton color="primary" iconOnly circular onClick={handleAddConcept}>
                  <Icon>add</Icon>
                </MDButton>
              </MDBox>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            {newConceptV ? (
              <PreviewConceptCard concept={newConceptV} setNewConcept={setNewConcept} />
            ) : (
              <MDTypography>&lt; Card &gt;</MDTypography>
            )}
          </Grid>
        </Grid>
      </MDBox>
      {difficultyError && <Alert color="error">{difficultyError}</Alert>}
      <Divider />
      <MDBox width="100%" display="flex" justifyContent="space-evenly" my={2} alignItems="center">
        <MDTypography textAlign="center">Arrange Concepts:</MDTypography>
        <MDButton color="info" variant="gradient" onClick={handleSubmit}>
          Submit Program
        </MDButton>
        {renderSuccessSB}
      </MDBox>
      {submitError && <Alert color="error">{submitError}</Alert>}
      <MDBox p={3} display="flex" width="100%" justifyContent="center">
        {conceptsV.length > 0 && (
          // eslint-disable-next-line react/jsx-no-bind
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="conceptsV">
              {(provided) => (
                <ul className="conceptsV" {...provided.droppableProps} ref={provided.innerRef}>
                  {conceptsV.map((concept, index) => (
                    <Draggable key={concept.uid} draggableId={concept.uid} index={index}>
                      {/* eslint-disable-next-line no-shadow */}
                      {(provided) => (
                        <MDBox mb={2}>
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ConceptCard
                              concept={concept}
                              index={index}
                              key={`concept-${concept.uid}`}
                              setConcepts={setConcepts}
                            />
                          </li>
                        </MDBox>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </MDBox>
    </ResourceFormLayout>
  );
}

export default AddVideoProgram;
