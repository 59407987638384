/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the page layout of Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the DefaultNavbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `name` key is used for the name of the route on the DefaultNavbar.
  3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
  5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  6. The `route` key is used to store the route location which is used for the react router.
  7. The `href` key is used to store the external links location.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages

import Home from "layouts/CompanyWebsite/Home";
// import ComingSoon from "layouts/CompanyWebsite/ComingSoon";
import OurTeam from "layouts/CompanyWebsite/AboutUs/OurTeam";
// import PricingPage from "layouts/CompanyWebsite/AboutEDYou/Pricing";
import LearningStudio from "layouts/CompanyWebsite/AboutEDYou/LearningStudio";
import VerifiedEducators from "layouts/CompanyWebsite/AboutEDYou/VerifiedEducators";
import OurMission from "layouts/CompanyWebsite/AboutUs/OurMission/index";
// import BlogHome from "layouts/CompanyWebsite/Blog/BlogHome";

const pageRoutes = [
  // Navbar routes
  {
    name: "home",
    icon: <Icon>home</Icon>,
    route: "/",
    component: <Home />,
    render: true,
  },
  {
    name: "about us",
    icon: <Icon>contacts</Icon>,
    render: true,
    columns: 2,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "about us",
        collapse: [
          {
            name: "our mission",
            route: "/our-mission",
            component: <OurMission />,
          },
          {
            name: "our team",
            route: "/our-team",
            component: <OurTeam />,
          },
        ],
      },
      {
        name: "about edyou",
        collapse: [
          {
            name: "learning studio",
            route: "/learning-studio",
            component: <LearningStudio />,
          },
          {
            name: "verified educators",
            route: "/verified-educators",
            component: <VerifiedEducators />,
          },
          /* {
            name: "upcoming features",
            route: "/upcoming-features",
            component: <ComingSoon />,
          },
          {
            name: "pricing",
            route: "/pricing",
            component: <PricingPage />,
          }, */
        ],
      },
    ],
  },
  /* {
    name: "Blog",
    icon: <Icon>view_day</Icon>,
    render: true,
    route: "/blog",
    component: <BlogHome />,
  }, */
  {
    name: "Contact Us",
    icon: <Icon>article</Icon>,
    route: "/contact-us",
  },
];

export default pageRoutes;
