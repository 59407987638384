import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import MDButton from "components/MDButton/index";
import { programConceptProps } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import ReturnToVideos from "./ReturnToVideosModal/index";

function ReturnButton(props) {
  const { source, selectedProgram, currentProgramIndex, selectedConcept } = props;
  const navigate = useNavigate();
  switch (source) {
    case "quiz":
      return (
        <MDButton color="primary" fontWeight="medium" onClick={() => navigate("/studio/quizzes")}>
          Return to Quiz Mode
        </MDButton>
      );
    case "review":
      return (
        <MDButton
          color="primary"
          fontWeight="medium"
          onClick={() => navigate("/studio/my-programs")}
        >
          Return to Programs
        </MDButton>
      );
    case "progress":
      return (
        <MDButton
          color="primary"
          fontWeight="medium"
          onClick={() => {
            navigate("/studio/my-progress/learning-progress");
          }}
        >
          Return to Progress
        </MDButton>
      );
    case "weekly quiz":
      return (
        <MDButton
          color="primary"
          fontWeight="medium"
          onClick={() => {
            navigate("/studio");
          }}
        >
          Return to Studio
        </MDButton>
      );
    default:
      return (
        <ReturnToVideos
          selectedConcept={selectedConcept}
          selectedProgram={selectedProgram}
          currentProgramIndex={currentProgramIndex}
          source={source}
        />
      );
  }
}

export default ReturnButton;

ReturnButton.defaultProps = {
  selectedProgram: null,
  currentProgramIndex: null,
};

ReturnButton.propTypes = {
  source: PropTypes.string.isRequired,
  selectedConcept: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    block: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    interestVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    programConcepts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    review: PropTypes.bool,
  }).isRequired,
  selectedProgram: PropTypes.shape({
    programId: PropTypes.string.isRequired,
    conceptList: PropTypes.arrayOf(programConceptProps),
  }),
  currentProgramIndex: PropTypes.number,
};
