import { useState } from "react";
import { Backdrop, Modal, Fade, Icon, Divider, Stack, styled } from "@mui/material";
import { useSlateStatic } from "slate-react";
import isUrl from "is-url";
import imageExtensions from "image-extensions";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { PropTypes } from "prop-types";
import MDInput from "components/MDInput/index";
import MDTypography from "components/MDTypography/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxWidth: "50rem",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function ImageModal(props) {
  const { onClose, open } = props;
  const [url, setUrl] = useState("");
  const [pictureUpload, setPictureUpload] = useState();

  const handleClose = () => {
    onClose();
  };

  const handleSubmitClose = (data) => {
    onClose(data);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={style}>
          <MDBox component="form" type="submit" display="flex">
            <MDInput
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://"
              label="From Url"
            />
            <MDButton onClick={() => handleSubmitClose(url)}>Submit</MDButton>
          </MDBox>
          <Divider />
          <Stack spacing={3} direction="row" alignItems="center">
            <MDTypography variant="body2">From Picture</MDTypography>
            <MDButton disabled={!!pictureUpload} component="label" color="info">
              Upload File
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setPictureUpload(e.target.files[0])}
              />
            </MDButton>
            {pictureUpload && (
              <>
                <MDButton
                  iconOnly
                  variant="text"
                  size="large"
                  color="secondary"
                  onClick={() => setPictureUpload(null)}
                >
                  <Icon>close</Icon>
                </MDButton>
                <MDTypography variant="button">{pictureUpload.name}</MDTypography>
              </>
            )}
            {/* delete image option */}
          </Stack>
          <MDButton onClick={() => handleSubmitClose({ files: [pictureUpload] })}>Submit</MDButton>
        </MDBox>
      </Fade>
    </Modal>
  );
}

ImageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ImageModalButton({ insertImage }) {
  const [open, setOpen] = useState(false);
  const editor = useSlateStatic();

  const isImageUrl = (url) => {
    if (!url) return false;
    if (!isUrl(url)) return false;
    const ext = new URL(url).pathname.split(".").pop();
    return imageExtensions.includes(ext);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = (data) => {
    setOpen(false);

    const { files } = data;
    // image files
    if (files) {
      // this set up so multiple files may be added in the future
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(data)) {
      insertImage(editor, data);
    } else {
      insertImage(editor, data);
    }
  };

  return (
    <MDBox>
      <MDButton iconOnly onClick={handleOpen}>
        <Icon>image</Icon>
      </MDButton>
      <ImageModal open={open} onClose={handleClose} />
    </MDBox>
  );
}

ImageModalButton.propTypes = {
  insertImage: PropTypes.func.isRequired,
};
