import React from "react";
import { PropTypes } from "prop-types";
import MDBox from "components/MDBox/index";
import Grid from "@mui/material/Grid";
import { intersection, find } from "lodash";
import { quizProps } from "layouts/LearningStudio/MediaViewer/components/DefaultValues";
import QuizCard from "./QuizCard";

function CardDisplay(props) {
  const { quizData, activeQuizzes, loading, filters } = props;
  if (loading) {
    return <h2>Loading...</h2>;
  }

  const filteredQuizData = quizData.filter((quiz) => {
    const audienceFilter = !(
      filters.targetAudience.length !== 0 && filters.targetAudience.indexOf(quiz.targetAudience) < 0
    );
    const categoriesFilter = !(
      filters.categories.length !== 0 &&
      intersection(filters.categories, quiz.categories).length === 0
    );

    return audienceFilter && categoriesFilter;
  });

  return (
    <MDBox>
      <Grid container rowSpacing={{ xs: 6, md: 4 }} columnSpacing={4}>
        {/* eslint-disable-next-line arrow-body-style */}
        {filteredQuizData?.map((quiz) => {
          const active = find(activeQuizzes, ["programId", quiz.programId]);
          return (
            <Grid key={quiz.programId} item xs={12} md={6} lg={4} xxl={3}>
              <MDBox height="100%" display="flex" justifyContent="space-around" pt={3}>
                <QuizCard quiz={quiz} active={active} />
              </MDBox>
            </Grid>
          );
        })}
      </Grid>
    </MDBox>
  );
}

CardDisplay.propTypes = {
  quizData: PropTypes.arrayOf(quizProps).isRequired,
  loading: PropTypes.bool.isRequired,
  activeQuizzes: PropTypes.arrayOf(
    PropTypes.shape({
      programId: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      quizProgress: PropTypes.object.isRequired,
    })
  ).isRequired,
  filters: PropTypes.shape({
    targetAudience: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    categories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
};

export default CardDisplay;
