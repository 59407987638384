/* eslint-disable no-nested-ternary */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Auth Context
import { UserAuth } from "context/AuthContext";

// @mui material components
import { Icon, Card, CircularProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton/index";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleFooter from "examples/Footer/SimpleFooter";
import DataTable from "layouts/LearningStudio/FocusMode/MyPrograms/components/DataTable";

// Lodash
import { filter } from "lodash";

// Firebase RDB
import { rdb } from "firebase-config";
import { child, get, ref } from "firebase/database";

// Data
import programsData from "layouts/LearningStudio/FocusMode/MyPrograms/components/ProgramsData/index";

function MyPrograms() {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [programHistory, setProgramHistory] = useState();
  const [loading, setLoading] = useState(true);

  const activePrograms =
    !loading && programHistory
      ? Object.keys(filter(programHistory, (program) => program.active)).length
      : false;

  // Table Data
  const { columns: pColumns, rows: pRows } = programsData();

  useEffect(() => {
    // declare the data fetching function
    const createRows = async () => {
      const database = ref(rdb);
      await get(child(database, `programHistory/${user.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const programSnapshot = snapshot.val();
            setProgramHistory(programSnapshot);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setLoading(false);
    };
    createRows();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar isMini />
      <MDBox pt={6} pb={6}>
        <Card sx={{ minHeight: "40.5rem" }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={4}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5" color="white">
              My Programs
            </MDTypography>
            {activePrograms && !loading ? (
              <MDButton variant="outlined" onClick={() => navigate("/studio/program-store")}>
                <Icon>add</Icon>&nbsp;&nbsp;Add Programs
              </MDButton>
            ) : null}
          </MDBox>
          <MDBox pt={3}>
            {activePrograms ? (
              <DataTable
                table={{ columns: pColumns, rows: pRows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            ) : loading ? (
              <MDBox pt={12} textAlign="center">
                <CircularProgress />
              </MDBox>
            ) : (
              <MDBox pt={6} textAlign="center">
                <MDTypography pb={4}>
                  You have no active programs. Please click the button below to add programs from
                  our store.
                </MDTypography>
                <MDButton
                  onClick={() => navigate("/studio/program-store")}
                  variant="gradient"
                  color="info"
                >
                  <Icon>add</Icon>&nbsp;&nbsp;Add Programs
                </MDButton>
              </MDBox>
            )}
          </MDBox>
        </Card>
      </MDBox>
      <SimpleFooter />
    </DashboardLayout>
  );
}

export default MyPrograms;
