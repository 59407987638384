/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox/index";
import MDProgress from "components/MDProgress/index";
// import MDButton from "components/MDButton";

// MUI
// import EastIcon from "@mui/icons-material/East";
// import CircularProgress from "@mui/material/CircularProgress";

// Firestore && RTDB
import { rdb } from "firebase-config";
import { child, ref, get } from "firebase/database";

// Auth Context
import { UserAuth } from "context/AuthContext";
import { capitalize } from "lodash";

function convertTimestamp(timestamp) {
  const dateInMillis = timestamp;
  const date = new Date(dateInMillis);
  const stringDate = date.toLocaleDateString();
  return stringDate;
}

export default function quizHistoryData() {
  const { user } = UserAuth();

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  const defaultRows = [
    {
      title: (
        <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
          Loading History ...
        </MDTypography>
      ),
      quizType: (
        <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
          ...
        </MDTypography>
      ),
      numConcepts: (
        <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
          ...
        </MDTypography>
      ),
      progress: (
        <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
          ...
        </MDTypography>
      ),
      lastActivity: (
        <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
          ...
        </MDTypography>
      ),
      score: (
        <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
          ...
        </MDTypography>
      ),
    },
  ];

  const [rows, setRows] = useState(defaultRows);

  useEffect(() => {
    // declare the data fetching function
    const createRows = async () => {
      // rdb data
      let quizHistory;
      const database = ref(rdb);
      await get(child(database, `quizHistory/${user.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            quizHistory = Object.values(snapshot.val()).reverse();
          }
        })
        .catch((error) => {
          console.error(error);
        });
      console.log(quizHistory);

      // Print the info on all programs
      let pRows;
      if (quizHistory) {
        pRows = quizHistory.map((quiz) => {
          const dateTime = convertTimestamp(quiz.timestamp);
          // set Type color
          let textColor;
          switch (quiz.type) {
            case "review":
              textColor = "warning";
              break;
            case "weekly quiz":
              textColor = "primary";
              break;
            // quiz
            default:
              textColor = "info";
              break;
          }

          const proto = {
            title: (
              <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
                {quiz.title}
              </MDTypography>
            ),
            quizType: (
              <MDTypography component="a" variant="button" color={textColor} fontWeight="medium">
                {capitalize(quiz.type)}
              </MDTypography>
            ),
            numConcepts: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                {quiz.numConcepts}
              </MDTypography>
            ),
            progress: (
              <Progress
                color={quiz.completed ? "primary" : "info"}
                value={
                  quiz.completed
                    ? 100
                    : Math.floor((quiz.currentConceptIndex / quiz.numConcepts) * 100)
                }
              />
            ),
            lastActivity: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                {dateTime}
              </MDTypography>
            ),
            score: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                {quiz.completed
                  ? `${quiz.currentCorrect}/${quiz.currentQuestionIndex} (${Math.floor(
                      (quiz.currentCorrect / quiz.currentQuestionIndex) * 100
                    )}%)`
                  : "-"}
              </MDTypography>
            ),
          };
          return proto;
        });
      } else {
        pRows = [
          {
            title: (
              <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
                No Quiz History
              </MDTypography>
            ),
            quizType: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                ...
              </MDTypography>
            ),
            numConcepts: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                ...
              </MDTypography>
            ),
            progress: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                ...
              </MDTypography>
            ),
            lastActivity: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                ...
              </MDTypography>
            ),
            score: (
              <MDTypography component="a" variant="button" color="inherit" fontWeight="medium">
                ...
              </MDTypography>
            ),
          },
        ];
      }
      setRows(pRows);
    };

    // call the function
    createRows()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const columns = [
    { Header: "title", accessor: "title", align: "left" },
    { Header: "type", accessor: "quizType", width: "10%", align: "center" },
    { Header: "# concepts", accessor: "numConcepts", width: "10%", align: "center" },
    { Header: "progress", accessor: "progress", width: "20%", align: "center" },
    { Header: "last activity", accessor: "lastActivity", width: "10%", align: "left" },
    { Header: "score", accessor: "score", width: "15%", align: "left" },
  ];

  return {
    columns,
    rows,
  };
}
