/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

// React
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Material Dashboard Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox/index";
import MDButton from "components/MDButton/index";
import PropTypes, { any } from "prop-types";

// MUI
import Stack from "@mui/material/Stack";
import { capitalize, random } from "lodash";

// Auth Context
import { UserAuth } from "context/AuthContext";

// Apollo
import { gql, useQuery } from "@apollo/client";

// Defaults and Typechecking
import {
  conceptProps,
  videoProps,
  defaultConcept,
  defaultVideo,
  noConcepts,
  noVideos,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

// Modals
import AnotherVideo from "./modals/AnotherVideoModal";
import InterestVideo from "./modals/InterestVideoModal";
import BroaderModalWithSteps from "./modals/BroaderConceptModal";
import NarrowerModalWithSteps from "./modals/NarrowerConceptModal";
import RelatedModalWithSteps from "./modals/RelatedConceptModal";
import NextProgram from "./modals/NextProgramModal";
import PeopleModalWithSteps from "./modals/PeoplePlacesEventsModal";
import VideoConceptModalWithSteps from "./modals/VideoConceptModal";
import PreviousVideo from "./modals/PreviousVideoModal";
import NextVideoProgram from "./modals/NextVideoProgramModal";
import AnotherProgramVideo from "./modals/AnotherProgramVideoModal";

export default function Navigation(props) {
  const {
    currentConcept,
    currentVideo,
    currentProgram,
    currentProgramIndex,
    setCurrentVideo,
    setCurrentConcept,
    setProgramIndex,
    setVideoType,
    prevHistory,
    // eslint-disable-next-line no-unused-vars
    completedSurvey,
    source,
    sourceData,
  } = props;
  const { user } = UserAuth();
  const navigate = useNavigate();

  // Apollo queries
  const GET_DATA = gql`
    query GetVideos($uid: ID!) {
      concepts(where: { uid: $uid }) {
        methodVideos {
          uid
          embedId
          title
          channelName
          methodConcepts(where: { uid_NOT: $uid }) {
            uid
            name
            block
            person
            place
            event
            methodVideos {
              uid
              embedId
              title
              channelName
            }
          }
          interestConcepts(where: { uid_NOT: $uid }) {
            uid
            name
            block
            person
            place
            event
            methodVideos {
              uid
              embedId
              title
              channelName
            }
          }
        }
        interestVideos {
          uid
          embedId
          title
          channelName
          methodConcepts(where: { uid_NOT: $uid }) {
            uid
            name
            block
            person
            place
            event
            methodVideos {
              uid
              embedId
              title
              channelName
            }
          }
          interestConcepts(where: { uid_NOT: $uid }) {
            uid
            name
            block
            person
            place
            event
            methodVideos {
              uid
              embedId
              title
              channelName
            }
          }
        }
        broaderConcepts {
          uid
          name
          block
          person
          place
          event
          methodVideos {
            uid
            embedId
            title
            channelName
          }
          narrowerConcepts {
            uid
            name
            block
            person
            place
            event
            methodVideos {
              uid
              embedId
              title
              channelName
            }
          }
        }
        narrowerConcepts {
          uid
          name
          block
          person
          place
          event
          methodVideos {
            uid
            embedId
            title
            channelName
          }
        }
        peopleConcepts {
          uid
          name
          block
          person
          place
          event
          methodVideos {
            uid
            embedId
            title
            channelName
          }
        }
        placeConcepts {
          uid
          name
          block
          person
          place
          event
          methodVideos {
            uid
            embedId
            title
            channelName
          }
        }
        eventConcepts {
          uid
          name
          block
          person
          place
          event
          methodVideos {
            uid
            embedId
            title
            channelName
          }
        }
        testingResources {
          uid
          difficulty
          text
          question
          picture
          answerChoices
          correctAnswer
          testedConcepts {
            uid
            name
          }
        }
      }
    }
  `;

  const { loading, data, refetch } = useQuery(GET_DATA, {
    variables: {
      uid: currentConcept.uid,
    },
  });

  // Filters for current video, current concept, and wrong concept type
  const filterVideoOptions = (videos) => {
    const filterCurrentVideo = videos.filter((video) => video.uid !== currentVideo.uid);
    const filteredOptions = filterCurrentVideo.length !== 0 ? filterCurrentVideo : noVideos;
    return filteredOptions;
  };

  const filterConceptOptions = (concepts, specialType) => {
    const filteredForType = specialType
      ? concepts.filter((concept) => concept[specialType])
      : concepts;
    const filteredForVideos =
      filteredForType.filter((concept) => filterVideoOptions(concept.methodVideos) !== noVideos)
        .length !== 0
        ? filteredForType.filter(
            (concept) =>
              concept.methodVideos.filter((video) => video.uid !== currentVideo.uid).length !== 0
          )
        : noConcepts;
    return filteredForVideos;
  };

  // Applying the filters above to the data
  const anotherVideoOptions =
    !loading && data ? filterVideoOptions(data.concepts[0].methodVideos) : defaultVideo;

  const interestVideoOptions =
    !loading && data ? filterVideoOptions(data.concepts[0].interestVideos) : defaultVideo;

  let anotherProgramVideoOptions = defaultVideo;
  if (currentProgram && currentProgram.type === "Video") {
    const programConcept = currentProgram.conceptList.find(
      (concept) => concept.uid === currentConcept.uid
    );
    anotherProgramVideoOptions =
      // Not really sure about the .exists() part. Could consider doing the check by finding a matching element in the program array
      currentProgram && currentProgram.type === "Video" && programConcept
        ? filterVideoOptions(programConcept.programVideos)
        : defaultVideo;
  }

  const broaderConceptOptions =
    !loading && data ? filterConceptOptions(data.concepts[0].broaderConcepts) : defaultConcept;

  const narrowerConceptOptions =
    !loading && data ? filterConceptOptions(data.concepts[0].narrowerConcepts) : defaultConcept;

  // Concatonates the method and interest concepts for this video
  let videoConceptStacking = [];
  if (!loading && data) {
    if (currentVideo.methodConcepts) {
      videoConceptStacking = videoConceptStacking
        .concat(currentVideo.methodConcepts)
        .concat(currentVideo.interestConcepts);
    } else {
      const dataCurrentVideo = data.concepts[0].methodVideos.filter(
        (video) => video.uid === currentVideo.uid
      );
      if (dataCurrentVideo[0]) {
        videoConceptStacking = videoConceptStacking
          .concat(dataCurrentVideo[0].methodConcepts)
          .concat(dataCurrentVideo[0].interestConcepts);
      }
    }
  }

  const videoConceptOptions =
    !loading && data ? filterConceptOptions(videoConceptStacking) : defaultConcept;

  // Concatonates the people, places, and events options because they are undirected
  const extraStacking =
    !loading && data
      ? data.concepts[0].peopleConcepts
          .concat(data.concepts[0].placeConcepts)
          .concat(data.concepts[0].eventConcepts)
      : defaultConcept;

  const peopleConceptOptions =
    !loading && data ? filterConceptOptions(extraStacking, "person") : defaultConcept;

  const placeConceptOptions =
    !loading && data ? filterConceptOptions(extraStacking, "place") : defaultConcept;

  const eventConceptOptions =
    !loading && data ? filterConceptOptions(extraStacking, "event") : defaultConcept;

  // Concatonates the video options from each lateral concept
  let relatedStacking = [];
  if (!loading && data) {
    data.concepts[0].broaderConcepts.forEach((broaderConcept) => {
      relatedStacking = relatedStacking.concat(broaderConcept.narrowerConcepts);
    });
    // For people, places, and events it adds their IS_PERSON, IS_PLACE, IS_EVENT concepts
    if (currentConcept.person || currentConcept.place || currentConcept.event) {
      extraStacking.forEach((concept) => {
        if (!concept.person && !concept.place && !concept.event)
          relatedStacking = relatedStacking.concat(concept);
      });
    }
  }
  const filteredStacking =
    relatedStacking.length !== 0
      ? relatedStacking.filter((concept) => concept.uid !== currentConcept.uid)
      : [];

  const relatedConceptOptions =
    !loading && data ? filterConceptOptions(filteredStacking) : defaultConcept;

  const prevHistoryOptions =
    prevHistory.length !== 0
      ? prevHistory
      : [{ concept: noConcepts[0], video: noVideos[0], videoType: "method" }];

  useEffect(() => {
    refetch(data);
  }, [currentConcept]);

  const handleTestReturn = () => {
    navigate("/studio/test-viewer", {
      state: {
        quiz: sourceData.quiz,
        source,
        currentIndex: sourceData.currentIndex,
        currentConcept: sourceData.currentConcept,
        testingResources: sourceData.testingResources,
        remainingTestingResources: sourceData.remainingTestingResources,
        currentConceptSubmitted: sourceData.currentConceptSubmitted,
        currentCorrect: sourceData.currentCorrect,
        currentQuestionIndex: sourceData.currentQuestionIndex,
        currentTest: sourceData.currentTest,
        currentTestPicture: sourceData.currentTestPicture,
      },
    });
  };

  const handleCheckUnderstanding = () => {
    navigate("/studio/test-viewer", {
      state: {
        quiz: {
          programId: currentConcept.uid,
          conceptList: [currentConcept],
        },
        currentIndex: 0,
        currentCorrect: 0,
        currentConcept,
        testingResources: !loading && data ? data.concepts[0].testingResources : null,
        currentTest:
          !loading && data
            ? data.concepts[0].testingResources[
                random(data.concepts[0].testingResources.length - 1)
              ]
            : null,
        source,
        sourceData: {
          currentProgram,
          currentProgramIndex,
        },
      },
    });
  };

  // console.log(`navigation:  ${sourceData.currentConceptSubmitted}`);

  return (
    <MDBox maxHeight="50rem">
      <MDTypography mb={{ xs: 0.5, lg: 1.5 }} textAlign="center" variant="h6">
        Current Concept: {currentConcept.name}
      </MDTypography>
      {(source === "quiz" || source === "review" || source === "weekly quiz") && (
        <MDBox>
          <MDButton
            fullWidth
            variant="gradient"
            sx={{ height: 45 }}
            size="small"
            color="info"
            onClick={handleTestReturn}
          >
            Return to {source === "weekly quiz" ? "Weekly Quiz" : capitalize(source)}
          </MDButton>
        </MDBox>
      )}
      {/* {source === "review" && (
        <MDBox>
          <MDButton
            fullWidth
            variant="gradient"
            sx={{ height: 45 }}
            size="small"
            color="info"
            onClick={handleReviewReturn}
          >
            Return to Review
          </MDButton>
        </MDBox>
      )} */}
      {(source === "search" || source === "progress") && (
        <MDBox>
          <MDButton
            fullWidth
            variant="gradient"
            sx={{ height: 45 }}
            size="small"
            color="info"
            disabled={!data || data.concepts[0].testingResources.length === 0}
            onClick={handleCheckUnderstanding}
          >
            Check Understanding
          </MDButton>
        </MDBox>
      )}
      {source === "program" &&
        (currentProgram.type === "Concept" ? (
          <>
            <NextProgram
              currentVideo={currentVideo}
              setCurrentVideo={setCurrentVideo}
              currentConcept={currentConcept}
              setCurrentConcept={setCurrentConcept}
              currentProgram={currentProgram}
              currentProgramIndex={currentProgramIndex}
              setProgramIndex={setProgramIndex}
              setVideoType={setVideoType}
              // completedSurvey={completedSurvey}
              userId={user.uid}
            />
            <MDBox mb={0.5} mt={1}>
              <MDButton
                fullWidth
                variant="gradient"
                sx={{ height: 45 }}
                size="small"
                color="extra"
                disabled={!data || data.concepts[0].testingResources.length === 0}
                onClick={handleCheckUnderstanding}
              >
                Check Understanding
              </MDButton>
            </MDBox>
          </>
        ) : (
          <>
            <NextVideoProgram
              currentVideo={currentVideo}
              setCurrentVideo={setCurrentVideo}
              currentConcept={currentConcept}
              setCurrentConcept={setCurrentConcept}
              currentProgram={currentProgram}
              currentProgramIndex={currentProgramIndex}
              setProgramIndex={setProgramIndex}
              setVideoType={setVideoType}
              // completedSurvey={completedSurvey}
              userId={user.uid}
            />
            <AnotherProgramVideo
              currentVideo={currentVideo}
              setCurrentVideo={setCurrentVideo}
              videoOptions={anotherProgramVideoOptions}
              setVideoType={setVideoType}
              currentConcept={currentConcept}
              currentProgram={currentProgram}
              // completedSurvey={completedSurvey}
            />
            <MDBox mb={0.5} mt={2}>
              <MDButton
                fullWidth
                variant="gradient"
                sx={{ height: 45 }}
                size="small"
                color="extra"
                disabled={!data || data.concepts[0].testingResources.length === 0}
                onClick={handleCheckUnderstanding}
              >
                Check Understanding
              </MDButton>
            </MDBox>
          </>
        ))}
      <MDTypography textAlign="left" variant="overline" fontWeight="bold">
        LEARN MORE
      </MDTypography>
      <Stack spacing={2} mb={0.5}>
        <AnotherVideo
          currentVideo={currentVideo}
          setCurrentVideo={setCurrentVideo}
          videoOptions={anotherVideoOptions}
          setVideoType={setVideoType}
          // completedSurvey={completedSurvey}
        />
        <InterestVideo
          currentVideo={currentVideo}
          setCurrentVideo={setCurrentVideo}
          videoOptions={interestVideoOptions}
          setVideoType={setVideoType}
          // completedSurvey={completedSurvey}
        />
        <VideoConceptModalWithSteps
          currentVideo={currentVideo}
          setCurrentVideo={setCurrentVideo}
          currentConcept={currentConcept}
          setCurrentConcept={setCurrentConcept}
          conceptOptions={videoConceptOptions}
          setVideoType={setVideoType}
        />
      </Stack>
      <MDTypography textAlign="left" variant="overline" fontWeight="bold">
        EXPLORE
      </MDTypography>
      <Stack spacing={2} mb={0.5}>
        <BroaderModalWithSteps
          currentVideo={currentVideo}
          setCurrentVideo={setCurrentVideo}
          currentConcept={currentConcept}
          setCurrentConcept={setCurrentConcept}
          conceptOptions={broaderConceptOptions}
          setVideoType={setVideoType}
          // completedSurvey={completedSurvey}
        />
        <NarrowerModalWithSteps
          currentVideo={currentVideo}
          setCurrentVideo={setCurrentVideo}
          currentConcept={currentConcept}
          setCurrentConcept={setCurrentConcept}
          conceptOptions={narrowerConceptOptions}
          setVideoType={setVideoType}
          // completedSurvey={completedSurvey}
        />
        <RelatedModalWithSteps
          currentVideo={currentVideo}
          setCurrentVideo={setCurrentVideo}
          currentConcept={currentConcept}
          setCurrentConcept={setCurrentConcept}
          conceptOptions={relatedConceptOptions}
          setVideoType={setVideoType}
          // completedSurvey={completedSurvey}
        />
      </Stack>
      <MDTypography textAlign="left" variant="overline" fontWeight="bold">
        EXTRA!
      </MDTypography>
      <PeopleModalWithSteps
        currentVideo={currentVideo}
        setCurrentVideo={setCurrentVideo}
        currentConcept={currentConcept}
        setCurrentConcept={setCurrentConcept}
        peopleConceptOptions={peopleConceptOptions}
        placeConceptOptions={placeConceptOptions}
        eventConceptOptions={eventConceptOptions}
        setVideoType={setVideoType}
        // completedSurvey={completedSurvey}
      />
      <PreviousVideo
        currentVideo={currentVideo}
        setCurrentVideo={setCurrentVideo}
        currentConcept={currentConcept}
        setCurrentConcept={setCurrentConcept}
        prevHistory={prevHistoryOptions}
        setVideoType={setVideoType}
      />
    </MDBox>
  );
}

Navigation.propTypes = {
  currentVideo: videoProps.isRequired,
  setCurrentVideo: PropTypes.func.isRequired,
  currentConcept: conceptProps.isRequired,
  setCurrentConcept: PropTypes.func.isRequired,
  currentProgram: PropTypes.shape({
    addedBy: PropTypes.string,
    conceptList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired,
        programVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
      })
    ),
    name: PropTypes.string,
    programId: PropTypes.string,
    type: PropTypes.string,
  }),
  currentProgramIndex: PropTypes.number,
  setProgramIndex: PropTypes.func,
  completedSurvey: PropTypes.bool.isRequired,
  setVideoType: PropTypes.func.isRequired,
  prevHistory: PropTypes.arrayOf(
    PropTypes.shape({
      concept: conceptProps.isRequired,
      video: videoProps.isRequired,
      videoType: PropTypes.string,
    })
  ).isRequired,
  source: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sourceData: any,
};

Navigation.defaultProps = {
  currentProgram: null,
  currentProgramIndex: null,
  setProgramIndex: null,
  sourceData: null,
};
