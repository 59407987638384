import { useEffect, useState } from "react";

// Neo4j
import { gql, useMutation } from "@apollo/client";

// components
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import ReportButton from "../ReportButton/index";
import { conceptProps, videoProps } from "../DefaultValues";

// mutation for updating video title
const UPDATE_VIDEO_TITLE = gql`
  mutation UpdateVideoTitle($uid: ID!, $title: String!) {
    updateVideos(update: { title: $title }, where: { uid: $uid }) {
      videos {
        uid
        title
      }
    }
  }
`;

const UPDATE_VIDEO_CHANNEL = gql`
  mutation UpdateVideoTitle($uid: ID!, $channelName: String!) {
    updateVideos(update: { channelName: $channelName }, where: { uid: $uid }) {
      videos {
        uid
        channelName
      }
    }
  }
`;

function VideoAttribution(props) {
  const { currentVideo, currentConcept } = props;
  const [currentVideoTitle, setCurrentVideoTitle] = useState();
  const [currentVideoChannel, setCurrentVideoChannel] = useState();

  // eslint-disable-next-line no-unused-vars
  const [updateVideoTitle] = useMutation(UPDATE_VIDEO_TITLE);
  const [updateVideoChannel] = useMutation(UPDATE_VIDEO_CHANNEL);

  useEffect(() => {
    if (currentVideo) {
      fetch(
        `https://noembed.com/embed?dataType=json&url=https://www.youtube.com/watch?v=${currentVideo.embedId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setCurrentVideoTitle(data.title);
          setCurrentVideoChannel(data.author_name);
          if (data.title !== currentVideo.title) {
            updateVideoTitle({
              variables: {
                uid: currentVideo.uid,
                title: data.title,
              },
            });
          }
          if (data.author_name !== currentVideo.channelName) {
            updateVideoChannel({
              variables: {
                uid: currentVideo.uid,
                channelName: data.author_name,
              },
            });
          }
        });
    }
  }, [currentVideo]);

  return (
    <MDBox display="flex" pb={1} mt={-0.5} justifyContent="space-between">
      <MDBox display="flex" sx={{ overflow: "auto" }} width="auto" maxHeight="4rem">
        <MDTypography display="inline" variant="h5" mr={1}>
          Title:
        </MDTypography>
        <MDTypography display="inline" mr={6} variant="h5" fontWeight="regular">
          {currentVideoTitle || currentVideo.title}
        </MDTypography>
        <MDTypography display="inline" mr={1} variant="h5">
          Channel:
        </MDTypography>
        <MDTypography display="inline" variant="h5" fontWeight="regular">
          {currentVideoChannel || currentVideo.channelName}
        </MDTypography>
      </MDBox>
      <MDBox display="inline" sx={{ whiteSpace: "nowrap" }}>
        <ReportButton currentVideo={currentVideo} currentConcept={currentConcept} />
      </MDBox>
    </MDBox>
  );
}

export default VideoAttribution;

VideoAttribution.propTypes = {
  currentVideo: videoProps.isRequired,
  currentConcept: conceptProps.isRequired,
};
