import { Card } from "@mui/material"; // Grid
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import { useQuery, gql } from "@apollo/client";
import DefaultCounterCard from "./DefaultCounterCard";

function ConceptsAdded() {
  const COUNT_CONCEPTS = gql`
    query countConcepts {
      conceptsAggregate {
        count
      }
    }
  `;
  const COUNT_VIDEOS = gql`
    query countVideos {
      videosAggregate {
        count
      }
    }
  `;
  const COUNT_TESTS = gql`
    query countTests {
      testsAggregate {
        count
      }
    }
  `;

  const { loading: loadingConcepts, data: dataConcepts } = useQuery(COUNT_CONCEPTS);
  const { loading: loadingVideos, data: dataVideos } = useQuery(COUNT_VIDEOS);
  const { loading: loadingTests, data: dataTests } = useQuery(COUNT_TESTS);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox width="100%" height="100%" p={3} textAlign="center">
        <MDTypography variant="h3" fontWeight="medium">
          Content Added
        </MDTypography>
        <MDBox
          display="flex"
          height="90%"
          sx={{ flexDirection: "column" }}
          alignItems="center"
          justifyContent="space-evenly"
        >
          <DefaultCounterCard
            color="primary"
            count={!loadingConcepts && dataConcepts ? dataConcepts.conceptsAggregate.count : 0}
            // suffix="+"
            title="Concepts"
          />
          <DefaultCounterCard
            color="error"
            count={!loadingVideos && dataVideos ? dataVideos.videosAggregate.count : 0}
            title="Videos"
          />
          <DefaultCounterCard
            color="info"
            count={!loadingTests && dataTests ? dataTests.testsAggregate.count : 0}
            title="Checks"
          />
        </MDBox>
        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mx: "auto" }}
          mt={1}
        >
          {/* <Grid item xs={12}>
            <MDTypography variant="caption" fontWeight="regular" fontSize="large">
              Yesterday
            </MDTypography>
  </Grid>
          <Grid item xs={12}>
            <DefaultCounterCard
              color="primary"
              count={!loadingConcepts && dataConcepts ? dataConcepts.conceptsAggregate.count : 0}
              // suffix="+"
              title="Concepts"
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultCounterCard
              color="error"
              count={!loadingVideos && dataVideos ? dataVideos.videosAggregate.count : 0}
              title="Videos"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <MDTypography variant="caption" fontWeight="regular" fontSize="large">
              Last 7 Days
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              color="primary"
              count={!loadingConcepts && dataConcepts ? dataConcepts.conceptsAggregate.count : 0}
              // suffix="+"
              title="Concepts"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              color="error"
              count={!loadingVideos && dataVideos ? dataVideos.videosAggregate.count : 0}
              title="Videos"
            />
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="caption" fontWeight="regular" fontSize="large">
              Last 30 Days
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              color="primary"
              count={!loadingConcepts && dataConcepts ? dataConcepts.conceptsAggregate.count : 0}
              // suffix="+"
              title="Concepts"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              color="error"
              count={!loadingVideos && dataVideos ? dataVideos.videosAggregate.count : 0}
              title="Videos"
            />
  </Grid>
        </Grid> */}
      </MDBox>
    </Card>
  );
}

export default ConceptsAdded;
