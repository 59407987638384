/* eslint-disable no-unused-vars */
import MDBox from "components/MDBox/index";
import MDTypography from "components/MDTypography/index";
import ProgressBar from "@ramonak/react-progress-bar";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
import "./styles.css";

function LevelProgressBar(props) {
  const { progress, color, source } = props;
  return (
    <MDBox width="100%" display="flex" justifyContent="center">
      <MDBox width="70%" textAlign="center">
        <MDTypography color="info" variant="h1" mb={3}>
          {source === "review" || source === "quiz" ? capitalize(source) : "Check"} Progress
        </MDTypography>
        <ProgressBar completed={progress} bgColor={color === "info" ? "#1A73E8" : "#00ad43"} />
        {/* className="wrapper" */}
        {/* <MDTypography mt={3} fontSize={25}>
          Question {questionIndex}
  </MDTypography> */}
      </MDBox>
    </MDBox>
  );
}

export default LevelProgressBar;

LevelProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  // questionIndex: PropTypes.number.isRequired,
};
