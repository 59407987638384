/* eslint-disable react/prop-types */

// React
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Props
import PropTypes from "prop-types";
import {
  defaultVideo,
  videoProps,
  programConceptProps,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

// Mui
import { List, ListItem, ListItemAvatar, ListItemText, Dialog, DialogTitle } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CloseIcon from "@mui/icons-material/Close";
import { blue, grey } from "@mui/material/colors";

// Material Kit
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";

// Firebase
// import { rdb } from "firebase-config";
// import { ref, update } from "firebase/database";

// Neo4j
import { gql, useQuery } from "@apollo/client";

// Auth Context
// import { UserAuth } from "context/AuthContext";

const GET_METHOD_VIDEO = gql`
  query GetMethodVideo($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      block
      name
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
      interestVideos {
        uid
        embedId
        title
        channelName
      }
    }
  }
`;

function ReturnToVideosDialog(props) {
  const { onClose, selectedVideo, open, selectedConcept } = props;

  let videoOptions = defaultVideo;
  let concept = selectedConcept;

  const handleClose = () => {
    onClose(selectedConcept, selectedVideo);
  };

  const handleListItemClick = (video) => {
    onClose(concept, video);
  };

  if (open) {
    const { loading: loadingMethodVideos, data: dataMethodVideos } = useQuery(GET_METHOD_VIDEO, {
      variables: {
        uid: selectedConcept.uid,
      },
    });
    videoOptions =
      !loadingMethodVideos && dataMethodVideos
        ? dataMethodVideos.concepts[0].methodVideos
        : defaultVideo;
    concept = videoOptions !== defaultVideo ? dataMethodVideos.concepts[0] : selectedConcept;
  }

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>Select a Video:&nbsp;&nbsp;{selectedConcept.name}</DialogTitle>
      <List sx={{ pt: 0, px: 2, pb: 2 }}>
        {selectedConcept.programVideos
          ? selectedConcept.programVideos.map((video) => (
              <ListItem button onClick={() => handleListItemClick(video)} key={video.uid}>
                <ListItemAvatar>
                  <MDAvatar sx={{ color: blue[600] }}>
                    <PlayCircleIcon fontSize="large" />
                  </MDAvatar>
                </ListItemAvatar>
                <ListItemText primary={video.title} />
              </ListItem>
            ))
          : videoOptions.map((video) => (
              <ListItem button onClick={() => handleListItemClick(video)} key={video.uid}>
                <ListItemAvatar>
                  <MDAvatar sx={{ color: blue[600] }}>
                    <PlayCircleIcon fontSize="large" />
                  </MDAvatar>
                </ListItemAvatar>
                <ListItemText primary={video.title} />
              </ListItem>
            ))}

        <ListItem autoFocus button onClick={() => handleClose()}>
          <ListItemAvatar>
            <MDAvatar sx={{ color: grey[600] }}>
              <CloseIcon fontSize="medium" />
            </MDAvatar>
          </ListItemAvatar>
          <ListItemText primary="Cancel" />
        </ListItem>
      </List>
    </Dialog>
  );
}

export default function ReturnToVideos({
  selectedConcept,
  selectedProgram,
  currentProgramIndex,
  source,
  completedQuiz,
}) {
  const navigate = useNavigate();
  // const { user } = UserAuth();
  const [open, setOpen] = useState(false);
  const [selectedVideo, setVideo] = useState(defaultVideo[0]);
  const fullConcept =
    selectedProgram && selectedProgram.type === "Video"
      ? {
          ...selectedConcept,
          programVideos: selectedProgram.conceptList[currentProgramIndex].programVideos,
        }
      : selectedConcept;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (concept, video) => {
    setOpen(false);
    setVideo(video);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (source === "program") {
      navigate("/studio/viewer", {
        state: {
          selectedVideo,
          selectedConcept,
          selectedProgram,
          currentProgramIndex,
          videoType: "method",
          source: "program",
        },
      });
    } else {
      navigate("/studio/viewer", {
        state: {
          selectedConcept,
          selectedVideo,
          videoType: "method",
          source,
        },
      });
    }
  }, [selectedVideo]);

  return (
    <MDBox>
      {source === "progress" && (
        <MDButton
          variant="gradient"
          color="primary"
          fontWeight="medium"
          sx={{ mr: 2 }}
          onClick={() => {
            navigate("/studio/my-progress/learning-progress");
          }}
        >
          Return to Progress
        </MDButton>
      )}
      <MDButton variant="gradient" color="info" fontWeight="medium" onClick={handleClickOpen}>
        {completedQuiz ? "Go To Videos" : "Select Video"}
      </MDButton>
      <ReturnToVideosDialog
        selectedVideo={selectedVideo}
        open={open}
        onClose={handleClose}
        selectedConcept={fullConcept}
        selectedProgram={selectedProgram}
      />
    </MDBox>
  );
}

ReturnToVideosDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedVideo: videoProps.isRequired,
  selectedConcept: PropTypes.shape({
    block: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    person: PropTypes.bool,
    place: PropTypes.bool,
    event: PropTypes.bool,
    methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }).isRequired,
};

ReturnToVideos.propTypes = {
  selectedConcept: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    block: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    interestVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    programConcepts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    review: PropTypes.bool,
  }).isRequired,
  selectedProgram: PropTypes.shape({
    programId: PropTypes.string.isRequired,
    conceptList: PropTypes.arrayOf(programConceptProps),
  }),
  currentProgramIndex: PropTypes.number,
  completedQuiz: PropTypes.bool.isRequired,
};

ReturnToVideos.defaultProps = {
  selectedProgram: null,
  currentProgramIndex: null,
};
