/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function TheIdea() {
  return (
    <MDBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MDTypography
            component="h6"
            variant="button"
            textTransform="uppercase"
            fontWeight="bold"
            color="info"
          >
            The Idea
          </MDTypography>
          <MDTypography variant="h3" mb={3}>
            Customized learning crowdsourced from internet education resources by experts around the
            world.
          </MDTypography>
          <MDTypography variant="body2">
            Content creators and other experts are producing huge quantities of high-quality
            educational materials every day. At EDYou we have created a learning studio and
            crowdsourcing software to index these materials into a interconnected map of knowledge
            so learners can access these resources in a structured network that takes the guesswork
            out of learning progression. This approach can result in thousands of resources for each
            concept, and we are using the newest data science techniques, databases, and moderator
            systems to recommend the best resource for you.
            <br /> <br />
            In designing our system, we went one step further and incorporated key values we believe
            have been lost in formal education. While schools and universities continue to serve an
            important purpose in our society, we believe these institutions have embraced practices
            that detract from personal growth and attainment. In particular, the focus on conformity
            mechanisms designed to compare and stratify learners, such as grades and standardized
            curriculums, are unsuited to driving long-term personal success in a 21st century market
            defined by a need for unique strengths and creativity.
            <br /> <br />
            To distill our ideas, we have identified ten principles for <b>MEANINGFUL</b> learning
            which guide our design and long-term aspirations for EDYou:
          </MDTypography>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default TheIdea;
