/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useRef, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Autocomplete, Icon, Stack, Alert } from "@mui/material";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSocialButton from "components/MDSocialButton";
import MDSnackbar from "components/MDSnackbar";

// Images
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import TikTokIcon from "assets/theme/components/tiktok";

// Auth Context
// import { UserAuth } from "context/AuthContext";

// Firebase
import { db } from "firebase-config";
import { collection, addDoc } from "firebase/firestore";

function ContactForm() {
  const [roleV, setRole] = useState();
  const [reasonV, setReason] = useState();
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const [successSB, setSuccessSB] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [key1, setKey1] = useState("Key 1");
  const [key2, setKey2] = useState("Key 2");

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success!"
      content="Your message has been sent. Someone will get back to you shortly!"
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const fullName = nameRef.current.value;
      const email = emailRef.current.value;
      const message = messageRef.current.value;
      const mail = {
        from: "contact-us@edyou.org",
        replyTo: email,
        to: "contact-us@edyou.org",
        message: {
          subject: reasonV,
          html: `<p>From: ${fullName} (${roleV})</p>
           <p>${message}</p>`,
        },
      };
      await addDoc(collection(db, "mail"), mail);
      openSuccessSB();
      nameRef.current.value = "";
      emailRef.current.value = "";
      messageRef.current.value = "";
      if (key1 === "Key 1") {
        setKey1("Not Key 1");
      } else {
        setKey1("Key 1");
      }
      if (key2 === "Key 2") {
        setKey2("Not Key 2");
      } else {
        setKey2("Key 2");
      }
    } catch (event) {
      setError(event);
    }
    setLoading(false);
  };

  return (
    <MDBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item px={6}>
          <MDBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7}>
                <MDBox p={2}>
                  <MDBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MDTypography variant="h2" mb={1}>
                      Contact Form
                    </MDTypography>
                    <MDTypography variant="body1" color="text">
                      Say Hi! We&apos;d love to talk with you.
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    pt={0.5}
                    pb={3}
                    px={3}
                    component="form"
                    role="form"
                    onSubmit={handleSubmit}
                  >
                    <Stack spacing={3}>
                      <MDInput
                        variant="outlined"
                        label="My name is"
                        placeholder="Full Name"
                        required
                        inputRef={nameRef}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                      <MDInput
                        variant="outlined"
                        type="email"
                        label="My email is"
                        placeholder="someone@example.com"
                        required
                        inputRef={emailRef}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                      <Autocomplete
                        key={key1}
                        options={[
                          "Student",
                          "Parent",
                          "Educator",
                          "Professional",
                          "Content Creator",
                          "Other",
                        ]}
                        fullWidth
                        onChange={(event, role) => {
                          setRole(role);
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            placeholder="- select -"
                            required
                            label="My role"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                      <Autocomplete
                        key={key2}
                        options={[
                          "Want to Work With Us",
                          "Request Moderator Status",
                          "Ask a Question",
                          "Support and Technical Issues",
                          "Other",
                        ]}
                        fullWidth
                        onChange={(event, reason) => {
                          setReason(reason);
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            placeholder="- select -"
                            required
                            label="Reason"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                      <MDInput
                        variant="outlined"
                        label="Your message"
                        placeholder="I want to say that..."
                        required
                        inputRef={messageRef}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        multiline
                        rows={6}
                      />
                      {error && <Alert color="error">{error.message}</Alert>}
                      <MDBox textAlign="right">
                        <MDButton
                          disabled={loading}
                          variant="gradient"
                          color="primary"
                          type="submit"
                        >
                          Send Message
                        </MDButton>
                      </MDBox>
                      {renderSuccessSB}
                    </Stack>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={5} position="relative" px={0}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  bgColor="primary"
                >
                  <MDBox py={6} px={5} pr={{ xs: 6, sm: 11 }} my="auto">
                    <MDTypography variant="h3" color="white" mb={1}>
                      Contact Information
                    </MDTypography>
                    <MDTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Fill out the form and our Team will get back to you as soon as we can.
                    </MDTypography>
                    <MDBox display="flex" color="white" p={1}>
                      <Icon>phone</Icon>
                      <MDTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        TBD
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" color="white" p={1}>
                      <Icon>email</Icon>
                      <MDTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        contact-us@edyou.org
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" color="white" p={1}>
                      <Icon>place</Icon>
                      <MDTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Los Angeles, CA, USA
                      </MDTypography>
                    </MDBox>
                    <Stack mt={5} direction="row" spacing={1.5}>
                      <MDSocialButton color="facebook" iconOnly circular>
                        <FacebookIcon />
                      </MDSocialButton>
                      <MDSocialButton
                        color="twitter"
                        href="https://www.facebook.com/profile.php?id=100093896413969"
                        iconOnly
                        circular
                      >
                        <TwitterIcon />
                      </MDSocialButton>
                      <MDSocialButton
                        color="instagram"
                        href="https://www.instagram.com/edyou_for_all"
                        iconOnly
                        circular
                      >
                        <InstagramIcon />
                      </MDSocialButton>
                      <MDSocialButton
                        color="tiktok"
                        href="https://www.tiktok.com/@edyou_for_all"
                        iconOnly
                        circular
                      >
                        <TikTokIcon />
                      </MDSocialButton>
                      <MDSocialButton
                        color="pinterest"
                        href="https://www.pinterest.com/horton8899/"
                        iconOnly
                        circular
                      >
                        <PinterestIcon />
                      </MDSocialButton>
                    </Stack>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default ContactForm;
