/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

// React
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Props
import PropTypes from "prop-types";

// Mui
import { List, ListItem, ListItemAvatar, ListItemText, Dialog, DialogTitle } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";

// Material Kit
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography/index";

// Firebase
// import { rdb } from "firebase-config";
// import { ref, update } from "firebase/database";

// Neo4j
import { gql, useQuery } from "@apollo/client";
import { countBy, random } from "lodash";

// Auth Context
// import { UserAuth } from "context/AuthContext";

const GET_TESTING_RESOURCE = gql`
  query GetTestingResource($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      name
      block
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
      testingResources {
        uid
        difficulty
        text
        question
        picture
        answerChoices
        correctAnswer
        testedConcepts {
          uid
          name
        }
      }
    }
  }
`;

function SelectDifficultyDialog(props) {
  const { onClose, open, selectedConcept } = props;
  let availableDifficulties;
  let loading;
  let data;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (difficulty, concept) => {
    onClose(difficulty, concept);
  };

  if (open) {
    const { loading: loadingTests, data: dataTests } = useQuery(GET_TESTING_RESOURCE, {
      variables: {
        uid: selectedConcept,
      },
    });
    loading = loadingTests;
    data = dataTests;
    const tests = loadingTests || !dataTests ? [] : dataTests.concepts[0].testingResources;
    const testCountBy = countBy(tests, "difficulty");
    availableDifficulties = Object.keys(testCountBy);
  }

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>Select a Testing Difficulty</DialogTitle>{" "}
      {loading || !data ? (
        <MDTypography>Loading Options ...</MDTypography>
      ) : availableDifficulties.length > 0 ? (
        <List sx={{ pt: 0, px: 2, pb: 2 }}>
          {availableDifficulties.map((difficulty) => (
            <ListItem
              button
              onClick={() => handleListItemClick(difficulty, data.concepts[0])}
              key={difficulty}
            >
              <ListItemAvatar>
                <MDAvatar sx={{ color: grey[900] }}>
                  <ArrowRightIcon fontSize="large" />
                </MDAvatar>
              </ListItemAvatar>
              <ListItemText primary={difficulty} />
            </ListItem>
          ))}

          <ListItem autoFocus button onClick={() => handleClose()}>
            <ListItemAvatar>
              <MDAvatar sx={{ color: grey[600] }}>
                <CloseIcon fontSize="medium" />
              </MDAvatar>
            </ListItemAvatar>
            <ListItemText primary="Cancel" />
          </ListItem>
        </List>
      ) : (
        <MDBox width="100%" textAlign="center" p={3}>
          <MDTypography mb={2}>
            Sorry. There are no testing options for this concept at this time.
          </MDTypography>
          <MDButton variant="gradient" color="info" onClick={() => handleClose()}>
            Close
          </MDButton>
        </MDBox>
      )}
    </Dialog>
  );
}

export default function SelectDifficulty({ selectedConcept }) {
  const navigate = useNavigate();
  // const { user } = UserAuth();
  const [open, setOpen] = useState(false);
  // const [selectedDifficulty, setDifficulty] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (difficulty, concept) => {
    if (difficulty && concept) {
      navigate("/studio/test-viewer", {
        state: {
          quiz: {
            programId: concept.uid,
            conceptList: [concept],
          },
          currentIndex: 0,
          currentCorrect: 0,
          currentConcept: concept,
          testingResources: concept.testingResources,
          currentTest: concept.testingResources[random(concept.testingResources.length - 1)],
          source: "progress",
          sourceData: {},
        },
      });
    }
    setOpen(false);
  };

  // const isFirstRender = useRef(true);

  /* useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      // eslint-disable-next-line no-useless-return
      return;
    }

    /* navigate("/studio/test-viewer", {
      state: {
        selectedVideo,
        selectedConcept,
        videoType: "method",
        source: "quiz",
        sourceData: {
          quiz: selectedQuiz,
          currentConcept: selectedConcept,
          currentCorrect,
          currentTest: nextTest,
          currentTestPicture: nextTestPicture,
          remainingTestingResources,
          testingResources,
          currentIndex: currentQuizIndex,
        },
      },
    });
  }, [selectedDifficulty]); */

  return (
    <MDBox>
      <MDButton onClick={handleClickOpen}>
        <EastIcon color="dark" fontSize="large" />
      </MDButton>
      <SelectDifficultyDialog open={open} onClose={handleClose} selectedConcept={selectedConcept} />
    </MDBox>
  );
}

SelectDifficultyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedConcept: PropTypes.string.isRequired,
};

SelectDifficulty.propTypes = {
  selectedConcept: PropTypes.string.isRequired,
};
