/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useRef, useEffect } from "react";
import PropTypes, { objectOf } from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import { blue, grey } from "@mui/material/colors";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import { gql, useQuery } from "@apollo/client";
import { rdb } from "firebase-config";
import { ref, update, child, getDatabase, get } from "firebase/database";
import MDTypography from "components/MDTypography";
import {
  conceptProps,
  videoProps,
  noVideos,
  defaultVideo,
  defaultConcept,
  programConceptProps,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxWidth: "35rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 3,
};

const GET_METHOD_VIDEO = gql`
  query GetMethodVideo($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      name
      block
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
    }
  }
`;

function NextProgramModal(props) {
  const {
    onClose,
    open,
    currentVideo,
    currentConcept,
    currentProgram,
    currentProgramIndex,
    userId,
  } = props;

  let videoOptions = defaultVideo;
  let concept = defaultConcept;
  let currentIndex = currentProgramIndex;
  // let programProgress;

  let newConcept =
    currentIndex + 1 !== currentProgram.conceptList.length
      ? currentProgram.conceptList[currentIndex + 1]
      : currentConcept;

  const handleClose = () => {
    onClose(currentConcept, currentVideo, currentIndex);
  };

  const handleListItemClick = (video) => {
    if (video.uid === "Default Video" || video.uid === "No Videos") {
      return;
    }
    onClose(concept, video, currentIndex + 1);
  };

  // Need to hide this in a useEffect or if-Statement
  if (open) {
    const {
      loading: loadingMethodVideos,
      data: dataMethodVideos,
      // refetch,
    } = useQuery(GET_METHOD_VIDEO, {
      variables: {
        uid: newConcept.uid,
      },
    });
    const videoData =
      !loadingMethodVideos && dataMethodVideos
        ? dataMethodVideos.concepts[0].methodVideos.filter(
            (video) => video.uid !== currentVideo.uid
          )
        : defaultVideo;
    videoOptions = videoData.length !== 0 ? videoData : noVideos;
    concept = videoOptions !== defaultVideo ? dataMethodVideos.concepts[0] : currentConcept;
  }

  /* useEffect(() => {
    if (open) {
      const checkCompletion = async () => {
        const rtdb = ref(getDatabase());
        await get(child(rtdb, `programHistory/${userId}/${currentProgram.programId}`))
          .then((snapshot) => {
            if (snapshot.exists()) {
              console.log(snapshot.val());
              programProgress = snapshot.val();
            } else {
              console.log("No data available");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
      checkCompletion();
    }
  }, [open]);
  const handleSkip = () => {
    currentIndex += currentIndex;
    refetch();
  }; */

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MDBox sx={style}>
          <MDBox pb={1}>
            <MDTypography variant="h5">Next Concept:&nbsp;&nbsp;{newConcept.name}</MDTypography>
            <MDTypography mt={1} variant="h5" fontWeight="regular">
              Select a Video
            </MDTypography>
          </MDBox>
          <MDBox>
            <List>
              {videoOptions.map((video) => (
                <ListItem button onClick={() => handleListItemClick(video)} key={video.uid}>
                  <ListItemAvatar>
                    <MDAvatar sx={{ color: blue[600] }}>
                      <PlayCircleIcon fontSize="large" />
                    </MDAvatar>
                  </ListItemAvatar>
                  <ListItemText primary={video.title} />
                </ListItem>
              ))}
              {/* programProgress && programProgress[newConcept.uid].completed && (
                <ListItem autoFocus button onClick={() => handleSkip()}>
                  <ListItemAvatar>
                    <MDAvatar sx={{ color: grey[600] }}>
                      <RedoIcon fontSize="medium" />
                    </MDAvatar>
                  </ListItemAvatar>
                  <ListItemText primary="Skip" />
                </ListItem>
              ) */}
              <ListItem autoFocus button onClick={() => handleClose()}>
                <ListItemAvatar>
                  <MDAvatar sx={{ color: grey[600] }}>
                    <CloseIcon fontSize="medium" />
                  </MDAvatar>
                </ListItemAvatar>
                <ListItemText primary="Cancel" />
              </ListItem>
            </List>
          </MDBox>
        </MDBox>
      </Fade>
    </Modal>
  );
}

export default function NextProgram({
  currentVideo,
  setCurrentVideo,
  setVideoType,
  currentConcept,
  setCurrentConcept,
  currentProgram,
  currentProgramIndex,
  setProgramIndex,
  userId,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = (concept, video, index) => {
    setOpen(false);
    setCurrentConcept(concept);
    if (video.uid !== currentVideo.uid) {
      setVideoType("method");
    }
    setCurrentVideo(video);
    setProgramIndex(index);
    update(ref(rdb, `programHistory/${userId}/${currentProgram.programId}`), {
      index,
    });
  };

  return (
    <MDBox mb={-1} ml={2}>
      <MDButton
        fullWidth
        disabled={currentProgram.conceptList.length === currentProgramIndex + 1}
        variant="text"
        size="large"
        color="info"
        onClick={handleOpen}
      >
        Next
        <NavigateNextIcon />
      </MDButton>
      <NextProgramModal
        open={open}
        onClose={handleClose}
        currentVideo={currentVideo}
        currentConcept={currentConcept}
        currentProgramIndex={currentProgramIndex}
        currentProgram={currentProgram}
        userId={userId}
      />
    </MDBox>
  );
}

NextProgramModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  currentVideo: videoProps.isRequired,
  currentConcept: conceptProps.isRequired,
  currentProgram: PropTypes.shape({
    addedBy: PropTypes.string,
    conceptList: PropTypes.arrayOf(programConceptProps),
    name: PropTypes.string,
    programId: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  currentProgramIndex: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
};

NextProgram.propTypes = {
  currentVideo: videoProps.isRequired,
  currentConcept: conceptProps.isRequired,
  setCurrentVideo: PropTypes.func.isRequired,
  setCurrentConcept: PropTypes.func.isRequired,
  setVideoType: PropTypes.func.isRequired,
  currentProgram: PropTypes.shape({
    addedBy: PropTypes.string,
    conceptList: PropTypes.arrayOf(programConceptProps),
    name: PropTypes.string,
    programId: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  currentProgramIndex: PropTypes.number.isRequired,
  setProgramIndex: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};
