/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import MathLiveElement from "./MathLiveElement";
import Image from "./Image";
import MultipleChoiceAnswers from "./MultipleChoice";

function Element(props) {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };

  switch (element.type) {
    case "image":
      return <Image {...props} />;
    case "quote":
      return (
        <blockquote
          style={{
            textAlign: style.textAlign,
            padding: "15px",
            background: "#eee",
            borderRadius: "5px",
            margin: "6px",
          }}
          {...attributes}
        >
          <span>&ldquo;</span>
          <em>{children}</em>
          <span>&rdquo;</span>
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={{ textAlign: style.textAlign, paddingInlineStart: 20 }} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "math-live":
      return <MathLiveElement {...props} />;
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={{ textAlign: style.textAlign, paddingInlineStart: 24 }} {...attributes}>
          {children}
        </ol>
      );
    case "answer-block":
      switch (element.answerData.answerType) {
        case "Multiple Choice": {
          return <MultipleChoiceAnswers {...props} answerData={element.answerData} />;
        }
        case "Validated Free Response (Math)":
          break;
        default:
          break;
      }
      break;
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
}

Element.defaultProps = {
  attributes: null,
};

Element.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
    mathText: PropTypes.string,
    answerData: PropTypes.shape({
      answers: PropTypes.arrayOf(PropTypes.string).isRequired,
      correctAnswer: PropTypes.string,
      answerType: PropTypes.string,
    }),
    align: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.any,
};

export default Element;
