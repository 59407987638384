/* eslint-disable react/prop-types */

// React
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Props
import PropTypes from "prop-types";
import {
  defaultVideo,
  videoProps,
  quizProps,
  testProps,
  conceptProps,
} from "layouts/LearningStudio/MediaViewer/components/DefaultValues";

// Mui
import { List, ListItem, ListItemAvatar, ListItemText, Dialog, DialogTitle } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CloseIcon from "@mui/icons-material/Close";
import { blue, grey } from "@mui/material/colors";

// Material Kit
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";

// Firebase
// import { rdb } from "firebase-config";
// import { ref, update } from "firebase/database";

// Neo4j
import { gql, useQuery } from "@apollo/client";

// Auth Context
// import { UserAuth } from "context/AuthContext";

const GET_METHOD_VIDEO = gql`
  query GetMethodVideo($uid: ID!) {
    concepts(where: { uid: $uid }) {
      uid
      name
      block
      person
      place
      event
      methodVideos {
        uid
        embedId
        title
        channelName
      }
      interestVideos {
        uid
        embedId
        title
        channelName
      }
    }
  }
`;

function SelectReviewVideoDialog(props) {
  const { onClose, selectedVideo, open, selectedConcept } = props;

  let videoOptions = defaultVideo;
  let concept = selectedConcept;

  const handleClose = () => {
    onClose(selectedConcept, selectedVideo);
  };

  const handleListItemClick = (video) => {
    onClose(concept, video);
  };

  if (open) {
    const { loading: loadingMethodVideos, data: dataMethodVideos } = useQuery(GET_METHOD_VIDEO, {
      variables: {
        uid: selectedConcept.uid,
      },
    });
    videoOptions =
      !loadingMethodVideos && dataMethodVideos
        ? dataMethodVideos.concepts[0].methodVideos
        : defaultVideo;
    concept = videoOptions !== defaultVideo ? dataMethodVideos.concepts[0] : selectedConcept;
  }

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>Select a Video:&nbsp;&nbsp;{selectedConcept.name}</DialogTitle>
      <List sx={{ pt: 0, px: 2, pb: 2 }}>
        {selectedConcept.programVideos
          ? selectedConcept.programVideos.map((video) => (
              <ListItem button onClick={() => handleListItemClick(video)} key={video.uid}>
                <ListItemAvatar>
                  <MDAvatar sx={{ color: blue[600] }}>
                    <PlayCircleIcon fontSize="large" />
                  </MDAvatar>
                </ListItemAvatar>
                <ListItemText primary={video.title} />
              </ListItem>
            ))
          : videoOptions.map((video) => (
              <ListItem button onClick={() => handleListItemClick(video)} key={video.uid}>
                <ListItemAvatar>
                  <MDAvatar sx={{ color: blue[600] }}>
                    <PlayCircleIcon fontSize="large" />
                  </MDAvatar>
                </ListItemAvatar>
                <ListItemText primary={video.title} />
              </ListItem>
            ))}

        <ListItem autoFocus button onClick={() => handleClose()}>
          <ListItemAvatar>
            <MDAvatar sx={{ color: grey[600] }}>
              <CloseIcon fontSize="medium" />
            </MDAvatar>
          </ListItemAvatar>
          <ListItemText primary="Cancel" />
        </ListItem>
      </List>
    </Dialog>
  );
}

export default function SelectReviewVideo({
  selectedConcept,
  selectedQuiz,
  currentQuizIndex,
  currentQuestionIndex,
  currentCorrect,
  currentConceptSubmitted,
  setCurrentConceptSubmitted,
  remainingTestingResources,
  testingResources,
  nextTest,
  nextTestPicture,
  source,
}) {
  const navigate = useNavigate();
  // const { user } = UserAuth();
  const [open, setOpen] = useState(false);
  const [selectedVideo, setVideo] = useState(defaultVideo[0]);
  const fullConcept =
    selectedQuiz && selectedQuiz.type === "Video"
      ? {
          ...selectedConcept,
          programVideos: selectedQuiz.conceptList[currentQuizIndex].programVideos,
        }
      : selectedConcept;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (concept, video) => {
    setOpen(false);
    setVideo(video);
  };

  const isFirstRender = useRef(true);

  // console.log(`modal:  ${currentConceptSubmitted}`);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    /* if (
      currentProgramIndex === selectedProgram.conceptList.length - 1 &&
      Object.keys(firstIncomplete).length !== 0
    ) {
      update(ref(rdb, `programHistory/${user.uid}/${selectedProgram.programId}`), {
        index: currentIndex,
      });
    } */
    setCurrentConceptSubmitted(currentConceptSubmitted + 1);
    navigate("/studio/viewer", {
      state: {
        selectedVideo,
        selectedConcept,
        videoType: "method",
        source,
        sourceData: {
          quiz: selectedQuiz,
          currentConcept: selectedConcept,
          currentConceptSubmitted: currentConceptSubmitted + 1,
          currentCorrect,
          currentQuestionIndex,
          currentTest: nextTest,
          currentTestPicture: nextTestPicture,
          remainingTestingResources,
          testingResources,
          currentIndex: currentQuizIndex,
        },
      },
    });
  }, [selectedVideo]);

  return (
    <MDBox>
      <MDButton variant="gradient" color="info" fontWeight="medium" onClick={handleClickOpen}>
        Select Video
      </MDButton>
      <SelectReviewVideoDialog
        selectedVideo={selectedVideo}
        open={open}
        onClose={handleClose}
        selectedConcept={fullConcept}
      />
    </MDBox>
  );
}

SelectReviewVideoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedVideo: videoProps.isRequired,
  selectedConcept: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    person: PropTypes.bool,
    place: PropTypes.bool,
    event: PropTypes.bool,
    methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }).isRequired,
};

SelectReviewVideo.propTypes = {
  selectedConcept: conceptProps.isRequired,
  selectedQuiz: quizProps.isRequired,
  currentQuizIndex: PropTypes.number.isRequired,
  currentConceptSubmitted: PropTypes.number.isRequired,
  setCurrentConceptSubmitted: PropTypes.func.isRequired,
  currentQuestionIndex: PropTypes.number.isRequired,
  currentCorrect: PropTypes.number.isRequired,
  testingResources: PropTypes.arrayOf(testProps).isRequired,
  nextTest: testProps.isRequired,
  nextTestPicture: PropTypes.string.isRequired,
  remainingTestingResources: PropTypes.arrayOf(testProps).isRequired,
  source: PropTypes.string.isRequired,
};
