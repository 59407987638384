/* eslint-disable no-unused-vars */
import { uniqueId } from "lodash";
import { Grid } from "@mui/material";
import { Text } from "slate";
import MDBox from "components/MDBox/index";
import PropTypes from "prop-types";
import Image from "./Image";
import MathLiveElement from "./MathLiveElement";
import MultipleChoice from "./MultipleChoice";

function Element(props) {
  const { element, setAnswer, answer } = props;
  const style = { textAlign: element.align };
  // const text = element.children[0].text === "" ? <br /> : element.children[0].text;

  const renderText = (n) => {
    let { text } = n;
    if (n.bold) {
      text = <strong key={uniqueId("bold-")}>{text}</strong>;
    }
    if (n.code) {
      text = <code key={uniqueId("code-")}>{text}</code>;
    }

    if (n.italic) {
      text = <em key={uniqueId("italic-")}>{text}</em>;
    }

    if (n.underline) {
      text = <u key={uniqueId("uline-")}>{text}</u>;
    }
    if (text === "") {
      return <br key={uniqueId("break-")} />;
    }
    return text;
  };

  const children = element.children.map((n) => {
    if (Text.isText(n)) {
      return renderText(n);
    }
    return n;
  });

  switch (element.type) {
    case "image":
      return <Image element={element} />; // could add children to add the line breaks
    case "quote":
      return (
        <blockquote
          style={{
            textAlign: style.textAlign,
            padding: "15px",
            background: "#eee",
            borderRadius: "5px",
            margin: "6px",
          }}
        >
          <span>&ldquo;</span>
          <em>{children}</em>
          <span>&rdquo;</span>
        </blockquote>
      );
    case "bulleted-list":
      return <ul style={{ textAlign: style.textAlign, paddingInlineStart: 20 }}>{children}</ul>;
    case "heading-one":
      return <h1 style={style}>{children}</h1>;
    case "heading-two":
      return <h2 style={style}>{children}</h2>;
    case "math-live":
      return <MathLiveElement {...props}>{children}</MathLiveElement>;
    case "answer-block":
      switch (element.answerData.answerType) {
        case "Multiple Choice": {
          return (
            <MultipleChoice
              {...props}
              answerData={element.answerData}
              setAnswer={setAnswer}
              answer={answer}
            />
          );
        }
        case "Validated Free Response (Math)":
          break;
        default:
          break;
      }
      break;
    case "list-item":
      return <li style={style}>{children}</li>;
    case "numbered-list":
      return <ol style={{ textAlign: style.textAlign, paddingInlineStart: 24 }}>{children}</ol>;
    default:
      return <p style={style}>{children}</p>;
  }
}

Element.defaultProps = {
  answer: null,
};

Element.propTypes = {
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
    mathText: PropTypes.string,
    answerData: PropTypes.shape({
      answers: PropTypes.arrayOf(PropTypes.string).isRequired,
      correctAnswer: PropTypes.string,
      answerType: PropTypes.string,
    }),
    children: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
      })
    ).isRequired,
    align: PropTypes.string,
  }).isRequired,
  answer: PropTypes.string,
  setAnswer: PropTypes.func.isRequired,
};

function Serializer({ content, answer, setAnswer, format }) {
  return (
    <Grid container item xs={12} md={format[0]}>
      <Grid container item xs={12} md={format[1]}>
        <MDBox width="100%" textAlign="center">
          {content.map((element) => (
            <Element
              key={uniqueId("element-")}
              element={element}
              answer={answer}
              setAnswer={setAnswer}
            />
          ))}
        </MDBox>
      </Grid>
    </Grid>
  );
}

Serializer.defaultProps = {
  answer: null,
};

Serializer.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        })
      ).isRequired,
      url: PropTypes.string,
      align: PropTypes.string,
    })
  ).isRequired,
  answer: PropTypes.string,
  setAnswer: PropTypes.func.isRequired,
  format: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

export default Serializer;
