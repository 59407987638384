import PropTypes from "prop-types";

const conceptProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  block: PropTypes.string.isRequired,
  person: PropTypes.bool,
  place: PropTypes.bool,
  event: PropTypes.bool,
  methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  interestVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  programVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
});

const programConceptProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  block: PropTypes.string.isRequired,
  person: PropTypes.bool,
  place: PropTypes.bool,
  event: PropTypes.bool,
  methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  interestVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  programVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
});

const videoProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  embedId: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  methodConcepts: PropTypes.arrayOf(conceptProps),
  interestConcepts: PropTypes.arrayOf(conceptProps),
  type: PropTypes.string,
});

const testProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  difficulty: PropTypes.string.isRequired,
  text: PropTypes.string,
  question: PropTypes.string,
  picture: PropTypes.bool,
  answerChoices: PropTypes.arrayOf(PropTypes.string.isRequired),
  correctAnswer: PropTypes.string,
  testedConcepts: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
});

const quizConceptProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  person: PropTypes.bool,
  place: PropTypes.bool,
  event: PropTypes.bool,
  difficulty: PropTypes.string.isRequired,
  methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  review: PropTypes.bool,
});

const quizProps = PropTypes.shape({
  addedBy: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  targetAudience: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  conceptList: PropTypes.arrayOf(quizConceptProps),
  type: PropTypes.string.isRequired,
});

const defaultVideo = [
  {
    uid: "Default Video",
    embedId: "GGo3MVBFr1A",
    title: "Video options are loading ...",
    channelName: "Web Dev Simplified",
  },
];
const defaultConcept = [
  {
    uid: "Default Concept",
    name: "Concept options are loading ...",
    block: "Learning Skills",
    person: false,
    place: false,
    event: false,
    methodVideos: defaultVideo,
    interestVideos: [],
  },
];
const noVideos = [
  {
    uid: "No Videos",
    embedId: "GGo3MVBFr1A",
    title: "No videos. More content coming soon!",
    channelName: "Web Dev Simplified",
  },
];
const noConcepts = [
  {
    uid: "No Concepts",
    name: "No concepts. More content coming soon!",
    block: "Learning Skills",
    person: false,
    place: false,
    event: false,
    methodVideos: noVideos,
  },
];

const categories = [
  "Social Sciences",
  "Health & Wellbeing",
  "Natural Sciences",
  "Technology & Engineering",
  "Mathematics & Computer Science",
  "Business & Career Skills",
  "Learning Skills",
  "Life Skills",
  "Communicative Arts",
  "Hobbies",
  "Fine Arts",
  "Humanities",
];

const targetAudience = ["K-2", "Grade 3-8", "General", "55+"];
const difficulties = [
  "K-2",
  "Grade 3-5",
  "Intro",
  "Foundational",
  "Advanced",
  "Expert",
  "Challenge",
];

export {
  conceptProps,
  videoProps,
  defaultConcept,
  defaultVideo,
  difficulties,
  noVideos,
  noConcepts,
  quizProps,
  quizConceptProps,
  programConceptProps,
  categories,
  targetAudience,
  testProps,
};
