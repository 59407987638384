/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Alert from "@mui/material/Alert";

// React imports
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// Auth context
import { UserAuth } from "context/AuthContext";

// Authentication layout components
import CoverLayout from "layouts/CompanyWebsite/Authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/embarrased.jpg";

function ResetPassword() {
  const emailRef = useRef();
  const { resetPassword } = UserAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const triggerResetEmail = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      const email = emailRef.current.value;
      await resetPassword(email);
      navigate("/log-in");
    } catch (event) {
      setError(event.message);
    }
    setLoading(false);
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <MDBox
        borderRadius="lg"
        bgColor="white"
        coloredShadow="success"
        mx={-1}
        mt={-3}
        py={2}
        mb={1}
        textAlign="center"
      >
        <MDBox mt={2} mb={1} justifyContent="center" textAlign="center">
          <MDTypography variant="h4" fontWeight="bold">
            Reset Password
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          You will receive an e-mail shortly.
        </MDTypography>
        {error && (
          <Alert pt={2} severity="error">
            {error}
          </Alert>
        )}
        <MDBox pt={6} pb={4} px={3}>
          <MDBox component="form" onSubmit={triggerResetEmail} role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="E-mail"
                variant="outlined"
                inputRef={emailRef}
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                placeholder="someone@example.com"
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                type="submit"
                disabled={loading}
                variant="gradient"
                color="primary"
                fullWidth
              >
                send reset link
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </CoverLayout>
  );
}

export default ResetPassword;
