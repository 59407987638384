/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "layouts/CompanyWebsite/AboutEDYou/VerifiedEducators/components/SimpleInfoCard";

function Information() {
  return (
    <MDBox component="section" py={{ xs: 6, md: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="queue"
                title="Curate Content"
                description="Approve videos and their associated concept connections to ensure the quality of learning studio content"
              />
              <SimpleInfoCard
                icon="groups"
                title="Build Communities"
                description="Create your own creator and subject communities to build engagement, source additional materials, and post educational events"
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="movie_creation"
                title="Publish Content"
                description="Add your own content to our knowledge map. All our videos include an attribution card and are embedded so you keep the ad revenue"
              />
              <SimpleInfoCard
                icon="star_half"
                title="Be a Leading Expert"
                description="Our top tier moderators steer the direction of the concept map, forever influencing the learning paths of our users"
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Information;
