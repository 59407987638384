import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "context/AuthContext";
import { PropTypes } from "prop-types";
import { useStore } from "context/ZustandStore";

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ access, route, children }) {
  const { user } = UserAuth(); // roles

  const roles = useStore((state) => state.roles);

  let accessGranted = false;
  // eslint-disable-next-line consistent-return
  if (roles) {
    // ( roles.stripeRole === "subscriber" || userRoles.modRole === "admin") {
    access.forEach((role) => {
      /* if (role === "subscriber") {
        // console.log("subscriber route");
        accessGranted = true;
      } else */ if (roles[role]) {
        // console.log(`routed permitted ${role} amd ${modRole}`);
        accessGranted = true;
        // console.log(role);
      }
    });
  }

  if (!accessGranted) {
    /* if (roles.stripeRole === "customer" || userRoles.modRole === "admin") {
      if (route !== "/studio") {
        return <Navigate to="/studio" />;
      }
    } */
    if (user) {
      if (route !== "/studio") {
        return <Navigate to="/studio" />;
      }
    } else {
      return <Navigate to="/log-in" />;
    }
  }

  return children;
}

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  access: PropTypes.arrayOf(PropTypes.string).isRequired,
};
