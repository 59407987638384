/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import MDButton from "components/MDButton";

// MUI
import { Icon } from "@mui/material";

// Firestore && RDB
import { db, rdb } from "firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { child, get, ref } from "firebase/database";

// Auth Context
import { UserAuth } from "context/AuthContext";

// Lodash
import { pickBy, pull, findIndex } from "lodash";

// Selection Modal
import SelectVideo from "../SelectVideoModal/index";
import SelectProgramVideo from "../SelectProgramVideoModal";
import RemoveProgramDialog from "../RemoveProgramDialog/index";

export default function programsData() {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [activeProgramHistory, setActiveProgramHistory] = useState({});
  const [programData, setProgramData] = useState([]);

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  const defaultRows = [
    {
      title: (
        <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
          Loading Programs ...
        </MDTypography>
      ),
      conceptsNum: (
        <MDTypography variant="button" color="text" fontWeight="medium">
          ...
        </MDTypography>
      ),
      status: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          ...
        </MDTypography>
      ),
      completion: <Progress color="info" value={0} />,
      action: (
        <MDTypography variant="button" color="info" fontWeight="medium">
          START
        </MDTypography>
      ),
      list: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          SEE LIST
        </MDTypography>
      ),
      remove: (
        <MDButton iconOnly>
          <Icon size="medium">close</Icon>
        </MDButton>
      ),
    },
  ];

  const [rows, setRows] = useState(defaultRows);

  useEffect(() => {
    // declare the data fetching function
    const fetchProgramData = async () => {
      const database = ref(rdb);
      let programHistory;
      await get(child(database, `programHistory/${user.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            programHistory = snapshot.val();
            // setProgramHistory(programSnapshot);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      // Get Program Info from Firestore
      const querySnapshot = await getDocs(collection(db, "programs"));
      const programSnapshot = querySnapshot.docs.map((doc) => doc.data());

      // Filter Program Info for Active Programs
      const filteredProgramHistory = pickBy(programHistory, (program) => program.active);
      setProgramData(programSnapshot);
      setActiveProgramHistory(filteredProgramHistory);
    };
    fetchProgramData();
  }, []);

  useEffect(() => {
    const createRows = async () => {
      // filter Program Data
      const activePrograms = Object.keys(activeProgramHistory);
      const activeProgramData = programData.filter((program) =>
        activePrograms.includes(program.programId)
      );

      // Print the info on all programs
      const pRows = activeProgramData.map((program) => {
        // eslint-disable-next-line prefer-destructuring
        let firstIncomplete = {};
        let completionCheck = true;
        let completed = 0;
        const selectedProgramHistory = activeProgramHistory[program.programId];
        const currentProgramIndex = selectedProgramHistory.index;
        const programConcepts = pull(Object.keys(selectedProgramHistory), "index", "active");
        programConcepts.forEach((concept) => {
          if (selectedProgramHistory[concept].completed) {
            completed += 1;
          } else if (completionCheck) {
            firstIncomplete = program.conceptList[findIndex(program.conceptList, ["uid", concept])];
            completionCheck = false;
          }
        });
        const status =
          completed === programConcepts.length
            ? "Completed"
            : completed > 0
            ? "In Progress"
            : "Not Started";
        const completion = Math.floor((completed / programConcepts.length) * 100);
        const selectedConcept = program.conceptList[currentProgramIndex];
        const proto = {
          title: (
            <MDTypography variant="button" fontWeight="bold" lineHeight={2}>
              {program.name}
            </MDTypography>
          ),
          conceptsNum: (
            <MDTypography variant="button" color="inherit" fontWeight="medium">
              {program.conceptList.length}
            </MDTypography>
          ),
          status: (
            <MDTypography
              variant="caption"
              color={
                status === "Not Started" ? "text" : status === "Completed" ? "success" : "warning"
              }
              fontWeight="medium"
            >
              {status}
            </MDTypography>
          ),
          completion: <Progress color="info" value={completion} />,
          action:
            program.type === "Concept" ? (
              <SelectVideo
                selectedConcept={selectedConcept}
                selectedProgram={program}
                currentProgramIndex={currentProgramIndex}
                completed={completed}
                firstIncomplete={firstIncomplete}
              />
            ) : (
              <SelectProgramVideo
                selectedConcept={selectedConcept}
                selectedProgram={program}
                currentProgramIndex={currentProgramIndex}
                completed={completed}
                firstIncomplete={firstIncomplete}
              />
            ),
          list: (
            <MDButton
              variant="text"
              color="text"
              fontWeight="medium"
              onClick={() =>
                navigate("/studio/my-programs/program-list", {
                  state: {
                    selectedProgramHistory,
                    selectedProgram: program,
                  },
                })
              }
            >
              LIST
            </MDButton>
          ),
          remove: (
            <RemoveProgramDialog
              program={program}
              setActiveProgramHistory={setActiveProgramHistory}
            />
          ),
        };
        return proto;
      });
      setRows(pRows);
    };

    // call the function
    createRows()
      // make sure to catch any error
      .catch(console.error);
  }, [activeProgramHistory]);

  const columns = [
    { Header: "title", accessor: "title", width: "30%", align: "left" },
    { Header: "concepts", accessor: "conceptsNum", align: "center" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "completion", accessor: "completion", align: "center" },
    { Header: "action", accessor: "action", width: "5%", align: "center" },
    { Header: "see more", accessor: "list", width: "5%", align: "center" },
    { Header: "", accessor: "remove", width: "5%", align: "center" },
  ];

  return {
    columns,
    rows,
  };
}
