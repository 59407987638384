/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MDBox from "components/MDBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "layouts/CompanyWebsite/Home/components/DefaultCounterCard";

// GQL
import { useQuery, gql } from "@apollo/client";

function Counters() {
  const COUNT_CONCEPTS = gql`
    query countConcepts {
      conceptsAggregate {
        count
      }
    }
  `;
  const COUNT_VIDEOS = gql`
    query countVideos {
      videosAggregate {
        count
      }
    }
  `;

  const { loading: loadingConcepts, data: dataConcepts } = useQuery(COUNT_CONCEPTS);
  const { loading: loadingVideos, data: dataVideos } = useQuery(COUNT_VIDEOS);

  return (
    <MDBox component="section" py={{ xs: 6, md: 3 }}>
      <Container>
        <Grid container item xs={12} lg={11} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              color="primary"
              count={12}
              // suffix="+"
              title="Categories"
              description="From STEM skills to life skills"
            />
          </Grid>
          <Grid
            item
            justifyContent="space-between"
            xs={12}
            display={{ xs: "block", md: "flex" }}
            md={4}
          >
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
            <DefaultCounterCard
              color="primary"
              count={!loadingConcepts && dataConcepts ? dataConcepts.conceptsAggregate.count : 0}
              // suffix="+"
              title="Concepts"
              description="Explore and expand your learning"
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              color="primary"
              count={!loadingVideos && dataVideos ? dataVideos.videosAggregate.count : 0}
              title="Videos"
              description="Learn from the best educators"
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <DefaultCounterCard
              color="primary"
              count={0}
              title="Resources"
              description="Check your understanding, take it offline, learn by doing"
            />
  </Grid> */}
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Counters;
