/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Invoice page components
import BaseLayout from "layouts/LearningStudio/MyAccount/components/BaseLayout";

// Components
import Announcements from "./components/Announcements";
import NewlyAddedPrograms from "./components/NewlyAddedPrograms/index";
import ConceptsAdded from "./components/ConceptsAdded/index";
import CategoryCoverage from "./components/CategoryCoverage";
import FeedbackForm from "./components/FeedbackForm";
import QuizzesOfTheWeek from "./components/QuizzesOfTheWeek/index";

function StudioHome() {
  return (
    <BaseLayout stickyNavbar>
      <MDBox mb={{ xs: 4, md: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <QuizzesOfTheWeek />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Announcements />
          </Grid>
          <Grid container item xs={12} md={6} lg={7} spacing={3}>
            <Grid item xs={12}>
              <NewlyAddedPrograms />
            </Grid>
            <Grid item xs={12}>
              <FeedbackForm />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <ConceptsAdded />
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <CategoryCoverage />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default StudioHome;
