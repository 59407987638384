/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Page components
import BaseLayout from "layouts/LearningStudio/MyAccount/components/BaseLayout";
import logo from "assets/images/logo-tree.png";
import ContactForm from "./components/ContactForm";

function SupportLS() {
  return (
    <BaseLayout stickyNavbar>
      <MDBox mb={{ xs: 4, md: 8 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "100%", height: "80vh", minWidth: "35rem", minHeight: "35rem" }}>
              <MDBox p={3}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12}>
                    <MDBox component="img" src={logo} width="3.5rem" p={1} mb={1} />
                  </Grid>
                  <Grid item alignItems="center" justifyContent="center" xs={12}>
                    <MDBox width="100%" textAlign="center" mt={{ xs: -6, lg: -2 }}>
                      <MDTypography variant="h2" fontWeight="medium">
                        Contact Us
                      </MDTypography>
                      <MDTypography pt={2} px={8} variant="h5" fontWeight="regular">
                        Please fill out the form below to send us a message. If it is a support
                        request we will get back to you as soon as we can.
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>{" "}
              </MDBox>
              <MDBox width="100%" textAlign="center">
                <ContactForm />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default SupportLS;
