/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
// React
import { useState, useEffect } from "react";

// Auth Context
import { UserAuth } from "context/AuthContext";

// Material Dashboard Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

// MUI
import { Stack, ToggleButton, ToggleButtonGroup, Icon, Tooltip } from "@mui/material";

// Firestore
import { db } from "firebase-config";
import { setDoc, doc, serverTimestamp, collection, addDoc } from "firebase/firestore";

export default function VideoSurvey({ currentVideo }) {
  const [instruction, setInstruction] = useState();
  const [production, setProduction] = useState();
  const [relevance, setRelevance] = useState();
  const [overall, setOverall] = useState();
  const [difficulty, setDifficulty] = useState();
  const { user } = UserAuth();

  useEffect(() => {
    const submitSurvey = async () => {
      const submissionTimestamp = serverTimestamp();
      const reviewMetaData = {
        user: user.uid,
      };
      const surveyData = {
        video: currentVideo.uid,
        instruction,
        production,
        relevance,
        overall,
        difficulty,
        timestamp: submissionTimestamp,
      };
      const docRef = doc(db, "videoSurvey", currentVideo.uid);
      const colRef = collection(docRef, "reviews");
      await setDoc(doc(db, "videoSurvey", currentVideo.uid), reviewMetaData);
      await addDoc(colRef, surveyData);
    };

    if (instruction || production || relevance || difficulty || overall) {
      submitSurvey();
    }

    setInstruction();
    setProduction();
    setRelevance();
    setOverall();
    setDifficulty();
  }, [currentVideo]);

  return (
    <Stack direction="row">
      <MDBox sx={{ width: "20%" }} lineHeight={1} textAlign="center">
        <MDBox px={{ xs: 0, md: 1, lg: 2.9, xxl: 0 }}>
          <MDTypography variant="button" lineHeight={1} fontWeight="medium" fontSize="medium">
            Instruction Quality: <br />
          </MDTypography>
        </MDBox>
        <ToggleButtonGroup
          color={instruction === "bad" ? "error" : instruction === "good" ? "success" : "standard"}
          value={instruction}
          onChange={(event, newValue) => setInstruction(newValue)}
          exclusive
          sx={{ mt: 1 }}
        >
          <ToggleButton value="good">
            <Icon>thumb_up</Icon>
          </ToggleButton>
          <ToggleButton value="bad">
            <Icon>thumb_down</Icon>
          </ToggleButton>
        </ToggleButtonGroup>
      </MDBox>
      <MDBox sx={{ width: "20%" }} textAlign="center" lineHeight={1}>
        <MDBox px={{ xs: 0, md: 1, lg: 2.9, xxl: 0 }}>
          <MDTypography variant="button" fontWeight="medium" fontSize="medium" lineHeight={1}>
            Production Quality: <br />
          </MDTypography>
        </MDBox>
        <ToggleButtonGroup
          color={production === "bad" ? "error" : production === "good" ? "success" : "standard"}
          value={production}
          onChange={(event, newValue) => setProduction(newValue)}
          exclusive
          sx={{ mt: 1 }}
        >
          <ToggleButton value="good">
            <Icon>thumb_up</Icon>
          </ToggleButton>
          <ToggleButton value="bad">
            <Icon>thumb_down</Icon>
          </ToggleButton>
        </ToggleButtonGroup>
      </MDBox>
      <MDBox sx={{ width: "20%" }} textAlign="center" lineHeight={1}>
        <MDBox px={{ xs: 0, md: 1, lg: 2.9, xxl: 0 }}>
          <MDTypography variant="button" fontWeight="medium" fontSize="medium" lineHeight={1}>
            Content Relevance: <br />
          </MDTypography>
        </MDBox>
        <ToggleButtonGroup
          color={relevance === "bad" ? "error" : relevance === "good" ? "success" : "standard"}
          value={relevance}
          onChange={(event, newValue) => setRelevance(newValue)}
          exclusive
          sx={{ mt: 1 }}
        >
          <ToggleButton value="good">
            <Icon>thumb_up</Icon>
          </ToggleButton>
          <ToggleButton value="bad">
            <Icon>thumb_down</Icon>
          </ToggleButton>
        </ToggleButtonGroup>
      </MDBox>
      <MDBox sx={{ width: "20%" }} textAlign="center" lineHeight={1}>
        <MDBox px={{ xs: 0, md: 1, lg: 2.8, xxl: 0 }}>
          <MDTypography variant="button" fontWeight="medium" fontSize="medium" lineHeight={1}>
            Overall Enjoyment: <br />
          </MDTypography>
        </MDBox>
        <ToggleButtonGroup
          color={overall === "bad" ? "error" : overall === "good" ? "success" : "standard"}
          value={overall}
          onChange={(event, newValue) => setOverall(newValue)}
          exclusive
          sx={{ mt: 1 }}
        >
          <ToggleButton value="good">
            <Icon>thumb_up</Icon>
          </ToggleButton>
          <ToggleButton value="bad">
            <Icon>thumb_down</Icon>
          </ToggleButton>
        </ToggleButtonGroup>
      </MDBox>
      <MDBox sx={{ width: "20%" }} textAlign="center" lineHeight={1}>
        <MDBox px={{ xs: 0, md: 1, lg: 3, xxl: 0 }}>
          <MDTypography variant="button" fontWeight="medium" fontSize="medium" lineHeight={1}>
            Content Difficulty: <br />
          </MDTypography>
        </MDBox>
        <ToggleButtonGroup
          size="small"
          color={
            difficulty === "too easy" || difficulty === "too hard"
              ? "error"
              : difficulty === "just right"
              ? "success"
              : "standard"
          }
          value={difficulty}
          onChange={(event, newValue) => setDifficulty(newValue)}
          exclusive
          sx={{ mt: 1 }}
        >
          <ToggleButton value="too easy">
            <Tooltip title="Too Easy">
              <MDTypography my={-0.3} mx={0.15} variant="h5">
                &#x1F634;
              </MDTypography>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="just right">
            <Tooltip title="Just Right">
              <MDTypography my={-0.3} mx={0.15} variant="h5">
                &#x1F60A;
              </MDTypography>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="too hard">
            <Tooltip title="Too Hard">
              <MDTypography my={-0.3} mx={0.15} variant="h5">
                &#x1F630;
              </MDTypography>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </MDBox>
    </Stack>
  );
}

const conceptProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  person: PropTypes.bool.isRequired,
  place: PropTypes.bool.isRequired,
  event: PropTypes.bool.isRequired,
  methodVideos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
});

const videoProps = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  embedId: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  methodConcepts: PropTypes.arrayOf(conceptProps),
  interestConcepts: PropTypes.arrayOf(conceptProps),
});

VideoSurvey.propTypes = {
  currentVideo: videoProps.isRequired,
};
